import { Popover, Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { ACCESS_BACKEND_URL } from '../../configs';
import { useNavigate } from 'react-router-dom';
import './requestpopup.css';
import { nanoid } from 'nanoid';
import CachedIcon from '@mui/icons-material/Cached';
import { Listbox } from '@headlessui/react';
//import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
//import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FlexRow from '../layout/FlexRow';
import { useAxios } from '../../contexts/axios';
import { useAuth } from '../../contexts/auth';

export default function Requestpopup() {
    // console.log(manageremail)
    const {user} = useAuth();
    const nav = useNavigate();
    const axios = useAxios();
    let [isOpen, setIsOpen] = useState(false);
    const [data, setdata] = useState([]);
    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
        handleaccessrequest();
    }

    const formsubmit = (event) => {
        event.preventDefault();
        if (checked.length == 0) {
            alert('select atleast one tool');
            return;
        }
        if (description == '') {
            alert('Please provide description');
            return;
        }
        var temp = [];
        for (var i = 0; i < checked.length; i++) {
            temp.push(checked[i].data);
        }
        const requestOptions = {
            method: 'POST',
            url: `${ACCESS_BACKEND_URL}/api/pendingrequest`,
            data: {
                granted:false,
                date: new Date(),
                productandaccess: temp,
                description: description,
            },
        };
        axios(requestOptions).then(function (res) {
        });
        closeModal();
    };

    const [isLoading, setIsLoading] = useState(false);
    const refresh = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/tools/refresh`
        };
        axios(requestOptions).then(function (res) {
            setIsLoading(false);
        });
    };
    const handleaccessrequest = () => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/products`
        };
        axios(requestOptions).then(function (res) {
            setdata(res.data);
        });
    };
    //console.log(data);
    // State with list of all checked item
    const handleRemoveItem = (dataid) => {
        const newdata = [...checked];
        const index = checked.findIndex((data) => data.id === dataid);
        delete added[newdata[index].data];
        newdata.splice(index, 1);
        setChecked(newdata);
    };

    const handleCheck = (event, value) => {
        event.preventDefault();
        if (added[value] !== -1) {
            const val = {
                id: nanoid(),
                data: value,
            };
            setChecked([...checked, val]);
            added[value] = -1;
        } else {
            alert('Cannot insert same role more than once');
        }
    };

    const [checked, setChecked] = useState([]);
    const [checkList, setcheckList] = useState([]);
    const name = user.name;
    const email = user.email;
    const [productlist, setproductlist] = useState([]);
    const [product, setproduct] = useState({ name: 'select' });
    const [products, setproducts] = useState({ name: 'select' });
    const [description, setdescription] = useState('');
    const [added, setadded] = useState({});
    const handledescription = (e) => {
        setdescription(e.target.value);
    };

    useEffect(() => {
        var temp = [];
        for (var i = 0; i < data.length; i++) {
            var first = data[i].product;
            var second = data[i].projects.split(',');
            for (var j = 0; j < second.length - 1; j++) {
                temp.push({ name: first + ' ' + second[j] });
            }
        }
        setproductlist(temp);
    }, [data]);

    //console.log(productlist);
    useEffect(() => {
        if (product != products) {
            const requestOptions = {
                method: 'GET',
                url: `${ACCESS_BACKEND_URL}/api/accesstype?productandproject=${product?.name}`,
            };
            axios(requestOptions).then(function (res) {
                var temp = res.data.access.split(',');
                temp.splice(temp.length - 1, 1);
                setcheckList(temp);
                //setdata(res.data);
            });
            /*fetch(`${BACKEND_URL}/api/accesstype`, requestOptions).then((res) =>
                res.json().then((data) => {
                    if (data.message) {
                        window.localStorage.clear();
                        return nav('/');
                    }
                    var temp = data.access.split(',');
                    temp.splice(temp.length - 1, 1);
                    setcheckList(temp);
                })
            );*/
        }
    }, [product, products]);

    return (
        <>
            <div className="flex-inline">
                <FlexRow>
                    <FlexRow></FlexRow>
                    <Button
                        size="large"
                        endIcon={<SendIcon />}
                        variant="contained"
                        color="success"
                        style={{
                            fontWeight: 'bold',
                            //marginLeft: '83vw',
                            //marginTop: '1vw',
                        }}
                        onClick={openModal}
                    >
                        Request Access
                    </Button>
                    <Button
                        size="large"
                        endIcon={<CachedIcon />}
                        variant="contained"
                        color="success"
                        style={{
                            fontWeight: 'bold',
                            //marginLeft: '83vw',
                            //marginTop: '1vw',
                        }}
                        onClick={refresh}
                    >
                        Refresh Tools
                    </Button>
                </FlexRow>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-0"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-0"
                            >
                                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-center font-medium leading-6 text-gray-900"
                                    >
                                        Access Request Form
                                    </Dialog.Title>
                                    <div
                                        style={{
                                            width: 'auto',
                                            height: 'auto',
                                            minHeight: '640px',
                                        }}
                                        className="mt-2"
                                    >
                                        <div class="mb-6">
                                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Your name
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                value={name}
                                                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                                placeholder="Name"
                                                required="true"
                                            />
                                        </div>
                                        <div class="mb-6">
                                            <label
                                                for="email"
                                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                Your email
                                            </label>
                                            <input
                                                type="email"
                                                id="email"
                                                value={email}
                                                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                                                placeholder="name@apna.co"
                                                required="true"
                                            />
                                        </div>

                                        <div class="grid md:grid-cols-2 md:gap-6">
                                            <div class="mb-6">
                                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    Product name
                                                </label>
                                                <div>
                                                    <Listbox
                                                        value={product}
                                                        onChange={setproduct}
                                                    >
                                                        <div className="relative mt-1">
                                                            <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                                <span className="block truncate">
                                                                    {
                                                                        product.name
                                                                    }
                                                                </span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ArrowDropDownIcon />
                                                                </span>
                                                            </Listbox.Button>
                                                            <Transition
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {productlist.map(
                                                                        (
                                                                            person,
                                                                            personIdx
                                                                        ) => (
                                                                            <Listbox.Option
                                                                                key={
                                                                                    personIdx
                                                                                }
                                                                                className={({
                                                                                    active,
                                                                                }) =>
                                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                                        active
                                                                                            ? 'bg-amber-100 text-amber-900'
                                                                                            : 'text-gray-900'
                                                                                    }`
                                                                                }
                                                                                value={
                                                                                    person
                                                                                }
                                                                            >
                                                                                {({
                                                                                    selected,
                                                                                }) => (
                                                                                    <>
                                                                                        <span
                                                                                            className={`block truncate ${
                                                                                                selected
                                                                                                    ? 'font-medium'
                                                                                                    : 'font-normal'
                                                                                            }`}
                                                                                        >
                                                                                            {
                                                                                                person.name
                                                                                            }
                                                                                        </span>
                                                                                        {selected ? (
                                                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                                <ArrowDropDownIcon />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Listbox.Option>
                                                                        )
                                                                    )}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </Listbox>
                                                </div>
                                            </div>
                                            <div class="mb-6">
                                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                    Tool access type
                                                </label>

                                                <div className="w-full px-14">
                                                    <Popover className="relative">
                                                        {({ open }) => (
                                                            <>
                                                                <Popover.Button
                                                                    className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center -ml-[55px] rounded-md bg-blue-700 px-[100px] py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                                                >
                                                                    <span>
                                                                        Select
                                                                        tools
                                                                    </span>
                                                                    <ArrowDropDownIcon />
                                                                </Popover.Button>
                                                                <Transition
                                                                    as={
                                                                        Fragment
                                                                    }
                                                                    enter="transition ease-out duration-200"
                                                                    enterFrom="opacity-0 translate-y-1"
                                                                    enterTo="opacity-100 translate-y-0"
                                                                    leave="transition ease-in duration-150"
                                                                    leaveFrom="opacity-100 translate-y-0"
                                                                    leaveTo="opacity-0 translate-y-1"
                                                                >
                                                                    <Popover.Panel
                                                                        style={{
                                                                            zIndex: 100,
                                                                            width: '450px',
                                                                        }}
                                                                        className="absolute mt-1 bg-white left-[170px] z-10 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-2xl"
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                height: '380px',
                                                                            }}
                                                                            className="overflow-scroll rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                                                                        >
                                                                            <div className="relative grid gap-8 lg:grid-cols-1">
                                                                                {checkList.map(
                                                                                    function (
                                                                                        item,
                                                                                        i
                                                                                    ) {
                                                                                        return (
                                                                                            <button
                                                                                                onClick={(
                                                                                                    event
                                                                                                ) => {
                                                                                                    handleCheck(
                                                                                                        event,
                                                                                                        product.name +
                                                                                                            ' ' +
                                                                                                            item
                                                                                                    );
                                                                                                }}
                                                                                                key={
                                                                                                    i
                                                                                                }
                                                                                                className="-m-3 ml-1 glow flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                                                                            >
                                                                                                <div className="ml-4">
                                                                                                    <p className="text-sm text-black">
                                                                                                        {
                                                                                                            product.name
                                                                                                        }{' '}
                                                                                                        {
                                                                                                            item
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                            </button>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </Popover.Panel>
                                                                </Transition>
                                                            </>
                                                        )}
                                                    </Popover>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-6">
                                            <label
                                                for="description"
                                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                                            >
                                                Checked Items
                                            </label>
                                            <div
                                                style={{
                                                    width: '900px',
                                                    overflowX: 'scroll',
                                                    overflowY: 'inherit',
                                                }}
                                                id="description"
                                                rows="4"
                                                cols="10"
                                                class="flex-wrap inline-flex p-2.5 max-w-[1200px] text-sm text-gray-900 bg-gray-50 resize rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Leave a comment..."
                                                required="true"
                                            >
                                                {checked.map((item, i) => {
                                                    return (
                                                        <div
                                                            class="border ml-2 mt-2 p-1 rounded-md min-w-fit border-gray-600 inline-flex"
                                                            key={i}
                                                        >
                                                            {item.data}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="blue"
                                                                class="ml-1 mt-1 bi bi-x-circle-fill"
                                                                viewBox="0 0 16 16"
                                                                onClick={() => {
                                                                    handleRemoveItem(
                                                                        item.id
                                                                    );
                                                                }}
                                                            >
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                            </svg>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div class="mb-6">
                                            <label
                                                for="description"
                                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                                            >
                                                Description
                                            </label>
                                            <textarea
                                                id="description"
                                                rows="4"
                                                onChange={(e) => {
                                                    handledescription(e);
                                                }}
                                                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 resize rounded-md border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Leave a comment..."
                                                required="true"
                                            />
                                        </div>

                                        <button
                                            onClick={formsubmit}
                                            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                        >
                                            Send Request
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
