import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const ItAdminRoute = () => {
    const { isItadmin} = useAuth();
    if (isItadmin()) {
        return <Outlet />;
    } else {
        <Navigate to={'/home'} />;
    }
};

export default ItAdminRoute;
