import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
const distributions = ['EQUAL', 'PROPORTIONATE'];
const configTypes = ['QUERY_STRING', 'SERVICE_ACCOUNT'];
const boolans = [0, 1];
function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        if (object[key] === '') {
            return default_value;
        } else {
            return object[key];
        }
    } else {
        return default_value;
    }
}
const BigqueryAnalysisConfigTable = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [finalTableData, setFinalTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isAdmin } = useAuth();
    function call(data, method) {
        const requestOptions = {
            url: `${BACKEND_URL}/gcp/bigquery-analysis-configs`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/gcp/bigquery-analysis-configs` }).then(
            function (res) {
                setTableData(res.data);
            }
        );
    }, []);

    useEffect(() => {
        var finalData = [];
        for (var i = 0; i < tableData.length; i++) {
            var dict = {};
            dict['DISTRIBUTION_TYPE'] = tableData[i]['DISTRIBUTION_TYPE'];
            dict['CONFIG_TYPE'] = tableData[i]['CONFIG_TYPE'];
            dict['CONFIG_VALUE'] = tableData[i]['CONFIG_VALUE'];
            dict['Business'] = tableData[i]['TEAMS']['Business'];
            dict['Business & Strategy'] =
                tableData[i]['TEAMS']['Business & Strategy'];
            dict['CEO Office'] = tableData[i]['TEAMS']['CEO Office'];
            dict['Data & Analytics'] =
                tableData[i]['TEAMS']['Data & Analytics'];
            dict['Design'] = tableData[i]['TEAMS']['Design'];
            dict['Engineering'] = tableData[i]['TEAMS']['Engineering'];
            dict['Finance'] = tableData[i]['TEAMS']['Finance'];
            dict['Human Resources'] = tableData[i]['TEAMS']['Human Resources'];
            dict['Leadership'] = tableData[i]['TEAMS']['Leadership'];
            dict['Legal'] = tableData[i]['TEAMS']['Legal'];
            dict['Marketing'] = tableData[i]['TEAMS']['Marketing'];
            dict['Operations'] = tableData[i]['TEAMS']['Operations'];
            dict['Product'] = tableData[i]['TEAMS']['Product'];
            dict['Sales'] = tableData[i]['TEAMS']['Sales'];
            finalData.push(dict);
        }
        setFinalTableData(finalData);
    }, [tableData]);
    //console.log(tableData)
    const handleCreateNewRow = (values) => {
        //console.log(values);
        var val = {
            DISTRIBUTION_TYPE: get(
                values,
                'DISTRIBUTION_TYPE',
                'PROPORTIONATE'
            ),
            CONFIG_TYPE: get(values, 'CONFIG_TYPE', 'QUERY_STRING'),
            CONFIG_VALUE: get(values, 'CONFIG_TYPE', ''),
            TEAMS: {
                Business: get(values, 'Business', 0),
                'Business & Strategy': get(values, 'Business & Strategy', 0),
                'CEO Office': get(values, 'CEO Office', 0),
                'Data & Analytics': get(values, 'Data & Analytics', 0),
                Design: get(values, 'Design', 0),
                Engineering: get(values, 'Engineering', 0),
                Finance: get(values, 'Finance', 0),
                'Human Resources': get(values, 'Human Resources', 0),
                Leadership: get(values, 'Leadership', 0),
                Legal: get(values, 'Legal', 0),
                Marketing: get(values, 'Marketing', 0),
                Operations: get(values, 'Operations', 0),
                Product: get(values, 'Product', 0),
                Sales: get(values, 'Sales', 0),
            },
        };
        tableData.push(val);
        setTableData([...tableData]);
        call(val, 'POST');
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = {
                DISTRIBUTION_TYPE: values['DISTRIBUTION_TYPE'],
                CONFIG_TYPE: values['CONFIG_TYPE'],
                CONFIG_VALUE: values['CONFIG_VALUE'],
                TEAMS: {
                    Business: values['Business'],
                    'Business & Strategy': values['Business & Strategy'],
                    'CEO Office': values['CEO Office'],
                    'Data & Analytics': values['Data & Analytics'],
                    Design: values['Design'],
                    Engineering: values['Engineering'],
                    Finance: values['Finance'],
                    'Human Resources': values['Human Resources'],
                    Leadership: values['Leadership'],
                    Legal: values['Legal'],
                    Marketing: values['Marketing'],
                    Operations: values['Operations'],
                    Product: values['Product'],
                    Sales: values['Sales'],
                },
            };
            setTableData([...tableData]);
            exitEditingMode();

            call(
                {
                    DISTRIBUTION_TYPE: values['DISTRIBUTION_TYPE'],
                    CONFIG_TYPE: values['CONFIG_TYPE'],
                    CONFIG_VALUE: values['CONFIG_VALUE'],
                    TEAMS: {
                        Business: values['Business'],
                        'Business & Strategy': values['Business & Strategy'],
                        'CEO Office': values['CEO Office'],
                        'Data & Analytics': values['Data & Analytics'],
                        Design: values['Design'],
                        Engineering: values['Engineering'],
                        Finance: values['Finance'],
                        'Human Resources': values['Human Resources'],
                        Leadership: values['Leadership'],
                        Legal: values['Legal'],
                        Marketing: values['Marketing'],
                        Operations: values['Operations'],
                        Product: values['Product'],
                        Sales: values['Sales'],
                    },
                },
                'PUT'
            );
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        'CONFIG_VALUE'
                    )}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            call(
                {
                    DISTRIBUTION_TYPE: row.getValue('DISTRIBUTION_TYPE'),
                    CONFIG_TYPE: row.getValue('CONFIG_TYPE'),
                    CONFIG_VALUE: row.getValue('CONFIG_VALUE'),
                    TEAMS: {
                        Business: row.getValue('Business'),
                        'Business & Strategy': row.getValue(
                            'Business & Strategy'
                        ),
                        'CEO Office': row.getValue('CEO Office'),
                        'Data & Analytics': row.getValue('Data & Analytics'),
                        Design: row.getValue('Design'),
                        Engineering: row.getValue('Engineering'),
                        Finance: row.getValue('Finance'),
                        'Human Resources': row.getValue('Human Resources'),
                        Leadership: row.getValue('Leadership'),
                        Legal: row.getValue('Legal'),
                        Marketing: row.getValue('Marketing'),
                        Operations: row.getValue('Operations'),
                        Product: row.getValue('Product'),
                        Sales: row.getValue('Sales'),
                    },
                },
                'DELETE'
            );
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'DISTRIBUTION_TYPE',
                header: 'Distribution Type',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: distributions.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'CONFIG_TYPE',
                header: 'Configuration Type',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: configTypes.map((con) => (
                        <MenuItem key={con} value={con}>
                            {con}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'CONFIG_VALUE',
                header: 'Configuration Value',
                muiTableBodyCellEditTextFieldProps: { disabled: true },
            },
            {
                accessorKey: 'Business',
                header: 'Business',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Business & Strategy',
                header: 'Business & Strategy',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'CEO Office',
                header: 'CEO Office',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Data & Analytics',
                header: 'Data & Analytics',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Design',
                header: 'Design',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Engineering',
                header: 'Engineering',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Finance',
                header: 'Finance',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Human Resources',
                header: 'Human Resources',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Leadership',
                header: 'Leadership',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Legal',
                header: 'Legal',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Marketing',
                header: 'Marketing',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Operations',
                header: 'Operations',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Product',
                header: 'Product',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'Sales',
                header: 'Sales',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: boolans.map((bool) => (
                        <MenuItem key={bool} value={bool}>
                            {bool}
                        </MenuItem>
                    )),
                },
            },
        ],
        [getCommonEditTextFieldProps]
    );

    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={finalTableData}
                editingMode="modal" //default
                enableColumnOrdering
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions'],
                    },
                    density: 'compact',
                }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isAdmin()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="primary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                        disabled={!isAdmin()}
                    >
                        Add New Config
                    </Button>
                )}
            />
            <AddNewUser
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
};

export const AddNewUser = ({ open, columns, onClose, onSubmit }) => {
    const [teamValue, setTeamValue] = useState([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ]);
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const [configType, setConfigType] = useState('QUERY_STRING');
    const [distribution, setDistribution] = useState('PROPORTIONATE');

    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add New Config</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                            marginTop: '20px',
                        }}
                    >
                        {(() => {
                            return (
                                <>
                                    <div className="text-left relative z-0">
                                        <div className="mt-4">
                                            <Select
                                                value={configType}
                                                name={columns[0].accessorKey}
                                                placeholder={
                                                    columns[0].accessorKey
                                                }
                                                style={{ width: '400px' }}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                    setConfigType(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem
                                                    value={'QUERY_STRING'}
                                                >
                                                    QUERY_STRING
                                                </MenuItem>
                                                <MenuItem
                                                    value={'SERVICE_ACCOUNT'}
                                                >
                                                    SERVICE_ACCOUNT
                                                </MenuItem>
                                            </Select>
                                        </div>
                                        <label
                                            style={{
                                                color: 'black',
                                                fontSize: '18px',
                                                fontWeight: 'bolder',
                                            }}
                                            htmlFor="floating_role"
                                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Configuration Type
                                        </label>
                                    </div>
                                    <div className="text-left relative z-0">
                                        <div className="mt-4">
                                            <Select
                                                value={distribution}
                                                name={columns[1].accessorKey}
                                                placeholder={
                                                    columns[1].accessorKey
                                                }
                                                style={{ width: '400px' }}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                    setDistribution(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={'EQUAL'}>
                                                    EQUAL
                                                </MenuItem>
                                                <MenuItem
                                                    value={'PROPORTIONATE'}
                                                >
                                                    PROPORTIONATE
                                                </MenuItem>
                                            </Select>
                                        </div>
                                        <label
                                            style={{
                                                color: 'black',
                                                fontSize: '18px',
                                                fontWeight: 'bolder',
                                            }}
                                            htmlFor="floating_role"
                                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            distribution-Policy
                                        </label>
                                    </div>
                                    <TextField
                                        key={columns[2].accessorKey}
                                        label={columns[2].header}
                                        name={columns[2].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                    {columns.map(function (object, i) {
                                        return i > 2 ? (
                                            <div className="text-left relative z-0">
                                                <div className="mt-4">
                                                    <Select
                                                        value={teamValue[i - 3]}
                                                        name={
                                                            columns[i]
                                                                .accessorKey
                                                        }
                                                        placeholder={
                                                            columns[i]
                                                                .accessorKey
                                                        }
                                                        style={{
                                                            width: '400px',
                                                        }}
                                                        onChange={(e) => {
                                                            setValues({
                                                                ...values,
                                                                [e.target.name]:
                                                                    e.target
                                                                        .value,
                                                            });
                                                            var teamVal =
                                                                teamValue;
                                                            teamVal[i - 3] =
                                                                e.target.value;
                                                            setTeamValue(
                                                                teamVal
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem value={0}>
                                                            {0}
                                                        </MenuItem>
                                                        <MenuItem value={1}>
                                                            {1}
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                                <label
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '18px',
                                                        fontWeight: 'bolder',
                                                    }}
                                                    htmlFor="floating_role"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    {columns[i].accessorKey}
                                                </label>
                                            </div>
                                        ) : (
                                            ''
                                        );
                                    })}
                                </>
                            );
                        })()}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add New Config
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default BigqueryAnalysisConfigTable;
