import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Mongo from './mongoCluster';
import Sentry from './sentry';
const Platform = () => {
    return (
        <>
            <Routes>
                <Route path="mongo/*" element={<Mongo />} />
                <Route path="sentry/*" element={<Sentry/>} />
            </Routes>
        </>
    );
};

export default Platform;