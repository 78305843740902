import HeaderTitle from '../../components/typography/header-title';
import SecurityUserIAM from '../../components/securityComponent/team';
import Task_sheet from '../../components/securityComponent/taskComponent';
import { Grid } from '@mui/material';

export default function SecurityTeam() {
    return (
        <>
            <HeaderTitle
                title={'Security Team'}
                subtitle={'List of people managing security for apna'}
            />
            <Grid className="mt-4" container rowGap={3}>
                <Grid xs={12}>
                    <SecurityUserIAM />
                </Grid>
                <Grid xs={12}>
                    <Task_sheet />
                </Grid>
            </Grid>
        </>
    );
}
