import React from 'react';
import DocumentCard from './documentcard';
import Grid from '@mui/material/Unstable_Grid2';
import DateRangeSelector from './dateRangeSelector';
import { useState, useEffect } from 'react';
import PrimaryButton from '../button/PrimaryButton';
import { Button } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import QueryCostComparator from '../queryCostComparator/costComparator';
import { useSearchParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function BigqueryCostComparator() {
    var dateObj = new Date();
    var dateObj1 = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    dateObj1.setDate(dateObj1.getDate() - 2);
    const [startDate1, setStartDate1] = useState(dateObj1);
    const [startDate2, setStartDate2] = useState(dateObj);
    const [endDate1, setEndDate1] = useState(dateObj1);
    const [endDate2, setEndDate2] = useState(dateObj);
    const [buttonDisability, setButtonDisability] = useState(false);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState({});
    const [ok, setOk] = useState('false');
    const [value, setValue] = useState(0);
    const [view, setView] = useState('no');
    const [searchParams, setSearchParams] = useSearchParams();
    const authAxios = useAxios();
    var count = 0;
    //console.log(endDate2);

    const isDateInputValid = () => {
        if (startDate2 <= endDate1) {
            alert('Start Date 2 should be greater than End Date 1');
            return false;
        }
        return true;
    };

    useEffect(() => {
        if (endDate1 < startDate1) {
            setStartDate1(endDate1);
        }
    }, [endDate1]);

    useEffect(() => {
        if (endDate2 < startDate2) {
            setStartDate2(endDate2);
        }
    }, [endDate2]);

    useEffect(() => {
        if (count === 0) {
            var start_date1 = searchParams.get('startDate1');
            var start_date2 = searchParams.get('startDate2');
            var end_date1 = searchParams.get('endDate1');
            var end_date2 = searchParams.get('endDate2');
            var view_value = searchParams.get('view');
            if (!!start_date1) {
                setStartDate1(new Date(start_date1));
            }
            if (!!start_date2) {
                setStartDate2(new Date(start_date2));
            }
            if (!!end_date1) {
                setEndDate1(new Date(end_date1));
            }
            if (!!end_date2) {
                setEndDate2(new Date(end_date2));
            }
            if (
                view_value === 'yes' &&
                !!start_date1 &&
                !!start_date2 &&
                !!end_date1 &&
                !!end_date2
            ) {
                setOk('load');
                getdata(
                    `${BACKEND_URL}/gcp/bigquery-analysis-cost-comparison?startDate1=${
                        start_date1.split('T')[0]
                    }&startDate2=${start_date2.split('T')[0]}&endDate1=${
                        end_date1.split('T')[0]
                    }&endDate2=${end_date2.split('T')[0]}`
                );
            }
        }
        count = 1;
    }, [searchParams]);
    //console.log(ok);

    const getdata = async (url) => {
        if (!isDateInputValid() || ok === 'load') {
            return;
        }
        setOk('load');
        setButtonDisability(true);
        try {
            const { data } = await authAxios.get(url);
            var principalData = data['principal_wise'];
            var totalData = data['total'];
            principalData.sort((a, b) => (a.change < b.change ? 1 : -1));
            setData(principalData);
            setTotalData(totalData);
            setOk('true');
        } catch (e) {
            console.error(e);
        }
    };

    const getData = () => {
        getdata(
            `${BACKEND_URL}/gcp/bigquery-analysis-cost-comparison?startDate1=${
                startDate1.toISOString().split('T')[0]
            }&startDate2=${startDate2.toISOString().split('T')[0]}&endDate1=${
                endDate1.toISOString().split('T')[0]
            }&endDate2=${endDate2.toISOString().split('T')[0]}`
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setData([]);
        setButtonDisability(false);
    }, [startDate1, startDate2, endDate1, endDate2]);

    return (
        <div className="mt-2">
            <Grid container spacing={3}>
                <Grid xs={9.6}>
                    <DateRangeSelector
                        startDate1={startDate1}
                        startDate2={startDate2}
                        endDate1={endDate1}
                        endDate2={endDate2}
                        maxDate1={dateObj1}
                        maxDate2={dateObj}
                        setSearchParams={setSearchParams}
                    />
                </Grid>
                <Grid xs={2.4}>
                    <div className="mt-[43px]">
                        <Button
                            size={'large'}
                            color={'success'}
                            variant={'contained'}
                            sx={{
                                height: '55px',
                                width: '250px',
                                fontWeight: 'bold',
                            }}
                            onClick={getData}
                            disabled={buttonDisability}
                        >
                            Get Principal
                        </Button>
                    </div>
                </Grid>
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'load':
                            return (
                                <Box
                                    sx={{
                                        width: '100%',
                                        marginTop: '20vh',
                                    }}
                                >
                                    <LinearProgress color="secondary" />
                                </Box>
                            );
                        case 'true':
                            return (
                                <Box
                                    sx={{
                                        width: '100%',
                                        marginTop: '30px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                        }}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                        >
                                            <Tab
                                                label="TOTAL"
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label="PRINCIPALS"
                                                {...a11yProps(1)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <div className="mt-2" style={{textAlign:'center'}}>
                                            <Chip
                                                label={
                                                    totalData['change'] >= 0
                                                        ? `There is increase in cost by ${totalData['change'].toFixed(2)}$ (%${totalData['percentage_change']} increase) in second date range w.r.t first date range`
                                                        : `There is decrease in cost by ${
                                                              -1 *
                                                              totalData[
                                                                  'change'
                                                              ].toFixed(2)
                                                          }$ (%${
                                                              -1 *
                                                              totalData[
                                                                  'percentage_change'
                                                              ]
                                                          } decrease) in second date range w.r.t first date range`
                                                }
                                                //color={totalData['change']>=0?'error':'success'}
                                                sx={{fontSize:'22px',padding:3,fontWeight:'bold',backgroundColor:totalData['change']>=0?'#ffcdd2':'#b2dfdb',color:totalData['change']>=0?'#c62828':'#00695c'}}
                                                
                                            />
                                            <QueryCostComparator
                                                agentsCost={
                                                    totalData['agents_cost']
                                                }
                                                agentsCount={
                                                    totalData['agents_count']
                                                }
                                                metabaseCost={
                                                    totalData['metabase_cost']
                                                }
                                                metabaseCount={
                                                    totalData['metabase_count']
                                                }
                                                height={3500}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <Grid spacing={3} container>
                                            {data.map(function (object, i) {
                                                return (
                                                    <Grid xs={4}>
                                                        <DocumentCard
                                                            Data={object}
                                                            key={i}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </TabPanel>
                                </Box>
                            );
                    }
                })()}
            </Grid>
        </div>
    );
}
