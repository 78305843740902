import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
const autoscaling = ['disabled', 'enabled'];
const StatusPill = ({ value }) => {
    return (
        <>
            <div className="inline-flex">
                <div style={{ marginRight: '2px' }}>
                    {value === 'FAILURE' ? (
                        <CancelIcon color="error" style={{ width: '18px' }} />
                    ) : value === 'SUCCESS' ? (
                        <CheckCircleIcon
                            color="success"
                            style={{ width: '18px' }}
                        />
                    ) : value === 'UPDATING' ? (
                        <CircularProgress color="warning" size={18} />
                    ) : value === 'STARTED' ? (
                        <AlarmOnIcon
                            style={{ width: '18px' }}
                            color="primary"
                        />
                    ) : (
                        <PendingIcon
                            style={{ width: '18px' }}
                            color="disabled"
                        />
                    )}
                </div>
                {value === 'FAILURE'
                    ? 'Failed'
                    : value === 'SUCCESS'
                    ? 'Success'
                    : value === 'UPDATING'
                    ? 'Updating'
                    : value === 'STARTED'
                    ? 'Started'
                    : 'Has not run yet'}
                <div></div>
            </div>
        </>
    );
};

const mongoCostPerHour = {
    M10: 0.1,
    M20: 0.23,
    M30: 0.53,
    M40: 1.03,
    M50: 1.99,
    M60: 3.95,
    M80: 7.36,
    M140: 11.46,
};

const distributions = ['M10', 'M20', 'M30', 'M40', 'M50', 'M60', 'M80', 'M140'];

const storageDistributions = {
    M10: { min: 10, max: 128 },
    M20: { min: 10, max: 256 },
    M30: { min: 10, max: 512 },
    M40: { min: 10, max: 1024 },
    M50: { min: 10, max: 4096 },
    M60: { min: 10, max: 4096 },
    M80: { min: 10, max: 4096 },
    M140: { min: 10, max: 4096 },
};

const MongoCluster = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [editLoader, setEditLoader] = useState([]);
    const [approveLoader, setApproveLoader] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [projectLoader, setProjectLoader] = useState(false);
    const axios = useAxios();
    const { isOwner } = useAuth();
    async function call(url, data, method, setloading, index) {
        if (index === -1) {
            setloading(true);
        } else {
            if (method === 'GET') {
                approveLoader[index] = true;
                setloading(approveLoader);
            } else {
                editLoader[index] = true;
                setloading(editLoader);
            }
        }
        var requestOptions = {};
        if (method === 'GET') {
            requestOptions = {
                url: `${BACKEND_URL}/${url}`,
                method: method,
            };
        } else {
            requestOptions = {
                url: `${BACKEND_URL}/${url}`,
                method: method,
                data: data,
            };
        }
        await axios(requestOptions).then(() => {
            if (index === -1) {
                setloading(false);
            } else {
                if (method === 'GET') {
                    approveLoader[index] = false;
                    setloading(approveLoader);
                } else {
                    editLoader[index] = false;
                    setloading(editLoader);
                }
                window.location.reload(false);
            }
        });
    }
    async function getProjects() {
        setProjectLoader(true);
        var requestOptions = {
            url: `${BACKEND_URL}/platform/mongo/projects`,
            method: 'GET',
        };
        await axios(requestOptions).then(function (res) {
            var data = res.data;
            setProjects(data);
            setProjectLoader(false);
        });
    }

    async function getData() {
        setDataLoader(true);
        var requestOptions = {
            url: `${BACKEND_URL}/platform/mongo/cluster`,
            method: 'GET',
        };
        await axios(requestOptions).then(function (res) {
            var data = res.data;
            var tempLoader1 = [];
            var tempLoader2 = [];
            for (var i = 0; i < data.length; i++) {
                tempLoader1.push(false);
                tempLoader2.push(false);
                data[i]['estimatedCost'] =
                    mongoCostPerHour[data[i]['config']] * 24 * 30;
            }
            setTableData(data);
            setEditLoader(tempLoader1);
            setApproveLoader(tempLoader2);
            setDataLoader(false);
        });
    }

    useEffect(() => {
        getData();
        getProjects();
    }, []);

   
    const handleCreateNewRow = (values) => {
        if (
            !isOwner() &&
            !window.confirm(
                `Once you confirm you won't be able to change. Are you sure you want to add this cluster?`
            )
        ) {
            return;
        }
        var val = {
            clusterName: values['clusterName'],
            projectName: values['projectName'],
            creatorEmail: values['creatorEmail'],
            autoScaling: values['autoScaling'],
            description: values['description'],
            diskSizeGB: values['diskSizeGB'],
            config: values['config'],
            scaleUpConfig: values['autoScaling']=='disabled'?'NA':values['scaleUpConfig'],
            scaleDownConfig: values['autoScaling']=='disabled'?'NA':values['scaleDownConfig'],
            approvedStatus: values['approvedStatus'],
        };
        var temp = tableData;
        for(var i=0;i<temp.length;i++)
        {
            if(temp[i]['clusterName'] == val['clusterName'] && temp[i]['projectName'] == val['projectName'])
            {
                alert(
                    `Cluster with name ${val['clusterName']} already exists for ${val['projectName']}`
                );
                return;
            }
        }
        call('platform/mongo/cluster', val, 'POST', setDataLoader, -1);
        val['estimatedCost'] = mongoCostPerHour[values['config']] * 24 * 30;
        temp.push(val);
        setTableData([...temp]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            if (
                values['autoScaling']=='enabled' && (
                values['scaleUpConfig'] <= values['scaleDownConfig']) && values['scaleUpConfig']!='NA' && values['scaleDownConfig']!='NA'
            ) {
                alert(
                    'ScaleUp config value should be greater than ScaleDown config value'
                );
                return;
            }
            if(values['autoScaling']=='enabled' && (values['scaleUpConfig'] == 'NA' || values['scaleDownConfig'] == 'NA'))
            {
                alert(
                    'Please select appropriate scale up and scale down config'
                );
                return;
            }
            tableData[row.index] = {
                clusterName: values['clusterName'],
                projectName: values['projectName'],
                creatorEmail: values['creatorEmail'],
                autoScaling: values['autoScaling'],
                description: values['description'],
                diskSizeGB: values['diskSizeGB'],
                config: values['config'],
                scaleUpConfig: values['autoScaling']=='disabled'?'NA':values['scaleUpConfig'],
                scaleDownConfig: values['autoScaling']=='disabled'?'NA':values['scaleDownConfig'],
                approvedStatus: values['approvedStatus'],
                estimatedCost: mongoCostPerHour[values['config']] * 24 * 30,
            };
            call(
                'platform/mongo/cluster',
                {
                    clusterName: values['clusterName'],
                    projectName: values['projectName'],
                    creatorEmail: values['creatorEmail'],
                    autoScaling: values['autoScaling'],
                    description: values['description'],
                    diskSizeGB: values['diskSizeGB'],
                    config: values['config'],
                    scaleUpConfig: values['autoScaling']=='disabled'?'NA':values['scaleUpConfig'],
                    scaleDownConfig: values['autoScaling']=='disabled'?'NA':values['scaleDownConfig'],
                    approvedStatus: values['approvedStatus'],
                },
                'PATCH',
                setEditLoader,
                row.index
            );
            setTableData([...tableData]);
        }
        exitEditingMode();
    };

    const handleApprove = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to ${
                        row.getValue('approvedStatus') === 'True'
                            ? 'Reject'
                            : 'Approve'
                    } ${row.getValue('clusterName')}`
                )
            ) {
                return;
            }
            tableData[row.index] = {
                clusterName: row.getValue('clusterName'),
                projectName: row.getValue('projectName'),
                creatorEmail: row.getValue('creatorEmail'),
                autoScaling: row.getValue('autoScaling'),
                description: row.getValue('description'),
                diskSizeGB: row.getValue('diskSizeGB'),
                config: row.getValue('config'),
                scaleUpConfig: row.getValue('scaleUpConfig'),
                scaleDownConfig: row.getValue('scaleDownConfig'),
                approvedStatus: row.getValue('approvedStatus')
            };
            if(row.getValue('approvedStatus') === 'False'){
                call(
                    'platform/mongo/cluster',
                    {
                        clusterName: row.getValue('clusterName'),
                        projectName: row.getValue('projectName'),
                        creatorEmail: row.getValue('creatorEmail'),
                        autoScaling: row.getValue('autoScaling'),
                        description: row.getValue('description'),
                        diskSizeGB: row.getValue('diskSizeGB'),
                        config: row.getValue('config'),
                        scaleUpConfig: row.getValue('scaleUpConfig'),
                        scaleDownConfig: row.getValue('scaleDownConfig'),
                        approvedStatus:
                        row.getValue('approvedStatus') === 'False'
                            ? 'True'
                            : 'False',
                    },
                    'POST',
                    setApproveLoader,
                    row.index
                );
            }
            else{
                call(
                    'platform/mongo/cluster',
                    {
                        clusterName: row.getValue('clusterName'),
                        projectName: row.getValue('projectName'),
                        creatorEmail: row.getValue('creatorEmail'),
                        autoScaling: row.getValue('autoScaling'),
                        description: row.getValue('description'),
                        diskSizeGB: row.getValue('diskSizeGB'),
                        config: row.getValue('config'),
                        scaleUpConfig: row.getValue('scaleUpConfig'),
                        scaleDownConfig: row.getValue('scaleDownConfig'),
                        approvedStatus:
                        row.getValue('approvedStatus') === 'False'
                            ? 'True'
                            : 'False',
                    },
                    'PATCH',
                    setApproveLoader,
                    row.index
                );
            }
            setTableData([...tableData]);
        },
        [tableData]
    );
    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        'clusterName'
                    )}`
                )
            ) {
                return;
            }
            call(
                'platform/mongo/cluster',
                {
                    clusterName: row.getValue('clusterName'),
                    projectName: row.getValue('projectName'),
                    creatorEmail: row.getValue('creatorEmail'),
                    autoScaling: row.getValue('autoScaling'),
                    description: row.getValue('description'),
                    diskSizeGB: row.getValue('diskSizeGB'),
                    config: row.getValue('config'),
                    scaleUpConfig: row.getValue('scaleUpConfig'),
                    scaleDownConfig: row.getValue('scaleDownConfig'),
                    approvedStatus: row.getValue('approvedStatus'),
                },
                'DELETE',
                setDataLoader,
                -1
            );
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'projectName',
                header: 'Project Name',
                enableEditing: false,
                size: 5
            },
            {
                accessorKey: 'clusterName',
                header: 'Cluster Name',
                enableEditing: false,
                size: 5
            },
            {
                accessorKey: 'creatorEmail',
                header: 'Creator Email',
                enableEditing: false,
                size: 5
            },
            {
                accessorKey: 'description',
                header: 'Description',
                muiTableBodyCellProps: {
                    sx: {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        fontWeight: 'bold',
                        //fontSize: '12px',
                    },
                },
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                size: 5
            },
            {
                accessorKey: 'config',
                header: 'Config',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: distributions.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                    
                },
                size: 5
            },
            {
                accessorKey: 'diskSizeGB',
                header: 'diskSizeGB',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
                size: 5
            },
            {
                accessorKey: 'autoScaling',
                header: 'Autoscaling',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: autoscaling.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                },
                size: 5
            },
            {
                accessorKey: 'scaleUpConfig',
                header: 'Scale Up Config',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: distributions.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                },
                size: 5
            },
            {
                accessorKey: 'scaleDownConfig',
                header: 'Scale Down Config',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: distributions.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                },
                size: 5
            },
            {
                accessorKey: 'estimatedCost',
                header: 'Estimated Monthly Cost',
                Cell: ({ cell }) => (
                    <Chip
                        color="success"
                        style={{ fontWeight: 'bolder' }}
                        label={`${cell.getValue()?.toFixed(2)} $`}
                    />
                ),
                enableEditing: false,
                size: 5
            },
            {
                accessorKey: 'approvedStatus',
                header: 'Approved',
                enableEditing: false,
                size: 5
            },
        ],
        [getCommonEditTextFieldProps]
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        var data = [];
        for (var i = 0; i < tableData.length; i++) {
            var dict = {};
            dict['projectName'] = tableData[i]['projectName'];
            dict['clusterName'] = tableData[i]['clusterName'];
            dict['creatorEmail'] = tableData[i]['creatorEmail'];
            dict['config'] = tableData[i]['config'];
            dict['autoScaling'] = tableData[i]['autoScaling'];
            dict['scaleUpConfig'] = tableData[i]['scaleUpConfig'];
            dict['scaleDownConfig'] = tableData[i]['scaleDownConfig'];
            dict['estimatedCost'] = tableData[i]['estimatedCost'];
            dict['approvedStatus'] = tableData[i]['approvedStatus'];
            data.push(dict);
        }
        csvExporter.generateCsv(data);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions', 'clusterName'],
                    },
                    density: 'compact',
                    columnVisibility: { approvedStatus: false },
                }}
                state={{ isLoading: dataLoader , sorting: [
                    {
                        id: 'approvedStatus',
                        desc: false
                    },
                ],}}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {editLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton
                                    disabled={!isOwner()}
                                    onClick={() => table.setEditingRow(row)}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isOwner()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>

                        {approveLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                }}
                            >
                                <CircularProgress size={5} />
                            </Box>
                        ) : (
                            <Tooltip
                                arrow
                                placement="right"
                                title={
                                    row.getValue('approvedStatus') === 'True'
                                        ? 'Reject'
                                        : 'Approve'
                                }
                            >
                                {row.getValue('approvedStatus') === 'True' ? (
                                    <IconButton
                                        color="warning"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isOwner()}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        color="success"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isOwner()}
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                            </Tooltip>
                        )}
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        {projectLoader ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Button
                                color="success"
                                onClick={() => setCreateModalOpen(true)}
                                variant="contained"
                            >
                                Add New Config
                            </Button>
                        )}

                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
            <AddNewJob
                projects={projects}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
};

export const AddNewJob = ({ open, onClose, onSubmit, projects }) => {
    //const [open, setOpen] = React.useState(false);
    const [autoScaling, setAutoScaling] = useState('disabled');
    const handleSubmit = () => {
        if (projectName == '') {
            alert('Choose a project to create cluster');
            return;
        }
        if (clusterName.length < 3) {
            alert('please provide a clustername of atleast three characters');
            return;
        }
        if (description.length < 3) {
            alert('please provide a valid description');
            return;
        }
        if (
            autoScaling=='enabled' &&
            scaleUpConfigType != '' &&
            scaleUpConfigType <= scaleDownConfigType
        ) {
            alert(
                'ScaleUp config value should be greater than ScaleDown config value'
            );
            return;
        }
        if (storage > storageDistributions[configType]['max']) {
            alert(
                `Maximum allowable storage for this configuration is ${storageDistributions[configType]['max']} GB`
            );
            return;
        }
        onSubmit({
            ['creatorEmail']: user.email,
            ['projectName']: projectName,
            ['clusterName']: clusterName,
            ['description']: description,
            ['autoScaling']: autoScaling,
            ['config']: configType,
            ['diskSizeGB']: storage,
            ['approvedStatus']: 'False',
            ['scaleUpConfig']: scaleUpConfigType,
            ['scaleDownConfig']: scaleDownConfigType,
        });
        setClusterName('');
        setProjectName(projects[0]);
        setDescription('');
        setConfigType('M10');
        setStorage(storageDistributions['M10']['min']);
        setAutoScaling('disabled');
        setScaleUpConfigType('');
        setScaleDownConfigType('');
        setEstimatedCost(0);
        onClose();
    };

    const { user } = useAuth();
    const [projectName, setProjectName] = useState(projects[0]);
    const [clusterName, setClusterName] = useState('');
    const [description, setDescription] = useState('');
    const [configType, setConfigType] = useState('M10');
    const [storage, setStorage] = useState(
        storageDistributions[configType]['min']
    );
    const [scaleUpConfigType, setScaleUpConfigType] = useState('');
    const [scaleDownConfigType, setScaleDownConfigType] = useState('');
    const [estimatedCost, setEstimatedCost] = useState(0);
    useEffect(() => {
        if (autoScaling) {
            setScaleUpConfigType('M10');
            setScaleDownConfigType('M10');
        } else {
            setScaleUpConfigType('NA');
            setScaleDownConfigType('NA');
        }
    }, [autoScaling]);
    useEffect(() => {
        setEstimatedCost((mongoCostPerHour[configType] * 24 * 30).toFixed(2));
    }, [configType]);
    return (
        <>
            <Dialog open={open}>
                <DialogTitle textAlign="center">
                    <div>Add New Cluster</div>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {
                                    xs: '300px',
                                    sm: '360px',
                                    md: '400px',
                                },
                                gap: '1.5rem',
                                marginTop: '21px',
                            }}
                        >
                            {(() => {
                                return (
                                    <>
                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={projectName}
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setProjectName(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {projects?.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Choose Project
                                            </label>
                                        </div>
                                        <TextField
                                            value={clusterName}
                                            placeholder="enter cluster name"
                                            onChange={(e) =>
                                                setClusterName(e.target.value)
                                            }
                                        />
                                        <TextField
                                            value={description}
                                            placeholder="why do you want this cluster"
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                        />
                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={configType}
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {distributions.map(
                                                        function (object, i) {
                                                            return (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={
                                                                        object
                                                                    }
                                                                >
                                                                    {object}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Config
                                            </label>
                                        </div>
                                        <TextField
                                            label="enter storage in GB"
                                            value={storage}
                                            type="number"
                                            placeholder="enter storage in GB"
                                            onChange={(e) =>
                                                setStorage(e.target.value)
                                            }
                                        />
                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                            <Select
                                                    value={autoScaling}
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setAutoScaling(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {autoscaling?.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Autoscaling
                                            </label>
                                        </div>
                                        {autoScaling =='enabled'? (
                                            <>
                                                <div className="text-left relative z-0 mt-4">
                                                    <div className="mt-4">
                                                        <Select
                                                            value={
                                                                scaleUpConfigType
                                                            }
                                                            style={{
                                                                width: '400px',
                                                            }}
                                                            onChange={(e) => {
                                                                setScaleUpConfigType(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {distributions.map(
                                                                function (
                                                                    object,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                i
                                                                            }
                                                                            value={
                                                                                object
                                                                            }
                                                                        >
                                                                            {
                                                                                object
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </div>
                                                    <label
                                                        style={{
                                                            color: 'black',
                                                            fontSize: '21px',
                                                            fontWeight:
                                                                'bolder',
                                                        }}
                                                        htmlFor="floating_role"
                                                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                    >
                                                        Scale Up Config
                                                    </label>
                                                </div>

                                                <div className="text-left relative z-0 mt-4">
                                                    <div className="mt-4">
                                                        <Select
                                                            value={
                                                                scaleDownConfigType
                                                            }
                                                            style={{
                                                                width: '400px',
                                                            }}
                                                            onChange={(e) => {
                                                                setScaleDownConfigType(
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        >
                                                            {distributions.map(
                                                                function (
                                                                    object,
                                                                    i
                                                                ) {
                                                                    return (
                                                                        <MenuItem
                                                                            key={
                                                                                i
                                                                            }
                                                                            value={
                                                                                object
                                                                            }
                                                                        >
                                                                            {
                                                                                object
                                                                            }
                                                                        </MenuItem>
                                                                    );
                                                                }
                                                            )}
                                                        </Select>
                                                    </div>
                                                    <label
                                                        style={{
                                                            color: 'black',
                                                            fontSize: '21px',
                                                            fontWeight:
                                                                'bolder',
                                                        }}
                                                        htmlFor="floating_role"
                                                        className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                    >
                                                        Scale Down Config
                                                    </label>
                                                </div>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                );
                            })()}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        color="secondary"
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Add New Job
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={2000}>
                <Alert severity="warning" sx={{ width: '100%' }}>
                    <Typography
                        style={{
                            textAlign: 'center',
                            fontFamily: 'serif',
                            fontWeight: 'bold',
                            color: 'green',
                            fontSize: '19.5px',
                        }}
                    >{`This cluster will cost around ${estimatedCost}$ per month`}</Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default MongoCluster;
