import { React, useState, useEffect } from 'react';
import Datatable from '../costing/Datatable';
import Loading from '../costing/Loading';

const TableFirstDataCell = ({ children }) => {
    return (
        <td style={{fontSize:"14px"}} className=" p-2 text-sm font-bold text-gray-900 text-left">
            {children}
        </td>
    );
};

export default function UnitCostingTotalTable({data,tool,groupByName}) {
    const [ok,setOk] = useState("false")
    const [total, setTotal] = useState(0);
    const [column, setColumn] = useState([])
    const [groupName, setGroupName] = useState(groupByName);
    //console.log(data);
    //groupby acccording to grouBy value
    
    useEffect(()=>{
        if(data.length>0){
            setOk("load")
            var col = Object.keys(data[0])
            setColumn([{header:col[0],
                accessorKey:col[0],
                Header: (
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '15px',
                        }}
                    >
                        {col[0].toUpperCase()}
                        
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                Cell: ({ cell }) => (
                    <TableFirstDataCell>
                        {cell.getValue().toUpperCase()}
                    </TableFirstDataCell>
                ),
            },
                
                {header:col[1],
                    accessorKey:col[1],
                    Header: (
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '15px',
                            }}
                        >
                            {col[1].toUpperCase()}
                            
                        </div>
                    ),
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    Cell: ({ cell }) => (
                        <TableFirstDataCell>
                            {groupName==="Dashboard (Count)"?cell.getValue()?.toFixed(0):cell.getValue()?.toFixed(2)}
                        </TableFirstDataCell>
                    ),
                }])
            var totalCost = 0
            for(var i=0; i<data.length; i++){
                totalCost+=data[i]["total"]
            }
            setTotal(totalCost)
        setOk("true")
        }
    },[groupName])
        
    return (
        <>
        
            
            {(() => {
                switch(ok) {
                    case "false": return <></>
                    case "load": return <Loading/>
                    case "true": return <>
                    <div className="inline flex">
                    <svg aria-hidden="true" className="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd"></path></svg>
                    <h3 style={{marginBottom:"15px",fontWeight:"bold",color:"red"}}>{`Total ${groupName==="Dashboard (Count)"||groupName==="Question (Count)"?'count':'cost'} of ${tool} ${groupName} for the selected date range is ${groupName==="Dashboard (Count)"||groupName==="Question (Count)"?'':'$'} ${groupName==="Dashboard (Count)"||groupName==="Question (Count)"?total.toFixed(0):total.toFixed(2)} `}</h3>
                    </div>
                    <Datatable columns={column} data={data}/>
                    </>
                }
            })()}
            
        </>
    
        
    );
}
