import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { TagsInput } from 'react-tag-input-component';

export default function TagsInputList({
    placeHolder,
    label,
    selected,
    setSelected,
    disabled,
    width,
    marginTop
}) {
    return (
        <div
            className={`flex flex-col flex-1 w-full space-y-2 min-w-max ${
                disabled ? 'opacity-50' : 'opacity-300'
            }`}
        >
            <div style={{ width: width, marginTop: marginTop}}>
                <TagsInput
                    disabled={disabled}
                    name={label}
                    value={selected}
                    onChange={setSelected}
                    placeHolder={placeHolder}
                />
            </div>
        </div>
    );
}
