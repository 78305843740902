import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box'

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: 435,
    },
    indeterminateColor: {
        color: '#f50057',
    },
    selectAllText: {
        fontWeight: 900,
    },
    selectedAll: {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
}));

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 20;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'menu',
};

export default function MultipleSelect({
    label,
    selected,
    setSelected,
    options,
    isLoading,
    margin,
}) {
    const classes = useStyles();
    //const [selected, setSelected] = useState([]);
    const isAllSelected =
        options.length > 0 && selected.length === options.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === 'all') {
            //setSelected(selected.length === options.length ? [] : options);
            setSelected(['all']);
            return;
        }
        else if(value.length > 5){
            alert("Can't select more than 5 option")
            return
        }
        setSelected(value);
    };

    return (
        <>
            {isLoading ? (
                <Box sx={{ display: 'flex' , alignItems: 'center', marginLeft: '100px', marginTop: '40px'}}>
                    <CircularProgress />
                </Box>
            ) : (
                <FormControl
                    className={classes.formControl}
                    style={{ marginTop: margin }}
                >
                    <InputLabel id="mutiple-select-label">{label}</InputLabel>
                    <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={selected}
                        onChange={handleChange}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        <MenuItem
                            value="all"
                            classes={{
                                root: isAllSelected ? classes.selectedAll : '',
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    classes={{
                                        indeterminate:
                                            classes.indeterminateColor,
                                    }}
                                    checked={isAllSelected}
                                    indeterminate={
                                        selected.length > 0 &&
                                        selected.length < options.length
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.selectAllText }}
                                primary="Select All"
                            />
                        </MenuItem>
                        {options.map((option) => (
                            <MenuItem key={option} value={option}>
                                <ListItemIcon>
                                    <Checkbox
                                        checked={selected.indexOf(option) > -1}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={option} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </>
    );
}
