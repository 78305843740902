import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { SECURITY_BACKEND_URL } from '../../configs';
import Card from '@mui/material/Card';
import { useAxios } from '../../contexts/axios';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

export default function BasicCard() {
    const [total, setTotal] = useState("")
    const nav = useNavigate()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const axios = useAxios()
    useEffect(() => {
        setLoading(true)
        const requestOptions = {
          method: 'GET',
          url : `${SECURITY_BACKEND_URL}/wazuh/devices`
        };
        axios(requestOptions).then(function(res){
            setData(res.data.data);
            setTotal(res.data.data["total"])
            setLoading(false)
          })
      },[])
    var series = Object.values(data);
  series.pop()
  var dataLabels = Object.keys(data);
  dataLabels.pop()
  var options = {
    chart: {
      width: 500,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: "gradient",
    },
    legend: {
        formatter: function(val, opts) {
          return val + " - " + opts.w.globals.series[opts.seriesIndex]
        }
      },
    labels: dataLabels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <Card style={{padding:"30px"}}>
      <CardContent>
        <Typography variant="h5" component="div">
        <h5 class="mb-4 text-2xl font-extrabold text-gray-900 dark:text-white md:text-2xl lg:text-3xl">
        <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          Devices
        </span>
      </h5>
        </Typography>
        <Typography variant="body2">
        
        <div>
        {loading?<CircularProgress color="inherit" />:<ReactApexChart
          options={options}
          series={series}
          type="pie"
          width={610}
          height={600}
        />}
        </div>
      
        </Typography>
        <Typography variant="h7" component="div">
          {"Total devices : "+total}
        </Typography>
      </CardContent>
    </Card>
  );
}