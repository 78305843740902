import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const getGCPProjects = async (authAxios) => {
    try {
        const { data } = await authAxios.get(`/gcp/projects`);
        return data;
    } catch (e) {
        console.error(e);
    }
};

const getGCPClusters = async (authAxios, project) => {
    try {
        const { data } = await authAxios.get(
            `/gcp/clusters?project_id=${project['id']}`
        );
        return data;
    } catch (e) {
        console.error(e);
    }
};

const useGCPProjects = () => {
    const authAxios = useAxios();
    return useQuery(['gcp-projects'], () => getGCPProjects(authAxios));
};

const useGCPClusters = (project, resource) => {
    const authAxios = useAxios();

    return useQuery(
        ['gcp-clusters', project, resource],
        () => getGCPClusters(authAxios, project),
        { enabled: !!project && !!resource }
    );
};

export { useGCPProjects, useGCPClusters };
