import React from 'react';
import BackButton from '../../../components/navigation/back-button';
import HeaderTitle from '../../../components/typography/header-title';
//import LogsTable from '../../../components/parsing_logs/logsTable'
import TableCreationInfo from '../../../components/parsing_logs/tablecreation';
import LogsTable from '../../../components/parsing_logs/logsTable';
export default function TableInfo() {
    return (
        <>
            
            <HeaderTitle
                title={'Bigquery Table Deletion Info'}
                subtitle={'Visualize the table deletion info'}
            />
            <LogsTable />
        </>
    );
}
