import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

export default function InternalTable({ columns, data, title, loading }) {
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        filename: title,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    return (
        <>
            <MaterialReactTable
                className="p-4 w-full mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                columns={columns}
                data={data}
                enableStickyHeader
                enableDensityToggle={false}
                state={{
                    isLoading:
                        loading,
                }}
                initialState={{
                    sorting: [
                        {
                            id: 'total',
                            desc: true,
                        },
                    ],
                    columnPinning: {
                        left: [
                            'mrt-row-select',
                            'mrt-row-actions',
                        ],
                    },
                    density: 'compact',
                }}
                positionToolbarAlertBanner="bottom"  
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="primary"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
        </>
    );
}
