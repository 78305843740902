import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useAuth } from '../../contexts/auth';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

function parseConfig(config) {
    var ram = parseInt(config.split('|')[1].split(' ')[1]);
    return ram;
}
const oldElasticClusterPerHour = {
    'es-production-jobs': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-jobs-follower': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-production-search': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-users': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-staging': {
        '60 GB storage | 2 GB RAM | Up to 2.4 vCPU': 0.6148,
        '120 GB storage | 4 GB RAM | Up to 2.4 vCPU': 0.9016,
        '240 GB storage | 8 GB RAM | Up to 2.4 vCPU': 1.4752,
        '480 GB storage | 16 GB RAM | Up to 2.4 vCPU': 2.6224,
        '960 GB storage | 32 GB RAM | Up to 4.9 vCPU': 4.9168,
    },
    'production-job-search': {
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2538,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.4454,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 0.8286,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 1.595,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
    },
    'production-suggester': {
        '8 GB storage | 1 GB RAM | Up to 3.7 vCPU': 0.1437,
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2874,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.5748,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.299,
    },
};
const elasticCostPerHour = {
    'gcp.es.datahot.n2.68x32x45': {
        'zone 1': [
            0.0622, 0.1244, 0.2488, 0.4976, 0.9952, 1.9904, 3.9808, 7.9616,
            11.9424, 15.9232,
        ],
        'zone 2': [
            0.1866, 0.311, 0.5598, 1.0574, 2.0526, 4.043, 8.0238, 15.9854,
            24.6312, 32.5928,
        ],
        'zone 3': [
            0.1866, 0.3732, 0.7464, 1.4928, 2.9856, 5.9712, 11.9424, 24.6312,
            36.5736, 48.516,
        ],
    },
    'gcp.es.datahot.n2.68x10x45': {
        'zone 1': [
            0.0466, 0.092, 0.184, 0.368, 0.736, 1.472, 2.944, 5.888, 8.832,
            11.776,
        ],
        'zone 2': [
            0.1542, 0.2462, 0.4302, 0.7982, 1.5342, 3.0062, 5.9502, 11.8382,
            18.4104, 24.2984,
        ],
        'zone 3': [
            0.138, 0.276, 0.552, 1.104, 2.208, 4.416, 8.832, 18.4104, 27.2424,
            36.0744,
        ],
    },
    'gcp.es.datahot.n2.68x16x45': {
        'zone 1': [
            0.0503, 0.1006, 0.2012, 0.4024, 0.8048, 1.6096, 3.2192, 6.4384,
            9.6576, 12.8768,
        ],
        'zone 2': [
            0.1628, 0.2634, 0.4646, 0.867, 1.6718, 3.2814, 6.5006, 12.939,
            20.0616, 26.5,
        ],
        'zone 3': [
            0.1509, 0.3018, 0.6036, 1.2072, 2.4144, 4.8288, 9.6576, 20.0616,
            29.7192, 39.3768,
        ],
    },
    'gcp.es.datahot.n2.68x10x95': {
        'zone 1': [
            0.0479, 0.0958, 0.1916, 0.3832, 0.7664, 1.5328, 3.0656, 6.1312,
            9.1968, 12.2624,
        ],
        'zone 2': [
            0.158, 0.2538, 0.4454, 0.8286, 1.595, 3.1278, 6.1934, 12.3246,
            19.14, 25.2712,
        ],
        'zone 3': [
            0.1437, 0.2874, 0.5748, 1.1496, 2.2992, 4.5984, 9.1968, 19.14,
            28.3368, 37.5336,
        ],
    },
    'gcp.data.highcpu.1': {
        'zone 1': [
            0.0479, 0.0958, 0.1916, 0.3832, 0.7664, 1.5328, 3.0656, 6.1312,
            9.1968, 12.2624,
        ],
        'zone 2': [
            0.158, 0.2538, 0.4454, 0.8286, 1.595, 3.1278, 6.1934, 12.3246,
            18.8268, 24.958,
        ],
        'zone 3': [
            0.1437, 0.2874, 0.5748, 1.1496, 2.2992, 4.5984, 9.1968, 18.8268,
            28.0236, 37.2204,
        ],
    },
    'gcp.data.highio.1': {
        'zone 1': [
            0.3758, 0.4236, 0.5192, 0.7104, 1.0928, 1.8576, 3.3872, 6.4464,
            9.5056, 12.5648,
        ],
        'zone 2': [
            0.4858, 0.5814, 0.7726, 1.155, 1.9198, 3.4494, 6.5086, 12.627,
            19.1164, 25.2348,
        ],
        'zone 3': [
            0.4714, 0.6148, 0.9016, 1.4752, 2.6224, 4.9168, 9.5056, 19.1164,
            28.294, 37.4716,
        ],
    },
};

const distributionsIndex = {
    '1 GB RAM': 0,
    '2 GB RAM': 1,
    '4 GB RAM': 2,
    '8 GB RAM': 3,
    '16 GB RAM': 4,
    '32 GB RAM': 5,
    '64 GB RAM': 6,
    '128 GB RAM': 7,
    '192 GB RAM': 8,
    '256 GB RAM': 9,
};

const elasticConfigs = {
    'gcp.es.datahot.n2.68x32x45': [
        '45 GB storage | 1 GB RAM | Up to 8 vCPU',
        '90 GB storage | 2 GB RAM | Up to 8 vCPU',
        '180 GB storage | 4 GB RAM | Up to 8 vCPU',
        '360 GB storage | 8 GB RAM | Up to 8 vCPU',
        '720 GB storage | 16 GB RAM | Up to 8 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 16 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 32 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 64 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 96 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 128 vCPU',
    ],
    'gcp.es.datahot.n2.68x10x45': [
        '45 GB storage | 1 GB RAM | Up to 2.5 vCPU',
        '90 GB storage | 2 GB RAM | Up to 2.5 vCPU',
        '180 GB storage | 4 GB RAM | Up to 2.5 vCPU',
        '360 GB storage | 8 GB RAM | Up to 2.5 vCPU',
        '720 GB storage | 16 GB RAM | Up to 2.5 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 5 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 10 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 20 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 30 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 40 vCPU',
    ],
    'gcp.es.datahot.n2.68x16x45': [
        '45 GB storage | 1 GB RAM | Up to 4 vCPU',
        '90 GB storage | 2 GB RAM | Up to 4 vCPU',
        '180 GB storage | 4 GB RAM | Up to 4 vCPU',
        '360 GB storage | 8 GB RAM | Up to 4 vCPU',
        '720 GB storage | 16 GB RAM | Up to 4 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 8 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 16 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 32 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 48 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 64 vCPU',
    ],
    'gcp.es.datahot.n2.68x10x95': [
        '95 GB storage | 1 GB RAM | Up to 2.5 vCPU',
        '190 GB storage | 2 GB RAM | Up to 2.5 vCPU',
        '380 GB storage | 4 GB RAM | Up to 2.5 vCPU',
        '760 GB storage | 8 GB RAM | Up to 2.5 vCPU',
        '1.48 TB storage | 16 GB RAM | Up to 2.5 vCPU',
        '2.97 TB storage | 32 GB RAM | Up to 5 vCPU',
        '5.94 TB storage | 64 GB RAM | Up to 10 vCPU',
        '11.88 TB storage | 128 GB RAM | Up to 20 vCPU',
        '17.81 TB storage | 192 GB RAM | Up to 30 vCPU',
        '23.75 TB storage | 256 GB RAM | Up to 40 vCPU',
    ],
    'gcp.data.highcpu.1': [
        '8 GB storage | 1 GB RAM | Up to 3.7 vCPU',
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU',
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU',
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU',
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU',
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU',
        '512 TB storage | 64 GB RAM | Up to 15.1 vCPU',
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU',
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU',
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU',
    ],
    'gcp.data.highio.1': [
        '30 GB storage | 1 GB RAM | Up to 2.4 vCPU',
        '60 GB storage | 2 GB RAM | Up to 2.4 vCPU',
        '120 GB storage | 4 GB RAM | Up to 2.4 vCPU',
        '240 GB storage | 8 GB RAM | Up to 2.4 vCPU',
        '480 GB storage | 16 GB RAM | Up to 2.4 vCPU',
        '960 GB storage | 32 GB RAM | Up to 4.9 vCPU',
        '512 TB storage | 64 GB RAM | Up to 9.8 vCPU',
        '1.88 TB storage | 128 GB RAM | Up to 19.7 vCPU',
        '3.75 TB storage | 192 GB RAM | Up to 29.5 vCPU',
        '5.63 TB storage | 256 GB RAM | Up to 39.4 vCPU',
    ],
};

function getValue(value) {
    var result = parseInt(value / 1024).toFixed(0);
    result = result.toString() + ' GB RAM';
    return result;
}

export default function ElasticTagging() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isAdmin } = useAuth();
    function call(url, data, method) {
        const requestOptions = {
            url: `${BACKEND_URL}/elastic/deployments-metadata${url}`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/elastic/deployments-metadata` }).then(
            function (res) {
                var table = res.data;
                var dataset = [];
                for (var i = 0; i < table.length; i++) {
                    var dataPoint = {};
                    dataPoint['deployment_id'] = table[i]['deployment_id'];
                    dataPoint['deployment_name'] = table[i]['deployment_name'];
                    dataPoint['instance_configuration_id'] =
                        table[i]['instance_configuration_id'];
                    dataPoint['zone'] = table[i]['zone'];
                    dataPoint['cpu'] = '';
                    dataPoint['memory'] = (
                        table[i]['memory'] / 1024
                    )?.toString();
                    dataPoint['storage'] = table[i]['storage'];
                    dataPoint['hourly_cost'] = '';
                    try {
                        var name = table[i]['deployment_name'];
                        var memory = getValue(table[i]['memory']);

                        var config_ids = Object.keys(elasticConfigs);
                        var config_id = table[i]['instance_configuration_id'];
                        var memory = getValue(table[i]['memory']);
                        var zone = 'zone ' + table[i]['zone'].toString();
                        if (config_ids.includes(config_id)) {
                            var configs = elasticConfigs[config_id];
                            for (var j = 0; j < configs.length; j++) {
                                if (configs[j].includes(memory)) {
                                    dataPoint['storage'] = configs[j]
                                        .split('|')[0]
                                        .split(' ')[0];
                                    dataPoint['cpu'] = configs[j]
                                        .split('|')[2]
                                        .split(' ')[3];
                                    dataPoint['hourly_cost'] =
                                        elasticCostPerHour[config_id][zone][
                                            distributionsIndex[memory]
                                        ].toFixed(3);
                                    break;
                                }
                            }
                        }
                    } catch (e) {}

                    dataPoint['team'] = table[i]['team'];
                    dataPoint['vertical'] = table[i]['vertical'];
                    dataPoint['application'] = table[i]['application'];
                    dataPoint['owner'] = table[i]['owner'];
                    dataset.push(dataPoint);
                }
                setTableData(dataset);
            }
        );
    }, []);

    const handleCreateNewRow = (values) => {
        if (values.deployment_name.length <= 2) {
            alert('Please enter valid value, too few characters');
            return;
        }

        tableData.push(values);
        setTableData([...tableData]);
        call(
            '',
            {
                deployment_id: values.deployment_id,
                deployment_name: values.deployment_name,
                instance_configuration_id: values.instance_configuration_id,
                zone: values.zone,
                memory: values.memory,
                storage: values.storage,
                vertical: values.vertical,
                team: values.team,
                owner: values.owner,
                application: values.application,
            },
            'POST'
        );
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();

            call(
                '',
                {
                    deployment_id: values.deployment_id,
                    deployment_name: values.deployment_name,
                    instance_configuration_id: values.instance_configuration_id,
                    zone: values.zone,
                    memory: values.memory,
                    storage: values.storage,
                    vertical: values.vertical,
                    team: values.team,
                    owner: values.owner,
                    application: values.application,
                },
                'PUT'
            );
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        'deployment_name'
                    )}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            call(
                '',
                {
                    deployment_id: row.getValue('deployment_id'),
                    deployment_name: row.getValue('deployment_name'),
                    instance_configuration_id: row.getValue(
                        'instance_configuration_id'
                    ),
                    zone: row.getValue('zone'),
                    memory: row.getValue('memory'),
                    storage: row.getValue('storage'),
                    team: row.getValue('team'),
                    vertical: row.getValue('vertical'),
                    owner: row.getValue('owner'),
                    application: row.getValue('application'),
                },
                'DELETE'
            );
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'deployment_id',
                header: 'Deployment Id',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'deployment_name',
                header: 'Deployment Name',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'instance_configuration_id',
                header: 'Instance Configuration',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'zone',
                header: 'Zone',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'cpu',
                header: 'Cpu',
                size: 140,
            },
            {
                accessorKey: 'memory',
                header: 'Memory(GB)',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'storage',
                header: 'Storage(GB/TB)',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'hourly_cost',
                header: 'Hourly Rate($)',
                size: 140,
            },
            {
                accessorKey: 'team',
                header: 'Team',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'vertical',
                header: 'Vertical',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'application',
                header: 'Application',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'owner',
                header: 'Owner',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ],
        [getCommonEditTextFieldProps]
    );
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        filename: 'Elastic Tagging ' + new Date().toDateString(),
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal"
                enableColumnOrdering
                enableEditing
                enableStickyHeader
                enableStickyFooter
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions', 'deployment_name'],
                    },
                    density: 'compact',
                    columnVisibility: { deployment_id: false },
                }}
                enableRowActions
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                disabled={!isAdmin()}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="success"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                            disabled={!isAdmin()}
                        >
                            Add New Cluster
                        </Button>
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
                onEditingRowSave={handleSaveRowEdits}
            />
            <AddNewUser
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
}

export const AddNewUser = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add Cluster Details</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map(function (object, i) {
                            return (
                                <TextField
                                    key={object.accessorKey}
                                    label={object.header}
                                    name={object.accessorKey}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            );
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add New Cluster
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
