import React from 'react';

const FlexRow = ({ children }) => {
    return (
        <div className="flex-1 md:flex-row flex-col flex items-center my-4 space-y-2 md:space-y-0 md:space-x-4">
            {children}
        </div>
    );
};

export default FlexRow;
