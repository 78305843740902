//import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../../components/typography/header-title';
import Pendingrequest from '../../../components/accessComponents/pendingrequest';
//import Home from '../../components/securityComponent/home';
export default function PendingRequest() {
    return (
        <>
            <HeaderTitle
                title={'Pending Request'}
                subtitle={
                    'Approve/Deny pending requests'
                }
            />
            <Pendingrequest/>
        </>
    );
}
