import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const ManagerRoute = () => {
    const { isManager} = useAuth();
    if (isManager()) {
        return <Outlet />;
    } else {
        <Navigate to={'/home'} />;
    }
};

export default ManagerRoute;
