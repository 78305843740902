import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ children }) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-between mb-3">
            <div
                onClick={() => navigate(-1)}
                className="flex items-center space-x-1 mb-3 cursor-pointer"
            >
                <ChevronLeftIcon className="h-5 w-5 text-gray-500" />
                <p className="text-sm text-gray-500 font-semibold">Back</p>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-4">{children}</div>
        </div>
    );
};

export default BackButton;
