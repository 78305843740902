import { React, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { BACKEND_URL } from '../../configs';
//import FlexRow from '../layout/FlexRow';
import { useAxios } from '../../contexts/axios';
import ReactJson from 'react-json-view';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery } from 'react-responsive';

export default function UserFullInformation() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [user, setUser] = useState(null);
    const [data1, setData1] = useState({});
    const [loading1, setLoading1] = useState(false);
    const [ok, setOk] = useState('false');
    const [buttonDisability, setButtonDisability] = useState(false);
    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const isInputValid = () => {
        if (user === null) {
            alert('Please enter userId or Phone Number');
            return;
        }
        return true;
    };

    const getData1 = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (user !== null) {
            getdata(
                `${BACKEND_URL}/gcp/user-preferences?user_id=${user}`,
                // `${BACKEND_URL}`
                setData1,
                setLoading1
            );
        }
        setButtonDisability(false);
    };

    useEffect(() => {
        setData1({});
        setOk('false');
    }, [user]);

    return (
        <>
            <Grid container rowGap={3} columnSpacing={3}>
                <Grid xs={isMobile ? 6 : 3}>
                    <TextField
                        label={'Enter UserId/Phone Number'}
                        value={user}
                        sx={{ maxWidth: 250 }}
                        onChange={(e) => {
                            setUser(e.target.value);
                        }}
                    />
                </Grid>
                <Grid xs={isMobile ? 6 : 3}>
                    <Box display="flex" width="100%">
                        <Button
                            children={'Get Prefrences'}
                            sx={{
                                //width: 200,
                                backgroundColor: '#0096FF',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            fullWidth={true}
                            onClick={getData1}
                            disabled={buttonDisability}
                        />
                    </Box>
                </Grid>
                {!isMobile ? (
                    <>
                        <Grid xs={3}></Grid>
                        <Grid xs={3}></Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>
            <Box display="flex" width="80vw" marginTop="20px">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <>
                                    {loading1 ? (
                                        <div
                                            style={{
                                                marginLeft: '40vw',
                                                marginTop: '30vh',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            <CircularProgress />
                                        </div>
                                    ) : (
                                        <ReactJson
                                            collapseStringsAfterLength={1200}
                                            src={data1}
                                            displayDataTypes={false}
                                            name={false}
                                        />
                                    )}
                                </>
                            );
                    }
                })()}
            </Box>
        </>
    );
}
