import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MongoAtlasEvents from './mongo-atlas-events';
import ElasticEvents from './elastic-events';
import GCPIncidents from './gcp-incidents';
import ReblazeDashboard from './reblazeDashboard';
const Events = () => {
    return (
        <>
            <Routes>
                <Route index element={<Navigate to={'/home'} />} />
                <Route
                    path="mongo-db-events/*"
                    element={<MongoAtlasEvents />}
                />
                <Route path="elastic-events/*" element={<ElasticEvents />} />
                <Route path="gcp-incidents/*" element={<GCPIncidents />} />
                <Route
                    path="reblaze-dashboard/*"
                    element={<ReblazeDashboard />}
                />
            </Routes>
        </>
    );
};

export default Events;
