import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function Change({value}){
    return (
      
        <span
            className={
                `px-3 py-1 leading-wide font-bold text-sm rounded-full shadow-sm whitespace-nowrap ${(() => {
                    if(value<=0){
                        return 'bg-green-100 text-green-700'
                    }
                    else{
                        return 'bg-red-100 text-red-700' 
                    }
                })()}`}
            style={{fontWeight:"bolder",fontSize:'13px'}}
        >
            {value<=0?'':'+'}{value}%{value<=0?<ArrowDownwardIcon/>:<ArrowUpwardIcon/>}
        </span>
        
    ); 
}