import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MongoTbas from './mongo';
import ElasticTbas from './elastic';
const Tbas = () => {
    return (
        <>
            <Routes>
                <Route index element={<Navigate to={'/home'} />} />
                <Route
                    path="mongo/*"
                    element={<MongoTbas/>}
                />
                <Route
                    path="elastic/*"
                    element={<ElasticTbas/>}
                />
            </Routes>
        </>
    );
};

export default Tbas;
