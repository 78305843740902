import React from 'react';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import BackgroundLetterAvatars from './backgroundAvator';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Number from './number';
import Change from '../bqtableStorage/change';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import QueryCostComparator from '../queryCostComparator/costComparator';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function parseName(name){
    var x = name.split('@')[0].split('.');
    if(x.length > 1){
        return x[0]+ " " + x[1];
    }
    else{
        return x[0]+" "+".";
    }
}

export default function DocumentCard({ Data }) {
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(parseName(Data["principal"]));
    const [change, setChange] = useState(Data["change"]);
    const [percentageChange, setPercentageChange] = useState(Data["percentage_change"]);
    const [posContribution, setPosContribution] = useState(Data["pos_contribution"]);
    const [negContribution, setNegContribution] = useState(Data["neg_contribution"]);
    const [agentsCost, setAgentsCost] = useState(Data["agents_cost"]);
    const [agentsCount, setAgentsCount] = useState(Data["agents_count"]);
    const [metabaseCost, setMetabaseCost] = useState(Data["metabase_cost"]);
    const [metabaseCount, setMetabaseCount] = useState(Data["metabase_count"]);
    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    const handleclick = () => {
        openModal();
    };
    return (
        <>
            <Paper
                elevation={3}
                sx={{
                    width: 'fit-content',
                    height: '180px',
                    borderRadius: '15px',
                    backgroundColor: 'ghostwhite',
                    padding: 1,
                }}
            >
                <div className="flex flex-inline space-between">
                    <div className="inline-flex" style={{ textAlign: 'left', marginLeft:'7px', marginTop:'7px' }}>
                        <BackgroundLetterAvatars change={change} name={name}/>
                        <Typography
                            variant="h6"
                            textTransform="capitalize"
                            sx={{
                                marginTop: '20px',
                                marginLeft: '15px',
                                fontWeight: 'bold',
                            }}
                        >
                            {name}
                        </Typography>
                        <Button
                            color="secondary"
                            onClick={handleclick}
                            size='small'
                            children={<InfoIcon/>}
                        />
                    </div>
                </div>
                <div className="inline-flex">
                    <div className="mt-4">
                        <Stack spacing={2}>
                            <Typography
                                variant="body"
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                Change in cost: <Number value={change.toFixed(2)} unit={'$'} />
                            </Typography>
                            <Typography
                                variant="body"
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                +ve Contribution: <Number value={posContribution} unit={'%'} />
                            </Typography>
                            
                        </Stack>
                    </div>
                    <div className="mt-4">
                        <Stack spacing={2}>
                        <Typography
                                variant="body"
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                Percentage: <Change value={percentageChange} />
                            </Typography>
                            <Typography
                                variant="body"
                                style={{
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                -ve Contribution: <Number value={negContribution} unit={'%'}/>
                            </Typography>
                        </Stack>
                    </div>
                </div>
            </Paper>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto mt-20">
                        <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[1600px] transform overflow-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="mt-2">
                                        <QueryCostComparator agentsCost={agentsCost} agentsCount={agentsCount} metabaseCost={metabaseCost} metabaseCount={metabaseCount} height={400}/>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}