import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DD2 from '../DD2';
import { useState, useEffect, useCallback } from 'react';
import FlexRow from '../layout/FlexRow';
import logo from '../../logo.svg';
import { BACKEND_URL } from '../../configs';
import { useAxios } from '../../contexts/axios';
import { useAuth } from '../../contexts/auth';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    IconButton,
    MenuItem,
    Stack,
    Select,
    Tooltip,
} from '@mui/material';

export default function SentryForm() {
    const sentryTypes = [
        { name: 'Staging', id: 'staging' },
        { name: 'Production', id: 'production' },
    ];
    const user = useAuth();
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [sentryType, setSentryType] = useState(sentryTypes[0]);
    const [uniqueName, setUniqueName] = useState('');
    const [ownerEmail, setOwnerEmail] = useState(user?.user?.email);
    const [managerEmail, setManagerEmail] = useState('');
    const [reason, setReason] = useState('');
    const [sentryNames, setSentryNames] = useState([]);
    const [getDataLoader, setGetDataLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const axios = useAxios();
    async function getData() {
        setGetDataLoader(true);
        var requestOptions = {
            url: `${BACKEND_URL}/platform/sentry`,
            method: 'GET',
        };
        await axios(requestOptions).then(function (res) {
            var data = res.data;
            var names = [];
            for (var i = 0; i < data?.length; i++) {
                names.push(data[i]['name']);
            }
            //console.log(names)
            setSentryNames(names);
            setGetDataLoader(false);
        });
    }

    async function handleClear() {
        setSentryType(sentryTypes[0]);
        setUniqueName('');
        setManagerEmail('');
        setReason('');
    }

    useEffect(() => {
        getData();
    }, []);

    async function handleCreateSentry() {
        if (sentryNames.includes(uniqueName)) {
            alert('This sentry name is already in use. Try something else');
            return;
        }
        if (uniqueName === '' || managerEmail === '' || reason === '') {
            alert('Fields cannot be empty.');
            return;
        }
        if (
            !window.confirm(
                `Once you confirm you won't be able to change. Are you sure you want to create this sentry instance?`
            )
        ) {
            return;
        }
        setOpen(true);
        var dateObj = new Date();
        var date = dateObj.toISOString().split('T')[0];
        var value = {
            name: uniqueName,
            owner: user?.user?.email,
            type: sentryType?.id,
            purpose: reason,
            request_time: date,
            manager_email: managerEmail,
            approval_status: 'False',
            sentry_username: 'apnasenrty',
            sentry_password: 'sentry@123',
            sentry_dns: `${uniqueName}.infra.apna.co`,
        };
        var requestOptions = {
            url: `${BACKEND_URL}/platform/sentry`,
            method: 'POST',
            data: value,
        };
        await axios(requestOptions).then(function (res) {
            setOpen(false);
            setCreateModalOpen(true);
        });
    }

    function handleCloseModal(){
        setCreateModalOpen(false);
        handleClear();
    }

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Card sx={{ display: 'flex' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <FlexRow>
                            <DD2
                                label={'Type'}
                                emptyLabel={'Production/Staging'}
                                options={sentryTypes}
                                value={sentryType}
                                onChange={setSentryType}
                                displayProperty={'name'}
                            />
                        </FlexRow>
                        <FlexRow>
                            <TextField
                                label={'Sentry Unique Name'}
                                value={uniqueName}
                                sx={{ width: 400 }}
                                onChange={(e) => {
                                    setUniqueName(e.target.value);
                                }}
                            />
                        </FlexRow>
                        <FlexRow>
                            <TextField
                                label={'Owner Email'}
                                value={ownerEmail}
                                sx={{ width: 400 }}
                                disabled={true}
                                onChange={(e) => {
                                    setOwnerEmail(e.target.value);
                                }}
                            />
                        </FlexRow>
                        <FlexRow>
                            <TextField
                                label={'Manager Email'}
                                value={managerEmail}
                                sx={{ width: 400 }}
                                onChange={(e) => {
                                    setManagerEmail(e.target.value);
                                }}
                            />
                        </FlexRow>
                        <FlexRow>
                            <TextField
                                label={'Reason'}
                                value={reason}
                                sx={{ width: 400 }}
                                onChange={(e) => {
                                    setReason(e.target.value);
                                }}
                            />
                        </FlexRow>
                    </CardContent>
                    <CardActions>
                        <Button
                            size="medium"
                            onClick={() => {
                                handleCreateSentry();
                            }}
                        >
                            Submit
                        </Button>
                        <Button
                            size="medium"
                            onClick={() => {
                                handleClear();
                            }}
                        >
                            Clear
                        </Button>
                    </CardActions>
                </Box>
                <CardMedia component="img" src={logo} sx={{ width: 550 }} />
            </Card>
            <InfoModal
                open={createModalOpen}
                sentryDns={`${uniqueName}.infra.apna.co`}
                onClose={() => handleCloseModal()}
            />
        </>
    );
}

export const InfoModal = ({ open, sentryDns, onClose }) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{fontWeight:'bolder'}}>
                        Your request has been submitted. You will get an email
                        once the sentry dns will be up and running after the approval of respective manager.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" style={{fontWeight:'bolder'}}>
                        Your sentry info will be:
                    </DialogContentText>
                    <List sx={{ pt: 0 , marginLeft:'15px', fontWeight:'bold', fontSize:'16px'}}>
                        <ListItem disableGutters>
                            <ListItemAvatar>
                                {'dns :'}
                            </ListItemAvatar>
                            <ListItemText style={{fontWeight:'bold', color:'red'}} primary={` ${sentryDns}`} />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemAvatar>
                                {'username :'}
                            </ListItemAvatar>
                            <ListItemText style={{fontWeight:'bold', color:'red'}} primary=" apnasentry" />
                        </ListItem>
                        <ListItem disableGutters>
                            <ListItemAvatar>
                                {'password :'}
                            </ListItemAvatar>
                            <ListItemText style={{fontWeight:'bold', color:'red'}} primary=" sentry@123" />
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
