import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ReactJson from 'react-json-view';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Fragment } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FlexRow from '../layout/FlexRow';
import PrimaryButton from '../button/PrimaryButton';
import DateTimePickerInput from '../DateTimePickerInput';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

export default function LogsTable() {
    var dateObj = new Date();
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [log, setLog] = useState([]);
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    const [index, setIndex] = useState(0);
    const [column, setColumn] = useState([
        {
            header: 'Project ID',
            accessorKey: 'projectId',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Dataset ID',
            accessorKey: 'datasetId',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Table ID',
            accessorKey: 'tableId',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Operation Done By',
            accessorKey: 'operationBy',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Agent',
            accessorKey: 'agent',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Severity',
            accessorKey: 'severity',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Resource Type',
            accessorKey: 'resourceType',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Caller IP',
            accessorKey: 'callerIp',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Time',
            accessorKey: 'timestamp',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Operation Happened',
            accessorKey: 'operation',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        entries: [],
        nextPageToken: '',
    });
    const [nextPageToken, setNextPageToken] = useState(null);
    const [finalData, setFinalData] = useState([]);
    const [ok, setOk] = useState('false');
    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
            if (get(data, 'entries', []).length == 0) {
                setloading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };
    const isDateInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };
    const getNextData = () => {
        if (!isDateInputValid() || loading) {
            return;
        }
        setOk('true');
        if (nextPageToken) {
            getdata(
                `${BACKEND_URL}/gcp/bigquery-table-delete-info?next_page_token=${nextPageToken}&start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
                setData,
                setLoading
            );
        } else {
            getdata(
                `${BACKEND_URL}/gcp/bigquery-table-delete-info?start_time=${startDate.toISOString()}&end_time=${endDate.toISOString()}`,
                setData,
                setLoading
            );
        }
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(finalData);
    };

    const handleclick = (ind) => {
        setIndex(ind);
        openModal();
    };

    useEffect(() => {
        setOk('false');
        setFinalData([]);
        setLog([]);
        setData({
            entries: [],
            nextPageToken: '',
        });
        setNextPageToken(null);
    }, [startDate, endDate]);

    useEffect(() => {
        var Data = get(data, 'entries', []);
        if (Data.length > 0) {
            var temp = [];
            var logs = [];
            for (var i = 0; i < Data.length; i++) {
                var operation = '';
                try {
                    var keys = Object.keys(Data[i].protoPayload.metadata);
                    for (var j = 0; j < keys.length; j++) {
                        if (keys[j] != '@type') {
                            operation = keys[j];
                            break;
                        }
                    }
                } catch {}
                var callerIpVal = get(
                    get(Data[i].protoPayload, 'requestMetadata', {
                        callerIp: 'No Ip Found',
                    }),
                    'callerIp',
                    'No Ip Found'
                );
                var agentVal = get(
                    get(Data[i].protoPayload, 'requestMetadata', {
                        callerSuppliedUserAgent: 'No Agent Found',
                    }),
                    'callerSuppliedUserAgent',
                    'No Agent Found'
                );
                agentVal =
                    agentVal.includes('Tableau') === true
                        ? 'Tableau Server'
                        : agentVal.indexOf('/') === -1
                        ? agentVal
                        : agentVal.split('/')[0];
                temp.push({
                    projectId: Data[i].resource.labels.project_id,
                    datasetId: Data[i].resource.labels.dataset_id,
                    tableId: Data[i].protoPayload.resourceName.split('/').pop(),
                    operationBy:
                        Data[i].protoPayload.authenticationInfo.principalEmail,
                    agent: agentVal,
                    severity: Data[i].severity,
                    resourceType: Data[i].resource.type,
                    callerIp: callerIpVal,
                    timestamp: Data[i].timestamp,
                    operation: operation,
                    //log: Data[i],
                });
                logs.push(Data[i]);
            }
            setLog([...log, ...logs]);
            setFinalData([...finalData, ...temp]);
            setNextPageToken(get(data, 'nextPageToken', null));
            setLoading(false);
        }
    }, [data]);

    return (
        <>
            <FlexRow>
                <FlexRow>
                    <DateTimePickerInput
                        label={'Start Date'}
                        value={startDate}
                        onChange={setStartDate}
                        minDate={new Date('2022-01-03T18:30:00Z')}
                        maxDate={endDate}
                    />
                </FlexRow>
                <FlexRow>
                    <DateTimePickerInput
                        label={'End Date'}
                        value={endDate}
                        onChange={setEndDate}
                        maxDate={dateObj}
                    />
                </FlexRow>

                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <div className="mt-8">
                <PrimaryButton
                    title={'Get Bigquery Table Deletion info'}
                    onClick={getNextData}
                />
            </div>

            <div className="grid grid-rows-2 p-4">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <>
                                    <div className="p-1">
                                        <MaterialReactTable
                                            data={finalData}
                                            columns={column}
                                            enableStickyFooter
                                            enableStickyHeader
                                            initialState={{
                                                columnPinning: {
                                                    left: [
                                                        'mrt-row-select',
                                                        'mrt-row-actions',
                                                    ],
                                                },
                                                density: 'compact',
                                            }}
                                            filterFns={{
                                                customFilterFn: (
                                                    row,
                                                    id,
                                                    filterValue
                                                ) => {
                                                    return row
                                                        .getValue(id)
                                                        .includes(filterValue);
                                                },
                                            }}
                                            enableRowActions
                                            state={{
                                                isLoading: loading,
                                            }}
                                            enableRowSelection
                                            enableDensityToggle={false}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: 'Get Full Metadata', //change header text
                                                    size: 150, //make actions column wider
                                                },
                                            }}
                                            renderRowActions={({ row }) => (
                                                <Box>
                                                    <Button
                                                        onClick={() => {
                                                            handleclick(
                                                                row.index
                                                            );
                                                        }}
                                                    >
                                                        View full metadata
                                                    </Button>
                                                </Box>
                                            )}
                                            positionToolbarAlertBanner="bottom"
                                            renderTopToolbarCustomActions={({
                                                table,
                                            }) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '1rem',
                                                        p: '0.5rem',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                        onClick={
                                                            handleExportData
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export All Data
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            table.getRowModel()
                                                                .rows.length ===
                                                            0
                                                        }
                                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                        onClick={() =>
                                                            handleExportRows(
                                                                table.getRowModel()
                                                                    .rows
                                                            )
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export Page Rows
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            !table.getIsSomeRowsSelected() &&
                                                            !table.getIsAllRowsSelected()
                                                        }
                                                        //only export selected rows
                                                        onClick={() =>
                                                            handleExportRows(
                                                                table.getSelectedRowModel()
                                                                    .rows
                                                            )
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export Selected Rows
                                                    </Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={getNextData}
                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                        startIcon={
                                                            <ArrowForwardIosIcon />
                                                        }
                                                        variant="contained"
                                                        disabled={
                                                            !!!nextPageToken ||
                                                            loading
                                                        }
                                                    >
                                                        Get Next Set of Data
                                                    </Button>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    <Transition
                                        appear
                                        show={isOpen}
                                        as={Fragment}
                                    >
                                        <Dialog
                                            as="div"
                                            className="relative z-10"
                                            onClose={closeModal}
                                        >
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                                            </Transition.Child>

                                            <div className="fixed inset-0 overflow-y-auto">
                                                <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 scale-95"
                                                        enterTo="opacity-100 scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 scale-100"
                                                        leaveTo="opacity-0 scale-95"
                                                    >
                                                        <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                METADATA
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <ReactJson
                                                                    src={
                                                                        log[
                                                                            index
                                                                        ]
                                                                    }
                                                                    displayDataTypes={
                                                                        false
                                                                    }
                                                                    name={false}
                                                                />
                                                            </div>
                                                        </Dialog.Panel>
                                                    </Transition.Child>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </Transition>
                                </>
                            );
                    }
                })()}
            </div>
        </>
    );
}
