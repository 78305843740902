import { React, useState, useEffect, useCallback } from 'react';
import { Box, Button } from '@mui/material';
//import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BACKEND_URL, ACCESS_BACKEND_URL } from '../../configs';
import TaskTable from '../table/taskTablse';
//import FlexRow from '../layout/FlexRow';
import { useAxios } from '../../contexts/axios';
//import ReactJson from 'react-json-view';
import TextField from '@mui/material/TextField';
//import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery } from 'react-responsive';

export default function OTPStatus() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [user, setUser] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState('false');
    const [column, setColumn] = useState([]);
    const [buttonDisability, setButtonDisability] = useState(false);
    const authAxios = useAxios();
    const [profileData, setProfileData] = useState({
        email: 'email',
        name: 'name user',
        profile: 'Please add one',
        team: 'Please add one',
        role: 'user',
        manageremail: 'Required for access request',
    });

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/profile?type=profile`,
        };
        authAxios(requestOptions).then(function (res) {
            setProfileData(res.data);
        });
    }, []);

    const getdata = async (
        url,
        setdata,
        setcolumn,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var keys = Object.keys(data);
            var result = [];
            var cols = [
                {
                    header: 'Query',
                    accessorKey: 'query',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                },
                {
                    header: 'Answer',
                    accessorKey: 'answer',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                },
            ];
            setcolumn(cols);
            if (keys.length > 2) {
                result.push({ query: 'Prev OTP', answer: data['prev_otp'] });
                result.push({
                    query: 'OTP was there in Redis',
                    answer: data['is_otp_in_redis'].toString().toUpperCase(),
                });
                result.push({
                    query: 'Triggred API to send OTP',
                    answer: data['trigger_api_to_send_otp']
                        .toString()
                        .toUpperCase(),
                });
                result.push({
                    query: 'Message from API',
                    answer: data['message_from_api'],
                });
                result.push({
                    query: 'OTP is there in Redis after triggering API',
                    answer: data['is_otp_in_redis_after_api_hit']
                        .toString()
                        .toUpperCase(),
                });
                result.push({ query: 'New OTP', answer: data['new_otp'] });
            } else if (keys.length == 2) {
                result.push({ query: 'OTP', answer: data['otp'] });
                result.push({
                    query: 'OTP was there in Redis',
                    answer: data['is_otp_in_redis'].toString().toUpperCase(),
                });
            } else {
                result.push({
                    query: 'Clear cache status',
                    answer: data['clear_cache'],
                });
            }
            setdata(result);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const isInputValid = () => {
        if (user === null) {
            alert('Please enter Phone Number');
            return;
        }
        if (user.toString().length !== 10) {
            alert('Enter valid Phone Number');
            return;
        }
        // Retrieve the email from localStorage
        const email = profileData?.email;
        console.log(email)
        const emailList = ["vaishakh@apna.co", "abhishek.ranjan@apna.co", "ravib@apna.co", "saurav@apna.co"];

        if (!emailList.includes(email) && user.toString().startsWith("112233")) {
            alert('Phone Number cannot start with "112233"');
            return;
        }
        return true;
    };

    const getData1 = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (user !== null) {
            getdata(
                `${BACKEND_URL}/gcp/check-otp-redis?phone_number=${user}`,
                setData,
                setColumn,
                setLoading,
                {
                    align: 'left',
                }
            );
        }
        setButtonDisability(false);
    };

    const getData2 = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (user !== null) {
            getdata(
                `${BACKEND_URL}/gcp/check-otp-status?phone_number=${user}`,
                setData,
                setColumn,
                setLoading,
                {
                    align: 'left',
                }
            );
        }
        setButtonDisability(false);
    };

    const getData3 = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (user !== null) {
            getdata(
                `${BACKEND_URL}/gcp/clear-cache?phone_number=${user}`,
                setData,
                setColumn,
                setLoading,
                {
                    align: 'left',
                }
            );
        }
        setButtonDisability(false);
    };

    useEffect(() => {
        setData([]);
        setColumn([]);
        setOk('false');
    }, [user]);

    return (
        <>
            <Grid container rowGap={3} columnSpacing={3}>
                <Grid xs={isMobile ? 6 : 3}>
                    <TextField
                        label={'Phone Number'}
                        value={user}
                        //sx={{ maxWidth: 300 }}
                        onChange={(e) => {
                            setUser(e.target.value);
                        }}
                    />
                </Grid>
                <Grid xs={isMobile ? 6 : 3}>
                    <Box display="flex" width="100%">
                        <Button
                            children={'Get OTP From Redis'}
                            sx={{
                                //width: 200,
                                backgroundColor: '#0096FF',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            fullWidth={true}
                            onClick={getData1}
                            disabled={buttonDisability}
                        />
                    </Box>
                </Grid>
                <Grid xs={isMobile ? 6 : 3}>
                    <Box display="flex" width="100%">
                        <Button
                            children={'Send OTP'}
                            sx={{
                                //width: 200,
                                backgroundColor: '#ffa200',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            fullWidth={true}
                            onClick={getData2}
                            disabled={buttonDisability}
                        />
                    </Box>
                </Grid>
                <Grid xs={isMobile ? 6 : 3}>
                    <Box display="flex" width="100%">
                        <Button
                            children={'Clear Cache'}
                            sx={{
                                //width: 200,
                                backgroundColor: '#ff3300',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            fullWidth={true}
                            onClick={getData3}
                            disabled={buttonDisability}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box width="75vw" marginTop="20px">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <TaskTable
                                    data={data}
                                    columns={column}
                                    loading={loading}
                                />
                            );
                    }
                })()}
            </Box>
        </>
    );
}
