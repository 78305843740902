import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UsersIAM from './users_iam';
import MongoMetaData from './mongo_tagging';
import ElasticMetaData from './elastic_tagging';
import BigqueryAnalysisConfig from './bigquery_analysis_config';
const Administration = () => {
    return (
        <>
            <Routes>
                <Route path="users-iam/*" element={<UsersIAM />} />
                <Route path="mongo-tagging/*" element={<MongoMetaData />} />
                <Route path="elastic-tagging/*" element={<ElasticMetaData />} />
                <Route
                    path="bigquery-analysis-config/*"
                    element={<BigqueryAnalysisConfig />}
                />
            </Routes>
        </>
    );
};

export default Administration;
