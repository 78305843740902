import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const getMongoClusters = async (authAxios) => {
    try {
        const { data } = await authAxios.get(`/mongo/clusters`);
        return data;
    } catch (e) {
        console.error(e);
    }
};

const getMongoClusterEvents = async (authAxios, cluster) => {
    try {
        const params = {
            cluster_id: cluster ? cluster['id'] : null,
        };

        const { data } = await authAxios.get(`/mongo/events`, { params });
        return data;
    } catch (e) {
        console.error(e);
    }
};

const getMongoClustersMetadata = async (authAxios) => {
    try {
        const { data } = await authAxios.get(`/mongo/clusters-metadata`);
        return data;
    } catch (e) {
        console.error(e);
    }
};

const useMongoClusters = () => {
    const authAxios = useAxios();

    return useQuery(['mongo-clusters'], () => getMongoClusters(authAxios));
};

const useMongoClusterEvents = (cluster) => {
    const authAxios = useAxios();

    return useQuery(
        ['mongo-cluster-events', cluster],
        () => getMongoClusterEvents(authAxios, cluster)
        // { enabled: !!cluster }
    );
};

const useMongoClustersMetadata = () => {
    const authAxios = useAxios();

    return useQuery(['mongo-clusters-metadata'], () =>
        getMongoClustersMetadata(authAxios)
    );
};

export { useMongoClusters, useMongoClusterEvents, useMongoClustersMetadata };
