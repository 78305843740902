import React from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../configs';
import { useAuth } from './auth';

const axiosContext = React.createContext();

export const AxiosProvider = ({ children }) => {
    const { getAccessToken, invalidateUser } = useAuth();

    const authAxios = axios.create({
        baseURL: BACKEND_URL,
    });

    const getHeader = async () => {
        return 'Bearer ' + getAccessToken();
    };

    authAxios.interceptors.request.use(
        async (config) => {
            config.headers.Authorization = await getHeader();
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    authAxios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (!!error.response && error.response.status === 401) {
                invalidateUser();
            }
            return error;
        }
    );

    return (
        <axiosContext.Provider value={authAxios}>
            {children}
        </axiosContext.Provider>
    );
};

export const useAxios = () => {
    return React.useContext(axiosContext);
};
