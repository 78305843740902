import React from "react";
import { useState, useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { SECURITY_BACKEND_URL } from "../../configs";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { ExportToCsv } from "export-to-csv";
import { useAxios } from "../../contexts/axios";
import Button from "@mui/material/Button";
import MaterialReactTable from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function get(object, key, default_value) {
  if (Object.keys(object).includes(key)) {
      return object[key];
  } else {
      return default_value;
  }
}

export default function VulnerabilityList() {
  const nav = useNavigate();
  const axios = useAxios();
  const columns = [
    {
      header: "Agent ID",
      accessorKey: "Agent ID",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Agent Name",
      accessorKey: "Agent Name",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Name",
      accessorKey: "Name",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "CVE Name",
      accessorKey: "CVE Name",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Severity",
      accessorKey: "Severity",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "CVSS3_Score",
      accessorKey: "CVSS3_Score",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "CVSS2_Score",
      accessorKey: "CVSS2_Score",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Version",
      accessorKey: "Version",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Title",
      accessorKey: "Title",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Type",
      accessorKey: "Type",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Condition",
      accessorKey: "Condition",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Architecture",
      accessorKey: "Architecture",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Status",
      accessorKey: "Status",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      header: "Reference",
      accessorKey: "Reference",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
  ];
  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };
  const [nextPageToken, setNextPageToken] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const requestOptions = {
      method: "GET",
      url : `${SECURITY_BACKEND_URL}/wazuh/vulnerabilities`
    };
    axios(requestOptions).then(function(res) {
      setData([...data, ...res.data['entries']]);
      setNextPageToken(get(res.data, 'nextPageToken', null))
        setLoading(false);
      })
  }, []);

  const getNextData = () => {
    if (nextPageToken) {
      setLoading(true);
      const requestOptions = {
        method: "GET",
        url: `${SECURITY_BACKEND_URL}/wazuh/vulnerabilities?nextPageToken=${nextPageToken}`
      };
      axios(requestOptions).then(function(res){
        setData([...data, ...res.data['entries']]);
        setNextPageToken(get(res.data, 'nextPageToken', null))
        setLoading(false);
      })
    }
};

  return (
    <>
      <h5 class="mb-4 text-2xl font-extrabold text-gray-900 dark:text-white md:text-2xl lg:text-3xl">
        <span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
          Vulnerability Info
        </span>
      </h5>
      {loading ? (
        <Box sx={{ width: "100%", marginTop: "10%" }}>
          <LinearProgress />
        </Box>
      ) : (
        <MaterialReactTable
          className="p-4 w-full mt-auto text-center bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
          columns={columns}
          data={data}
          muiTableDetailPanelProps={"Vulnerabilities"}
          enableRowSelection
          enableStickyHeader
          enableTableHead
          enableDensityToggle={false}
          initialState={{
            density: "compact",
            columnPinning: {
              left: ["mrt-row-select"],
            },
          }}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleExportRows(table.getSelectedRowModel().rows)
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
              <Button
                color="warning"
                onClick={getNextData}
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                startIcon={<ArrowForwardIosIcon />}
                variant="contained"
                disabled={!!!nextPageToken}
              >
                Get Next Set of Data
              </Button>
            </Box>
          )}
        />
      )}
    </>
  );
}
