import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const fetchGcpData = async (authAxios,url) => {
  const params = {};
  try {
    const { data } = await authAxios.get(url);
    return data;
  } catch (e) {
    console.error(e);
  }
};

const useGcpData = ({url,key}) => {
  const authAxios = useAxios();
  return useQuery([key], () =>
    fetchGcpData(authAxios,url)
  );
};

export default useGcpData;
