import React from 'react';
import Datatable from './Datatable';
import TableContainer from '../table/table-container';
import TableHeadContainer from '../table/table-head-container';
import TableRowContainer from '../table/table-row-container';
import MaterialReactTable from 'material-react-table';
import moment from 'moment';
import { useAxios } from '../../contexts/axios';

const TableBodyContainer = ({ children }) => {
    return <tbody>{children}</tbody>;
};

const TableHeaderCell = ({ children }) => {
    return (
        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer select-none">
            {children}
        </th>
    );
};

const TableDataCell = ({ children }) => {
    return (
        <td className="px-6 py-4 text-sm font-medium text-gray-900 text-left">
            {children}
        </td>
    );
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const EventPill = ({ value }) => {
    const event = value ? value.toLowerCase() : 'unknown';

    return (
        <span
            className={classNames(
                'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap',
                event.startsWith('scale-down')
                    ? 'bg-green-100 text-green-700'
                    : null,
                event.startsWith('scale-up')
                    ? 'bg-orange-100 text-orange-700'
                    : null,
                event.startsWith('create')
                    ? 'bg-yellow-100 text-yellow-700'
                    : null,
                event.startsWith('terminate')
                    ? 'bg-gray-200 text-gray-700'
                    : null
            )}
        >
            {event}
        </span>
    );
};

const CostPill = ({ event_type, cost }) => {
    const event = event_type ? event_type.toLowerCase() : 'unknown';
    const cost_string = `$${(cost * 720) / 100} / mo`;
    const cost_delta =
        event.startsWith('scale-down') || event.startsWith('terminate')
            ? '-'
            : '+';

    return (
        <span
            className={classNames(
                'px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap',
                event.startsWith('scale-down') || event.startsWith('terminate')
                    ? 'bg-green-100 text-green-700'
                    : null,
                event.startsWith('scale-up') || event.startsWith('create')
                    ? 'bg-red-100 text-red-700'
                    : null
            )}
        >
            {`${cost_delta} ${cost_string}`}
        </span>
    );
};

const MongoAtlasEventsTable = ({ data, loading }) => {
    const column = [
        {
            header: 'Cluster Id',
            accessorKey: 'cluster_id',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Cluster Name',
            accessorKey: 'cluster_name',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <div className="whitespace-nowrap">{cell.getValue()}</div>
            ),
        },
        {
            header: 'Event',
            accessorKey: 'event_type',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => <EventPill value={cell.getValue()} />,
        },
        {
            header: 'Description',
            accessorKey: 'event_description',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Cost',
            accessorKey: 'cost',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell, row }) => (
                <CostPill
                    event_type={row.getValue('event_type')}
                    cost={cell.getValue()}
                />
            ),
        },
        {
            header: 'Event Time',
            accessorKey: 'created_at',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <div className="whitespace-nowrap">
                    {moment
                        .utc(cell.getValue())
                        .local()
                        .format('MMM D, h:mm a')}
                </div>
            ),
        },
    ];
    return (
        
        <Datatable data={data} columns={column} loading={loading}/>
        
    );
};

export default MongoAtlasEventsTable;
