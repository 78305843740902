//import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../../components/typography/header-title';
//import Admin from '../../../components/access/pages/admin';
//import Home from '../../components/securityComponent/home';
import Usermanagement from '../../../components/accessComponents/usermanagement';
export default function UserManagement() {
    return (
        <>
            <HeaderTitle
                title={'User Management'}
                subtitle={
                    'Manage users'
                }
            />
            <Usermanagement/>
        </>
    );
}
