import React from 'react';
import Auth from '../hooks/useAuth';

const authContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const auth = Auth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return React.useContext(authContext);
};
