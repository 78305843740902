import React, {useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { SECURITY_BACKEND_URL } from "../../configs";
import { useAuth } from "../../contexts/auth";
import { useAxios } from "../../contexts/axios";
import { TextField,Box,Button } from "@mui/material";

const Task_sheet = () => {
  const [addFormData, setAddFormData] = useState("");
  const {isSecurityAdmin} = useAuth()
  const axios = useAxios()
  const [Data, setData] = useState({"src":"","id":""});
  const nav = useNavigate()

  useEffect(() => {
    // Using fetch to fetch the api from 
    // flask server it will be redirected to proxy
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json',"Authorization": `Bearer ${localStorage.getItem('JWT')}` },
      url : `${SECURITY_BACKEND_URL}/task`
  };
    axios(requestOptions).then(function(res) {
            setData(res.data);
        })
}, []);
  const handleAddFormChange = (val) => {
    setAddFormData(val);
    
  };
  const handleAddFormSubmit = () => {
    const newdata = {
      'src':addFormData,
      'id':'1'
    }
    setData(newdata);
    const requestOptions = {
      method: 'PUT',
      url : `${SECURITY_BACKEND_URL}/tasksheet`,
      data: newdata
  };
  axios(requestOptions)
  };
  return (
    <>
      <div class="card text-center border border shadow-lg rounded position-center margin" style={{ width: '100%' }}>
        <div class="card-header" style={{ color: 'white',backgroundColor:'darkgreen', fontSize:"20px" ,fontWeight:'bold'}}>
          TASK SHEET
        </div>
        <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic" name="src" label="Enter url" variant="outlined" onChange={(e)=>{handleAddFormChange(e.target.value)}}/>
      <Button variant="contained" disabled={!isSecurityAdmin} onClick={handleAddFormSubmit}>Add</Button>
        </Box>
        <div class="card-body">
          <iframe src = {Data.src} style={{width:'1500px',height:'700px'}}/>
        </div>
      </div>
    </>
  )
}
export default Task_sheet