import React from 'react';
import { Column } from '@ant-design/plots';

export default function SkuGraph({ data, spikeData}) {
    var posDates = spikeData['posSpikeDates'];
    var negDates = spikeData['negSpikeDates'];
    const config = {
        data,
        height: 130,
        width: 250,
        isStack: true,
        xField: 'date',
        yField: 'cost',
        //seriesField: 'type',
        xAxis: {
            offset:2,
            //range: [0, 1],
            //tickCount: 5,
        },
        columnStyle: ({ date }) => {
            //var color = get(spikeDates,date,0);
            //console.log(date,color);
            var isPosSpike = posDates.includes(date);
            var isNegSpike = negDates.includes(date);
            return {
                fill:
                    isPosSpike
                        ? 'l(270) 0.5:#F4664A 1:#F4664A'
                        : isNegSpike
                        ? 'l(270) 0.5:#03ab41 1:#03ab41'
                        : 'l(270) 0.5:#7ec2f3 1:#1890ff',
            };
        },
    };

    return <Column {...config} />;
}
