import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AllotTeam from './allotTeam';
import ManagerDashboard from './managerDashboard';
import PendingRequest from './pendingRequest';
const Manager = () => {
    return (
        <>
            <Routes>
                <Route path="pendingrequest/*" element={<PendingRequest />} />
                <Route path="allotteam/*" element={<AllotTeam />} />
                <Route path="dashboard/*" element={<ManagerDashboard />} />
            </Routes>
        </>
    );
};

export default Manager;
