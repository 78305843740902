import { React, useState, useEffect } from 'react';
import Datatable from '../costing/Datatable';
import Loading from '../costing/Loading';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import DetailsOutlinedIcon from '@mui/icons-material/DetailsOutlined';
import ListItemIcon from '@mui/material';
import MenuItem from '@mui/material';
import { ExportToCsv } from 'export-to-csv';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
const TableFirstDataCell = ({ children }) => {
    return (
        <td
            style={{ fontSize: '13px' }}
            className="min-w-[300px] p-2 text-sm font-bold text-gray-900 text-left"
        >
            {children}
        </td>
    );
};

export default function TotalDescriptionTable({
    tool,
    resource_key,
    resource_value,
    project,
    startDate,
    endDate,
    credit,
}) {
    const authAxios = useAxios();
    const [loading, setLoading] = useState(false);
    const [finalData, setFinalData] = useState([]);
    const [column, setColumn] = useState([]);
    useEffect(()=>{
        if(tool==="Kubernetes Engine/CCD8-9BF1-090E"){
            setColumn([
                {
                    header: 'Sku Description',
                    accessorKey: 'skuDescription',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                },
        
                {
                    header: 'TOTAL',
                    accessorKey: 'total',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                },
            ])
        }
        else{
            setColumn([
                {
                    header: 'Sku Id',
                    accessorKey: 'skuId',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                },
        
                {
                    header: 'TOTAL',
                    accessorKey: 'total',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                },
            ])
        }
    },[tool])
    const [nextPageToken, setNextPageToken] = useState('');
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const getGcpUnitCostingData = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            if (nextPageToken !== '') {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/unitcosting/gcp/skus?toolName=${tool}&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&view=${'total'}&projectId=${project}&next_page_token=${nextPageToken}&resource_key=${resource_key}&resource_value=${resource_value}&periodicity=daily&credit=${credit}`
                );
                setFinalData(data['entries']);
                setNextPageToken(get(data, 'nextPageToken', ''));
            } else {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/unitcosting/gcp/skus?toolName=${tool}&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&view=${'total'}&projectId=${project}&resource_key=${resource_key}&resource_value=${resource_value}&periodicity=daily&credit=${credit}`
                );
                setFinalData(data['entries']);
                setNextPageToken(get(data, 'nextPageToken', ''));
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getGcpUnitCostingData();
    }, []);

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(finalData);
    };
    //groupby acccording to grouBy value

    return (
        <>
            {
                <MaterialReactTable
                    className="p-4 w-full mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                    columns={column}
                    data={finalData}
                    enableFullScreenToggle={false}
                    enableStickyHeader
                    enableStickyFooter
                    muiTableBodyCellProps={{
                        sx: {
                            fontWeight: 'bold',
                            fontSize: '13px',
                        },
                    }}
                    enableDensityToggle={false}
                    initialState={{
                        density: 'compact',
                        sorting: [
                            {
                                id: 'total',
                                desc: true,
                            },
                        ],
                    }}
                    state={{
                        isLoading: loading,
                    }}
                    positionToolbarAlertBanner="bottom"
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Data
                            </Button>
                        </Box>
                    )}
                />
            }
        </>
    );
}
