import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ReactJson from 'react-json-view';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Fragment } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FlexRow from '../layout/FlexRow';
import PrimaryButton from '../button/PrimaryButton';
import DateTimePickerInput from '../DateTimePickerInput';
import SearchDD from '../SearchDD';
import TextInput from '../TextInput';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

export default function AuditPIIuserIdjobId() {
    var dateObj = new Date();
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [columnList, setColumnList] = useState([
        { label: 'User Id', id: 'userId' },
        { label: 'Job Id', id: 'jobId' },
    ]);
    const [columnName, setColumnName] = useState(columnList[0]);
    const [id, setId] = useState(null);
    const [log, setLog] = useState([]);
    let [isOpen, setIsOpen] = useState(false);
    const [buttonDisability, setButtonDisability] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    //console.log(id);
    function openModal() {
        setIsOpen(true);
    }
    const [index, setIndex] = useState(0);
    const [column, setColumn] = useState([
        {
            header: 'Name',
            accessorKey: 'name',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Email',
            accessorKey: 'email',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Date',
            accessorKey: 'date',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
    ]);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        entries: [],
        nextPageToken: '',
    });
    const [nextPageToken, setNextPageToken] = useState(null);
    const [finalData, setFinalData] = useState([]);
    const [ok, setOk] = useState('false');
    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
            if (get(data, 'entries', []).length == 0) {
                setloading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };
    const isDateInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };
    const getNextData = () => {
        if (!isDateInputValid() || loading) {
            return;
        }
        setButtonDisability(true);
        setOk('true');
        if (nextPageToken) {
            getdata(
                `${BACKEND_URL}/gcp/audit-pii-data/${id}?type=${columnName.id}&next_page_token=${nextPageToken}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&id=${id}`,
                setData,
                setLoading
            );
        } else {
            getdata(
                `${BACKEND_URL}/gcp/audit-pii-data/${id}?type=${columnName.id}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&id=${id}`,
                setData,
                setLoading
            );
        }
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(finalData);
    };
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleclick = (ind) => {
        setIndex(ind);
        openModal();
    };

    useEffect(() => {
        setOk('false');
        setFinalData([]);
        setLog([]);
        setData({
            entries: [],
            nextPageToken: '',
        });
        setNextPageToken(null);
        setButtonDisability(false);
    }, [startDate, endDate,columnName,id]);

    useEffect(() => {
        var Data = get(data, 'entries', []);
        if (Data.length > 0) {
            var temp = [];
            for (var i = 0; i < Data.length; i++) {
                temp.push({
                    name: Data[i].name,
                    email: Data[i].email,
                    date: Data[i].date
                })
            }
            setFinalData([...finalData, ...temp]);
            setNextPageToken(get(data, 'nextPageToken', null));
            setLoading(false);
        }
    }, [data]);

    return (
        <>
            <FlexRow>
                <FlexRow>
                    <DateTimePickerInput
                        label={'Start Date'}
                        value={startDate}
                        onChange={setStartDate}
                        minDate={new Date('2022-01-03T18:30:00Z')}
                        maxDate={endDate}
                    />
                </FlexRow>
                <FlexRow>
                    <DateTimePickerInput
                        label={'End Date'}
                        value={endDate}
                        onChange={setEndDate}
                        maxDate={dateObj}
                    />
                </FlexRow>
                <SearchDD
                    label={'Select Key'}
                    emptyLabel={'Select Key'}
                    options={columnList}
                    value={columnName}
                    onChange={setColumnName}
                />
                <TextInput
                    label={`Enter ${columnName.label}`}
                    emptyLabel={'Enter value'}
                    value={id}
                    setId={setId}
                    marginTop={'44px'}
                />
            </FlexRow>
            <div className="mt-8">
                <PrimaryButton
                    title={'Get Audit Data'}
                    onClick={getNextData}
                    disabled={buttonDisability}
                />
            </div>

            <div className="grid grid-rows-2 p-4">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <>
                                    <div className="p-1">
                                        <MaterialReactTable
                                            data={finalData}
                                            columns={column}
                                            enableStickyFooter
                                            enableStickyHeader
                                            initialState={{
                                                density: 'compact',
                                                sorting: [
                                                      {
                                                        id: 'date', //sort by age by default on page load
                                                        desc: true,
                                                      },
                                                    ],
                                                
                                            }}
                                            filterFns={{
                                                customFilterFn: (
                                                    row,
                                                    id,
                                                    filterValue
                                                ) => {
                                                    return row
                                                        .getValue(id)
                                                        .includes(filterValue);
                                                }
                                            }}
                                            
                                            state={{
                                                isLoading: loading,
                                            }}
                                            enableRowSelection
                                            enableDensityToggle={false}
                                            
                                            positionToolbarAlertBanner="bottom"
                                            renderTopToolbarCustomActions={({
                                                table,
                                            }) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '1rem',
                                                        p: '0.5rem',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                        onClick={
                                                            handleExportData
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export All Data
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            table.getRowModel()
                                                                .rows.length ===
                                                            0
                                                        }
                                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                        onClick={() =>
                                                            handleExportRows(
                                                                table.getRowModel()
                                                                    .rows
                                                            )
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export Page Rows
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            !table.getIsSomeRowsSelected() &&
                                                            !table.getIsAllRowsSelected()
                                                        }
                                                        //only export selected rows
                                                        onClick={() =>
                                                            handleExportRows(
                                                                table.getSelectedRowModel()
                                                                    .rows
                                                            )
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export Selected Rows
                                                    </Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={getNextData}
                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                        startIcon={
                                                            <ArrowForwardIosIcon />
                                                        }
                                                        variant="contained"
                                                        disabled={
                                                            !!!nextPageToken ||
                                                            loading
                                                        }
                                                    >
                                                        Get Next Set of Data
                                                    </Button>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                </>
                            );
                    }
                })()}
            </div>
        </>
    );
}
