import React from 'react';
import BackButton from '../../../components/navigation/back-button';
import HeaderTitle from '../../../components/typography/header-title';
//import ParsedLogsTableUsageInfo from '../../../components/parsing_logs/parsedLogsTable';
import QueryLogsTable from '../../../components/parsing_logs/queryLogsTable';
export default function QueryLogs() {
    return (
        <>
            
            <HeaderTitle
                title={'Threshold Query Logs'}
                subtitle={
                    'Visualize Previous (having billed bytes more than 20GB) and Realtime Queries '
                }
            />
            <QueryLogsTable/>
        </>
    );
}
