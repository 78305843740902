import React, { useState, useEffect } from 'react';
//import Current_Status from '../../components/security/Current_Status';
//import Tot_Sec_Issues from '../../components/security/Tot_Sec_Issues';
//import IssuesPastD from '../../components/security/IssuesPastD';
//import { useNavigate } from 'react-router';
import HeaderTitle from '../../components/typography/header-title';
//import { BACKEND_URL } from '../../configs';
import Home from '../../components/securityComponent/home';
export default function SecurityHome() {
    return (
        <>
            <HeaderTitle
                title={'Jira Security Issues'}
                subtitle={
                    'Track total security issues, current status and past due date'
                }
            />
            <Home />
        </>
    );
}
