import MaterialReactTable from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import Svg from '../../SVG/svg';
import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import SkuGraph from './skuGraph';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
function reverse(datevalue) {
    var datelst = datevalue.split('-');
    return datelst[2] + '-' + datelst[1] + '-' + datelst[0];
}
export default function OrgProjectTable({ title, data, spikes }) {
    //console.log(data);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    //console.log(spikes);
    useEffect(() => {
        if (data.length > 0) {
            var value = [];
            for (var i = 0; i < data.length; i++) {
                var keys = Object.keys(data[i]);
                var dateWiseCost = [];
                var skuCost = 0;
                for (var j = 0; j < keys.length; j++) {
                    if (keys[j] !== 'skuId') {
                        skuCost+=data[i][keys[j]];
                        dateWiseCost.push({
                            date: reverse(keys[j]),
                            cost: data[i][keys[j]],
                        });
                    }
                }
                //"apnatime-onehouse/Networking/E505-1604-58F8/NA/015F-5732-FFF0"
                var val = data[i]['skuId'].split('/');
                value.push({
                    project: val[0],
                    service: val[1],
                    object: val[3],
                    sku: val[4],
                    graph: {cost:dateWiseCost,skuTotal:spikes[data[i]['skuId']]['totPosSpike'],spikes:spikes[data[i]['skuId']]},
                });
            }
            setTableData(value);
            setLoading(false);
        }
    }, [data]);

    const columns = [
        {
            header: 'Project',
            accessorKey: 'project',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
            Cell: ({ cell }) => (
                <Typography
                    fontFamily={'serif'}
                    variant="overline"
                    style={{
                        marginLeft: '5px',
                        fontWeight: 'bold',
                        color: '#0E86D4',
                        fontSize: '13px',
                    }}
                >
                    {cell.getValue()}
                </Typography>
            ),
        },
        {
            header: 'Service',
            accessorKey: 'service',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
            Cell: ({ cell }) => (
                <Typography
                    fontFamily={'serif'}
                    variant="overline"
                    style={{
                        marginLeft: '5px',
                        fontWeight: 'bold',
                        color: '#0E86D4',
                        fontSize: '13px',
                    }}
                >
                    {cell.getValue()}
                </Typography>
            ),
        },
        {
            header: 'Instance/Bucket/Cluster',
            accessorKey: 'object',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
            Cell: ({ cell }) => (
                <Typography
                    fontFamily={'serif'}
                    variant="overline"
                    style={{
                        marginLeft: '5px',
                        fontWeight: 'bold',
                        color: '#0E86D4',
                        fontSize: '13px',
                    }}
                >
                    {cell.getValue()}
                </Typography>
            ),
        },
        {
            header: 'Sku',
            accessorKey: 'sku',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
            Cell: ({ cell }) => (
                <Typography
                    fontFamily={'serif'}
                    variant="overline"
                    style={{
                        marginLeft: '5px',
                        fontWeight: 'bold',
                        color: '#0E86D4',
                        fontSize: '13px',
                    }}
                >
                    {cell.getValue()}
                </Typography>
            ),
        },
        {
            header: 'Graph',
            accessorKey: 'graph',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            sortingFn: 'myCustomSortingFn',
            Cell: ({ cell, row }) => <SkuGraph data={cell.getValue().cost} spikeData={cell.getValue().spikes} />,
        },
    ];

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    return (
        <>
            <MaterialReactTable
                className="p-4 w-auto max-h-[300px] mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                columns={columns}
                data={tableData}
                enableStickyHeader
                
                /*filterFns={{
                    customFilterFn: (row, id, filterValue) => {
                        return row.getValue(id).includes(filterValue);
                    },
                }}*/
                enableDensityToggle={false}
                enablePagination={false}
                state={{
                    isLoading: loading,
                    /*columnFilters:
                        filter === 'All'
                            ? []
                            : [{ id: 'service', value: filter }],*/
                }}
                initialState={{
                    density: 'compact',
                    sorting: [
                        {
                            id: 'graph',
                            desc: true
                        },
                    ],
                    //pagination:{pageIndex:0,pageSize:3}
                }}
                sortingFns={{
                    //will add a new sorting function to the list of other sorting functions already available
                    myCustomSortingFn: (rowA, rowB, columnId) =>
                        rowA.getValue(columnId)['skuTotal'] >
                        rowB.getValue(columnId)['skuTotal']
                            ? 1
                            : -1,
                }}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={() => (
                    <span className="inline-flex">
                        <Svg serviceName={'project'} />
                        <Typography
                            fontFamily={'serif'}
                            variant="h6"
                            style={{
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                color: '#0E86D4',
                            }}
                        >
                            {title}
                        </Typography>
                    </span>
                )}
            />
        </>
    );
}
