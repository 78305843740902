import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import SearchDD from '../SearchDD';
import Chip from '@mui/material/Chip';
import TimePickerInput from '../costing/TimePickerInput';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
const StatusPill = ({ value }) => {
    return (
        <>
            <div className="inline-flex">
                <div style={{ marginRight: '2px' }}>
                    {value === 'FAILURE' ? (
                        <CancelIcon color="error" style={{ width: '18px' }} />
                    ) : value === 'SUCCESS' ? (
                        <CheckCircleIcon
                            color="success"
                            style={{ width: '18px' }}
                        />
                    ) : value === 'UPDATING' ? (
                        <CircularProgress color="warning" size={18} />
                    ) : value === 'STARTED' ? (
                        <AlarmOnIcon
                            style={{ width: '18px' }}
                            color="primary"
                        />
                    ) : (
                        <PendingIcon
                            style={{ width: '18px' }}
                            color="disabled"
                        />
                    )}
                </div>
                {value === 'FAILURE'
                    ? 'Failed'
                    : value === 'SUCCESS'
                    ? 'Success'
                    : value === 'UPDATING'
                    ? 'Updating'
                    : value === 'STARTED'
                    ? 'Started'
                    : 'Has not run yet'}
                <div></div>
            </div>
        </>
    );
};

function getTimeDifference(time1, time2) {
    //console.log((time2?.getTime() - time1?.getTime()) / 3600000);
    if (time1.getTime() > time2.getTime())
        return (time1?.getTime() - time2?.getTime()) / 3600000;
    else return (24 - (time2?.getTime() - time1?.getTime()) / 3600000) % 24;
}

function compareTime(scaleUpFrequency, scaleDownFrequency) {
    var time1 = scaleUpFrequency.getTime() / 1000;
    var time2 = scaleDownFrequency.getTime() / 1000;
    if (Math.abs(time1 - time2) <= 1800) {
        return true;
    }
    return false;
}

/*function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        if (object[key] === '') {
            return default_value;
        } else {
            return object[key];
        }
    } else {
        return default_value;
    }
}*/

function parseCron(cron) {
    const parser = require('cron-parser');
    var cronExpression = cron;
    //console.log(cronExpression);
    var interval = parser.parseExpression(cronExpression).next();
    //console.log(interval.getDay());
    var res = `${interval.getDate().toString()}/${(
        interval.getMonth() + 1
    ).toString()}/${interval.getFullYear().toString()} ${
        interval.getHours() > 12
            ? (interval.getHours() - 12).toString()
            : interval.getHours().toString()
    }:${interval.getMinutes().toString()}:00 ${
        interval.getHours() >= 12 ? 'PM' : 'AM'
    }`;
    //console.log(interval);
    return res;
}

function getTime(cron) {
    const parser = require('cron-parser');
    var cronExpression = cron;
    //console.log(cronExpression);
    var interval = parser.parseExpression(cronExpression).next().toISOString();
    return new Date(interval);
}

function parseTime(Time) {
    var date = Time.split('T')[0].split('-');
    var time = Time.split('T')[1].split(':');
    var hr = parseInt(time[0]);
    var res = `${date[2]}/${date[1]}/${date[0]} ${hr > 12 ? hr - 12 : hr}:${
        time[1]
    }:${time[2]} ${hr >= 12 ? 'PM' : 'AM'}`;
    //console.log(interval);
    return res;
}
function getCron(Time) {
    var time = new Date(Time);
    time.setHours(time.getHours() + 5);
    time.setMinutes(time.getMinutes() + 30);
    time = time.toISOString();
    var minuteHour = time.split('T')[1].split(':');
    var cron = `${minuteHour[1]} ${minuteHour[0]} * * *`;
    return cron;
}
function getCronDifference(cron1, cron2) {
    const parser = require('cron-parser');
    var time1 = parser.parseExpression(cron1).next();
    var time2 = parser.parseExpression(cron2).next();
    //(time1, time2);
    //console.log(getTimeDifference(time1,time2));
    return getTimeDifference(time1, time2);
}
const mongoCostPerHour = {
    M10: 0.1,
    M20: 0.23,
    M30: 0.53,
    M40: 1.03,
    M50: 1.99,
    M60: 3.95,
    M80: 7.36,
    M140: 11.46,
};

function getPerHourCostDifference(config1, config2) {
    return Math.abs(mongoCostPerHour[config1] - mongoCostPerHour[config2]);
}
const distributionsIndex = {
    M10: 0,
    M20: 1,
    M30: 2,
    M40: 3,
    M50: 4,
    M60: 5,
    M80: 6,
    M100: 7,
    M140: 8,
    M200: 9,
    M300: 10,
    R40: 11,
    R50: 12,
    R60: 13,
    R80: 14,
    R200: 15,
    R300: 16,
    R400: 17,
    R700: 18,
    M40_NVME: 19,
    M50_NVME: 20,
    M60_NVME: 21,
    M80_NVME: 22,
    M200_NVME: 23,
    M400_NVME: 24,
};
const distributions = [
    'M10',
    'M20',
    'M30',
    'M40',
    'M50',
    'M60',
    'M80',
    'M100',
    'M140',
    'M200',
    'M300',
    'R40',
    'R50',
    'R60',
    'R80',
    'R200',
    'R300',
    'R400',
    'R700',
    'M40_NVME',
    'M50_NVME',
    'M60_NVME',
    'M80_NVME',
    'M200_NVME',
    'M400_NVME',
];

const MongoTbasTable = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [clustersDup, setClustersDup] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [editLoader, setEditLoader] = useState([]);
    const [approveLoader, setApproveLoader] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const axios = useAxios();
    const { isAdminApprover } = useAuth();
    async function call(url, data, method, setloading, index) {
        if (index === -1) {
            setloading(true);
        } else {
            if (method === 'GET') {
                approveLoader[index] = true;
                setloading(approveLoader);
            } else {
                editLoader[index] = true;
                setloading(editLoader);
            }
        }
        var requestOptions = {};
        if (method === 'GET') {
            requestOptions = {
                url: `${BACKEND_URL}/mongo/tbas${url}`,
                method: method,
            };
        } else {
            requestOptions = {
                url: `${BACKEND_URL}/mongo/tbas${url}`,
                method: method,
                data: data,
            };
        }
        await axios(requestOptions).then(() => {
            if (index === -1) {
                setloading(false);
            } else {
                if (method === 'GET') {
                    approveLoader[index] = false;
                    setloading(approveLoader);
                } else {
                    editLoader[index] = false;
                    setloading(editLoader);
                }
                window.location.reload(false);
            }
        });
    }

    async function getData() {
        setDataLoader(true);
        var requestOptions = {
            url: `${BACKEND_URL}/mongo/tbas`,
            method: 'GET',
        };
        await axios(requestOptions).then(function (res) {
            var data = res.data;
            var tempLoader1 = [];
            var tempLoader2 = [];
            for (var i = 0; i < data.length; i++) {
                tempLoader1.push(false);
                tempLoader2.push(false);
                data[i]['estimatedSaving'] =
                    getCronDifference(
                        data[i]['scaleUpFrequency'],
                        data[i]['scaleDownFrequency']
                    ) *
                    getPerHourCostDifference(
                        data[i]['scaleUpConfig'],
                        data[i]['scaleDownConfig']
                    ) *
                    30;
            }
            setTableData(data);
            setEditLoader(tempLoader1);
            setApproveLoader(tempLoader2);
            setDataLoader(false);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/mongo/clusters-metadata/size` }).then(
            function (res) {
                var temp = res.data;
                for (var i = 0; i < tableData.length; i++) {
                    delete temp[tableData[i]['clusterName']];
                }
                setClusters(temp);
            }
        );
    }, [tableData]);

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/mongo/clusters-metadata/size` }).then(
            function (res) {
                setClustersDup(res.data);
            }
        );
    }, []);

    //console.log(tableData)
    const handleCreateNewRow = (values) => {
        if (
            !isAdminApprover() &&
            !window.confirm(
                `Once you confirm you won't be able to change. Are you sure you want to add this Tbas Config?`
            )
        ) {
            return;
        }
        var val = {
            clusterName: values['clusterName'],
            creatorEmail: values['creatorEmail'],
            scaleUpStatus: values['scaleUpStatus'],
            scaleDownStatus: values['scaleDownStatus'],
            scaleUpFrequency: getCron(values['scaleUpFrequency']),
            scaleUpConfig: values['scaleUpConfig'],
            scaleDownFrequency: getCron(values['scaleDownFrequency']),
            scaleDownConfig: values['scaleDownConfig'],
            slackChannelId: values['slackChannelId'],
            scaleUpLastRun: values['scaleUpLastRun'],
            scaleUpNextRun: parseCron(getCron(values['scaleUpFrequency'])),
            scaleDownLastRun: values['scaleDownLastRun'],
            scaleDownNextRun: parseCron(getCron(values['scaleDownFrequency'])),
            approvedStatus: values['approvedStatus'],
        };
        call('', val, 'POST', setDataLoader, -1);
        var temp = tableData;
        val['estimatedSaving'] =
            getCronDifference(
                val['scaleUpFrequency'],
                val['scaleDownFrequency']
            ) *
            getPerHourCostDifference(
                val['scaleUpConfig'],
                val['scaleDownConfig']
            ) *
            30;
        temp.push(val);
        setTableData([...temp]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        var configMaxIndex =
            distributionsIndex[
                clustersDup[values['clusterName']]?.max_instance_size
            ];
        var configMinIndex =
            distributionsIndex[
                clustersDup[values['clusterName']]?.min_instance_size
            ];
        var valueMaxIndex = distributionsIndex[values['scaleUpConfig']];
        var valueMinIndex = distributionsIndex[values['scaleDownConfig']];

        if (valueMaxIndex > configMaxIndex || valueMinIndex < configMinIndex) {
            alert(
                `ScaleUp config value should be <= ${
                    clustersDup[values['clusterName']]?.max_instance_size
                } and ScaleDown config value should be >= ${
                    clustersDup[values['clusterName']]?.min_instance_size
                }`
            );
            return;
        }
        if (valueMaxIndex <= valueMinIndex) {
            alert(
                'ScaleUp config value should be greater than ScaleDown config value'
            );
            return;
        }
        if (
            compareTime(
                getTime(values['scaleUpFrequency']),
                getTime(values['scaleDownFrequency'])
            )
        ) {
            alert(
                'Difference between ScaleUp and ScaleDown Frequency should be atleast greater than 30 min'
            );
            return;
        }

        if (!Object.keys(validationErrors).length) {
            var temp = tableData[row.index];
            tableData[row.index] = {
                clusterName: values['clusterName'],
                creatorEmail: values['creatorEmail'],
                scaleUpStatus:
                    values['scaleUpFrequency'] !==
                    tableData[row.index]['scaleUpFrequency']
                        ? ''
                        : values['scaleUpStatus'],
                scaleDownStatus:
                    values['scaleDownFrequency'] !==
                    tableData[row.index]['scaleDownFrequency']
                        ? ''
                        : values['scaleDownStatus'],
                scaleUpFrequency: values['scaleUpFrequency'],
                scaleUpConfig: values['scaleUpConfig'],
                scaleDownFrequency: values['scaleDownFrequency'],
                scaleDownConfig: values['scaleDownConfig'],
                estimatedSaving:
                    getCronDifference(
                        values['scaleUpFrequency'],
                        values['scaleDownFrequency']
                    ) *
                    getPerHourCostDifference(
                        values['scaleUpConfig'],
                        values['scaleDownConfig']
                    ) *
                    30,
                slackChannelId: values['slackChannelId'],
                scaleUpLastRun:
                    values['scaleUpFrequency'] !==
                    tableData[row.index]['scaleUpFrequency']
                        ? ''
                        : values['scaleUpLastRun'],
                scaleUpNextRun:
                    values['scaleUpFrequency'] !==
                    tableData[row.index]['scaleUpFrequency']
                        ? parseCron(values['scaleUpFrequency'])
                        : values['scaleUpNextRun'],
                scaleDownLastRun:
                    values['scaleDownFrequency'] !==
                    tableData[row.index]['scaleDownFrequency']
                        ? ''
                        : values['scaleDownLastRun'],
                scaleDownNextRun:
                    values['scaleDownFrequency'] !==
                    tableData[row.index]['scaleDownFrequency']
                        ? parseCron(values['scaleDownFrequency'])
                        : values['scaleDownNextRun'],
                approvedStatus: values['approvedStatus'],
            };
            call(
                '',
                {
                    clusterName: values['clusterName'],
                    creatorEmail: values['creatorEmail'],
                    scaleUpStatus:
                        values['scaleUpFrequency'] !== temp['scaleUpFrequency']
                            ? ''
                            : values['scaleUpStatus'],
                    scaleDownStatus:
                        values['scaleDownFrequency'] !==
                        temp['scaleDownFrequency']
                            ? ''
                            : values['scaleDownStatus'],
                    scaleUpFrequency: values['scaleUpFrequency'],
                    scaleUpConfig: values['scaleUpConfig'],
                    scaleDownFrequency: values['scaleDownFrequency'],
                    scaleDownConfig: values['scaleDownConfig'],
                    slackChannelId: values['slackChannelId'],
                    scaleUpLastRun:
                        values['scaleUpFrequency'] !== temp['scaleUpFrequency']
                            ? ''
                            : values['scaleUpLastRun'],
                    scaleUpNextRun:
                        values['scaleUpFrequency'] !== temp['scaleUpFrequency']
                            ? parseCron(values['scaleUpFrequency'])
                            : values['scaleUpNextRun'],
                    scaleDownLastRun:
                        values['scaleDownFrequency'] !==
                        temp['scaleDownFrequency']
                            ? ''
                            : values['scaleDownLastRun'],
                    scaleDownNextRun:
                        values['scaleDownFrequency'] !==
                        temp['scaleDownFrequency']
                            ? parseCron(values['scaleDownFrequency'])
                            : values['scaleDownNextRun'],
                    approvedStatus: values['approvedStatus'],
                },
                'PUT',
                setEditLoader,
                row.index
            );
            setTableData([...tableData]);
        }
        exitEditingMode();
    };

    const handleApprove = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to ${
                        row.getValue('approvedStatus') === 'True'
                            ? 'Reject'
                            : 'Approve'
                    } ${row.getValue('clusterName')}`
                )
            ) {
                return;
            }
            tableData[row.index] = {
                clusterName: row.getValue('clusterName'),
                creatorEmail: row.getValue('creatorEmail'),
                scaleUpStatus: row.getValue('scaleUpStatus'),
                scaleDownStatus: row.getValue('scaleDownStatus'),
                scaleUpFrequency: row.getValue('scaleUpFrequency'),
                scaleUpConfig: row.getValue('scaleUpConfig'),
                scaleDownFrequency: row.getValue('scaleDownFrequency'),
                scaleDownConfig: row.getValue('scaleDownConfig'),
                scaleDownConfig: row.getValue('scaleDownConfig'),
                estimatedSaving: row.getValue('estimatedSaving'),
                slackChannelId: row.getValue('slackChannelId'),
                scaleUpLastRun: row.getValue('scaleUpLastRun'),
                scaleUpNextRun: row.getValue('scaleUpFrequency'),
                scaleDownLastRun: row.getValue('scaleDownLastRun'),
                scaleDownNextRun: row.getValue('scaleDownFrequency'),
                approvedStatus:
                    row.getValue('approvedStatus') === 'True'
                        ? 'False'
                        : 'True',
            };
            call(
                `/${
                    row.getValue('approvedStatus') === 'True'
                        ? 'reject'
                        : 'approve'
                }?name=${row.getValue('clusterName')}`,
                null,
                'GET',
                setApproveLoader,
                row.index
            );
            setTableData([...tableData]);
        },
        [tableData]
    );
    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        'clusterName'
                    )}`
                )
            ) {
                return;
            }
            call(
                '',
                {
                    clusterName: row.getValue('clusterName'),
                    creatorEmail: row.getValue('creatorEmail'),
                    scaleUpStatus: row.getValue('scaleUpStatus'),
                    scaleDownStatus: row.getValue('scaleDownStatus'),
                    scaleUpFrequency: row.getValue('scaleUpFrequency'),
                    scaleUpConfig: row.getValue('scaleUpConfig'),
                    scaleDownFrequency: row.getValue('scaleDownFrequency'),
                    scaleDownConfig: row.getValue('scaleDownConfig'),
                    slackChannelId: row.getValue('slackChannelId'),
                    scaleUpLastRun: row.getValue('scaleUpLastRun'),
                    scaleUpNextRun: row.getValue('scaleUpFrequency'),
                    scaleDownLastRun: row.getValue('scaleDownLastRun'),
                    scaleDownNextRun: row.getValue('scaleDownFrequency'),
                    approvedStatus: row.getValue('approvedStatus'),
                },
                'DELETE',
                setDataLoader,
                -1
            );
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'clusterName',
                header: 'Cluster Name',
                enableEditing: false,
            },
            {
                accessorKey: 'creatorEmail',
                header: 'Creator Email',
                enableEditing: false,
            },
            {
                accessorKey: 'scaleUpStatus',
                header: 'Scale Up Status',
                Cell: ({ cell }) => <StatusPill value={cell.getValue()} />,
                enableEditing: false,
            },
            {
                accessorKey: 'scaleDownStatus',
                header: 'Scale Down Status',
                Cell: ({ cell }) => <StatusPill value={cell.getValue()} />,
                enableEditing: false,
            },
            {
                accessorKey: 'scaleUpFrequency',
                header: 'Scale Up Frequency',
            },
            {
                accessorKey: 'scaleUpConfig',
                header: 'Scale Up Config',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: distributions.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'scaleDownFrequency',
                header: 'Scale Down Frequency',
            },
            {
                accessorKey: 'scaleDownConfig',
                header: 'Scale Down Config',
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: distributions.map((distribution) => (
                        <MenuItem key={distribution} value={distribution}>
                            {distribution}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'estimatedSaving',
                header: 'Estimated Monthly Saving',
                Cell: ({ cell }) => (
                    <Chip
                        color="success"
                        style={{ fontWeight: 'bolder' }}
                        label={`${cell.getValue()?.toFixed(2)} $`}
                    />
                ),
                enableEditing: false,
            },
            {
                accessorKey: 'slackChannelId',
                header: 'Slack Channel Id',
            },
            {
                accessorKey: 'scaleUpLastRun',
                header: 'Scale Up Last Run',
                Cell: ({ cell }) =>
                    cell.getValue() === '' ? '' : parseTime(cell.getValue()),
                enableEditing: false,
            },
            {
                accessorKey: 'scaleUpNextRun',
                header: 'Scale Up Next Run',
                enableEditing: false,
            },
            {
                accessorKey: 'scaleDownLastRun',
                header: 'Scale Down Last Run',
                Cell: ({ cell }) =>
                    cell.getValue() === '' ? '' : parseTime(cell.getValue()),
                enableEditing: false,
            },
            {
                accessorKey: 'scaleDownNextRun',
                header: 'Scale Down Next Run',
                enableEditing: false,
            },
            {
                accessorKey: 'approvedStatus',
                header: 'Approved',
                enableEditing: false,
            },
        ],
        [getCommonEditTextFieldProps]
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        var data = [];
        for (var i = 0; i < tableData.length; i++) {
            var dict = {};
            dict['clusterName'] = tableData[i]['clusterName'];
            dict['creatorEmail'] = tableData[i]['creatorEmail'];
            dict['scaleUpStatus'] = tableData[i]['scaleUpStatus'];
            dict['scaleDownStatus'] = tableData[i]['scaleDownStatus'];
            dict['scaleUpFrequency'] = tableData[i]['scaleUpFrequency'];
            dict['scaleUpConfig'] = tableData[i]['scaleUpConfig'];
            dict['scaleDownFrequency'] = tableData[i]['scaleDownFrequency'];
            dict['scaleDownConfig'] = tableData[i]['scaleDownConfig'];
            dict['estimatedSaving'] = tableData[i]['estimatedSaving'];
            dict['slackChannelId'] = tableData[i]['slackChannelId'];
            dict['scaleUpLastRun'] = tableData[i]['scaleUpLastRun'];
            dict['scaleUpNextRun'] = tableData[i]['scaleUpNextRun'];
            dict['scaleDownLastRun'] = tableData[i]['scaleDownLastRun'];
            dict['scaleDownNextRun'] = tableData[i]['scaleDownNextRun'];
            dict['approvedStatus'] = tableData[i]['approvedStatus'];
            data.push(dict);
        }
        csvExporter.generateCsv(data);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions','clusterName'],
                    },
                    density: 'compact',
                    columnVisibility: { approvedStatus: false },
                }}
                state={{ isLoading: dataLoader }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {editLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton
                                    disabled={!isAdminApprover()}
                                    onClick={() => table.setEditingRow(row)}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isAdminApprover()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>

                        {approveLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Tooltip
                                arrow
                                placement="right"
                                title={
                                    row.getValue('approvedStatus') === 'True'
                                        ? 'Reject'
                                        : 'Approve'
                                }
                            >
                                {row.getValue('approvedStatus') === 'True' ? (
                                    <IconButton
                                        color="warning"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isAdminApprover()}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        color="success"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isAdminApprover()}
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                            </Tooltip>
                        )}
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="success"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                        >
                            Add New Config
                        </Button>
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
            <AddNewJob
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                clusters={clusters}
            />
        </>
    );
};

export const AddNewJob = ({ open, columns, onClose, onSubmit, clusters }) => {
    //const [open, setOpen] = React.useState(false);
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    //console.log(clusters);
    const [checked, setChecked] = useState(false);
    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };
    const [clusterList, setClusterList] = useState([]);
    useEffect(() => {
        var keys = Object.keys(clusters)
        var temp = []
       for(var i=0;i<keys.length;i++)
            {
                if(!checked)
                {
                    if(clusters[keys[i]].instance_size!='M10')
                    {
                        temp.push(keys[i])
                    }
                }    
                else{
                    temp.push(keys[i])
                }    
            }
        setClusterList(temp);
    }, [clusters,checked]);
    const [configList, setConfigList] = useState([]);
    const handleSubmit = () => {
        if (values['slackChannelId'] === '') {
            alert('Provide Slack Channel Id');
            return;
        }
        if (scaleUpConfigType <= scaleDownConfigType) {
            alert(
                'ScaleUp config value should be greater than ScaleDown config value'
            );
            return;
        }
        if (compareTime(scaleUpFrequency, scaleDownFrequency)) {
            alert(
                'Difference between ScaleUp and ScaleDown Frequency should be atleast greater than 30 min'
            );
            return;
        }

        onSubmit({
            ...values,
            ['clusterName']: cluster,
            ['creatorEmail']: user.email,
            ['approvedStatus']: false,
            ['scaleUpConfig']: scaleUpConfigType,
            ['scaleDownConfig']: scaleDownConfigType,
            ['scaleUpFrequency']: scaleUpFrequency.toISOString(),
            ['scaleDownFrequency']: scaleDownFrequency.toISOString(),
        });
        onClose();
    };

    const dateObj = new Date();
    const { user } = useAuth();
    const [cluster, setCluster] = useState(clusterList[0]);
    const [scaleUpConfigType, setScaleUpConfigType] = useState(null);
    const [scaleDownConfigType, setScaleDownConfigType] = useState(null);
    const [scaleUpFrequency, setScaleUpFrequency] = useState(dateObj);
    const [scaleDownFrequency, setScaleDownFrequency] = useState(dateObj);
    const [estimatedSaving, setEstimatedSaving] = useState(0);

    useEffect(() => {
        setCluster(clusterList[0]);
    }, [clusterList]);

    useEffect(() => {
        setScaleUpConfigType(clusters[cluster]?.instance_size);
        setScaleDownConfigType(clusters[cluster]?.instance_size);
    }, [cluster]);

    useEffect(() => {
        setEstimatedSaving(
            (
                getCronDifference(
                    getCron(scaleUpFrequency.toISOString()),
                    getCron(scaleDownFrequency.toISOString())
                ) *
                getPerHourCostDifference(
                    scaleUpConfigType,
                    scaleDownConfigType
                ) *
                30
            ).toFixed(2)
        );
    }, [
        scaleUpConfigType,
        scaleDownConfigType,
        scaleUpFrequency,
        scaleDownFrequency,
    ]);
    //console.log(scaleUpConfigType, scaleDownConfigType);
    useEffect(() => {
        var configMaxIndex =
            distributionsIndex[clusters[cluster]?.max_instance_size];
        var configMinIndex =
            distributionsIndex[clusters[cluster]?.min_instance_size];
        setConfigList(distributions.slice(configMinIndex, configMaxIndex + 1));
    }, [cluster]);
    return (
        <>
            <Dialog open={open}>
                <DialogTitle textAlign="center">
                    <div>Add New Cluster</div>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {
                                    xs: '300px',
                                    sm: '360px',
                                    md: '400px',
                                },
                                gap: '1.5rem',
                                marginTop: '21px',
                            }}
                        >
                            {(() => {
                                return (
                                    <>
                                        <div className="text-left relative z-1">
                                            <div className="text-left relative z-0 mt-4">
                                                <div className="mt-4">
                                                    <Switch
                                                        checked={checked}
                                                        onChange={handleCheck}
                                                        inputProps={{
                                                            'aria-label':
                                                                'controlled',
                                                        }}
                                                    />
                                                </div>
                                                <label
                                                    style={{
                                                        color: 'black',
                                                        fontSize: '22px',
                                                        fontWeight: 'bolder',
                                                    }}
                                                    htmlFor="floating_role"
                                                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                                >
                                                    Show M10 Clusters
                                                </label>
                                            </div>
                                            <div>
                                                <SearchDD
                                                    label={'Cluster Name'}
                                                    emptyLabel={
                                                        'Select Cluster'
                                                    }
                                                    options={clusterList}
                                                    value={cluster}
                                                    onChange={setCluster}
                                                    disabled={false}
                                                />
                                            </div>
                                        </div>

                                        <TimePickerInput
                                            label={'Scale Up Frequency'}
                                            value={scaleUpFrequency}
                                            onChange={(item) => {
                                                setScaleUpFrequency(
                                                    new Date(item.toISOString())
                                                );
                                            }}
                                        />

                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={scaleUpConfigType}
                                                    name={
                                                        columns[5].accessorKey
                                                    }
                                                    placeholder={
                                                        columns[5].accessorKey
                                                    }
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setScaleUpConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {configList.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Scale Up Config
                                            </label>
                                        </div>

                                        <TimePickerInput
                                            label={'Scale Down Frequency'}
                                            value={scaleDownFrequency}
                                            onChange={(item) => {
                                                setScaleDownFrequency(
                                                    new Date(item.toISOString())
                                                );
                                            }}
                                        />

                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={scaleDownConfigType}
                                                    name={
                                                        columns[7].accessorKey
                                                    }
                                                    placeholder={
                                                        columns[7].accessorKey
                                                    }
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setScaleDownConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {configList.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Scale Down Config
                                            </label>
                                        </div>

                                        <TextField
                                            key={columns[9].accessorKey}
                                            label={columns[9].header}
                                            name={columns[9].accessorKey}
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                        <Typography
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: 'serif',
                                                fontWeight: 'bold',
                                                color: 'green',
                                                fontSize: '19.5px',
                                            }}
                                        >{`You are saving around ${estimatedSaving}$ per month`}</Typography>
                                    </>
                                );
                            })()}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        color="secondary"
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Add New Job
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={2000}>
                <Alert severity="warning" sx={{ width: '100%' }}>
                    Initially Scale Up and Scale Down config is set to default
                    cluster config!
                </Alert>
            </Snackbar>
        </>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default MongoTbasTable;
