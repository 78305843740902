import DD2 from '../DD2';
import './date-picker.css';
import Loading from './Loading';
import { BACKEND_URL } from '../../configs';
import ReactApexChart from 'react-apexcharts';
import { useAxios } from '../../contexts/axios';
import DatePickerInput from '../DatePickerInput';
import { React, useState, useEffect } from 'react';
import PrimaryButton from '../button/PrimaryButton';
import 'react-datepicker/dist/react-datepicker.css';
import LoadvsGcpDescription from '../description/load-vs-gcp-des';
import FlexRow from '../layout/FlexRow';

export default function LoadvsGcpCost() {
    const tools = [
        { name: 'App User Session Start vs GCP Cost' },
        { name: 'App User Session Start (Notifications Only) vs GCP Cost' },
        { name: 'Web User Session Start vs GCP Cost' },
        { name: 'Load balancer Requests vs GCP Cost' },
    ];
    var dateObj = new Date();
    const authAxios = useAxios();
    const [subTypes, setSubTypes] = useState([])
    const [data, setData] = useState([]);
    const [ok, setOk] = useState('false');
    const [option, setOption] = useState();
    const [load, setLoad] = useState(false);
    const [tool, setTool] = useState(tools[0]);
    const [subType, setSubType] = useState('');
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    function handleLoad() {
        if (!!!tool) {
            alert('Please select a tool');
            return;
        }
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        setLoad(true);
        setOk('load');
        if (!load) {
            getdata(
                `${BACKEND_URL}/loadvsgcp/?start_date=${
                    startDate.toISOString().split('T')[0]
                }&end_date=${endDate.toISOString().split('T')[0]}&type=${
                    tool.name
                }&sub_type=${subType.name}`,
                setData,
                setLoading
            );
        }
        setOk('true');
    }

    useEffect(() => {
        if(tool.name==="Load balancer Requests vs GCP Cost"){
            getdata(
                `${BACKEND_URL}/loadvsgcp/subtype`,
                setSubTypes,
                setLoading
            );
        }
    }, [tool]);

    useEffect(() => {
        setOk('false');
        setLoad(false);
        setData([]);
    }, [startDate, endDate, tool, subType]);

    useEffect(() => {
        if (data.length > 0) {
            setOk('true');
        }
    }, [data]);

    useEffect(() => {
        if (tool.name === 'App User Session Start vs GCP Cost') {
            setOption({
                series: [
                    {
                        name: 'App User Session Start',
                        type: 'column',
                        data: data['UserSessionsStarted'],
                    },
                    {
                        name: 'Cost in $',
                        type: 'line',
                        data: data['ActualCost'],
                    },
                ],
                options: {
                    colors: ['#1589FF', '#FF0000'],
                    chart: { height: 350, type: 'line' },
                    stroke: { width: [0, 4] },
                    title: { text: tool.name },
                    dataLabels: { enabled: false, enabledOnSeries: [1] },
                    labels: data['dateTimeSeries'],
                    xaxis: { type: 'datetime' },
                    yaxis: [
                        {
                            title: { text: 'App User Session Start' },
                            labels: {
                                formatter: function (val) {
                                    return val.toFixed(0) / 1000 + '  k';
                                },
                            },
                        },
                        {
                            opposite: true,
                            title: { text: 'Cost in $' },
                            labels: {
                                formatter: function (val) {
                                    return (val / 1000).toFixed(2) + 'k $';
                                },
                            },
                        },
                    ],
                },
            });
        } else if (
            tool.name ===
            'App User Session Start (Notifications Only) vs GCP Cost'
        ) {
            setOption({
                series: [
                    {
                        name: 'App User Session Start (Notifications Only)',
                        type: 'column',
                        data: data['UserSessionsStarted'],
                    },
                    {
                        name: 'Cost in $',
                        type: 'line',
                        data: data['ActualCost'],
                    },
                ],
                options: {
                    colors: ['#1589FF', '#FF0000'],
                    chart: { height: 350, type: 'line' },
                    stroke: { width: [0, 4] },
                    title: { text: tool.name },
                    dataLabels: { enabled: false, enabledOnSeries: [1] },
                    labels: data['dateTimeSeries'],
                    xaxis: { type: 'datetime' },
                    yaxis: [
                        {
                            title: {
                                text: 'App User Session Start (Notifications Only)',
                            },
                            labels: {
                                formatter: function (val) {
                                    return val.toFixed(0) / 1000 + '  k';
                                },
                            },
                        },
                        {
                            opposite: true,
                            title: { text: 'Cost in $' },
                            labels: {
                                formatter: function (val) {
                                    return (val / 1000).toFixed(2) + 'k $';
                                },
                            },
                        },
                    ],
                },
            });
        } else if (tool.name === 'Web User Session Start vs GCP Cost') {
            setOption({
                series: [
                    {
                        name: 'Web users session starts',
                        type: 'column',
                        data: data['UserSessionsStarted'],
                    },
                    {
                        name: 'Cost in $',
                        type: 'line',
                        data: data['ActualCost'],
                    },
                ],
                options: {
                    colors: ['#1589FF', '#FF0000'],
                    chart: { height: 350, type: 'line' },
                    stroke: { width: [0, 4] },
                    title: { text: tool.name },
                    dataLabels: { enabled: false, enabledOnSeries: [1] },
                    labels: data['dateTimeSeries'],
                    xaxis: { type: 'datetime' },
                    yaxis: [
                        {
                            title: { text: 'Web users session starts' },
                            labels: {
                                formatter: function (val) {
                                    return val.toFixed(0) / 1000 + '  k';
                                },
                            },
                        },
                        {
                            opposite: true,
                            title: { text: 'Cost in $' },
                            labels: {
                                formatter: function (val) {
                                    return (val / 1000).toFixed(2) + 'k $';
                                },
                            },
                        },
                    ],
                },
            });
        } else {
            setOption({
                series: [
                    {
                        name: 'Load balancer Requests ',
                        type: 'column',
                        data: data['UserSessionsStarted'],
                    },
                    {
                        name: 'Cost in $',
                        type: 'line',
                        data: data['ActualCost'],
                    },
                ],
                options: {
                    colors: ['#1589FF', '#FF0000'],
                    chart: { height: 350, type: 'line' },
                    stroke: { width: [0, 4] },
                    title: { text: tool.name },
                    dataLabels: { enabled: false, enabledOnSeries: [1] },
                    labels: data['dateTimeSeries'],
                    xaxis: { type: 'datetime' },
                    yaxis: [
                        {
                            title: { text: 'Load balancer Requests ' },
                            labels: {
                                formatter: function (val) {
                                    return val.toFixed(0) / 1000 + '  k';
                                },
                            },
                        },
                        {
                            opposite: true,
                            title: { text: 'Cost in $' },
                            labels: {
                                formatter: function (val) {
                                    return (val / 1000).toFixed(2) + 'k $';
                                },
                            },
                        },
                    ],
                },
            });
        }
    }, [tool.name, data]);

    return (
        <>
            {loading === true ? (
                <Loading />
            ) : (
                <>
                    <FlexRow>
                        <DD2
                            label={'Tool Name'}
                            emptyLabel={'Select a Tool Type'}
                            options={tools}
                            value={tool}
                            onChange={setTool}
                            displayProperty={'name'}
                            disabled={false}
                        />

                        <DD2
                            label={'Sub Type'}
                            emptyLabel={'Select a Sub Type'}
                            options={subTypes}
                            value={subType}
                            onChange={setSubType}
                            displayProperty={'name'}
                            disabled={
                                tool.name !==
                                'Load balancer Requests vs GCP Cost'
                            }
                        />
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                    </FlexRow>
                    <FlexRow>
                        <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                        />

                        <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            onChange={setEndDate}
                            maxDate={dateObj}
                        />
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                    </FlexRow>

                    <div className="mt-8">
                        <PrimaryButton
                            title={'Generate Report'}
                            onClick={handleLoad}
                            disabled={load}
                        />
                    </div>

                    {(() => {
                        switch (ok) {
                            case 'false':
                                return <></>;
                            case 'load':
                                return (
                                    <div
                                        style={{
                                            marginLeft: '600px',
                                            marginTop: '400px',
                                            position: 'absolute',
                                            borderBlockColor: 'green',
                                        }}
                                        className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
                                    />
                                );
                            case 'true':
                                return (
                                    <>
                                        <div
                                            class="mt-[45px] mb-[20px]"
                                            id="chart"
                                        >
                                            <ReactApexChart
                                                options={option.options}
                                                series={option.series}
                                                type="line"
                                                height={350}
                                            />
                                        </div>
                                        <LoadvsGcpDescription
                                            value={option.series[0].name}
                                        />
                                    </>
                                );
                        }
                    })()}
                </>
            )}
        </>
    );
}
