import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SECURITY_BACKEND_URL } from '../../configs';
import DD2 from '../../components/DD2';
import DD2InsideLabel from '../DD2InsideLabel';
import FlexRow from '../layout/FlexRow';
import Datatable from '../costing/Datatable';
import { useAxios } from '../../contexts/axios';
import { Box, Button } from '@mui/material';
import TextInput from '../TextInput';
import HeaderData from './editMUITable';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import LinearProgress from '@mui/material/LinearProgress';
import ReactJson from 'react-json-view';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function AutomationTab({
    data,
    automation,
    name,
    setLoadingChild,
    setTriggerNewtab,
}) {
    //console.log(data);
    const axios = useAxios();
    const callOption = ['GET', 'POST', 'PUT', 'DELETE'];
    const [value, setValue] = useState(data?.description);
    const [callType, setCallType] = useState(callOption[0]);
    const [url, setUrl] = useState(data?.url);
    const [tableData, setTableData] = useState([]);
    const [result, setResult] = useState([]);
    const [loadResult, setLoadResult] = useState(false);
    //const [loading, setLoading] = useState(false);

    useEffect(() => {
        var table = [];
        if (data?.header.length > 0) {
            for (var i = 0; i < data.header.length; i++) {
                table.push({
                    inputType: 'Header',
                    description: data.header[i].description,
                    value: data.header[i].value,
                });
            }
            setCallType(data?.request);
        }
        if (data?.data.length > 0) {
            for (var i = 0; i < data.data.length; i++) {
                table.push({
                    inputType: 'Data',
                    description: data.data[i].description,
                    value: data.data[i].value,
                });
            }
            setCallType(data?.request);
        }
        setTableData(table);
    }, [data]);

    function handleAddParameter() {
        var val = {};
        val['automation'] = automation?.value;
        val['name'] = name?.value;
        val['description'] = value;
        val['request'] = callType;
        val['url'] = url;
        val['data'] = [];
        val['header'] = [];
        //console.log(tableData);
        if (tableData?.length > 0) {
            for (var i = 0; i < tableData?.length; i++) {
                if (tableData[i].inputType === 'Header') {
                    val['header'].push({
                        description: tableData[i].description,
                        value: tableData[i].value,
                    });
                } else {
                    val['data'].push({
                        description: tableData[i].description,
                        value: tableData[i].value,
                    });
                }
            }
        }
        //console.log(val);
        const requestOptions = {
            method: 'POST',
            url: `${SECURITY_BACKEND_URL}/automation-entries`,
            data: val,
        };
        setLoadingChild(true);
        axios(requestOptions).then(function (res) {
            setLoadingChild(false);
        });
        setTriggerNewtab(true);
    }

    function getResult() {
        const requestOptions = {
            method: 'GET',
            url: `${SECURITY_BACKEND_URL}/curl?automation=${automation?.value}&name=${name?.value}`,
        };
        setLoadResult(true);
        axios(requestOptions).then(function (res) {
            setResult(res.data);
            setLoadResult(false);
        });
    }
    return (
        <>
            <FlexRow>
                <textarea
                    id="message"
                    rows="5"
                    class="block p-2.5 w-full text-sm text-black bg-white-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Write description"
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                />
            </FlexRow>
            <FlexRow>
                <DD2InsideLabel
                    label={'Call Type'}
                    option={callOption}
                    value={callType}
                    setValue={setCallType}
                />
                <TextInput
                    label={'Url'}
                    value={url}
                    setId={setUrl}
                    disabled={false}
                    marginTop={'0px'}
                />
            </FlexRow>
            <HeaderData tableData={tableData} setTableData={setTableData} />
            <FlexRow>
                <Button
                    variant="contained"
                    color="warning"
                    sx={{
                        width: '400px',
                        fontWeight: 'bold',
                    }}
                    onClick={handleAddParameter}
                >
                    Save Request Parameter
                </Button>
                <Button
                    variant="contained"
                    color="success"
                    onClick={getResult}
                    sx={{
                        width: '400px',
                        fontWeight: 'bold',
                    }}
                >
                    Send Request
                </Button>
            </FlexRow>
            {loadResult ? (
                <LinearProgress />
            ) : (
                <FlexRow>
                    <ReactJson
                        collapseStringsAfterLength={1200}
                        src={result}
                        displayDataTypes={false}
                        name={false}
                    />
                </FlexRow>
            )}
        </>
    );
}
