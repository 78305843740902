import React from 'react';
import DatePickerInput from '../DatePickerInput';
import Grid from '@mui/material/Unstable_Grid2';

export default function DateRangeSelector({
    startDate1,
    startDate2,
    endDate1,
    endDate2,
    maxDate1,
    maxDate2,
    setSearchParams
}) {
    return (
        <Grid container spacing={3}>
            <Grid xs={3}>
                <DatePickerInput
                    label={'Start Date 1'}
                    value={startDate1}
                    maxDate={endDate1}
                    minDate={new Date("2023-04-13T00:00:00Z")}
                    onChange={(item) => {
                        setSearchParams({
                            startDate1: item.toISOString(),
                            endDate1: endDate1.toISOString(),
                            startDate2: startDate2.toISOString(),
                            endDate2: endDate2.toISOString(),
                        });
                    }}
                />
            </Grid>
            <Grid xs={3}>
                <DatePickerInput
                    label={'End Date 1'}
                    value={endDate1}
                    maxDate={maxDate1}
                    minDate={new Date("2023-04-13T00:00:00Z")}
                    onChange={(item) => {
                        setSearchParams({
                            startDate1: startDate1.toISOString(),
                            endDate1: item.toISOString(),
                            startDate2: startDate2.toISOString(),
                            endDate2: endDate2.toISOString(),
                        });
                    }}
                />
            </Grid>
            <Grid xs={3}>
                <DatePickerInput
                    label={'Start Date 2'}
                    value={startDate2}
                    maxDate={endDate2}
                    minDate={new Date("2023-04-13T00:00:00Z")}
                    onChange={(item) => {
                        setSearchParams({
                            startDate1: startDate1.toISOString(),
                            endDate1: endDate1.toISOString(),
                            startDate2: item.toISOString(),
                            endDate2: endDate2.toISOString(),
                        });
                    }}
                />
            </Grid>
            <Grid xs={3}>
                <DatePickerInput
                    label={'End Date 2'}
                    value={endDate2}
                    maxDate={maxDate2}
                    minDate={new Date("2023-04-13T00:00:00Z")}
                    onChange={(item) => {
                        setSearchParams({
                            startDate1: startDate1.toISOString(),
                            endDate1: endDate1.toISOString(),
                            startDate2: startDate2.toISOString(),
                            endDate2: item.toISOString(),
                        });
                    }}
                />
            </Grid>
        </Grid>
    );
}
