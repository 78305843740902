import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SearchDD from '../SearchDD';
import FlexRow from '../layout/FlexRow';
//import MultipleValueTextInput from 'react-multivalue-text-input';
import TagsInputList from '../tagAdder';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
    Chip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const PiiTable = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [buttonDisability, setButtonDisability] = useState(false);
    //const [piiButtonDisability, SetpiiButtonDisability] = useState(true);
    const [selected, setSelected] = useState([]);
    const [columnList, setColumnList] = useState([
        { label: 'User Id', id: 'userId' },
        { label: 'Job Id', id: 'jobId' },
    ]);
    const [columnName, setColumnName] = useState(columnList[0]);
    const [column, setColumn] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState('false');
    const axios = useAxios();
    const { isAdmin, isOwner, isAdminApprover } = useAuth();
    const owner = isOwner();
    const adminApprover = isAdminApprover();
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState(10);
    const [emails, setEmails] = useState([]);

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    function handleDelete(item) {
        var values = emails;
        values.splice(emails.indexOf(item), 1);
        setEmails(values);
    }

    //console.log(selected);
    useEffect(() => {
        var data = [];
        var ide = columnName?.id;
        if (ide === 'userId') {
            for (var i = 0; i < selected.length; i++) {
                data.push({ userId: selected[i] });
            }
        } else {
            for (var i = 0; i < selected.length; i++) {
                data.push({ jobId: selected[i] });
            }
        }
        setTableData(data);
    }, [selected]);

    useEffect(() => {
        setColumn([
            {
                accessorKey: columnName?.id,
                header: columnName?.label,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ]);
    }, [columnName]);

    function getPIIData() {
        if (tableData.length == 0) {
            alert('Please insert atleast one userId');
            return;
        }
        if (tableData.length > 100000) {
            alert('Cannot process more than 100000 data points');
            return;
        }
        if (checked && columnName === null) {
            alert('Please choose key corresponding to which you want PII data');
            return;
        }
        setLoading(true);
        setButtonDisability(true);
        //console.log(tableData, column);
        if (checked) {
            var data = { column: columnName?.id, values: [] };
            for (var i = 0; i < tableData.length; i++) {
                data['values'].push(tableData[i][column[0]?.accessorKey]);
            }
        } else {
            var data = { column: columnName?.id, values: [] };
            for (var i = 0; i < selected.length; i++) {
                data['values'].push(selected[i]);
            }
        }
        const requestOptions = {
            url: `${BACKEND_URL}/gcp/pii-data`,
            method: 'POST',
            data: data,
        };
        axios(requestOptions).then(function (res) {
            setTableData(res.data);
            if (res.data.length > 0) {
                column.push({
                    accessorKey: 'phone_number',
                    header: 'Phone Number',
                    size: 140,
                    muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                        ...getCommonEditTextFieldProps(cell),
                    }),
                });
                setColumn(column);
                for (var i = 0; i < res.data.length; i++) {
                    tableData[i]['phone_number'] = res.data[i]['phone_number'];
                }
                setTableData(tableData);
                setCsvOptions({
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    useBom: true,
                    filename: 'PII Data',
                    useKeysAsHeaders: false,
                    headers: column.map((c) => c.header),
                });
                setLoading(false);
            }
        });
    }

    /*const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };*/

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        columnName?.id
                    )}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );

        const list = [];
        for (let i = 1; i < Math.min(dataStringLines.length, 100001); i++) {
            const row = dataStringLines[i].split(
                /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
            );
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"') d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter((x) => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        // prepare columns list from headers
        //console.log(columns)
        setColumn(
            headers.map((c) => ({
                accessorKey: c,
                header: c,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            }))
        );
        setTableData(list);
        /*var col = [];
        for (var i = 0; i < headers.length; i++) {
            col.push({
                label: headers[i],
                id: headers[i],
            });
        }
        setColumnList(col);*/
    };

    // handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    };

    const [csvOptions, setCsvOptions] = useState({});

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };

    return (
        <>
            <FlexRow>
                <SearchDD
                    label={'Select key'}
                    emptyLabel={'Select key'}
                    options={columnList}
                    value={columnName}
                    onChange={setColumnName}
                    disabled={buttonDisability}
                />
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <FlexRow>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={buttonDisability}
                                    color="secondary"
                                    checked={checked}
                                    onChange={handleCheck}
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                />
                            }
                            label={
                                <Chip
                                    label={'UPLOAD CSV'}
                                    style={{ fontWeight: 'bold' }}
                                    color={
                                        buttonDisability ? 'default' : 'error'
                                    }
                                />
                            }
                            labelPlacement="start"
                        />
                    </FormGroup>
                </FormControl>
                {/*<Button
                    style={{
                        width: '150px',
                        height: '31px',
                        fontWeight: 'bold',
                    }}
                    color="primary"
                    onClick={() => setCreateModalOpen(true)}
                    variant="contained"
                    disabled={buttonDisability || checked}
                >
                    Add New Row
                </Button>*/}
                <input
                    size={400}
                    disabled={buttonDisability || !checked}
                    //style={{width:'400px',height:'200px'}}
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={handleFileUpload}
                />
                <FlexRow></FlexRow>
            </FlexRow>
            <FlexRow>
                <TagsInputList
                    disabled={checked}
                    label={'Label'}
                    width={'700px'}
                    selected={selected}
                    setSelected={setSelected}
                    placeHolder={`Enter ${columnName?.id}`}
                />
                <Button
                    style={{
                        width: '300px',
                        height: '50px',
                        fontWeight: 'bold',
                    }}
                    color="warning"
                    onClick={getPIIData}
                    variant="contained"
                    disabled={buttonDisability}
                >
                    Get Phone Number
                </Button>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <Alert severity="warning">
                Don't upload csv containing more than 100000 data points.
            </Alert>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={column}
                data={tableData}
                editingMode="modal" //default
                enableColumnOrdering
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                state={{
                    isLoading: loading,
                }}
                initialState={{ density: 'compact' }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={buttonDisability}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={buttonDisability}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            zIndex: 5,
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            style={{
                                width: '200px',
                                height: '31px',
                                fontWeight: 'bold',
                            }}
                            color="success"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
            {/*<AddNewUser
                columns={column}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                        />*/}
        </>
    );
};

/*export const AddNewUser = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const [iam, setIam] = useState('');
    const { isOwner, isAdminApprover } = useAuth();
    var owner = isOwner();
    var adminApprover = isAdminApprover();
    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add New User Id</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {(() => {
                            return (
                                <>
                                    <TextField
                                        key={columns[0].accessorKey}
                                        label={columns[0].header}
                                        name={columns[0].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </>
                            );
                        })()}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add New UserId
                </Button>
            </DialogActions>
        </Dialog>
    );
};*/

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default PiiTable;
