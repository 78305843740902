import { useEffect, useState } from 'react';
import { ACCESS_BACKEND_URL } from '../../configs';
import './table.css';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/axios';

export default function Pendingrequest() {
    const nav = useNavigate();
    const axios = useAxios();
    const [data, setdata] = useState([]);
    let [isOpen, setIsOpen] = useState(false);
    const [val, setval] = useState(0);
    const [ok, setok] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    function closeModal() {
        setIsOpen(false);
    }

    const openModal = (i, kind) => {
        setIsOpen(true);
        setval(i);
        setok(kind);
    };
    useEffect(() => {
        // Using fetch to fetch the api from
        // flask server it will be redirected to proxy
        setIsLoading(true);
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/pendingrequest`,
        };
        axios(requestOptions).then(function (res) {
            setdata(res.data);
            setIsLoading(false);
        });
    }, []);

    const [message, setmessage] = useState('');
    const handlemessage = (e) => {
        setmessage(e.target.value);
    };
    const handleok = (i) => {
        const requestOptions = {
            method: 'POST',
            url: `${ACCESS_BACKEND_URL}/api/handlerequest`,
            data: {
                granted: true,
                date: data[i]['date'],
                email: data[i]['email'],
                productandaccess: data[i]['productandaccess'],
                message: message,
            },
        };
        axios(requestOptions).then(function (res) {});
        const newdata = [...data];
        newdata.splice(i, 1);
        setdata(newdata);
        closeModal();
        /*fetch(`${BACKEND_URL}/api/handlerequest`, requestOptions).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                alert(data.status)  
            })
        );
        const newdata = [...data];
        newdata.splice(i, 1);
        setdata(newdata);
        closeModal();*/
    };

    const handlenotok = (i) => {
        const requestOptions = {
            method: 'POST',
            url: `${ACCESS_BACKEND_URL}/api/handlerequest`,
            data: {
                granted: false,
                date: data[i]['date'],
                email: data[i]['email'],
                message: message,
                productandaccess: data[i]['productandaccess'],
            },
        };
        axios(requestOptions).then(function (res) {});
        const newdata = [...data];
        newdata.splice(i, 1);
        setdata(newdata);
        closeModal();
        /*fetch(`${BACKEND_URL}/api/handlerequest`, requestOptions).then((res) =>
            res.json().then((data) => {
                // Setting a data from api
                alert(data.status)  
            })
        );
        const newdata = [...data];
        newdata.splice(i, 1);
        setdata(newdata)
        closeModal()*/
    };
    return (
        <>
            {isLoading ? (
                <div
                    style={{
                        marginLeft: '900px',
                        marginTop: '400px',
                        position: 'absolute',
                        borderBlockColor: 'green',
                    }}
                    className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
                />
            ) : (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            marginLeft: '150px',
                            marginTop: '150px',
                            zIndex: -1,
                            height: 'auto',
                            maxHeight: '700px',
                            maxWidth: '1600px',
                        }}
                        class="d-flex flex-column justify-content-center fixTableHead shadow-md sm:rounded-lg rounded-t-lg"
                    >
                        {data.length > 0 ? (
                            <table class="text-sm text-center text-black-500 dark:text-black-400">
                                <thead class="text-xs text-gray-900 uppercase rounded-t-lg bg-blue-200 dark:bg-gray-100 dark:text-gray-900">
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">
                                            Product and access Type
                                        </th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Message</th>
                                        <th scope="col" class="py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(function (object, i) {
                                        return (
                                            <tr
                                                key={i}
                                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                            >
                                                <td class="py-3 ">
                                                    {object['date']}
                                                </td>
                                                <td class="py-3 ">
                                                    {object['name']}
                                                </td>
                                                <td class="py-3 ">
                                                    {object['email']}
                                                </td>
                                                <th
                                                    scope="row"
                                                    class="py-3 font-medium text-gray-900 whitespace-wrap dark:text-white"
                                                >
                                                    {(() => {
                                                        var temp = '';
                                                        for (
                                                            var i = 0;
                                                            i <
                                                            object[
                                                                'productandaccess'
                                                            ].length;
                                                            i++
                                                        ) {
                                                            temp =
                                                                temp +
                                                                object[
                                                                    'productandaccess'
                                                                ][i] +
                                                                ',' +
                                                                ' ';
                                                        }
                                                        return temp;
                                                    })()}
                                                </th>
                                                <td class="py-4 px-3">
                                                    {object['description']}
                                                </td>
                                                <td class="py-4 ">
                                                    <div class="flex items-center">
                                                        <form>
                                                            <label class="block">
                                                                <textarea
                                                                    id="message"
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handlemessage(
                                                                            e
                                                                        );
                                                                    }}
                                                                    rows="1"
                                                                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                    placeholder="Leave a message..."
                                                                ></textarea>
                                                            </label>
                                                        </form>
                                                    </div>
                                                </td>
                                                <td class="py-4 ">
                                                    <div
                                                        style={{
                                                            display:
                                                                'inline-flex',
                                                        }}
                                                    >
                                                        <button>
                                                            <svg
                                                                style={{
                                                                    width: '35px',
                                                                }}
                                                                onClick={() => {
                                                                    openModal(
                                                                        i,
                                                                        true
                                                                    );
                                                                }}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="green"
                                                                class="bi bi-hand-thumbs-up-fill highlight"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                                            </svg>
                                                        </button>
                                                        <button>
                                                            <svg
                                                                style={{
                                                                    width: '35px',
                                                                }}
                                                                onClick={() => {
                                                                    openModal(
                                                                        i,
                                                                        false
                                                                    );
                                                                }}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="red"
                                                                class="ml-3 bi bi-hand-thumbs-down-fill highlight"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.378 1.378 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51.136.02.285.037.443.051.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.896 1.896 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2.094 2.094 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.162 3.162 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.823 4.823 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591z" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h1
                                style={{ color: 'green', fontWeight: 'bolder' }}
                            >
                                {' '}
                                No pending request
                            </h1>
                        )}
                    </div>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-10"
                            onClose={closeModal}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Caution
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    {ok
                                                        ? 'Are you sure that you want to provide access to the user?'
                                                        : 'If you proceed then the request will be permanently deleted'}
                                                </p>
                                            </div>

                                            <div className="mt-4">
                                                <button
                                                    type="button"
                                                    className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium ${
                                                        ok
                                                            ? 'bg-green-300 text-green-900 hover:bg-green-200'
                                                            : ' bg-red-300 text-red-900 hover:bg-red-200'
                                                    }`}
                                                    onClick={() => {
                                                        ok
                                                            ? handleok(val)
                                                            : handlenotok(val);
                                                    }}
                                                >
                                                    {ok
                                                        ? 'Grant Access'
                                                        : 'Delete Request'}
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </>
            )}
        </>
    );
}
