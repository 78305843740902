import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import { Box } from '@mui/material';
import { useState } from 'react';
//import BlacklistResetUser from '../../../../components/AuditPii/blackListUser';
import ReblazeDash from '../../../../components/AuditPii/reblazeDash';
const ReblazeDashboard = () => {
    return (
        <>
            <HeaderTitle
                title={'Reblaze Dashboard'}
                subtitle={'Realtime analytics on Reblaze Logs'}
            />
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <ReblazeDash/>
            </Box>
        </>
    );
};

export default ReblazeDashboard;
