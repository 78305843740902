import { React, useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { Box, Button } from '@mui/material';
function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
//processing data
function ProcessCost(data) {
    if (data === null) {
        data = 0;
    }

    if (data > 10000) {
        data = data / 1000;
        data = data.toFixed(3);
        data = data.toString() + 'k $';
    } else {
        data = data.toFixed(3);
        data = data.toString() + ' $';
    }
    return data;
}
function reverse(datevalue) {
    var datelst = datevalue.split('-');
    return datelst[2] + '-' + datelst[1] + '-' + datelst[0];
}
function ProcessIncrement(data) {
    if (data === null) {
        data = 0;
    }
    if (data !== 'kickstart') {
        data = data.toFixed(2);
        data = data.toString() + '%';
    }

    return data;
}

function ParseDate(data) {
    var date = data.split('-');
    return date[0] + '/' + date[1] + " '" + date[2][2] + date[2][3];
}
//*************************** Building tags *****************************************
const TableDataCell = ({ cost, increment }) => {
    return (
        <div
            style={{ fontSize: '16px' }}
            className="w-[240px] h-[87px] py-4 font-medium text-gray-900 text-left diagonal-split-background"
        >
            <span className="sup">{ProcessCost(cost)}</span>
            {increment > 0 ? (
                <span className="inf" style={{ color: 'red' }}>
                    {'+' + ProcessIncrement(increment)}
                </span>
            ) : (
                <span className="inf" style={{ color: 'green' }}>
                    {ProcessIncrement(increment)}
                </span>
            )}
        </div>
    );
};

const TableFooterDataCell = ({ cost, increment }) => {
    return (
        <div
            style={{ fontSize: '16px', fontWeight: 900 }}
            className="w-[240px] h-[87px] py-4 text-sm font-medium text-white text-left diagonal-footer-split-background"
        >
            <span className="sup font-bold">{ProcessCost(cost)}</span>
            {increment > 0 ? (
                <span className="inf font-bold" style={{ color: 'red' }}>
                    {'+' + ProcessIncrement(increment)}
                </span>
            ) : (
                <span className="inf font-bold" style={{ color: 'green' }}>
                    {ProcessIncrement(increment)}
                </span>
            )}
        </div>
    );
};

const TableFirstDataCell = ({ children }) => {
    return (
        <div
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            className="min-w-auto max-w-[335px] p-1 text-sm font-bold text-gray-900 text-center"
        >
            <h6>{children}</h6>
        </div>
    );
};

export default function DescriptionTable({
    resource_key,
    resource_value,
    toolName,
    view,
    startDate,
    endDate,
    periodicity,
    projectId,
    credit
}) {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState([]);
    const [data, setData] = useState([]);
    //const [finalData, setFinalData] = useState([]);
    const [nextPageToken, setNextPageToken] = useState('');
    const [finalData, setFinalData] = useState([]);
    const [totalData, setTotalData] = useState(null);
    const authAxios = useAxios();
    var id = toolName==="Kubernetes Engine/CCD8-9BF1-090E"?'skuDescription': 'skuId'
    

    const getGcpUnitCostingData = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            if (nextPageToken !== '') {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/unitcosting/gcp/skus?toolName=${toolName}&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&periodicity=${
                        !!!periodicity ? 'none' : periodicity
                    }&view=${view}&projectId=${projectId}&next_page_token=${nextPageToken}&resource_key=${resource_key}&resource_value=${resource_value}&credit=${credit}`
                );
                setInfo([...info, ...data['entries']]);
                setNextPageToken(get(data, 'nextPageToken', ''));
            } else {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/unitcosting/gcp/skus?toolName=${toolName}&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&periodicity=${
                        !!!periodicity ? 'none' : periodicity
                    }&view=${view}&projectId=${projectId}&resource_key=${resource_key}&resource_value=${resource_value}&credit=${credit}`
                );
                setInfo([...info, ...data['entries']]);
                setNextPageToken(get(data, 'nextPageToken', ''));
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getGcpUnitCostingData();
    }, []);

    //console.log("RENDERED")
    useEffect(() => {
        if (info.length > 0) {
            var dataDict = {};
            for (var i = 0; i < info.length; i++) {
                var keys = Object.keys(info[i]);
                if (
                    Object.keys(dataDict).includes(info[i][id]) === false
                ) {
                    dataDict[info[i][id]] = {};
                    if (periodicity === 'daily') {
                        var start = startDate.getTime();
                        var end = endDate.getTime();
                        for (var date = start; date <= end; date += 86400000) {
                            var loopDay = new Date(date);
                            dataDict[info[i][id]][
                                reverse(loopDay.toISOString().split('T')[0])
                            ] = 0;
                        }
                    }
                }
                for (var j = 0; j < keys.length; j++) {
                    if (keys[j] !== id) {
                        if(get(dataDict[info[i][id]],keys[j],0)===0){
                            dataDict[info[i][id]][keys[j]] = info[i][keys[j]];
                        }
                    }
                }
            }
            var keys = Object.keys(dataDict);
            var result = [];
            for (var k = 0; k < keys.length; k++) {
                var tempDict = {};
                tempDict[id] = keys[k];
                var tempKeys = Object.keys(dataDict[keys[k]]);
                for (var l = 0; l < tempKeys.length; l++) {
                    tempDict[tempKeys[l]] = dataDict[keys[k]][tempKeys[l]];
                }
                result.push(tempDict);
            }
            //console.log(result);
            setData(result);
        }
    }, [info]);

    //const [ok, setOk] = useState('false');
    const [column, setColumn] = useState([]);
    const [header, setHeader] = useState([]);
    const [totalCost, setTotalCost] = useState([]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    useEffect(() => {
        if (data?.length > 0) {
            const head = Object.keys(data[0]);

            setHeader(head);
            //Getting cost and increment of services per day/week/month
            const Cost = [];
            for (var i = 0; i < data.length; i++) {
                var ServiceCostData = [];
                ServiceCostData.push(data[i][id]); //use useState
                ServiceCostData.push([0, data[i][head[1]]]); // isme value aaega-->
                for (var j = 2; j < head.length; j++) {
                    ServiceCostData.push([
                        data[i][head[j - 1]] !== 0
                            ? ((data[i][head[j]] - data[i][head[j - 1]]) *
                                  100) /
                              data[i][head[j - 1]]
                            : data[i][head[j]] !== 0
                            ? 'kickstart'
                            : 0,
                        data[i][head[j]],
                    ]);
                }
                Cost.push(ServiceCostData);
            }

            //Getting total cost and avg increment of services per day/week/month
            const total = [];
            let totalCost = [];
            total.push('Total');
            for (var i = 1; i < Cost[0].length; i++) {
                var TotalServiceCost = 0;
                var TotalServiceCostPrevDay = 0;
                if (i > 1) {
                    for (var j = 0; j < Cost.length; j++) {
                        TotalServiceCost = TotalServiceCost + Cost[j][i][1];
                        TotalServiceCostPrevDay =
                            TotalServiceCostPrevDay + Cost[j][i - 1][1];
                    }
                    total.push([
                        TotalServiceCostPrevDay !== 0
                            ? ((TotalServiceCost - TotalServiceCostPrevDay) *
                                  100) /
                              TotalServiceCostPrevDay
                            : TotalServiceCost !== 0
                            ? 'kickstart'
                            : 0,
                        TotalServiceCost,
                    ]);
                } else {
                    for (var j = 0; j < Cost.length; j++) {
                        TotalServiceCost = TotalServiceCost + Cost[j][i][1];
                    }
                    total.push([0, TotalServiceCost]);
                }
                totalCost.push(TotalServiceCost);
            }
            setTotalCost(total);
            setTotalData(totalCost);
            //data definitions...
            const finalRows = [];
            for (var i = 0; i < Cost.length; i++) {
                var rowData = {};
                for (var j = 0; j < head.length; j++) {
                    rowData[head[j]] = Cost[i][j];
                }
                finalRows.push(rowData);
            }
            setFinalData(finalRows);
            setLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (header.length > 0 && totalCost.length > 0 && data.length > 0) {
            var cols = [
                {
                    accessorKey: header[0],
                    header: header[0],
                    Header: (
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '15px',
                            }}
                        >
                            {header[0].toUpperCase()}
                        </div>
                    ),
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    Cell: ({ cell }) => (
                        <TableFirstDataCell toolName={'gcp'}>
                            {cell.getValue()}
                        </TableFirstDataCell>
                    ),
                    Footer: (
                        <TableFirstDataCell>{'TOTAL COST'}</TableFirstDataCell>
                    ),
                },
            ];
            if (periodicity === 'weekly') {
                const customHeader = [];
                for (var i = 0; i < header.length; i++) {
                    customHeader.push(header[i].split(' '));
                }

                for (var i = 1; i < header.length; i++) {
                    cols.push({
                        accessorKey: header[i],
                        header: header[i],
                        Header: (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                }}
                                className="grid grid-rows-2 grid-flow-col gap-1"
                            >
                                <div>
                                    {'Week ' + customHeader[i][0].split('-')[1]}
                                </div>
                                <div>
                                    {ParseDate(customHeader[i][1]) +
                                        ' - ' +
                                        ParseDate(customHeader[i][2])}
                                </div>
                            </div>
                        ),
                        sortingFn: 'myCustomSortingFn',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                        Cell: ({ cell }) => (
                            <TableDataCell
                                cost={cell.getValue()[1]}
                                increment={cell.getValue()[0]}
                            ></TableDataCell>
                        ),
                        Footer: (
                            <TableFooterDataCell
                                cost={totalCost[i][1]}
                                increment={totalCost[i][0]}
                            ></TableFooterDataCell>
                        ),
                    });
                }
            } else {
                for (var i = 1; i < header.length; i++) {
                    cols.push({
                        accessorKey: header[i],
                        header: header[i],
                        Header: (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                {header[i].toUpperCase()}
                            </div>
                        ),
                        sortingFn: 'myCustomSortingFn',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                        Cell: ({ cell }) => (
                            <TableDataCell
                                cost={cell.getValue()[1]}
                                increment={cell.getValue()[0]}
                            ></TableDataCell>
                        ),
                        Footer: (
                            <TableFooterDataCell
                                cost={totalCost[i][1]}
                                increment={totalCost[i][0]}
                            ></TableFooterDataCell>
                        ),
                    });
                }
            }
            setColumn(cols);
        }
    }, [totalCost]);

    //console.Console.log()
    //console.log(id)
    return (
        <MaterialReactTable
            data={finalData}
            columns={column}
            // enableStickyFooter
            enableDensityToggle={false}
            enableFullScreenToggle={false}
            enableStickyHeader
            enableStickyFooter
            muiTableBodyProps={{
                sx: {
                    marginTop: '100px',
                },
            }}
            sortingFns={{
                //will add a new sorting function to the list of other sorting functions already available
                myCustomSortingFn: (rowA, rowB, columnId) =>
                    rowA.getValue(columnId)[1] > rowB.getValue(columnId)[1]
                        ? 1
                        : -1,
            }}
            state={{
                isLoading: loading,
            }}
            initialState={{
                columnPinning: {
                    left: ['mrt-row-select'],
                    right: [id],
                }, //use useState
                density: 'compact',
            }}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        color="warning"
                        onClick={getGcpUnitCostingData}
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                        startIcon={<ArrowForwardIosIcon />}
                        variant="contained"
                        disabled={nextPageToken === ''}
                    >
                        Get Next Set of Data
                    </Button>
                </Box>
            )}
        />
    );
}
