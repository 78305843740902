import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import MongoTbasTable from '../../../../components/tbas/mongotable';
const MongoTbas = () => {
    return (
        <>
           
            <HeaderTitle
                title={'Time Based Auto Scaling of Mongo Clusters'}
                subtitle={'Minimize Cost of mongo clusters by scaling it on time based monitoring'}
            />
            <MongoTbasTable/>
        </>
    );
};

export default MongoTbas;
