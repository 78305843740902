import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import MetabaseData from '../../../../components/administration/metabaseData';
const MetabaseMetadata = () => {
    return (
        <>
           
            <HeaderTitle
                title={'Metabase metadata'}
                subtitle={'Information related to dasboards in metabase'}
            />
            <MetabaseData/>
        </>
    );
};

export default MetabaseMetadata;
