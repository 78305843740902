//import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../components/typography/header-title';
//import Home from '../../components/securityComponent/home';
export default function ApnaTechstack() {
    return (
        <>
            <HeaderTitle
                title={'Apna tech stack'}
                subtitle={
                    'Get to know about the tech stack we are using in apna'
                }
            />
            <div>Coming Soon</div>
        </>
    );
}
