import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ReactJson from 'react-json-view';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Fragment } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PrimaryButton from '../button/PrimaryButton';
import DateTimePickerInput from '../DateTimePickerInput';
import DD2 from '../DD2';
import DD3 from '../DD3';
import { useAsyncError } from 'react-router-dom';
import useGcpData from '../../hooks/gcp/useGcpData';
import Grid from '@mui/material/Unstable_Grid2';
import MultipleSelect from '../multiselect';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

export default function ParsedLogsTableUsageInfo() {
    const methods = [
        { name: 'Last Usage', id: 'last_usage' },
        { name: 'Created', id: 'created' },
    ];
    var dateObj = new Date();
    const { data: datasets, isLoading: datasetIdsLoading } = useGcpData({
        url: '/gcp/bigquery-datasets',
        key: 'bigqueryDatasets',
    });
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [method, setMethod] = useState(methods[1]);
    const [options, setOptions] = useState([]);
    const [datasetIds, setDatasetIds] = useState([]);
    const [datasetIds1, setDatasetIds1] = useState([]);
    const [datasetId, setDatasetId] = useState([]);
    const [buttonDisability, setButtonDisability] = useState(false);
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    const [index, setIndex] = useState(0);
    const [column, setColumn] = useState([
        {
            header: 'Project ID',
            accessorKey: 'projectId',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Dataset ID',
            accessorKey: 'datasetId',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Table ID',
            accessorKey: 'tableId',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Operation Done By',
            accessorKey: 'operationBy',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Agent',
            accessorKey: 'agent',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Last Usage',
            accessorKey: 'lastUsage',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Last Usage Time',
            accessorKey: 'lastTime',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Created By',
            accessorKey: 'createdBy',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Create Time',
            accessorKey: 'createdTime',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Last Modified Time',
            accessorKey: 'lastModifiedTime',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Storage Size',
            accessorKey: 'storageSize',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>
                    {cell.getValue() > 1000000000
                        ? (cell.getValue() / 1000000000).toFixed(2).toString() +
                          ' GB'
                        : cell.getValue() > 1000000
                        ? (cell.getValue() / 1000000).toFixed(2).toString() +
                          ' MB'
                        : cell.getValue() > 1000
                        ? (cell.getValue() / 1000).toFixed(2).toString() + ' KB'
                        : cell.getValue().toString() + ' Bytes'}
                </div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Resource Type',
            accessorKey: 'resourceType',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Caller IP',
            accessorKey: 'callerIp',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({
        entries: [],
        nextPageToken: '',
    });
    const [nextPageToken, setNextPageToken] = useState(null);
    const [finalData, setFinalData] = useState([]);
    const [log, setLog] = useState([]);
    const [ok, setOk] = useState('false');
    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        setButtonDisability(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
            if (data.entries.length == 0) {
                setloading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        if (datasets) {
            var Datasetid = [{ value: 1, label: 'All' }];
            for (var i = 0; i < datasets.length; i++) {
                Datasetid.push({
                    value: i + 2,
                    label: datasets[i]['datasetReference']['datasetId'],
                });
            }
            setDatasetIds(Datasetid);
            //setDatasetIds1(Datasetid);
        }
    }, [datasets]);

    const isDateInputValid = () => {
        if (method.id === 'created') {
            if (startDate > endDate) {
                alert('Please enter valid start and end date');
                return;
            }
        }
        return true;
    };
    const getNextData = () => {
        if (!isDateInputValid() || loading) {
            return;
        }
        if (datasetId.length == 0) {
            alert('Please select atleast one dataset id');
            return;
        }
        setOk('true');
        var datasetids = ``;
        if (datasetId.includes('All')) {
            datasetids = `&datasetId=all`
            /*for (var i = 0; i < datasets.length; i++) {
                datasetids =
                    datasetids +
                    `&datasetId=${datasets[i]['datasetReference']['datasetId']}`;
            }*/
        } else {
            for (var i = 0; i < datasetId.length; i++) {
                datasetids = datasetids + `&datasetId=${datasetId[i]}`;
            }
        }
        var start = startDate.toISOString().split(':');
        var end = endDate.toISOString().split(':');
        if (nextPageToken) {
            getdata(
                `${BACKEND_URL}/gcp/bigquery-table-usage-info?next_page_token=${nextPageToken}&start_time=${
                    start[0] + ':' + start[1] + ':00.000Z'
                }&end_time=${end[0] + ':' + end[1] + ':00.000Z'}&method=${
                    method.id
                }` + `${datasetids}`,
                setData,
                setLoading
            );
        } else {
            getdata(
                `${BACKEND_URL}/gcp/bigquery-table-usage-info?start_time=${
                    start[0] + ':' + start[1] + ':00.000Z'
                }&end_time=${end[0] + ':' + end[1] + ':00.000Z'}&method=${
                    method.id
                }` + `${datasetids}`,
                setData,
                setLoading
            );
        }
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(finalData);
    };

    const handleclick = (ind) => {
        setIndex(ind);
        openModal();
    };
    useEffect(() => {
        setOk('false');
        setFinalData([]);
        setLog([]);
        setData({
            entries: [],
            nextPageToken: '',
        });
        setNextPageToken(null);
        setButtonDisability(false);
    }, [startDate, endDate, method, datasetId]);
    useEffect(() => {
        setStartDate(dateObj);
        setEndDate(dateObj);
    }, [method]);
    
    useEffect(() => {
        if (data['entries'].length > 0) {
            var temp = [];
            var logs = [];
            for (var i = 0; i < data['entries'].length; i++) {
                var bytes =
                    parseInt(data['entries'][i].numLongTermLogicalBytes) +
                    parseInt(data['entries'][i].numActiveLogicalBytes);
                var point = {
                    projectId: data['entries'][i].projectId,
                    datasetId: data['entries'][i].datasetId,
                    tableId: data['entries'][i].tableId,
                    operationBy: 'Unknown',
                    agent: 'Unknown',
                    lastUsage: data['entries'][i].last_usage_operation,
                    lastTime:
                        data['entries'][i].last_usage_time === ''
                            ? 'No Time Found'
                            : data['entries'][i].last_usage_time,
                    createdBy:
                        data['entries'][i].created_by === ''
                            ? 'Not Found'
                            : data['entries'][i].created_by,
                    createdTime: data['entries'][i].create_time,
                    lastModifiedTime: data['entries'][i].lastModifiedTime,
                    storageSize: bytes,
                    resourceType: 'bigquery_dataset',
                    callerIp: 'Unknown',
                };
                logs.push(data['entries'][i].last_usage_log_entry);
                if (
                    Object.keys(data['entries'][i]['last_usage_log_entry'])
                        .length > 0
                ) {
                    var value = data['entries'][i]['last_usage_log_entry'];
                    var callerIpVal = get(
                        get(value.protoPayload, 'requestMetadata', {
                            callerIp: 'No Ip Found',
                        }),
                        'callerIp',
                        'No Ip Found'
                    );
                    var agentVal = get(
                        get(value.protoPayload, 'requestMetadata', {
                            callerSuppliedUserAgent: 'No Agent Found',
                        }),
                        'callerSuppliedUserAgent',
                        'No Agent Found'
                    );
                    agentVal =
                        agentVal.includes('Tableau') === true
                            ? 'Tableau Server'
                            : agentVal.indexOf('/') === -1
                            ? agentVal
                            : agentVal.split('/')[0];
                    point['operationBy'] =
                        value.protoPayload.authenticationInfo.principalEmail;

                    point['resourceType'] = value.resource.type;
                    point['callerIp'] = callerIpVal;
                    point['agent'] = agentVal;
                }

                temp.push(point);
            }
            setLog([...log, ...logs]);
            setFinalData([...finalData, ...temp]);
            setNextPageToken(get(data, 'nextPageToken', null));
            setLoading(false);
        }
    }, [data]);

    return (
        <>
            <Grid container rowGap={3} columnSpacing={4}>
                <Grid xs={4}>
                    <DD2
                        label={'Get Tables By'}
                        emptyLabel={'Select a Method'}
                        options={methods}
                        value={method}
                        onChange={setMethod}
                        displayProperty={'name'}
                        disabled={false}
                    />
                </Grid>
                <Grid xs={4}>
                    <DD3
                        label={'Select Dataset Ids'}
                        options={datasetIds}
                        value={datasetId}
                        onChange={setDatasetId}
                        displayProperty={'label'}
                        disabled={datasetId.includes('All')}
                        minWidth={900}
                        isLoading={datasetIdsLoading}
                    />
                </Grid>

                <Grid xs={4}></Grid>

                {method.name === 'Last Usage' ? (
                    <>
                        <Grid xs={4}>
                            <DateTimePickerInput
                                label={'End Date'}
                                value={endDate}
                                onChange={setEndDate}
                                minDate={new Date('2023-01-13T18:30:00Z')}
                                maxDate={dateObj}
                                height="100px"
                            />
                        </Grid>
                        <Grid xs={4}></Grid>
                        <Grid xs={4}></Grid>
                    </>
                ) : (
                    <>
                        <Grid xs={4}>
                            <DateTimePickerInput
                                label={'Start Date'}
                                value={startDate}
                                onChange={setStartDate}
                                minDate={new Date('2020-11-20T18:30:00Z')}
                                maxDate={endDate}
                            />
                        </Grid>
                        <Grid xs={4}>
                            <DateTimePickerInput
                                label={'End Date'}
                                value={endDate}
                                onChange={setEndDate}
                                maxDate={dateObj}
                            />
                        </Grid>
                        <Grid xs={4}></Grid>
                    </>
                )}
            </Grid>
            <div className="mt-8">
                <PrimaryButton
                    title={
                        method.id === 'last_usage'
                            ? 'Get Tables Not Used After Given Date'
                            : 'Get Tables Created in Selected Date Range'
                    }
                    onClick={getNextData}
                    disabled={buttonDisability}
                />
            </div>

            <div className="grid grid-rows-2 p-4">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <>
                                    <div className="p-1 max-w-[1600px]">
                                        <MaterialReactTable
                                            data={finalData}
                                            columns={column}
                                            enableStickyFooter
                                            enableStickyHeader
                                            initialState={{
                                                columnPinning: {
                                                    left: [
                                                        'mrt-row-select',
                                                        'mrt-row-actions',
                                                    ],
                                                },
                                                density: 'compact',
                                            }}
                                            enableRowActions
                                            state={{
                                                isLoading: loading,
                                            }}
                                            enableRowSelection
                                            enableDensityToggle={false}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: 'Get Full Metadata', //change header text
                                                    size: 150, //make actions column wider
                                                },
                                            }}
                                            renderRowActions={({ row }) => (
                                                <Box>
                                                    <Button
                                                        onClick={() => {
                                                            handleclick(
                                                                row.index
                                                            );
                                                        }}
                                                    >
                                                        View full metadata
                                                    </Button>
                                                </Box>
                                            )}
                                            enableFilterMatchHighlighting
                                            filterFns={{
                                                customFilterFn: (
                                                    row,
                                                    id,
                                                    filterValue
                                                ) => {
                                                    return row
                                                        .getValue(id)
                                                        .includes(filterValue);
                                                },
                                            }}
                                            positionToolbarAlertBanner="bottom"
                                            renderTopToolbarCustomActions={({
                                                table,
                                            }) => (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        gap: '1rem',
                                                        p: '0.5rem',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    <Button
                                                        color="primary"
                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                        onClick={
                                                            handleExportData
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export All Data
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            table.getRowModel()
                                                                .rows.length ===
                                                            0
                                                        }
                                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                        onClick={() =>
                                                            handleExportRows(
                                                                table.getRowModel()
                                                                    .rows
                                                            )
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export Page Rows
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            !table.getIsSomeRowsSelected() &&
                                                            !table.getIsAllRowsSelected()
                                                        }
                                                        //only export selected rows
                                                        onClick={() =>
                                                            handleExportRows(
                                                                table.getSelectedRowModel()
                                                                    .rows
                                                            )
                                                        }
                                                        startIcon={
                                                            <FileDownloadIcon />
                                                        }
                                                        variant="contained"
                                                    >
                                                        Export Selected Rows
                                                    </Button>
                                                    <Button
                                                        color="warning"
                                                        onClick={getNextData}
                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                        startIcon={
                                                            <ArrowForwardIosIcon />
                                                        }
                                                        variant="contained"
                                                        disabled={
                                                            !!!nextPageToken ||
                                                            loading
                                                        }
                                                    >
                                                        Get Next Set of Data
                                                    </Button>
                                                </Box>
                                            )}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            height: '120px',
                                            marginTop: '20px',
                                        }}
                                        class="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-gray-800 dark:text-red-400"
                                        role="alert"
                                    >
                                        <svg
                                            aria-hidden="true"
                                            class="flex-shrink-0 inline w-5 h-5 mr-3"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                        <span class="sr-only">Danger</span>
                                        <div>
                                            <span class="font-medium">
                                                Note:
                                            </span>
                                            <ul class="mt-1.5 ml-4 list-disc list-inside">
                                                <li>
                                                    For old tables whose creator
                                                    could not be found, the last
                                                    person who used the table is
                                                    shown as the creator of the
                                                    table
                                                </li>
                                                <li>
                                                    For old tables whose last
                                                    usage info is not there,
                                                    it's last usage time is
                                                    saved to "" by default.
                                                    These tables are having Last
                                                    Usage as: "not used after
                                                    14th January 2023"
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Transition
                                        appear
                                        show={isOpen}
                                        as={Fragment}
                                    >
                                        <Dialog
                                            as="div"
                                            className="relative z-10"
                                            onClose={closeModal}
                                        >
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                                            </Transition.Child>

                                            <div className="fixed inset-0 overflow-y-auto">
                                                <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 scale-95"
                                                        enterTo="opacity-100 scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 scale-100"
                                                        leaveTo="opacity-0 scale-95"
                                                    >
                                                        <Dialog.Panel className="w-full max-w-[800px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                            >
                                                                METADATA
                                                            </Dialog.Title>
                                                            <div className="mt-2">
                                                                <ReactJson
                                                                    src={
                                                                        log[
                                                                            index
                                                                        ]
                                                                    }
                                                                    displayDataTypes={
                                                                        false
                                                                    }
                                                                    name={false}
                                                                />
                                                            </div>
                                                        </Dialog.Panel>
                                                    </Transition.Child>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </Transition>
                                </>
                            );
                    }
                })()}
            </div>
        </>
    );
}
