import { React, useState, useEffect, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BACKEND_URL } from '../../configs';
import { ExportToCsv } from 'export-to-csv';
import LaunchIcon from '@mui/icons-material/Launch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import { useAxios } from '../../contexts/axios';
import { useSearchParams } from 'react-router-dom';

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function MetabaseData() {
    const [ok, setOk] = useState(false);
    const [metaData, setMetaData] = useState([]);
    const [questionMetaData, setQuestionMetaData] = useState([]);
    const authAxios = useAxios();
    const [value, setValue] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        var tab = searchParams.get('tab')
            ? parseInt(searchParams.get('tab'))
            : 0;
        var dashboardName = searchParams.get('dashboard');
        var questionName = searchParams.get('question');
        //console.log(questionName, dashboardName);
        setValue(tab);
        setOk(true);
        if (dashboardName !== null) {
            authAxios({
                url: `${BACKEND_URL}/gcp/metabase-metadata?type=dashboard&name=${dashboardName}`,
            }).then(function (res) {
                setMetaData(res.data);
            });
        }
        if (questionName !== null) {
            authAxios({
                url: `${BACKEND_URL}/gcp/metabase-metadata?type=question&name=${questionName}`,
            }).then(function (res) {
                setQuestionMetaData(res.data);
            });
        }
        setOk(false);
    }, [searchParams]);

    const [columnDashboard, setColumnDashboard] = useState([
        {
            header: 'Dashboard Name',
            accessorKey: 'name',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Dashboard Id',
            accessorKey: 'id',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },

        {
            header: 'Creator Name',
            accessorKey: 'creator_name',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Creator Email',
            accessorKey: 'creator_email',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Created At',
            accessorKey: 'created_at',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Last Updated At',
            accessorKey: 'updated_at',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
    ]);
    const [columnQuestion, setColumnQuestion] = useState([
        {
            header: 'Question Name',
            accessorKey: 'name',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Question Id',
            accessorKey: 'id',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },

        {
            header: 'Creator Name',
            accessorKey: 'creator_name',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Creator Email',
            accessorKey: 'creator_email',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Created At',
            accessorKey: 'created_at',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
        {
            header: 'Last Updated At',
            accessorKey: 'updated_at',
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
        },
    ]);

    const csvOptionsDashboard = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columnDashboard.map((c) => c.header),
    };

    const csvExporterDashboard = new ExportToCsv(csvOptionsDashboard);

    const handleExportDataDashboard = () => {
        csvExporterDashboard.generateCsv(metaData);
    };

    const csvOptionsQuestion = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columnQuestion.map((c) => c.header),
    };

    const csvExporterQuestion = new ExportToCsv(csvOptionsQuestion);

    const handleExportDataQuestion = () => {
        csvExporterQuestion.generateCsv(metaData);
    };

    //console.log(value);
    //console.log(metaData);
    const getData = async () => {
        setOk(true);
        if (value === 0) {
            try {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/gcp/metabase-metadata?type=dashboard`
                );
                setMetaData(data);
            } catch (e) {
                console.error(e);
            }
        } else {
            try {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/gcp/metabase-metadata?type=question`
                );
                setQuestionMetaData(data);
            } catch (e) {
                console.error(e);
            }
        }
        setOk(false);
    };

    useEffect(() => {
        if (
            searchParams.get('dashboard') === null &&
            searchParams.get('question') == null
        ) {
            if (
                (value === 0 && metaData.length === 0) ||
                (value === 1 && questionMetaData.length === 0)
            ) {
                getData();
            }
        }
    }, [value, searchParams]);

    function myFunction() {
        window.confirm(
            'You will get redirected to this dashboard/question and it will get runned which will incurr cost. Do you still want to open this?'
        );
    }

    return (
        <Box
            sx={{
                width: '100%',
                marginTop: '30px',
            }}
        >
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="DASHBOARD" {...a11yProps(0)} />
                    <Tab label="QUESTIONS" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <MaterialReactTable
                    className="p-4 w-auto mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                    columns={columnDashboard}
                    data={metaData}
                    initialState={{
                        columnPinning: {
                            left: ['mrt-row-select', 'mrt-row-actions'],
                        },
                        density: 'compact',
                    }}
                    enableRowSelection
                    enableStickyHeader
                    enableStickyFooter
                    enableRowActions
                    state={{
                        isLoading: ok,
                    }}
                    enableDensityToggle={false}
                    positionToolbarAlertBanner="bottom"
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Link', //change header text
                            //size: 150, //make actions column wider
                        },
                    }}
                    renderRowActions={({ row }) => (
                        <Box>
                            <a
                                href={`https://metabase.infra.apna.co/dashboard/${row.getValue(
                                    'id'
                                )}`}
                                onClick={myFunction}
                                target="_blank"
                            >
                                <LaunchIcon />
                            </a>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportDataDashboard}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Data
                            </Button>
                        </Box>
                    )}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <MaterialReactTable
                    className="p-4 w-auto mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                    columns={columnQuestion}
                    data={questionMetaData}
                    initialState={{
                        columnPinning: {
                            left: ['mrt-row-select', 'mrt-row-actions'],
                        },
                        density: 'compact',
                    }}
                    enableRowSelection
                    enableStickyHeader
                    enableStickyFooter
                    enableRowActions
                    state={{
                        isLoading: ok,
                    }}
                    enableDensityToggle={false}
                    positionToolbarAlertBanner="bottom"
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: 'Link', //change header text
                            //size: 150, //make actions column wider
                        },
                    }}
                    renderRowActions={({ row }) => (
                        <Box>
                            <a
                                href={`https://metabase.infra.apna.co/question/${row.getValue(
                                    'id'
                                )}`}
                                onClick={myFunction}
                                target="_blank"
                            >
                                <LaunchIcon />
                            </a>
                        </Box>
                    )}
                    renderTopToolbarCustomActions={({ table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                                p: '0.5rem',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Button
                                color="primary"
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportDataQuestion}
                                startIcon={<FileDownloadIcon />}
                                variant="contained"
                            >
                                Export All Data
                            </Button>
                        </Box>
                    )}
                />
            </TabPanel>
        </Box>
    );
}
