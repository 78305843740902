import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import BigqueryAnalysisConfigTable from '../../../../components/administration/bigqueryAnalysisConfig';

const BigqueryAnalysisConfig = () => {
    return (
        <>
           
            <HeaderTitle
                title={'Bigquery Analysis Config'}
                subtitle={'Manage Cost Distribution of specific queries in various teams'}
            />
            <BigqueryAnalysisConfigTable/>
        </>
    );
};

export default BigqueryAnalysisConfig;
