import React from 'react';
import TotalCostingTable from '../../../../components/costing/total-costing';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';

export default function TotalCosting() {
    return (
        <>
            <HeaderTitle
                title={'Total Costing'}
                subtitle={
                    'Total Cost segregation for different tools in different verticals'
                }
            />
            <TotalCostingTable />
        </>
    );
}
