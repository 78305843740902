import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminDashboard from './adminDashboard';
import ToolManagement from './toolManagement';
import UserManagement from './userManagement';
const Admin = () => {
    return (
        <>
            <Routes>
                <Route path="usermanagement/*" element={<UserManagement />} />
                <Route path="toolmanagement/*" element={<ToolManagement />} />
                <Route path="dashboard/*" element={<AdminDashboard />} />
            </Routes>
        </>
    );
};

export default Admin;
