import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TableInfo from './tableInfo';
import ParsedLogs from './parsedLogs';
import QueryLogs from './thresholdQueryLogs';
import TableStorageCostAnalysis from './storageCostAnalysis';
import QueryCostSpike from './queryCostSpike';
import MetabaseQuery from './metabaseQuery';
const Observability = () => {
    return (
        <>
            <Routes>
                <Route path="bigquery-table-deletion-info/*" element={<TableInfo />} />
                <Route path="bigquery-table-usage-info/*" element={<ParsedLogs />} />
                <Route path="query-log-threshold-exceeded-info/*" element={<QueryLogs />} />
                <Route path="metabase-queries-info/*" element={<MetabaseQuery />} />
                <Route path="bigquery-table-storage-analysis/*" element={<TableStorageCostAnalysis />} />
                <Route path="bigquery-analysis-cost-comparator/*" element={<QueryCostSpike />} />
            </Routes>
        </>
    );
};

export default Observability;
