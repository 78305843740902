import React from 'react';
import BackButton from '../../../components/navigation/back-button';
import HeaderTitle from '../../../components/typography/header-title';
//import LogsTable from '../../../components/parsing_logs/logsTable'
import ParsedLogsTableUsageInfo from '../../../components/parsing_logs/parsedLogsTable';
export default function ParsedLogs() {
    return (
        <>
           
            <HeaderTitle
                title={'Bigquery Audit Metadata'}
                subtitle={
                    'Visualize the audit logs to keep record of table creation and any other operation'
                }
            />
            <ParsedLogsTableUsageInfo />
        </>
    );
}
