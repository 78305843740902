import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import NumberCard from './numberCard';
import DatePickerInput from '../DatePickerInput';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import BqStorageTable from './storageTable';
import DD2 from '../DD2';
import AreaChartCard from './chartAreaCard';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import Alert from '@mui/material/Alert';
import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { Dialog } from '@headlessui/react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
    ASIA_SOUTH1_LONG_TERM_COST_RATE,
    ASIA_SOUTH1_ACTIVE_COST_RATE,
} from '../../configs';
function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
function getunitandsize(value) {
    var pow = Math.floor(Math.log10(value)) + 1;
    var result = {};

    if (pow > 12) {
        result['size'] = parseFloat((value / Math.pow(10, 12)).toFixed(2));
        result['unit'] = 'TB';
        return result;
    }
    if (pow > 9) {
        result['size'] = parseFloat((value / Math.pow(10, 9)).toFixed(2));
        result['unit'] = 'GB';
        return result;
    }
    if (pow > 6) {
        result['size'] = parseFloat((value / Math.pow(10, 6)).toFixed(2));
        result['unit'] = 'MB';
        return result;
    }
    if (pow > 3) {
        result['size'] = parseFloat((value / Math.pow(10, 3)).toFixed(2));
        result['unit'] = 'KB';
        return result;
    }
    result['size'] = value;
    result['unit'] = 'B';
    return result;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function StorageCostAnalysis() {
    const authAxios = useAxios();
    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    var dateObj1 = new Date();
    const periodicities = [
        { name: 'Daily', id: 'Day' },
        { name: 'Weekly', id: 'Week' },
        { name: 'Monthly', id: 'Month' },
    ];
    dateObj1.setDate(dateObj1.getDate() - 6);
    const [isOpen, setIsOpen] = useState(false);
    const [instruction, setInstruction] = useState(0);
    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }

    const [finalTableData, setFinalTableData] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj1);
    const [periodicity, setPeriodicity] = useState(null);
    const [ok, setOk] = useState('false');
    const [pageLoading, setPageLoading] = useState(false);
    const [totalCost, setTotalCost] = useState(null);
    const [usedCost, setUsedCost] = useState([]);
    const [notUsedCost, setNotUsedCost] = useState([]);
    const [activeCost, setActiveCost] = useState([]);
    const [longTermCost, setLongTermCost] = useState([]);
    const [createdCost, setCreatedCost] = useState([]);
    const [liveCostData, setLiveCostData] = useState([]);
    const [liveInfoData, setLiveInfoData] = useState([]);
    const [graphCostData, setGraphCostData] = useState([]);
    const [graphCountSizeData, setGraphCountSizeData] = useState([]);
    const [tableData, setTableData] = useState({
        entries: [],
        nextPageToken: '',
    });
    const [tableLoading, setTableLoading] = useState(false);
    const [buttonDisability, setButtonDisability] = useState(false);
    const [totalLiveCost, setTotalLiveCost] = useState(null);
    const [usedLiveCost, setUsedLiveCost] = useState(null);
    const [notUsedLiveCost, setNotUsedLiveCost] = useState(null);
    const [activeLiveCost, setActiveLiveCost] = useState(null);
    const [longTermLiveCost, setLongTermLiveCost] = useState(null);
    const [createdLiveCost, setCreatedLiveCost] = useState(null);
    const [highLiveCost, setHighLiveCost] = useState(null);
    const [mediumLiveCost, setMediumLiveCost] = useState(null);
    const [lowLiveCost, setLowLiveCost] = useState(null);
    const [totalLiveInfo, setTotalLiveInfo] = useState(null);
    const [usedLiveInfo, setUsedLiveInfo] = useState(null);
    const [notUsedLiveInfo, setNotUsedLiveInfo] = useState(null);
    const [activeLiveInfo, setActiveLiveInfo] = useState(null);
    const [longTermLiveInfo, setLongTermLiveInfo] = useState(null);
    const [createdLiveInfo, setCreatedLiveInfo] = useState(null);
    const [highLiveInfo, setHighLiveInfo] = useState(null);
    const [mediumLiveInfo, setMediumLiveInfo] = useState(null);
    const [lowLiveInfo, setLowLiveInfo] = useState(null);
    const [total, setTotal] = useState([]);
    const [inUse, setInUse] = useState([]);
    const [notInUse, setNotInUse] = useState([]);
    const [active, setActive] = useState([]);
    const [longterm, setLongterm] = useState([]);
    const [created, setCreated] = useState([]);
    const [high, setHigh] = useState([]);
    const [medium, setMedium] = useState([]);
    const [low, setLow] = useState([]);
    const [maxEndDate, setMaxEndDate] = useState(null);
    const getdata = async (url, setdata) => {
        //setloading(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
        } catch (e) {
            console.error(e);
        }
    };
    const isDateInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    const getTableData = () => {
        if (!isDateInputValid() || tableLoading) {
            return;
        }
        setTableLoading(true);
        var time = new Date(startDate.toISOString());
        time.setDate(time.getDate() - 1);
        time = time.toISOString().split('T')[0] + 'T18:30:00Z';
        if (nextPageToken) {
            getdata(
                `${BACKEND_URL}/gcp/bigquery-table-not-used?start_time=${time}&next_page_token=${nextPageToken}`,
                setTableData
            );
        } else {
            getdata(
                `${BACKEND_URL}/gcp/bigquery-table-not-used?start_time=${time}`,
                setTableData
            );
        }
    };

    const getTableGraphData = () => {
        if (!isDateInputValid() || pageLoading) {
            return;
        }
        setOk('true');
        setPageLoading(true);
        //await sleep(1000);
        setButtonDisability(true);
        getdata(
            `${BACKEND_URL}/gcp/bigquery-storage-cost?view=${'overtime'}&startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${
                endDate.toISOString().split('T')[0]
            }&periodicity=${periodicity.name.toLowerCase()}`,
            setGraphCostData
        );
        getdata(
            `${BACKEND_URL}/gcp/bigquery-storage-information?startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${endDate.toISOString().split('T')[0]}`,
            setGraphCountSizeData
        );
        getdata(
            `${BACKEND_URL}/gcp/bigquery-storage-cost?view=${'current'}&startDate=${
                dateObj.toISOString().split('T')[0]
            }&endDate=${
                dateObj.toISOString().split('T')[0]
            }&periodicity=${periodicity.name.toLowerCase()}`,
            setLiveCostData
        );
        getdata(
            `${BACKEND_URL}/gcp/bigquery-storage-information?startDate=${
                dateObj.toISOString().split('T')[0]
            }&endDate=${dateObj.toISOString().split('T')[0]}`,
            setLiveInfoData
        );
        getTableData();
        if (graphCostData.length === 0 || graphCountSizeData.length === 0) {
            setPageLoading(false);
        }
        if (instruction + 1 == 1) {
            openModal();
        }
        setInstruction(instruction + 1);
    };

    useEffect(() => {
        var Data = get(tableData, 'entries', []);
        if (Data.length === 0) {
            setTableLoading(false);
        }
        if (Data.length > 0) {
            var temp = [];
            for (var i = 0; i < Data.length; i++) {
                var last_usage_time = Data[i].last_usage_time;
                if (last_usage_time == '') {
                    last_usage_time = new Date('2023-01-13T18:30:00Z');
                } else {
                    last_usage_time = new Date(last_usage_time);
                }
                var now = new Date(
                    new Date().toISOString().split('T')[0] + 'T18:30:00Z'
                );
                var timediff = (now - last_usage_time) / 1000;
                var cost =
                    (Data[i].numActiveLogicalBytes / Math.pow(2, 30)) *
                        (ASIA_SOUTH1_ACTIVE_COST_RATE / (30 * 24 * 60 * 60)) *
                        timediff +
                    (Data[i].numLongTermLogicalBytes / Math.pow(2, 30)) *
                        (ASIA_SOUTH1_LONG_TERM_COST_RATE /
                            (30 * 24 * 60 * 60)) *
                        timediff;
                temp.push({
                    projectId: Data[i].projectId,
                    datasetId: Data[i].datasetId,
                    tableId: Data[i].tableId,
                    lastTime:
                        Data[i].last_usage_time == ''
                            ? 'no time found'
                            : Data[i].last_usage_time,
                    activeSize: Data[i].numActiveLogicalBytes,
                    longTermSize: Data[i].numLongTermLogicalBytes,
                    cost: cost,
                });
            }
            setFinalTableData([...finalTableData, ...temp]);
            setNextPageToken(get(tableData, 'nextPageToken', ''));
            setTableLoading(false);
        }
    }, [tableData]);

    useEffect(() => {
        if (graphCostData.length > 0 && graphCountSizeData.length > 0) {
            var totallongtermcost = 0;
            var totalusedcost = 0;
            var totalactivecost = 0;
            var totalcreatedcost = 0;
            var totalData = { cost: [], count: [], size: [] };
            var inUseData = { cost: [], count: [], size: [] };
            var notInUseData = { cost: [], count: [], size: [] };
            var activeData = { cost: [], size: [] };
            var longtermData = { cost: [], size: [] };
            var createdData = { cost: [], count: [], size: [] };
            var highData = { cost: [], count: [], size: [] };
            var mediumData = { cost: [], count: [], size: [] };
            var lowData = { cost: [], count: [], size: [] };
            for (var j = 0; j < graphCostData.length; j++) {
                var keys = Object.keys(graphCostData[j]);
                if (keys.length > 0) {
                    if (graphCostData[j].category === 'total_longterm_cost') {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            longtermData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                            totallongtermcost += graphCostData[j][keys[i]];
                        }
                    } else if (
                        graphCostData[j].category === 'total_active_cost'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            activeData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                            totalactivecost += graphCostData[j][keys[i]];
                        }
                    } else if (
                        graphCostData[j].category === 'total_used_cost'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            inUseData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                            totalusedcost += graphCostData[j][keys[i]];
                        }
                    } else if (
                        graphCostData[j].category === 'total_created_count'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            createdData['count'].push({
                                time: key,
                                count: graphCostData[j][keys[i]],
                            });
                        }
                    } else if (
                        graphCostData[j].category === 'total_created_size'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            createdData['size'].push({
                                time: key,
                                size: (
                                    graphCostData[j][keys[i]] / 1000000000000
                                ).toFixed(2),
                            });
                        }
                    } else if (
                        graphCostData[j].category === 'total_created_cost'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            createdData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                            totalcreatedcost += graphCostData[j][keys[i]];
                        }
                    } else if (
                        graphCostData[j].category ===
                        'total_high_threshold_cost'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            highData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCostData[j].category ===
                        'total_medium_threshold_cost'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            mediumData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCostData[j].category === 'total_low_threshold_cost'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            lowData['cost'].push({
                                time: key,
                                cost: parseFloat(
                                    graphCostData[j][keys[i]].toFixed(2)
                                ),
                            });
                        }
                    }
                }
            }

            for (var j = 0; j < graphCountSizeData.length; j++) {
                var keys = Object.keys(graphCountSizeData[j]);
                if (keys.length > 0) {
                    if (graphCountSizeData[j]['category'] === 'total_count') {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            totalData['count'].push({
                                time: key,
                                count: graphCountSizeData[j][keys[i]],
                            });
                        }
                    }
                    if (graphCountSizeData[j]['category'] === 'total_size') {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            totalData['size'].push({
                                time: key,
                                size: parseFloat(
                                    (
                                        graphCountSizeData[j][keys[i]] /
                                        1000000000000
                                    ).toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_active_size'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            activeData['size'].push({
                                time: key,
                                size: parseFloat(
                                    (
                                        graphCountSizeData[j][keys[i]] /
                                        1000000000000
                                    ).toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] === 'total_used_count'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            inUseData['count'].push({
                                time: key,
                                count: graphCountSizeData[j][keys[i]],
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] === 'total_used_size'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            inUseData['size'].push({
                                time: key,
                                size: parseFloat(
                                    (
                                        graphCountSizeData[j][keys[i]] /
                                        1000000000000
                                    ).toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_high_threshold_count'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            highData['count'].push({
                                time: key,
                                count: graphCountSizeData[j][keys[i]],
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_high_threshold_size'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            highData['size'].push({
                                time: key,
                                size: parseFloat(
                                    (
                                        graphCountSizeData[j][keys[i]] /
                                        1000000000000
                                    ).toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_medium_threshold_count'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            mediumData['count'].push({
                                time: key,
                                count: graphCountSizeData[j][keys[i]],
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_medium_threshold_size'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            mediumData['size'].push({
                                time: key,
                                size: parseFloat(
                                    (
                                        graphCountSizeData[j][keys[i]] /
                                        1000000000000
                                    ).toFixed(2)
                                ),
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_low_threshold_count'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            lowData['count'].push({
                                time: key,
                                count: graphCountSizeData[j][keys[i]],
                            });
                        }
                    } else if (
                        graphCountSizeData[j]['category'] ===
                        'total_low_threshold_size'
                    ) {
                        for (var i = keys.length - 1; i >= 1; i--) {
                            var key = keys[i];
                            lowData['size'].push({
                                time: key,
                                size: parseFloat(
                                    (
                                        graphCountSizeData[j][keys[i]] /
                                        1000000000000
                                    ).toFixed(2)
                                ),
                            });
                        }
                    }
                }
            }

            for (var i = 0; i < longtermData['cost'].length; i++) {
                totalData['cost'].push({
                    time: longtermData['cost'][i]['time'],
                    cost: parseFloat(
                        (
                            longtermData['cost'][i]['cost'] +
                            activeData['cost'][i]['cost']
                        ).toFixed(2)
                    ),
                });
                notInUseData['cost'].push({
                    time: longtermData['cost'][i]['time'],
                    cost: parseFloat(
                        (
                            longtermData['cost'][i]['cost'] +
                            activeData['cost'][i]['cost'] -
                            inUseData['cost'][i]['cost']
                        ).toFixed(2)
                    ),
                });
            }
            for (var i = 0; i < totalData['count'].length; i++) {
                notInUseData['count'].push({
                    time: totalData['count'][i]['time'],
                    count:
                        totalData['count'][i]['count'] -
                        inUseData['count'][i]['count'],
                });
            }
            for (var i = 0; i < totalData['size'].length; i++) {
                notInUseData['size'].push({
                    time: totalData['size'][i]['time'],
                    size: parseFloat(
                        (
                            totalData['size'][i]['size'] -
                            inUseData['size'][i]['size']
                        ).toFixed(2)
                    ),
                });
                longtermData['size'].push({
                    time: totalData['size'][i]['time'],
                    size: parseFloat(
                        (
                            totalData['size'][i]['size'] -
                            activeData['size'][i]['size']
                        ).toFixed(2)
                    ),
                });
            }
            setTotal(totalData);
            setInUse(inUseData);
            setNotInUse(notInUseData);
            setActive(activeData);
            setLongterm(longtermData);
            setCreated(createdData);
            setHigh(highData);
            setMedium(mediumData);
            setLow(lowData);
            setPageLoading(false);
            //console.log(totallongtermcost, totalactivecost);
            setTotalCost(
                parseFloat((totallongtermcost + totalactivecost).toFixed(2))
            );
            setUsedCost(parseFloat(totalusedcost.toFixed(2)));
            setNotUsedCost(
                parseFloat(
                    (
                        totallongtermcost +
                        totalactivecost -
                        totalusedcost
                    ).toFixed(2)
                )
            );
            setActiveCost(parseFloat(totalactivecost.toFixed(2)));
            setLongTermCost(parseFloat(totallongtermcost.toFixed(2)));
            setCreatedCost(parseFloat(totalcreatedcost.toFixed(2)));
        }
    }, [graphCostData, graphCountSizeData]);

    useEffect(() => {
        if (liveCostData.length > 0) {
            var longtermlive;
            var usedlive;
            var activelive;
            var createdinfo = {};
            for (var j = 0; j < liveCostData.length; j++) {
                if (liveCostData[j].category == 'total_longterm_cost') {
                    longtermlive = liveCostData[j];
                }
                if (liveCostData[j].category == 'total_used_cost') {
                    usedlive = liveCostData[j];
                }
                if (liveCostData[j].category == 'total_active_cost') {
                    activelive = liveCostData[j];
                }
                if (liveCostData[j].category == 'total_created_cost') {
                    var values = Object.values(liveCostData[j]);
                    if (values[2] == 0) {
                        setCreatedLiveCost({
                            value: values[1],
                            change: 'kickstart',
                        });
                    } else {
                        setCreatedLiveCost({
                            value: values[1],
                            change: ((values[1] - values[2]) * 100) / values[2],
                        });
                    }
                }
                if (liveCostData[j].category == 'total_created_count') {
                    var values = Object.values(liveCostData[j]);
                    if (values[2] == 0) {
                        createdinfo['count'] = {
                            value: values[1],
                            change: 'kickstart',
                        };
                    } else {
                        createdinfo['count'] = {
                            value: values[1],
                            change: ((values[1] - values[2]) * 100) / values[2],
                        };
                    }
                }
                if (liveCostData[j].category == 'total_created_size') {
                    var values = Object.values(liveCostData[j]);
                    if (values[2] == 0) {
                        createdinfo['size'] = {
                            value: values[1],
                            change: 'kickstart',
                        };
                    } else {
                        createdinfo['size'] = Object.assign(
                            {
                                change:
                                    ((values[1] - values[2]) * 100) / values[2],
                            },
                            getunitandsize(values[1])
                        );
                    }
                }
                if (liveCostData[j].category == 'total_high_threshold_cost') {
                    var values = Object.values(liveCostData[j]);
                    if (values[2] == 0) {
                        setHighLiveCost({
                            value: values[1],
                            change: 'kickstart',
                        });
                    } else {
                        setHighLiveCost({
                            value: values[1],
                            change: ((values[1] - values[2]) * 100) / values[2],
                        });
                    }
                }
                if (liveCostData[j].category == 'total_medium_threshold_cost') {
                    var values = Object.values(liveCostData[j]);
                    if (values[2] == 0) {
                        setMediumLiveCost({
                            value: values[1],
                            change: 'kickstart',
                        });
                    } else {
                        setMediumLiveCost({
                            value: values[1],
                            change: ((values[1] - values[2]) * 100) / values[2],
                        });
                    }
                }
                if (liveCostData[j].category == 'total_low_threshold_cost') {
                    var values = Object.values(liveCostData[j]);
                    if (values[2] == 0) {
                        setLowLiveCost({
                            value: values[1],
                            change: 'kickstart',
                        });
                    } else {
                        setLowLiveCost({
                            value: values[1],
                            change: ((values[1] - values[2]) * 100) / values[2],
                        });
                    }
                }
            }
            setCreatedLiveInfo(createdinfo);
            var longtermvalues = Object.values(longtermlive);
            setLongTermLiveCost({
                value: longtermvalues[1],
                change:
                    ((longtermvalues[1] - longtermvalues[2]) * 100) /
                    longtermvalues[2],
            });
            var usedvalues = Object.values(usedlive);
            setUsedLiveCost({
                value: usedvalues[1],
                change: ((usedvalues[1] - usedvalues[2]) * 100) / usedvalues[2],
            });
            var activevalues = Object.values(activelive);
            setActiveLiveCost({
                value: activevalues[1],
                change:
                    ((activevalues[1] - activevalues[2]) * 100) /
                    activevalues[2],
            });
            setNotUsedLiveCost({
                value: activevalues[1] + longtermvalues[1] - usedvalues[1],
                change:
                    ((activevalues[1] +
                        longtermvalues[1] -
                        usedvalues[1] -
                        activevalues[2] -
                        longtermvalues[2] +
                        usedvalues[2]) *
                        100) /
                    (longtermvalues[2] - usedvalues[2]),
            });
            setTotalLiveCost({
                value: longtermvalues[1] + activevalues[1],
                change:
                    ((longtermvalues[1] +
                        activevalues[1] -
                        longtermvalues[2] -
                        activevalues[2]) *
                        100) /
                    (longtermvalues[2] + activevalues[2]),
            });
        }
        if (liveInfoData.length > 0) {
            var totallive = {};
            var usedlive = {};
            var activelive = {};
            var highlive = {};
            var mediumlive = {};
            var lowlive = {};
            for (var j = 0; j < liveInfoData.length; j++) {
                if (liveInfoData[j].category == 'total_count') {
                    var values = Object.values(liveInfoData[j]);
                    totallive['count'] = values[1];
                }
                if (liveInfoData[j].category == 'total_size') {
                    var values = Object.values(liveInfoData[j]);
                    totallive['size'] = values[1];
                }
                if (liveInfoData[j].category == 'total_active_size') {
                    var values = Object.values(liveInfoData[j]);
                    activelive['size'] = values[1];
                }
                if (liveInfoData[j].category == 'total_used_size') {
                    var values = Object.values(liveInfoData[j]);
                    usedlive['size'] = values[1];
                }
                if (liveInfoData[j].category == 'total_used_count') {
                    var values = Object.values(liveInfoData[j]);
                    usedlive['count'] = values[1];
                }
                if (liveInfoData[j].category == 'total_high_threshold_count') {
                    var values = Object.values(liveInfoData[j]);
                    highlive['count'] = values[1];
                }
                if (liveInfoData[j].category == 'total_high_threshold_size') {
                    var values = Object.values(liveInfoData[j]);
                    highlive['size'] = values[1];
                }
                if (
                    liveInfoData[j].category == 'total_medium_threshold_count'
                ) {
                    var values = Object.values(liveInfoData[j]);
                    mediumlive['count'] = values[1];
                }
                if (liveInfoData[j].category == 'total_medium_threshold_size') {
                    var values = Object.values(liveInfoData[j]);
                    mediumlive['size'] = values[1];
                }
                if (liveInfoData[j].category == 'total_low_threshold_count') {
                    var values = Object.values(liveInfoData[j]);
                    lowlive['count'] = values[1];
                }
                if (liveInfoData[j].category == 'total_low_threshold_size') {
                    var values = Object.values(liveInfoData[j]);
                    lowlive['size'] = values[1];
                }
            }
            setTotalLiveInfo(
                Object.assign(
                    { count: totallive['count'] },
                    getunitandsize(totallive['size'])
                )
            );
            setUsedLiveInfo(
                Object.assign(
                    { count: usedlive['count'] },
                    getunitandsize(usedlive['size'])
                )
            );
            setActiveLiveInfo(
                Object.assign({}, getunitandsize(activelive['size']))
            );
            setNotUsedLiveInfo(
                Object.assign(
                    { count: totallive['count'] - usedlive['count'] },
                    getunitandsize(totallive['size'] - usedlive['size'])
                )
            );
            setLongTermLiveInfo(
                Object.assign(
                    {},
                    getunitandsize(totallive['size'] - activelive['size'])
                )
            );
            setHighLiveInfo(
                Object.assign(
                    { count: highlive['count'] },
                    getunitandsize(highlive['size'])
                )
            );
            setMediumLiveInfo(
                Object.assign(
                    { count: mediumlive['count'] },
                    getunitandsize(mediumlive['size'])
                )
            );
            setLowLiveInfo(
                Object.assign(
                    { count: lowlive['count'] },
                    getunitandsize(lowlive['size'])
                )
            );
        }
    }, [liveCostData, liveInfoData]);

    useEffect(()=>{
        if(periodicity?.id==="Week"){
            var date = new Date()
            date.setDate(date.getDate()-date.getDay());
            setMaxEndDate(date);
            setStartDate(date);
            setEndDate(date);
        }
        else if(periodicity?.id==="Month"){
            var date = new Date()
            date.setDate(0);
            setStartDate(date);
            setEndDate(date);
            setMaxEndDate(date);
        }
        else{
            setEndDate(dateObj);
            setStartDate(dateObj);
            setMaxEndDate(dateObj);
        }
    },[periodicity])
    
    useEffect(() => {
        setButtonDisability(false);
        setOk('false');
        setFinalTableData([]);
        setTableData({ entries: [], netPageToken: '' });
        setTotalCost(null);
        setUsedCost([]);
        setTotal([]);
        setInUse([]);
        setNotInUse([]);
        setActive([]);
        setLongterm([]);
        setCreated([]);
        setHigh([]);
        setMedium([]);
        setLow([]);
        setNotUsedCost([]);
        setActiveCost([]);
        setLongTermCost([]);
        setCreatedCost([]);
        setLiveCostData([]);
        setLiveInfoData([]);
        setGraphCostData([]);
        setGraphCountSizeData([]);
        setTotalLiveCost(null);
        setUsedLiveCost(null);
        setNotUsedLiveCost(null);
        setActiveLiveCost(null);
        setLongTermLiveCost(null);
        setCreatedLiveCost(null);
        setHighLiveCost(null);
        setMediumLiveCost(null);
        setLowLiveCost(null);
        setTotalLiveInfo(null);
        setUsedLiveInfo(null);
        setNotUsedLiveInfo(null);
        setActiveLiveInfo(null);
        setLongTermLiveInfo(null);
        setCreatedLiveInfo(null);
        setHighLiveInfo(null);
        setMediumLiveInfo(null);
        setLowLiveInfo(null);
    }, [startDate, endDate, periodicity]);
    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={pageLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container rowGap={3} columnSpacing={3}>
                    <Grid xs={4}>
                        <DD2
                            label={'Periodicity'}
                            emptyLabel={'Select a periodicity'}
                            options={periodicities}
                            value={periodicity}
                            onChange={setPeriodicity}
                            displayProperty={'name'}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            minDate={new Date('2023-03-01T18:30:00Z')}
                            maxDate={endDate}
                            onChange={setStartDate}
                            disabled={!!!periodicity}
                        />
                    </Grid>
                    <Grid xs={4}>
                        <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            maxDate={maxEndDate}
                            onChange={setEndDate}
                            disabled={!!!periodicity}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <Button
                            children={'get cost analysis'}
                            sx={{
                                width: 250,
                                backgroundColor: 'burlywood',
                                fontWeight: 'bold',
                                color: 'green',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#b1936c',
                                    color: 'white',
                                },
                            }}
                            onClick={getTableGraphData}
                            disabled={buttonDisability}
                        />
                    </Grid>
                    <Grid xs={3}></Grid>
                    <Grid xs={3}></Grid>
                    <Grid xs={3}></Grid>
                    {(() => {
                        switch (ok) {
                            case 'false':
                                return <></>;
                            case 'true':
                                return (
                                    <>
                                        <Grid xs={2}>
                                            <NumberCard
                                                heading={'cost'}
                                                noOfEntities={totalCost}
                                                textColor={'blue'}
                                            />
                                        </Grid>
                                        <Grid xs={2}>
                                            <NumberCard
                                                heading={'used'}
                                                noOfEntities={usedCost}
                                                textColor={'green'}
                                            />
                                        </Grid>
                                        <Grid xs={2}>
                                            <NumberCard
                                                heading={'not used'}
                                                noOfEntities={notUsedCost}
                                                textColor={'red'}
                                            />
                                        </Grid>
                                        <Grid xs={2}>
                                            <NumberCard
                                                heading={'active'}
                                                noOfEntities={activeCost}
                                                textColor={'#b28704'}
                                            />
                                        </Grid>
                                        <Grid xs={2}>
                                            <NumberCard
                                                heading={'long term'}
                                                noOfEntities={longTermCost}
                                                textColor={'#9c27b0'}
                                            />
                                        </Grid>
                                        <Grid xs={2}>
                                            <NumberCard
                                                heading={'created'}
                                                noOfEntities={createdCost}
                                                textColor={'#33ab9f'}
                                            />
                                        </Grid>

                                        <Grid xs={4}>
                                            {totalLiveCost && totalLiveInfo ? (
                                                <AreaChartCard
                                                    data={total}
                                                    graphColor={'#9fa8da'}
                                                    graphHeading={'Total'}
                                                    textColor={'#0d47a1'}
                                                    liveCost={totalLiveCost}
                                                    liveInfo={totalLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {usedLiveCost && usedLiveInfo ? (
                                                <AreaChartCard
                                                    data={inUse}
                                                    graphColor={'#80cbc4'}
                                                    graphHeading={'In use'}
                                                    textColor={'#004d40'}
                                                    liveCost={usedLiveCost}
                                                    liveInfo={usedLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {notUsedLiveCost &&
                                            notUsedLiveInfo ? (
                                                <AreaChartCard
                                                    data={notInUse}
                                                    graphColor={'#f48fb1'}
                                                    graphHeading={'Not in use'}
                                                    textColor={'#880e4f'}
                                                    liveCost={notUsedLiveCost}
                                                    liveInfo={notUsedLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {activeLiveCost &&
                                            activeLiveInfo ? (
                                                <AreaChartCard
                                                    data={active}
                                                    graphColor={'#bcaaa4'}
                                                    graphHeading={
                                                        'Having active pricing'
                                                    }
                                                    textColor={'#3e2723'}
                                                    liveCost={activeLiveCost}
                                                    liveInfo={activeLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {longTermLiveCost &&
                                            longTermLiveInfo ? (
                                                <AreaChartCard
                                                    data={longterm}
                                                    graphColor={'#e0e0e0'}
                                                    graphHeading={
                                                        'Having long-term pricing'
                                                    }
                                                    textColor={'#212121'}
                                                    liveCost={longTermLiveCost}
                                                    liveInfo={longTermLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {createdLiveCost &&
                                            createdLiveInfo ? (
                                                <AreaChartCard
                                                    data={created}
                                                    graphColor={'#b39ddb'}
                                                    textColor={'#311b92'}
                                                    graphHeading={'Created'}
                                                    liveCost={createdLiveCost}
                                                    liveInfo={createdLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {highLiveCost && highLiveInfo ? (
                                                <AreaChartCard
                                                    data={high}
                                                    graphColor={'#ef9a9a'}
                                                    graphHeading={
                                                        'Not in use(HIGH) cost'
                                                    }
                                                    textColor={'#b71c1c'}
                                                    liveCost={highLiveCost}
                                                    liveInfo={highLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {mediumLiveCost &&
                                            mediumLiveInfo ? (
                                                <AreaChartCard
                                                    data={medium}
                                                    graphColor={'#fff59d'}
                                                    graphHeading={
                                                        'Not in use(MEDIUM) cost'
                                                    }
                                                    textColor={'#f57f17'}
                                                    liveCost={mediumLiveCost}
                                                    liveInfo={mediumLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>
                                        <Grid xs={4}>
                                            {lowLiveCost && lowLiveInfo ? (
                                                <AreaChartCard
                                                    data={low}
                                                    graphColor={'#c5e1a5'}
                                                    graphHeading={
                                                        'Not in use(LOW) cost'
                                                    }
                                                    textColor={'#33691e'}
                                                    liveCost={lowLiveCost}
                                                    liveInfo={lowLiveInfo}
                                                    periodicity={periodicity.id}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </Grid>

                                        <Grid xs={12}>
                                            <BqStorageTable
                                                tableData={finalTableData}
                                                loading={tableLoading}
                                                nextPageToken={nextPageToken}
                                                getTableData={getTableData}
                                            />
                                        </Grid>
                                        <Grid xs={12}>
                                            <Alert severity="error">
                                                All the top six cards shown
                                                above represents the cost of
                                                various scenarios for the
                                                selected time range
                                            </Alert>
                                            <Alert severity="error">
                                                The next nine graph cards
                                                represents the plot of cost as
                                                per periodicity for the selected
                                                time range. The cost which is
                                                highlighted in these cards are
                                                the cost of current periodicity.
                                                Suppose the periodicty is weekly
                                                then it shows the cost from the
                                                starting of this present week
                                                till now.
                                            </Alert>
                                            <Alert severity="error">
                                                These nine graphs have info
                                                button which on clicking shows
                                                plots of size and count for this
                                                scenario.
                                            </Alert>
                                            <Alert severity="error">
                                                The last table shows all the
                                                bigquery tables which are not
                                                used after the selected start
                                                date including various other
                                                details
                                            </Alert>
                                            <Alert severity="error">
                                                The estimated cost for the
                                                tables is calculated from the
                                                last usage time till end of
                                                today based on current size of
                                                tables. For those tables whose
                                                last usage time is not found for
                                                them last usage time is 14th Jan
                                                2023 as we are monitoring table
                                                usage from that time.
                                            </Alert>
                                        </Grid>
                                    </>
                                );
                        }
                    })()}
                </Grid>
            </Box>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto mt-20">
                        <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[600px] transform overflow-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all bg-red-100">
                                    <Dialog.Title
                                        as="h2"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        INSTRUCTION
                                    </Dialog.Title>
                                    <div style={{ marginTop: '20px' }}>
                                        <Typography
                                            variant="overline"
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'black',
                                            }}
                                        >
                                            1.All the top six cards shown above
                                            represents the cost of various
                                            scenarios for the selected time
                                            range
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="overline"
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'black',
                                            }}
                                        >
                                            2.The next nine graph cards
                                            represents the plot of cost as per
                                            periodicity for the selected time
                                            range. The cost which is highlighted
                                            in these cards are the cost of
                                            current periodicity. Suppose the
                                            periodicty is weekly then it shows
                                            the cost from the starting of this
                                            present week till now.
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="overline"
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'black',
                                            }}
                                        >
                                            3. These nine graphs have info
                                            button which on clicking shows plots
                                            of size and count for this scenario.
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="overline"
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'black',
                                            }}
                                        >
                                            4.The last table shows all the
                                            bigquery tables which are not used
                                            after the selected start date
                                            including various other details
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="overline"
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'black',
                                            }}
                                        >
                                            5.The estimated cost for the tables
                                            is calculated from the last usage
                                            time till end of today based on
                                            current size of tables. For those
                                            tables whose last usage time is not
                                            found for them last usage time is
                                            14th Jan 2023 as we are monitoring
                                            table usage from that time.
                                        </Typography>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
