//import MetabaseQueryTable from '../table/metabaseQuery';
import { React, useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MaterialReactTable from 'material-react-table';
import DD2 from '../DD2';
import { ExportToCsv } from 'export-to-csv';
import DatePickerInput from '../DatePickerInput';
import FlexRow from '../layout/FlexRow';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import SearchDD from '../SearchDD';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Loading from '../costing/Loading';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

function ParseDate(data) {
    var date = data.split('-');
    return date[0] + '/' + date[1] + " '" + date[2][2] + date[2][3];
}
//*************************** Building tags *****************************************

const TableFooterDataCell = ({ cost }) => {
    return (
        <div className="text-align-center">
            <h6>{cost}</h6>
        </div>
    );
};

const TableFirstDataCell = ({ children }) => {
    return (
        <div
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            className="min-w-auto max-w-[335px] p-2 text-sm font-bold text-gray-900 text-center"
        >
            <h6>{children}</h6>
        </div>
    );
};

export default function MetabaseData() {
    const [users, setUsers] = useState([]);
    const [info, setInfo] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    const [buttonDisability, setButtonDisability] = useState(false);
    const [user, setUser] = useState({ label: 'All', id: 'all' });
    const [data, setData] = useState([]);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalData, setTotalData] = useState(null);
    const [total, setTotal] = useState(0);
    const [finalData, setFinalData] = useState([]);
    const [metaData, setMetaData] = useState([]);
    const [ok, setOk] = useState(false);
    const [column, setColumn] = useState([]);
    const [header, setHeader] = useState([]);
    const [totalCost, setTotalCost] = useState([]);
    //const [finalData, setFinalData] = useState([]);
    const authAxios = useAxios();

    const views = [
        { name: 'Total Count', id: 'total' },
        { name: 'Overtime Count', id: 'overtime' },
    ];
    const periodicities = [
        { name: 'Daily Count', id: 'daily' },
        { name: 'Weekly Count', id: 'weekly' },
        { name: 'Monthly Count', id: 'monthly' },
    ];

    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    const [viewCount, setViewCount] = useState(views[0]);
    const [periodicity, setPeriodicity] = useState(null);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);

    const getUserList = async () => {
        setUsersLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/gcp/bigquery-metabase-users`
            );
            setUsers(data);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        var allUser = [{ label: 'All', id: 'all' }];
        if (users?.length > 0) {
            for (var i = 0; i < users.length; i++) {
                allUser.push({
                    label: users[i]['email'],
                    id: users[i]['email'],
                });
            }
        }
        setUsersData(allUser);
        setUsersLoading(false);
    }, [users]);

    useEffect(() => {
        getUserList();
    }, []);

    const isInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    const getData = async () => {
        if (!isInputValid() || loading) {
            return;
        }
        setLoading(true);
        //setButtonDisability(true);
        //setOk('true');
        try {
            if (nextPageToken) {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/gcp/bigquery-metabase-queries?email=${
                        user.id
                    }&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&periodicity=${
                        !!!periodicity ? 'none' : periodicity.id
                    }&view=${viewCount.id}&next_page_token=${nextPageToken}`
                );
                setInfo([...info, ...data['entries']]);
                setNextPageToken(get(data, 'nextPageToken', ''));
            } else {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/gcp/bigquery-metabase-queries?email=${
                        user.id
                    }&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&periodicity=${
                        !!!periodicity ? 'none' : periodicity.id
                    }&view=${viewCount.id}`
                );
                setInfo([...info, ...data['entries']]);
                setNextPageToken(get(data, 'nextPageToken', ''));
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };
    //console.log(info);

    useEffect(() => {
        if (info.length > 0) {
            if (viewCount.id === 'overtime') {
                var dataDict = {};
                for (var i = 0; i < info.length; i++) {
                    var keys = Object.keys(info[i]);
                    if (
                        Object.keys(dataDict).includes(
                            info[i]['principal'] + '/' + info[i]['hash']
                        ) === false
                    ) {
                        dataDict[info[i]['principal'] + '/' + info[i]['hash']] =
                            {};
                    }
                    for (var j = 0; j < keys.length; j++) {
                        if (keys[j] !== 'principal' && keys[j] !== 'hash') {
                            if (
                                get(
                                    dataDict[
                                        info[i]['principal'] +
                                            '/' +
                                            info[i]['hash']
                                    ],
                                    keys[j],
                                    0
                                ) === 0
                            ) {
                                dataDict[
                                    info[i]['principal'] + '/' + info[i]['hash']
                                ][keys[j]] = info[i][keys[j]];
                            }
                        }
                    }
                }
                var keys = Object.keys(dataDict);
                var result = [];
                for (var k = 0; k < keys.length; k++) {
                    var tempDict = {};
                    tempDict['principal'] = keys[k].split('/')[0];
                    var tempKeys = Object.keys(dataDict[keys[k]]);
                    for (var l = 0; l < tempKeys.length; l++) {
                        tempDict[tempKeys[l]] = dataDict[keys[k]][tempKeys[l]];
                    }
                    result.push(tempDict);
                }
                //console.log(result);
                setData(result);
            } else {
                if (info.length > 0) {
                    var totalResult = [];
                    for (var k = 0; k < info.length; k++) {
                        var tempDict = {};
                        tempDict['principal'] = info[k]['principal'];
                        tempDict['query'] = info[k]['query'];
                        tempDict['total'] = info[k]['total'];
                        totalResult.push(tempDict);
                    }
                    setData(totalResult);
                }
            }
        }
    }, [info, viewCount]);

    useEffect(() => {
        if (data?.length > 0) {
            setOk('load');
            if (viewCount?.id === 'overtime') {
                const head = Object.keys(data[0]);
                setHeader(head);
                //Getting cost and increment of services per day/week/month
                const Cost = [];
                for (var i = 0; i < data.length; i++) {
                    var ServiceCostData = [];
                    ServiceCostData.push(data[i]['principal']); //use useState
                    ServiceCostData.push(data[i]['query']); //use useState
                    //ServiceCostData.push([0, data[i][head[1]]]); // isme value aaega-->
                    for (var j = 0; j < head.length; j++) {
                        if (head[j] !== 'principal' && head[j] !== 'query') {
                            ServiceCostData.push(data[i][head[j]]);
                        }
                    }

                    Cost.push(ServiceCostData);
                }
                //console.log(Cost);

                //Getting total cost and avg increment of services per day/week/month
                const total = [];
                let totalCost = [];
                total.push('Total');
                for (var i = 2; i < Cost[0].length; i++) {
                    var TotalServiceCost = 0;
                    if (i > 1) {
                        for (var j = 0; j < Cost.length; j++) {
                            TotalServiceCost = TotalServiceCost + Cost[j][i];
                        }
                        total.push(TotalServiceCost);
                    } else {
                        for (var j = 0; j < Cost.length; j++) {
                            TotalServiceCost = TotalServiceCost + Cost[j][i];
                        }
                        total.push(TotalServiceCost);
                    }
                    totalCost.push(TotalServiceCost);
                }
                setTotalCost(total);
                setTotalData(totalCost);
                //data definitions...
                const finalRows = [];
                for (var i = 0; i < Cost.length; i++) {
                    var rowData = {};
                    rowData['principal'] = Cost[i][0];
                    rowData['query'] = Cost[i][1];
                    var indx = 2;
                    for (var j = 0; j < head.length; j++) {
                        if (head[j] !== 'principal' && head[j] !== 'query') {
                            rowData[head[j]] = Cost[i][indx];
                            indx = indx + 1;
                        }
                    }
                    finalRows.push(rowData);
                }
                setFinalData(finalRows);
            } else {
                var col = Object.keys(data[0]);
                setColumn([
                    {
                        header: 'Principal',
                        accessorKey: 'principal',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                            sx: {
                                overflow: 'visible',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                fontWeight: 'bold'
                                //fontSize: '12px',
                            },
                        },
                    },

                    {
                        header: 'Query',
                        accessorKey: 'query',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                            sx: {
                                overflow: 'visible',
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                outerWidth: '500px',
                                fontSize: '12px',
                            },
                        },
                    },
                    {
                        header: 'Count',
                        accessorKey: 'total',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                    },
                ]);
                var totalCost = 0;
                for (var i = 0; i < data.length; i++) {
                    totalCost += data[i]['total'];
                }
                setTotal(totalCost);
                setFinalData(data);
            }

            setOk('true');
        }
    }, [data, viewCount]);
    //console.log(data);
    useEffect(() => {
        if (header.length > 0 && totalCost.length > 0 && data.length > 0) {
            var cols = [
                {
                    header: 'Principal',
                    accessorKey: 'principal',
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                        sx: {
                            overflow: 'visible',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            fontWeight: 'bold'
                            //fontSize: '12px',
                        },
                    },
                    muiTableFooterCellProps: {
                        align: 'center',
                    },
                    Footer: 'Total Count',
                }
            ];
            if (periodicity?.id === 'weekly') {
                const customHeader = [];
                for (var i = 0; i < header.length; i++) {
                    customHeader.push(header[i].split(' '));
                }

                for (var i = 0; i < header.length; i++) {
                    if (header[i] !== 'principal' && header[i] !== 'query') {
                        cols.push({
                            accessorKey: header[i],
                            header: header[i],
                            Header: (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                    }}
                                    className="grid grid-rows-2 grid-flow-col gap-1"
                                >
                                    <div>
                                        {'Week ' +
                                            customHeader[i][0].split('-')[1]}
                                    </div>
                                    <div>
                                        {ParseDate(customHeader[i][1]) +
                                            ' - ' +
                                            ParseDate(customHeader[i][2])}
                                    </div>
                                </div>
                            ),
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            muiTableBodyCellProps: {
                                align: 'center',
                            },
                            muiTableFooterCellProps: {
                                align: 'center',
                            },
                            Footer: totalCost[i],
                        });
                    }
                }
            } else {
                //console.log(periodicity);
                for (var i = 0; i < header.length; i++) {
                    if (header[i] !== 'principal' && header[i] !== 'query') {
                        cols.push({
                            accessorKey: header[i],
                            header: header[i],
                            Header: (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '15px',
                                    }}
                                >
                                    {header[i].toUpperCase()}
                                </div>
                            ),
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            muiTableBodyCellProps: {
                                align: 'center',
                            },
                            muiTableFooterCellProps: {
                                align: 'center',
                            },
                            Footer: totalCost[i],
                        });
                    }
                }
            }
            cols.push({
                header: 'Query',
                accessorKey: 'query',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                    sx: {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        fontSize: '12px',
                    },
                },
            })
            setColumn(cols);
        }
    }, [totalCost]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    //console.log(header);
    //console.log(data);
    //console.log(finalData);
    //console.log(column);

    useEffect(() => {
        setOk('false');
        setData([]);
        setFinalData([]);
        setMetaData([]);
        setInfo([]);
        setColumn([]);
        setNextPageToken(null);
        //setButtonDisability(false);
    }, [startDate, endDate, periodicity, viewCount, user]);

    return (
        <>
            {!!loading ? (
                <Box sx={{ width: '100%', marginTop: '40vh' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <>
                    <FlexRow>
                        <SearchDD
                            label={'User Email'}
                            emptyLabel={'Select email'}
                            options={usersData}
                            value={user}
                            onChange={setUser}
                            isLoading={usersLoading}
                        />
                        <DD2
                            label={'View'}
                            emptyLabel={'Select a View Type'}
                            options={views}
                            value={viewCount}
                            onChange={setViewCount}
                            displayProperty={'name'}
                            disabled={false}
                        />
                        <DD2
                            label={'Periodicity'}
                            emptyLabel={'Select a periodicity'}
                            options={periodicities}
                            value={periodicity}
                            onChange={setPeriodicity}
                            displayProperty={'name'}
                            disabled={viewCount?.id === 'total'}
                        />
                        <FlexRow />
                    </FlexRow>
                    <FlexRow>
                        <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                        />
                        <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            onChange={setEndDate}
                            maxDate={dateObj}
                            minDate={startDate}
                        />
                        <FlexRow>
                            <div className="mt-10">
                                <Button
                                    children={'Get user wise queries'}
                                    sx={{
                                        width: 400,
                                        backgroundColor: '#0096FF',
                                        fontWeight: 'bold',
                                        color: 'white',
                                        height: 53,
                                        padding: 2,
                                        ':hover': {
                                            backgroundColor: '#6F8FAF',
                                            color: 'white',
                                        },
                                    }}
                                    onClick={getData}
                                    disabled={buttonDisability}
                                />
                            </div>
                        </FlexRow>
                        <FlexRow></FlexRow>
                    </FlexRow>
                    {(() => {
                        switch (ok) {
                            case 'false':
                                return <></>;
                            case 'load':
                                return <Loading />;
                            case 'true':
                                return (
                                    <MaterialReactTable
                                        data={finalData}
                                        columns={column}
                                        enableDensityToggle={false}
                                        enableFullScreenToggle
                                        enableStickyFooter
                                        enableStickyHeader
                                        muiTableBodyCellProps={{
                                            sx: {
                                                fontWeight: 'bold',
                                                fontSize: '14px',
                                            },
                                        }}
                                        muiTableBodyProps={{
                                            sx: {
                                                marginTop: '100px',
                                            },
                                        }}
                                        initialState={{
                                            columnPinning: {
                                                left: [
                                                    'principal',
                                                    'query',
                                                ],
                                            }, //use useState
                                            density: 'compact',
                                        }}
                                        positionToolbarAlertBanner="bottom"
                                        renderTopToolbarCustomActions={({
                                            table,
                                        }) => (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: '1rem',
                                                    p: '0.5rem',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <Button
                                                    color="primary"
                                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                    onClick={handleExportData}
                                                    startIcon={
                                                        <FileDownloadIcon />
                                                    }
                                                    variant="contained"
                                                >
                                                    Export All Data
                                                </Button>

                                                <Button
                                                    color="warning"
                                                    onClick={getData}
                                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                    startIcon={
                                                        <ArrowForwardIosIcon />
                                                    }
                                                    variant="contained"
                                                    disabled={
                                                        nextPageToken === ''
                                                    }
                                                >
                                                    Get Next Set of Data
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                );
                        }
                    })()}
                </>
            )}
        </>
    );
}
