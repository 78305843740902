import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

/*function stringToColor(string) {
  let hash = 0;
  let i;

  
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 35) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 225)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
 

  return color;
}*/

function stringAvatar(change,name) {
  return {
    sx: {
      bgcolor: change>=0?'#EE4B2B':'#50C878',
      width:'70px',
      height:'70px',
      fontWeight:'bold',
      textTransform:"uppercase",
      color: 'white',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export default function BackgroundLetterAvatars({change, name}) {
  return (
      <Avatar className='drop-shadow-xl' {...stringAvatar(change,name)}/>
  );
}