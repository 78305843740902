import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';

function BasicCard({heading,description,href,buttonText}) {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14, fontWeight:'bold' }} color="text.secondary" gutterBottom>
          {heading}
        </Typography>
        <Typography color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button href={href} target='_blank' size="small">{buttonText}</Button>
      </CardActions>
    </Card>
  );
}

export default function Policies() {

  return (
         
          <Grid className='mt-4' container rowGap={3} columnGap={2}>
          <Grid xs={12}>
          <h3 className="text-center">POLICIES</h3>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'OWASP Cheat Sheet Series ASVS Index'} description={'With supporting text below as a natural lead-in to additional content.'} href={"https://cheatsheetseries.owasp.org/IndexASVS.html"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'OWASP Cheat Sheet Series MASVS Index'} description={'With supporting text below as a natural lead-in to additional content'} href={"https://cheatsheetseries.owasp.org/IndexMASVS.html"} buttonText={'Go to page'}/>
           </Grid>
           <Grid xs={3}/>
           <Grid xs={3}/>
           <Grid xs={12}>
          <h3 className="text-center">READING REFERENCES</h3>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Application Security'} description={'Best Practice for Application Security in the Cloud'} href={"https://www.shiftleft.io/blog/best-practice-for-application-security-in-the-cloud/"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Gitlab'} description={'Threat Modeling'} href={"https://about.gitlab.com/handbook/security/threat_modeling/"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Gitlab'} description={'Threat Modeling'} href={"https://about.gitlab.com/handbook/security/threat_modeling/"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Threat Modeling cheatsheet'} href={"https://cheatsheetseries.owasp.org/cheatsheets/Threat_Modeling_Cheat_Sheet.html"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Application security'} description={'Complete guide to application security'} href={"https://snyk.io/learn/application-security/"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Application security'} description={'15 Application Security Best Practices'} href={"https://snyk.io/learn/application-security-best-practices/"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Application security'} description={'Blazing the trail for cloud native application security'} href={"https://snyk.io/blog/blazing-the-trail-for-cloud-native-application-security/"} buttonText={'Go to page'}/>
            </Grid>
            <Grid xs={3}>
            <BasicCard heading={'Shift Left'} description={'Shift Left DevOps'} href={"https://www.aquasec.com/cloud-native-academy/devsecops/shift-left-devops/#:~:text=What%20Is%20Shift%20Left%20DevOps,%2C%20security%2C%20and%20operations)."} buttonText={'Go to page'}/>
            </Grid>
           </Grid>
  );
}
