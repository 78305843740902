import { React, useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { BACKEND_URL } from '../../configs';
import TaskTable from '../table/taskTablse';
//import FlexRow from '../layout/FlexRow';
import { useAxios } from '../../contexts/axios';
import ReactJson from 'react-json-view';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Unstable_Grid2';
import { useMediaQuery } from 'react-responsive';

export default function Information() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [user, setUser] = useState(null);
    const [data1, setData1] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [ok, setOk] = useState('false');
    const [column1, setColumn1] = useState([]);
    const [buttonDisability, setButtonDisability] = useState(false);
    const authAxios = useAxios();

    const getdata = async (
        url,
        setdata,
        setcolumn,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var keys = Object.keys(data[0]);
            var cols = [];
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] === 'campaign_info') {
                    cols.push({
                        header: keys[i],
                        accessorKey: keys[i],
                        Cell: ({ cell }) => (
                            <ReactJson
                                collapseStringsAfterLength={80}
                                src={cell.getValue()}
                                displayDataTypes={false}
                                name={false}
                            />
                        ),
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                    });
                } else {
                    cols.push({
                        header: keys[i],
                        accessorKey: keys[i],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 5,
                        muiTableBodyCellProps: muitablebodycellprops,
                    });
                }
            }
            setcolumn(cols);
            setdata(data);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const isInputValid = () => {
        if (user === null) {
            alert('Please enter userId or Phone Number');
            return;
        }
        return true;
    };

    const getData = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (user !== null) {
            getdata(
                `${BACKEND_URL}/gcp/blacklisted-user-info?key=${user}`,
                setData1,
                setColumn1,
                setLoading1,
                {
                    align: 'left',
                }
            );
        }
        setButtonDisability(false);
    };

    useEffect(() => {
        setData1([]);
        setColumn1([]);
        setOk('false');
    }, [user]);

    return (
        <>
            <Grid container rowGap={3} columnSpacing={3}>
                <Grid xs={isMobile ? 6 : 3}>
                    <TextField
                        label={'Enter UserId/Phone Number'}
                        value={user}
                        //sx={{ maxWidth: 250 }}
                        onChange={(e) => {
                            setUser(e.target.value);
                        }}
                    />
                </Grid>
                <Grid xs={isMobile ? 6 : 3}>
                    <Box display="flex" width="100%">
                        <Button
                            children={'Get Status'}
                            sx={{
                                //width: 200,
                                backgroundColor: '#0096FF',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            fullWidth={true}
                            onClick={getData}
                            disabled={buttonDisability}
                        />
                    </Box>
                </Grid>
                {!isMobile ? (
                    <>
                        <Grid xs={3}></Grid>
                        <Grid xs={3}></Grid>
                    </>
                ) : (
                    <></>
                )}
            </Grid>
            <Box display="flex" width="80vw" marginTop="20px">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <Grid xs={12}>
                                    <TaskTable
                                        data={data1}
                                        columns={column1}
                                        loading={loading1}
                                    />
                                    {data1.length > 0 &&
                                    data1[0]['is_blacklisted'] === 'False' ? (
                                        <>
                                            {data1[0][
                                                'core_blacklisteduser'
                                            ] === 'Present' ? (
                                                <Alert severity="error">
                                                    This user is present in
                                                    core_blacklisteduser table
                                                </Alert>
                                            ) : (
                                                <></>
                                            )}
                                            {data1[0]['deleted_user'] ===
                                            'Present' ? (
                                                <Alert severity="error">
                                                    This user is present in
                                                    deleted_user table
                                                </Alert>
                                            ) : (
                                                <></>
                                            )}
                                            {data1[0]['core_bannedemployer'] ===
                                            'Present' ? (
                                                <Alert severity="error">
                                                    This user is present in
                                                    core_bannedemployer table
                                                </Alert>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Grid>
                            );
                    }
                })()}
            </Box>
        </>
    );
}
