import React from 'react';
import { Listbox } from '@headlessui/react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

const TimePickerInput = ({ label, value, onChange, disabled, maxTime }) => {
    return (
        <div className="flex flex-col flex-1 w-full space-y-0 min-w-max">
            <Listbox disabled={disabled}>
                <Listbox.Label className="text-s font-bold text-gray-700 mt-0">
                    {label}
                </Listbox.Label>
                <div className="w-full">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                            className="w-full"
                            value={value}
                            onChange={onChange}
                            renderInput={(params) => <TextField {...params} />}
                            maxTime={maxTime}
                            disabled={disabled}
                        ></MobileTimePicker>
                    </LocalizationProvider>
                </div>
            </Listbox>
        </div>
    );
};

export default TimePickerInput;
