import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import { useState, useEffect } from 'react';
import PrimaryButton from '../../../../components/button/PrimaryButton';
import { BACKEND_URL } from '../../../../configs';
import Datatable from '../../../../components/costing/Datatable';
import { useAxios } from '../../../../contexts/axios';
import DD2 from '../../../../components/DD2';
import DatePickerInput from '../../../../components/DatePickerInput';
import FilterPercentage from '../../../../components/costing/filter-percentage';
import { useGCPProjects } from '../../../../hooks/costing/useGCP';
import Grid from '@mui/material/Unstable_Grid2';
export default function GCPResourceUtilization() {
    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(`${BACKEND_URL}/gcp/${url}`);
            setdata(data);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const { data: projects, isLoading: projectsLoading } = useGCPProjects();
    const [project, setProject] = useState(null);
    const [metricTypes, setmetricTypes] = useState([
        { name: 'GCE CPU Utilization', value: 'GCE_CPU_Utilization' },
    ]);
    const [filterOperators, setFilterOperators] = useState([
        { value: 'LessThan', name: '<' },
        { value: 'LessThanEqualsTo', name: '<=' },
        { value: 'GreaterThan', name: '>' },
        { value: 'GreaterThanEqualsTo', name: '>=' },
    ]);
    const [dataLoading, setDataLoading] = useState(false);
    const [filterOperator, setFilterOperator] = useState(null);
    const [filterPercentage, setFilterPercentage] = useState(25);
    const [metricType, setMetricType] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    // const [firstRow, setFirstRow] = useState([]);
    const [rawData, setRawData] = useState(null);
    const [load, setLoad] = useState(false);
    const [ok, setOk] = useState('false');
    const [columns] = useState([
        { header: 'Instance Name', accessorKey: 'InstanceName',filterFn: 'customFilterFn' },
        { header: 'Project Id', accessorKey: 'ProjectId',filterFn: 'customFilterFn' },
        { header: 'Resource Id', accessorKey: 'ResourceId',filterFn: 'customFilterFn' },
        { header: 'Status', accessorKey: 'Status',filterFn: 'customFilterFn' },
        { header: 'Resource Type', accessorKey: 'ResourceType',filterFn: 'customFilterFn' },
        { header: 'Resource Zone', accessorKey: 'ResourceZone',filterFn: 'customFilterFn' },
        { header: 'Metric Type', accessorKey: 'MetricType',filterFn: 'customFilterFn' },
        {
            header: 'Avg CPU Utilization',
            accessorKey: 'AVGCPUUtilizationPercentage',
            filterFn: 'customFilterFn'
        },
        {
            header: 'Min CPU Utilization',
            accessorKey: 'MinCPUUtilizationPercentage',filterFn: 'customFilterFn'
        },
        {
            header: 'Max CPU Utilization',
            accessorKey: 'MaxCPUUtilizationPercentage',filterFn: 'customFilterFn'
        },
    ]);
    const [data, setData] = useState([]);
    const handleload = () => {
        if (!!!project) {
            alert('Please select a project');
            return;
        }
        if (!!!metricType) {
            alert('Please select a metric type');
            return;
        }
        if (startDate > endDate) {
            alert('Please select valid start and end date');
            return;
        }
        if (!!!filterOperator) {
            alert('Please select a filter Operator');
            return;
        }
        setLoad(true);
        setOk('load');
        if (!load) {
            getdata(
                `gce-utilization?project_id=${project.id}&metricType=${
                    metricType.value
                }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&filterOperator=${
                    filterOperator.value
                }&filterPercentage=${filterPercentage}`,
                setRawData,
                setDataLoading
            );
        }
    };

    useEffect(() => {
        if (load) {
            getdata(
                `gce-utilization?project_id=${project.id}&metricType=${
                    metricType.value
                }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&filterOperator=${
                    filterOperator.value
                }&filterPercentage=${filterPercentage}`,
                setRawData,
                setDataLoading
            );
        }
    }, [load]);

    useEffect(() => {
        if (rawData !== null) {
            setData(rawData);
            setOk('true');
        }
    }, [rawData]);

    useEffect(() => {
        setOk('false');
        setLoad(false);
        setRawData(null);
    }, [
        project,
        metricType,
        filterOperator,
        filterPercentage,
        startDate,
        endDate,
    ]);

    return (
        <>
            {dataLoading ? (
                <div
                    style={{
                        marginLeft: '600px',
                        marginTop: '400px',
                        position: 'absolute',
                        borderBlockColor: 'green',
                    }}
                    className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
                />
            ) : (
                <>
                    <HeaderTitle
                        title={'GCP Resource Utilization'}
                        subtitle={'Track Compute Engine CPU Utilization'}
                    />
                    <Grid container rowGap={4} columnSpacing={4}>
                        <Grid xs={3} sx={{ zIndex: 4 }}>
                            <DD2
                                label={'GCP Project'}
                                emptyLabel={'Select a GCP Project'}
                                options={projects}
                                value={project}
                                onChange={setProject}
                                displayProperty={'name'}
                                disabled={false}
                                isLoading={projectsLoading}
                            />
                        </Grid>
                        <Grid xs={3} sx={{ zIndex: 4 }}>
                            <DD2
                                label={'Metric Type'}
                                emptyLabel={'Select a Metric Type'}
                                options={metricTypes}
                                value={metricType}
                                onChange={setMetricType}
                                displayProperty={'name'}
                                disabled={!!!project}
                            />
                        </Grid>
                        <Grid xs={3} sx={{ zIndex: 2 }}>
                            <DD2
                                label={'Filter Operator'}
                                emptyLabel={'Select a Filter Operator'}
                                options={filterOperators}
                                value={filterOperator}
                                onChange={setFilterOperator}
                                displayProperty={'name'}
                                disabled={!!!metricType}
                                isLoading={false}
                            />
                        </Grid>
                        <Grid xs={3} sx={{ zIndex: 2, marginTop:'10px' }}>
                            <FilterPercentage
                                label={'Filter Percentage'}
                                value={filterPercentage}
                                onChange={(e) =>
                                    setFilterPercentage(e.target.value)
                                }
                                disabled={!!!filterOperator}
                            />
                        </Grid>

                        <Grid xs={3} sx={{ zIndex: 3 }}>
                            <DatePickerInput
                                label={'Start Date'}
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </Grid>
                        <Grid xs={3} sx={{ zIndex: 3 }}>
                            <DatePickerInput
                                label={'End Date'}
                                value={endDate}
                                onChange={setEndDate}
                            />
                        </Grid>
                        <Grid xs={3} sx={{ zIndex: 2 }} />
                        <Grid xs={3} sx={{ zIndex: 2 }} />
                        <Grid xs={3}>
                            <PrimaryButton
                                title={'Generate Report'}
                                onClick={handleload}
                            />
                        </Grid>
                        <Grid xs={3} />
                        <Grid xs={3} />
                        <Grid xs={3} />

                        <Grid xs={12}>
                            <div className="mt-[20px] max-w-[1600px]">
                                {(() => {
                                    switch (ok) {
                                        case 'false':
                                            return <></>;
                                        case 'load':
                                            return (
                                                <div
                                                    style={{
                                                        marginLeft: '600px',
                                                        marginTop: '400px',
                                                        position: 'absolute',
                                                        borderBlockColor:
                                                            'green',
                                                    }}
                                                    className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
                                                />
                                            );
                                        case 'true':
                                            return (
                                                <Datatable
                                                    columns={columns}
                                                    data={data}
                                                />
                                            );
                                    }
                                })()}
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
