import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import { Box } from '@mui/material';
import { useState } from 'react';
import BlacklistResetUser from '../../../../components/AuditPii/blackListUser';
const BlacklistUser = () => {
    return (
        <>
            <HeaderTitle
                title={'Blacklist/Delete Token of User'}
                subtitle={'Blacklist/Delete token of malicious users'}
            />
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <BlacklistResetUser/>
            </Box>
        </>
    );
};

export default BlacklistUser;
