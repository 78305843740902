import { useEffect, useState } from 'react';
import './table.css';
import Svg from './svg';
//import Navsidebar from "../components/navsidebar";
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/axios';
import { ACCESS_BACKEND_URL } from '../../configs';
import LoadingSpinner from './loader';
import './tool.css';
/*
useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${SECURITY_BACKEND_URL}/automation`,
        };
        setLoading(true);
        axios(requestOptions).then(function (res) {
            setTabNames(res.data);
            setValue(0);
            setLoading(false);
        });
    }, []);
*/
export default function Tool() {
    const nav = useNavigate();
    const axios = useAxios();
    const [data, setData] = useState([{ 'tool name': '', 'access type': '' }]);
    const [data1, setData1] = useState([
        'cloudbuild',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
        'cloudrun',
        'cloudbuild',
        'cloudrun',
    ]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true)
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/tools`,
        };
        axios(requestOptions).then(function (res) {
            setData(res.data);
            setIsLoading(false);
        });
    }, [])

    var rows = [];
    var i = 0;
    var q = data1.length / 3;

    for (; i < q; i++)
        rows.push(
            <tr
                key={i}
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
                <td class="py-3 ">
                    <div style={{ marginLeft: '90px' }}>
                        <Svg type={data1[i * 3]} />
                    </div>
                </td>
                <td class="py-3 ">
                    <div style={{ marginLeft: '90px' }}>
                        <Svg type={data1[i * 3 + 1]} />
                    </div>
                </td>
                <td class="py-3 ">
                    <div style={{ marginLeft: '90px' }}>
                        <Svg type={data1[i * 3 + 2]} />
                    </div>
                </td>
            </tr>
        );
    if (data1.length % 3 == 1) {
        rows.push(
            <tr
                key={i}
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
                <td class="py-3 ">
                    <div style={{ marginLeft: '90px' }}>
                        <Svg type={data1[q * 3]} />
                    </div>
                </td>
                <td class="py-3 "></td>
                <td class="py-3 "></td>
            </tr>
        );
    } else if (data1.length % 3 == 2) {
        rows.push(
            <tr
                key={i}
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
                <td class="py-3 ">
                    <div style={{ marginLeft: '90px' }}>
                        <Svg type={data1[q * 3]} />
                    </div>
                </td>
                <td class="py-3 ">
                    <div style={{ marginLeft: '90px' }}>
                        <Svg type={data1[q * 3 + 1]} />
                    </div>
                </td>
                <td class="py-3 "></td>
            </tr>
        );
    }
    return (
        <>
            <div
                style={{ position: 'absolute', marginLeft: '10vw', zIndex: -1 }}
                class="d-flex flex-column justify-content-center"
            >
                <div class="p-2">
                    <div
                        style={{
                            height: 'auto',
                            maxHeight: '700px',
                            width: '60vw',
                            maxWidth: '60vw',
                            //minWidth: '300px',
                        }}
                        class="fixTableHead shadow-2xl rounded-lg"
                    >
                        {data?.length > 0 ? (
                            <table class="text-sm text-center text-black-500 dark:text-black-400">
                                <thead
                                    style={{ height: '40px' }}
                                    class="text-xs text-gray-900 uppercase rounded-t-lg bg-blue-200 dark:bg-gray-100 dark:text-gray-900"
                                >
                                    <tr>
                                        <th scope="col">Tool name</th>
                                        <th scope="col">Access type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(function (object, i) {
                                        return (
                                            <tr
                                                key={i}
                                                class="max-h-[100px] border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                            >
                                                <td class="py-3 ">
                                                    <div
                                                        style={{
                                                            marginLeft: '90px',
                                                        }}
                                                    >
                                                        {isLoading ? (
                                                            <LoadingSpinner />
                                                        ) : (
                                                            <Svg
                                                                type={
                                                                    object[
                                                                        'tool name'
                                                                    ]
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                                <td class="py-3 ">
                                                    {isLoading ? (
                                                        <LoadingSpinner />
                                                    ) : (
                                                        object['access type']
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h1> No pending request</h1>
                        )}
                    </div>
                </div>
                <div class="p-2">
                    <div
                        style={{
                            height: 'auto',
                            maxHeight: '700px',
                            maxWidth: '60vw',
                            minWidth: '60vw',
                            width: '60vw',
                            marginTop: '50px',
                            marginLeft: '0px',
                        }}
                        class="fixTableHead shadow-2xl rounded-lg"
                    >
                        {data.length > 0 ? (
                            <table class="text-sm text-center text-black-500 dark:text-black-400">
                                <thead
                                    style={{ height: '40px' }}
                                    class="text-xs text-gray-900 uppercase rounded-t-lg bg-blue-200 dark:bg-gray-100 dark:text-gray-900"
                                >
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">
                                            your recommended tools
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.map(function (object, i) {
                                        return <>{object}</>;
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h1> No pending request</h1>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
