import { useQuery } from 'react-query';
import { useAxios } from '../../contexts/axios';

const getElasticDeploymentsMetadata = async (authAxios) => {
    try {
        const { data } = await authAxios.get(`/elastic/deployments-metadata`);
        return data;
    } catch (e) {
        console.error(e);
    }
};

const useElasticDeploymentsMetadata = () => {
    const authAxios = useAxios();

    return useQuery(['elastic-deployments-metadata'], () =>
        getElasticDeploymentsMetadata(authAxios)
    );
};

export { useElasticDeploymentsMetadata };
