import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import Chip from '@mui/material/Chip';
import TimePickerInput from '../costing/TimePickerInput';
import SearchDD from '../SearchDD';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
const StatusPill = ({ value }) => {
    return (
        <>
            <div className="inline-flex">
                <div style={{ marginRight: '2px' }}>
                    {value === 'FAILURE' ? (
                        <CancelIcon color="error" style={{ width: '18px' }} />
                    ) : value === 'SUCCESS' ? (
                        <CheckCircleIcon
                            color="success"
                            style={{ width: '18px' }}
                        />
                    ) : value === 'UPDATING' ? (
                        <CircularProgress color="warning" size={18} />
                    ) : value === 'STARTED' ? (
                        <AlarmOnIcon
                            style={{ width: '18px' }}
                            color="primary"
                        />
                    ) : (
                        <PendingIcon
                            style={{ width: '18px' }}
                            color="disabled"
                        />
                    )}
                </div>
                {value === 'FAILURE'
                    ? 'Failed'
                    : value === 'SUCCESS'
                    ? 'Success'
                    : value === 'UPDATING'
                    ? 'Updating'
                    : value === 'STARTED'
                    ? 'Started'
                    : 'Has not run yet'}
                <div></div>
            </div>
        </>
    );
};

function getTimeDifference(time1, time2) {
    if (time1.getTime() > time2.getTime())
        return (time1?.getTime() - time2?.getTime()) / 3600000;
    else return (24 - (time2?.getTime() - time1?.getTime()) / 3600000) % 24;
}

function compareTime(scaleUpFrequency, scaleDownFrequency) {
    var time1 = scaleUpFrequency.getTime() / 1000;
    var time2 = scaleDownFrequency.getTime() / 1000;
    if (Math.abs(time1 - time2) <= 1800) {
        return true;
    }
    return false;
}

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        if (object[key] === '') {
            return default_value;
        } else {
            return object[key];
        }
    } else {
        return default_value;
    }
}

function parseCron(cron) {
    const parser = require('cron-parser');
    var cronExpression = cron;
    var interval = parser.parseExpression(cronExpression).next();
    var res = `${interval.getDate().toString()}/${(
        interval.getMonth() + 1
    ).toString()}/${interval.getFullYear().toString()} ${
        interval.getHours() > 12
            ? (interval.getHours() - 12).toString()
            : interval.getHours().toString()
    }:${interval.getMinutes().toString()}:00 ${
        interval.getHours() >= 12 ? 'PM' : 'AM'
    }`;
    return res;
}

function getTime(cron) {
    const parser = require('cron-parser');
    var cronExpression = cron;
    var interval = parser.parseExpression(cronExpression).next().toISOString();
    return new Date(interval);
}

function parseTime(Time) {
    var date = Time.split('T')[0].split('-');
    var time = Time.split('T')[1].split(':');
    var hr = parseInt(time[0]);
    var res = `${date[2]}/${date[1]}/${date[0]} ${hr > 12 ? hr - 12 : hr}:${
        time[1]
    }:${time[2]} ${hr >= 12 ? 'PM' : 'AM'}`;
    return res;
}
function getCron(Time) {
    var time = new Date(Time);
    time.setHours(time.getHours() + 5);
    time.setMinutes(time.getMinutes() + 30);
    time = time.toISOString();
    var minuteHour = time.split('T')[1].split(':');
    var cron = `${minuteHour[1]} ${minuteHour[0]} * * *`;
    return cron;
}
function getCronDifference(cron1, cron2) {
    const parser = require('cron-parser');
    var time1 = parser.parseExpression(cron1).next();
    var time2 = parser.parseExpression(cron2).next();
    return getTimeDifference(time1, time2);
}

function parseConfig(config) {
    var ram = parseInt(config.split('|')[1].split(' ')[1]);
    return ram;
}
const oldElasticConfig = {
    'es-production-jobs': 'gcp.data.highcpu.1',
    'es-production-jobs-follower': 'gcp.data.highcpu.1',
    'es-production-search': 'gcp.data.highcpu.1',
    'es-production-users': 'gcp.data.highcpu.1',
    'es-staging': 'gcp.data.highio.1',
    'production-job-search': 'gcp.data.highcpu.1',
    'production-suggester': 'gcp.data.highcpu.1',
};
const oldElasticClusterRamCostPerHour = {
    'es-production-jobs': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-jobs-follower': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-production-search': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-users': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-staging': {
        '60 GB storage | 2 GB RAM | Up to 2.4 vCPU': 0.6148,
        '120 GB storage | 4 GB RAM | Up to 2.4 vCPU': 0.9016,
        '240 GB storage | 8 GB RAM | Up to 2.4 vCPU': 1.4752,
        '480 GB storage | 16 GB RAM | Up to 2.4 vCPU': 2.6224,
        '960 GB storage | 32 GB RAM | Up to 4.9 vCPU': 4.9168,
    },
    'production-job-search': {
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2538,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.4454,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 0.8286,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 1.595,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
    },
    'production-suggester': {
        '8 GB storage | 1 GB RAM | Up to 3.7 vCPU': 0.1437,
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2874,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.5748,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.299,
    },
};
const oldElasticClusterPerHour = {
    'es-production-jobs': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-jobs-follower': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-production-search': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-users': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-staging': {
        '60 GB storage | 2 GB RAM | Up to 2.4 vCPU': 0.6148,
        '120 GB storage | 4 GB RAM | Up to 2.4 vCPU': 0.9016,
        '240 GB storage | 8 GB RAM | Up to 2.4 vCPU': 1.4752,
        '480 GB storage | 16 GB RAM | Up to 2.4 vCPU': 2.6224,
        '960 GB storage | 32 GB RAM | Up to 4.9 vCPU': 4.9168,
    },
    'production-job-search': {
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2538,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.4454,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 0.8286,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 1.595,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
    },
    'production-suggester': {
        '8 GB storage | 1 GB RAM | Up to 3.7 vCPU': 0.1437,
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2874,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.5748,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.299,
    },
};
const elasticCostPerHour = {
    'gcp.es.datahot.n2.68x32x45': {
        'zone 1': [
            0.0622, 0.1244, 0.2488, 0.4976, 0.9952, 1.9904, 3.9808, 7.9616,
            11.9424, 15.9232,
        ],
        'zone 2': [
            0.1866, 0.311, 0.5598, 1.0574, 2.0526, 4.043, 8.0238, 15.9854,
            24.6312, 32.5928,
        ],
        'zone 3': [
            0.1866, 0.3732, 0.7464, 1.4928, 2.9856, 5.9712, 11.9424, 24.6312,
            36.5736, 48.516,
        ],
    },
    'gcp.es.datahot.n2.68x10x45': {
        'zone 1': [
            0.0466, 0.092, 0.184, 0.368, 0.736, 1.472, 2.944, 5.888, 8.832,
            11.776,
        ],
        'zone 2': [
            0.1542, 0.2462, 0.4302, 0.7982, 1.5342, 3.0062, 5.9502, 11.8382,
            18.4104, 24.2984,
        ],
        'zone 3': [
            0.138, 0.276, 0.552, 1.104, 2.208, 4.416, 8.832, 18.4104, 27.2424,
            36.0744,
        ],
    },
    'gcp.es.datahot.n2.68x16x45': {
        'zone 1': [
            0.0503, 0.1006, 0.2012, 0.4024, 0.8048, 1.6096, 3.2192, 6.4384,
            9.6576, 12.8768,
        ],
        'zone 2': [
            0.1628, 0.2634, 0.4646, 0.867, 1.6718, 3.2814, 6.5006, 12.939,
            20.0616, 26.5,
        ],
        'zone 3': [
            0.1509, 0.3018, 0.6036, 1.2072, 2.4144, 4.8288, 9.6576, 20.0616,
            29.7192, 39.3768,
        ],
    },
    'gcp.es.datahot.n2.68x10x95': {
        'zone 1': [
            0.0479, 0.0958, 0.1916, 0.3832, 0.7664, 1.5328, 3.0656, 6.1312,
            9.1968, 12.2624,
        ],
        'zone 2': [
            0.158, 0.2538, 0.4454, 0.8286, 1.595, 3.1278, 6.1934, 12.3246,
            19.14, 25.2712,
        ],
        'zone 3': [
            0.1437, 0.2874, 0.5748, 1.1496, 2.2992, 4.5984, 9.1968, 19.14,
            28.3368, 37.5336,
        ],
    },
};

const distributionsIndex = {
    1: 0,
    2: 1,
    4: 2,
    8: 3,
    16: 4,
    32: 5,
    64: 6,
    128: 7,
    192: 8,
    256: 9,
};

const elasticConfigs = {
    'gcp.es.datahot.n2.68x32x45': [
        '45 GB storage | 1 GB RAM | Up to 8 vCPU',
        '90 GB storage | 2 GB RAM | Up to 8 vCPU',
        '180 GB storage | 4 GB RAM | Up to 8 vCPU',
        '360 GB storage | 8 GB RAM | Up to 8 vCPU',
        '720 GB storage | 16 GB RAM | Up to 8 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 16 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 32 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 64 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 96 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 128 vCPU',
    ],
    'gcp.es.datahot.n2.68x10x45': [
        '45 GB storage | 1 GB RAM | Up to 2.5 vCPU',
        '90 GB storage | 2 GB RAM | Up to 2.5 vCPU',
        '180 GB storage | 4 GB RAM | Up to 2.5 vCPU',
        '360 GB storage | 8 GB RAM | Up to 2.5 vCPU',
        '720 GB storage | 16 GB RAM | Up to 2.5 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 5 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 10 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 20 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 30 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 40 vCPU',
    ],
    'gcp.es.datahot.n2.68x16x45': [
        '45 GB storage | 1 GB RAM | Up to 4 vCPU',
        '90 GB storage | 2 GB RAM | Up to 4 vCPU',
        '180 GB storage | 4 GB RAM | Up to 4 vCPU',
        '360 GB storage | 8 GB RAM | Up to 4 vCPU',
        '720 GB storage | 16 GB RAM | Up to 4 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 8 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 16 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 32 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 48 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 64 vCPU',
    ],
    'gcp.es.datahot.n2.68x10x95': [
        '95 GB storage | 1 GB RAM | Up to 2.5 vCPU',
        '190 GB storage | 2 GB RAM | Up to 2.5 vCPU',
        '380 GB storage | 4 GB RAM | Up to 2.5 vCPU',
        '760 GB storage | 8 GB RAM | Up to 2.5 vCPU',
        '1.48 TB storage | 16 GB RAM | Up to 2.5 vCPU',
        '2.97 TB storage | 32 GB RAM | Up to 5 vCPU',
        '5.94 TB storage | 64 GB RAM | Up to 10 vCPU',
        '11.88 TB storage | 128 GB RAM | Up to 20 vCPU',
        '17.81 TB storage | 192 GB RAM | Up to 30 vCPU',
        '23.75 TB storage | 256 GB RAM | Up to 40 vCPU',
    ],
};

function getPerHourCostDifference(
    cluster,
    config1,
    config2,
    instanceConfig,
    zone
) {
    var oldcluster = Object.keys(oldElasticConfig);
    if (oldcluster.includes(cluster)) {
        return (
            oldElasticClusterPerHour[cluster][config1] -
            oldElasticClusterPerHour[cluster][config2]
        );
    }
    if (instanceConfig && zone) {
        var save = Math.abs(
            elasticCostPerHour[instanceConfig]['zone ' + zone?.toString()][
                distributionsIndex[parseConfig(config1)]
            ] -
                elasticCostPerHour[instanceConfig]['zone ' + zone?.toString()][
                    distributionsIndex[parseConfig(config2)]
                ]
        );
        return save;
    }

    return 0;
}
const ElasticTbasTable = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editableRow, setEditableRow] = useState(null);
    //const [clusters, setClusters] = useState({});
    const [clusterList, setClusterList] = useState([]);
    const [clustersDup, setClustersDup] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [editLoader, setEditLoader] = useState([]);
    const [approveLoader, setApproveLoader] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [clusterLoader, setClusterLoader] = useState(false);
    const axios = useAxios();
    const { isAdminApprover } = useAuth();
    async function call(url, data, method, setloading, index) {
        if (index === -1) {
            setloading(true);
        } else {
            if (method === 'GET') {
                approveLoader[index] = true;
                setloading(approveLoader);
            } else {
                editLoader[index] = true;
                setloading(editLoader);
            }
        }
        var requestOptions = {};
        if (method === 'GET') {
            requestOptions = {
                url: `${BACKEND_URL}/elastic/tbas${url}`,
                method: method,
            };
        } else {
            requestOptions = {
                url: `${BACKEND_URL}/elastic/tbas${url}`,
                method: method,
                data: data,
            };
        }
        await axios(requestOptions).then(() => {
            if (index === -1) {
                setloading(false);
            } else {
                if (method === 'GET') {
                    approveLoader[index] = false;
                    setloading(approveLoader);
                } else {
                    editLoader[index] = false;
                    setloading(editLoader);
                }
                window.location.reload(false);
            }
        });
    }

    async function getData() {
        setDataLoader(true);
        var requestOptions = {
            url: `${BACKEND_URL}/elastic/tbas`,
            method: 'GET',
        };
        await axios(requestOptions).then(function (res) {
            var data = res.data;
            var tempLoader1 = [];
            var tempLoader2 = [];
            for (var i = 0; i < data.length; i++) {
                tempLoader1.push(false);
                tempLoader2.push(false);
                data[i]['estimatedSaving'] =
                    getCronDifference(
                        data[i]['scaleUpFrequency'],
                        data[i]['scaleDownFrequency']
                    ) *
                    getPerHourCostDifference(
                        data[i]['clusterName'],
                        data[i]['scaleUpConfig'],
                        data[i]['scaleDownConfig'],
                        data[i]['instanceConfig'],
                        data[i]['zoneCount']
                    ) *
                    30;
            }
            setTableData(data);
            setEditLoader(tempLoader1);
            setApproveLoader(tempLoader2);
            setDataLoader(false);
        });
    }

    function editHandler(row) {
        setEditableRow(row);
        setEditModalOpen(true);
    }

    useEffect(() => {
        getData();
    }, []);

    async function getClusters() {
        setClusterLoader(true);
        await axios({ url: `${BACKEND_URL}/elastic/deployments` }).then(
            function (res) {
                var temp = res.data;
                var dictCluster = {};
                for (var i = 0; i < temp.length; i++) {
                    dictCluster[temp[i]['name']] = temp[i];
                }
                for (var i = 0; i < tableData.length; i++) {
                    delete dictCluster[tableData[i]['clusterName']];
                }
                var keys = Object.keys(dictCluster);
                var listClusters = [];
                for (var i = 0; i < keys.length; i++) {
                    listClusters.push({
                        label: keys[i],
                        id: dictCluster[keys[i]].id,
                    });
                }

                setClusterList(listClusters);
            }
        );
        setClusterLoader(false);
    }

    useEffect(() => {
        if (tableData) {
            getClusters();
        }
    }, [tableData]);

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/elastic/deployments` }).then(function (
            res
        ) {
            var temp = res.data;
            var dictCluster = {};
            for (var i = 0; i < temp.length; i++) {
                dictCluster[temp[i]['name']] = temp[i];
            }
            setClustersDup(dictCluster);
        });
    }, []);

    const handleCreateNewRow = (values) => {
        if (
            !isAdminApprover() &&
            !window.confirm(
                `Once you confirm you won't be able to change. Are you sure you want to add this Tbas Config?`
            )
        ) {
            return;
        }
        var val = {
            clusterName: values['clusterName'],
            deploymentId: values['deploymentId'],
            instanceConfig: values['instanceConfig'],
            zoneCount: values['zoneCount'],
            creatorEmail: values['creatorEmail'],
            scaleUpStatus: values['scaleUpStatus'],
            scaleDownStatus: values['scaleDownStatus'],
            scaleUpFrequency: getCron(values['scaleUpFrequency']),
            scaleUpConfig: values['scaleUpConfig'],
            scaleDownFrequency: getCron(values['scaleDownFrequency']),
            scaleDownConfig: values['scaleDownConfig'],
            slackChannelId: values['slackChannelId'],
            scaleUpLastRun: values['scaleUpLastRun'],
            scaleUpNextRun: parseCron(getCron(values['scaleUpFrequency'])),
            scaleDownLastRun: values['scaleDownLastRun'],
            scaleDownNextRun: parseCron(getCron(values['scaleDownFrequency'])),
            approvedStatus: values['approvedStatus'],
        };
        call('', val, 'POST', setDataLoader, -1);
        var temp = tableData;
        val['estimatedSaving'] =
            getCronDifference(
                val['scaleUpFrequency'],
                val['scaleDownFrequency']
            ) *
            getPerHourCostDifference(
                val['clusterName'],
                val['scaleUpConfig'],
                val['scaleDownConfig'],
                val['instanceConfig'],
                val['zoneCount']
            ) *
            30;
        temp.push(val);
        setTableData([...temp]);
    };

    const handleSaveRowEdits = (index, values) => {
        var configMaxIndex =
            distributionsIndex[
                clustersDup[values['clusterName']]?.max_instance_size
            ];
        var configMinIndex =
            distributionsIndex[
                clustersDup[values['clusterName']]?.min_instance_size
            ];
        var valueMaxIndex = distributionsIndex[values['scaleUpConfig']];
        var valueMinIndex = distributionsIndex[values['scaleDownConfig']];

        if (valueMaxIndex > configMaxIndex || valueMinIndex < configMinIndex) {
            alert(
                `ScaleUp config value should be <= ${
                    clustersDup[values['clusterName']]?.max_instance_size
                } and ScaleDown config value should be >= ${
                    clustersDup[values['clusterName']]?.min_instance_size
                }`
            );
            return;
        }
        if (valueMaxIndex <= valueMinIndex) {
            alert(
                'ScaleUp config value should be greater than ScaleDown config value'
            );
            return;
        }
        if (
            compareTime(
                getTime(values['scaleUpFrequency']),
                getTime(values['scaleDownFrequency'])
            )
        ) {
            alert(
                'Difference between ScaleUp and ScaleDown Frequency should be atleast greater than 30 min'
            );
            return;
        }

        var temp = tableData[index];
        tableData[index] = {
            clusterName: values['clusterName'],
            deploymentId: values['deploymentId'],
            instanceConfig: values['instanceConfig'],
            zoneCount: values['zoneCount'],
            creatorEmail: values['creatorEmail'],
            scaleUpStatus:
                values['scaleUpFrequency'] !==
                    tableData[index]['scaleUpFrequency'] ||
                values['scaleUpConfig'] !== tableData[index]['scaleUpConfig']
                    ? ''
                    : values['scaleUpStatus'],
            scaleDownStatus:
                values['scaleDownFrequency'] !==
                    tableData[index]['scaleDownFrequency'] ||
                values['scaleDownConfig'] !==
                    tableData[index]['scaleDownConfig']
                    ? ''
                    : values['scaleDownStatus'],
            scaleUpFrequency: values['scaleUpFrequency'],
            scaleUpConfig: values['scaleUpConfig'],
            scaleDownFrequency: values['scaleDownFrequency'],
            scaleDownConfig: values['scaleDownConfig'],
            estimatedSaving:
                getCronDifference(
                    values['scaleUpFrequency'],
                    values['scaleDownFrequency']
                ) *
                getPerHourCostDifference(
                    values['clusterName'],
                    values['scaleUpConfig'],
                    values['scaleDownConfig'],
                    values['instanceConfig'],
                    values['zoneCount']
                ) *
                30,
            slackChannelId: values['slackChannelId'],
            scaleUpLastRun:
                values['scaleUpFrequency'] !==
                tableData[index]['scaleUpFrequency']
                    ? ''
                    : values['scaleUpLastRun'],
            scaleUpNextRun:
                values['scaleUpFrequency'] !==
                tableData[index]['scaleUpFrequency']
                    ? parseCron(values['scaleUpFrequency'])
                    : values['scaleUpNextRun'],
            scaleDownLastRun:
                values['scaleDownFrequency'] !==
                tableData[index]['scaleDownFrequency']
                    ? ''
                    : values['scaleDownLastRun'],
            scaleDownNextRun:
                values['scaleDownFrequency'] !==
                tableData[index]['scaleDownFrequency']
                    ? parseCron(values['scaleDownFrequency'])
                    : values['scaleDownNextRun'],
            approvedStatus: values['approvedStatus'],
        };
        call(
            '',
            {
                clusterName: values['clusterName'],
                deploymentId: values['deploymentId'],
                instanceConfig: values['instanceConfig'],
                zoneCount: values['zoneCount'],
                creatorEmail: values['creatorEmail'],
                scaleUpStatus:
                    values['scaleUpFrequency'] !== temp['scaleUpFrequency'] ||
                    values['scaleUpConfig'] !== temp['scaleUpConfig']
                        ? ''
                        : values['scaleUpStatus'],
                scaleDownStatus:
                    values['scaleDownFrequency'] !==
                        temp['scaleDownFrequency'] ||
                    values['scaleDownConfig'] !== temp['scaleDownConfig']
                        ? ''
                        : values['scaleDownStatus'],
                scaleUpFrequency: values['scaleUpFrequency'],
                scaleUpConfig: values['scaleUpConfig'],
                scaleDownFrequency: values['scaleDownFrequency'],
                scaleDownConfig: values['scaleDownConfig'],
                slackChannelId: values['slackChannelId'],
                scaleUpLastRun:
                    values['scaleUpFrequency'] !== temp['scaleUpFrequency']
                        ? ''
                        : values['scaleUpLastRun'],
                scaleUpNextRun:
                    values['scaleUpFrequency'] !== temp['scaleUpFrequency']
                        ? parseCron(values['scaleUpFrequency'])
                        : values['scaleUpNextRun'],
                scaleDownLastRun:
                    values['scaleDownFrequency'] !== temp['scaleDownFrequency']
                        ? ''
                        : values['scaleDownLastRun'],
                scaleDownNextRun:
                    values['scaleDownFrequency'] !== temp['scaleDownFrequency']
                        ? parseCron(values['scaleDownFrequency'])
                        : values['scaleDownNextRun'],
                approvedStatus: values['approvedStatus'],
            },
            'PUT',
            setEditLoader,
            index
        );
        setTableData([...tableData]);
    };

    const handleApprove = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to ${
                        row.getValue('approvedStatus') === 'True'
                            ? 'Reject'
                            : 'Approve'
                    } ${row.getValue('clusterName')}`
                )
            ) {
                return;
            }
            tableData[row.index] = {
                clusterName: row.getValue('clusterName'),
                deploymentId: row.getValue('deploymentId'),
                instanceConfig: row.getValue('instanceConfig'),
                zoneCount: row.getValue('zoneCount'),
                creatorEmail: row.getValue('creatorEmail'),
                scaleUpStatus: row.getValue('scaleUpStatus'),
                scaleDownStatus: row.getValue('scaleDownStatus'),
                scaleUpFrequency: row.getValue('scaleUpFrequency'),
                scaleUpConfig: row.getValue('scaleUpConfig'),
                scaleDownFrequency: row.getValue('scaleDownFrequency'),
                scaleDownConfig: row.getValue('scaleDownConfig'),
                scaleDownConfig: row.getValue('scaleDownConfig'),
                estimatedSaving: row.getValue('estimatedSaving'),
                slackChannelId: row.getValue('slackChannelId'),
                scaleUpLastRun: row.getValue('scaleUpLastRun'),
                scaleUpNextRun: row.getValue('scaleUpFrequency'),
                scaleDownLastRun: row.getValue('scaleDownLastRun'),
                scaleDownNextRun: row.getValue('scaleDownFrequency'),
                approvedStatus:
                    row.getValue('approvedStatus') === 'True'
                        ? 'False'
                        : 'True',
            };
            call(
                `/${
                    row.getValue('approvedStatus') === 'True'
                        ? 'reject'
                        : 'approve'
                }?name=${row.getValue('clusterName')}`,
                null,
                'GET',
                setApproveLoader,
                row.index
            );
            setTableData([...tableData]);
        },
        [tableData]
    );
    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        'clusterName'
                    )}`
                )
            ) {
                return;
            }
            call(
                '',
                {
                    clusterName: row.getValue('clusterName'),
                    deploymentId: row.getValue('deploymentId'),
                    instanceConfig: row.getValue('instanceConfig'),
                    zoneCount: row.getValue('zoneCount'),
                    creatorEmail: row.getValue('creatorEmail'),
                    scaleUpStatus: row.getValue('scaleUpStatus'),
                    scaleDownStatus: row.getValue('scaleDownStatus'),
                    scaleUpFrequency: row.getValue('scaleUpFrequency'),
                    scaleUpConfig: row.getValue('scaleUpConfig'),
                    scaleDownFrequency: row.getValue('scaleDownFrequency'),
                    scaleDownConfig: row.getValue('scaleDownConfig'),
                    slackChannelId: row.getValue('slackChannelId'),
                    scaleUpLastRun: row.getValue('scaleUpLastRun'),
                    scaleUpNextRun: row.getValue('scaleUpFrequency'),
                    scaleDownLastRun: row.getValue('scaleDownLastRun'),
                    scaleDownNextRun: row.getValue('scaleDownFrequency'),
                    approvedStatus: row.getValue('approvedStatus'),
                },
                'DELETE',
                setDataLoader,
                -1
            );
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'clusterName',
                header: 'Cluster Name',
                enableEditing: false,
            },
            {
                accessorKey: 'creatorEmail',
                header: 'Creator Email',
                enableEditing: false,
            },
            {
                accessorKey: 'scaleUpStatus',
                header: 'Scale Up Status',
                Cell: ({ cell }) => <StatusPill value={cell.getValue()} />,
                enableEditing: false,
            },
            {
                accessorKey: 'scaleDownStatus',
                header: 'Scale Down Status',
                Cell: ({ cell }) => <StatusPill value={cell.getValue()} />,
                enableEditing: false,
            },
            {
                accessorKey: 'scaleUpFrequency',
                header: 'Scale Up Frequency',
            },
            {
                accessorKey: 'scaleUpConfig',
                header: 'Scale Up Config',
                /*muiTableBodyCellEditTextFieldProps: ({ row }) => ({
                    select: true, //change to select for a dropdown
                    children: () => {
                        var oldcluster = Object.keys(oldElasticConfig);
                        if (oldcluster.includes(row.getValue('clusterName'))) {
                            var oldConfigList = Object.keys(
                                oldElasticClusterPerHour[
                                    row.getValue('clusterName')
                                ]
                            );
                            return oldConfigList.map((distribution) => (
                                <MenuItem
                                    key={distribution}
                                    value={distribution}
                                >
                                    {distribution}
                                </MenuItem>
                            ));
                        } else {
                            var newConfigList = Object.keys(
                                elasticConfigs[row.getValue('instanceConfig')]
                            );
                            return newConfigList.map((distribution) => (
                                <MenuItem
                                    key={distribution}
                                    value={distribution}
                                >
                                    {distribution}
                                </MenuItem>
                            ));
                        }
                    },
                }),*/
            },
            {
                accessorKey: 'scaleDownFrequency',
                header: 'Scale Down Frequency',
            },
            {
                accessorKey: 'scaleDownConfig',
                header: 'Scale Down Config',
                /*muiTableBodyCellEditTextFieldProps: ({ row }) => ({
                    select: true, //change to select for a dropdown
                    children: () => {
                        var oldcluster = Object.keys(oldElasticConfig);
                        if (oldcluster.includes(row.getValue('clusterName'))) {
                            var oldConfigList = Object.keys(
                                oldElasticClusterPerHour[
                                    row.getValue('clusterName')
                                ]
                            );
                            return oldConfigList.map((distribution) => (
                                <MenuItem
                                    key={distribution}
                                    value={distribution}
                                >
                                    {distribution}
                                </MenuItem>
                            ));
                        } else {
                            var newConfigList = Object.keys(
                                elasticConfigs[row.getValue('instanceConfig')]
                            );
                            return newConfigList.map((distribution) => (
                                <MenuItem
                                    key={distribution}
                                    value={distribution}
                                >
                                    {distribution}
                                </MenuItem>
                            ));
                        }
                    },
                }),*/
            },
            {
                accessorKey: 'estimatedSaving',
                header: 'Estimated Monthly Saving',
                Cell: ({ cell }) => (
                    <Chip
                        color="success"
                        style={{ fontWeight: 'bolder' }}
                        label={`${cell.getValue()?.toFixed(2)} $`}
                    />
                ),
                enableEditing: false,
            },
            {
                accessorKey: 'slackChannelId',
                header: 'Slack Channel Id',
            },
            {
                accessorKey: 'scaleUpLastRun',
                header: 'Scale Up Last Run',
                Cell: ({ cell }) =>
                    cell.getValue() === '' ? '' : parseTime(cell.getValue()),
                enableEditing: false,
            },
            {
                accessorKey: 'scaleUpNextRun',
                header: 'Scale Up Next Run',
                enableEditing: false,
            },
            {
                accessorKey: 'scaleDownLastRun',
                header: 'Scale Down Last Run',
                Cell: ({ cell }) =>
                    cell.getValue() === '' ? '' : parseTime(cell.getValue()),
                enableEditing: false,
            },
            {
                accessorKey: 'scaleDownNextRun',
                header: 'Scale Down Next Run',
                enableEditing: false,
            },
            {
                accessorKey: 'approvedStatus',
                header: 'Approved',
                enableEditing: false,
            },
            {
                accessorKey: 'instanceConfig',
                header: 'Instance Config',
                enableEditing: false,
            },
            {
                accessorKey: 'zoneCount',
                header: 'Zone Count',
                enableEditing: false,
            },
            {
                accessorKey: 'deploymentId',
                header: 'Deployment Id',
                enableEditing: false,
            },
        ],
        [getCommonEditTextFieldProps]
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        var data = [];
        for (var i = 0; i < tableData.length; i++) {
            var dict = {};
            dict['clusterName'] = tableData[i]['clusterName'];
            dict['creatorEmail'] = tableData[i]['creatorEmail'];
            dict['scaleUpStatus'] = tableData[i]['scaleUpStatus'];
            dict['scaleDownStatus'] = tableData[i]['scaleDownStatus'];
            dict['scaleUpFrequency'] = tableData[i]['scaleUpFrequency'];
            dict['scaleUpConfig'] = tableData[i]['scaleUpConfig'];
            dict['scaleDownFrequency'] = tableData[i]['scaleDownFrequency'];
            dict['scaleDownConfig'] = tableData[i]['scaleDownConfig'];
            dict['estimatedSaving'] = tableData[i]['estimatedSaving'];
            dict['slackChannelId'] = tableData[i]['slackChannelId'];
            dict['scaleUpLastRun'] = tableData[i]['scaleUpLastRun'];
            dict['scaleUpNextRun'] = tableData[i]['scaleUpNextRun'];
            dict['scaleDownLastRun'] = tableData[i]['scaleDownLastRun'];
            dict['scaleDownNextRun'] = tableData[i]['scaleDownNextRun'];
            dict['approvedStatus'] = tableData[i]['approvedStatus'];
            dict['instanceConfig'] = tableData[i]['instanceConfig'];
            dict['zoneCount'] = tableData[i]['zoneCount'];
            dict['deploymentId'] = tableData[i]['deploymentId'];
            data.push(dict);
        }
        csvExporter.generateCsv(data);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData ? tableData : []}
                editingMode="modal" //default
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions','clusterName'],
                    },
                    density: 'compact',
                    columnVisibility: { approvedStatus: false },
                }}
                state={{ isLoading: dataLoader }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            fontWeight: 'bold',
                        }}
                    >
                        {editLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton
                                    disabled={!isAdminApprover()}
                                    onClick={() => editHandler(row)}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                        )}

                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isAdminApprover()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>

                        {approveLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>
                        ) : (
                            <Tooltip
                                arrow
                                placement="right"
                                title={
                                    row.getValue('approvedStatus') === 'True'
                                        ? 'Reject'
                                        : 'Approve'
                                }
                            >
                                {row.getValue('approvedStatus') === 'True' ? (
                                    <IconButton
                                        color="warning"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isAdminApprover()}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        color="success"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isAdminApprover()}
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                            </Tooltip>
                        )}
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        {clusterLoader?<Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                    padding: '5px',
                                }}
                            >
                                <CircularProgress size={28} />
                            </Box>:<Button
                            color="success"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                            disabled={clusterLoader}
                        >
                            Add New Config
                        </Button>}
                        
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
            <AddNewJob
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
                clusterList={clusterList}
            />
            <EditJob
                columns={columns}
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                onSubmit={handleSaveRowEdits}
                row={editableRow}
            />
        </>
    );
};

export const AddNewJob = ({
    open,
    columns,
    onClose,
    onSubmit,
    clusterList,
}) => {
    //const [open, setOpen] = React.useState(false);
    const axios = useAxios();
    //const [clusterLoader, setClusterLoader] = useState(false);

    const [configList, setConfigList] = useState([]);

    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );

    const { user } = useAuth();
    const [cluster, setCluster] = useState(null);
    const [scaleUpConfigType, setScaleUpConfigType] = useState(null);
    const [scaleDownConfigType, setScaleDownConfigType] = useState(null);
    const [currentConfig, setCurrentConfig] = useState(null);
    const [scaleUpFrequency, setScaleUpFrequency] = useState(new Date());
    const [scaleDownFrequency, setScaleDownFrequency] = useState(new Date());
    const [estimatedSaving, setEstimatedSaving] = useState(0);

    useEffect(() => {
        setCluster(clusterList[0]);
    }, [clusterList]);

    useEffect(() => {
        if (cluster) {
            axios({
                url: `${BACKEND_URL}/elastic/deployments/${cluster?.id}`,
            }).then(function (res) {
                setCurrentConfig(res.data);
                var ram = res.data.memory / 1024;
                var oldcluster = Object.keys(oldElasticConfig);
                if (oldcluster.includes(cluster.label)) {
                    var oldConfigList = Object.keys(
                        oldElasticClusterPerHour[cluster.label]
                    );
                    setScaleUpConfigType(oldConfigList[2]);
                    setScaleDownConfigType(oldConfigList[2]);
                    setConfigList(oldConfigList);
                } else {
                    setScaleUpConfigType(
                        elasticConfigs[res.data.instance_config][
                            distributionsIndex[ram]
                        ]
                    );
                    setScaleDownConfigType(
                        elasticConfigs[res.data.instance_config][
                            distributionsIndex[ram]
                        ]
                    );
                    var configMaxIndex = distributionsIndex[ram] + 1;
                    var configMinIndex =
                        distributionsIndex[ram] >= 2
                            ? distributionsIndex[ram] - 2
                            : 0;

                    setConfigList(
                        elasticConfigs[res.data.instance_config].slice(
                            configMinIndex,
                            configMaxIndex + 1
                        )
                    );
                }
            });
        }
    }, [cluster]);

    useEffect(() => {
        if (currentConfig) {
            setEstimatedSaving(
                (
                    getCronDifference(
                        getCron(scaleUpFrequency.toISOString()),
                        getCron(scaleDownFrequency.toISOString())
                    ) *
                    getPerHourCostDifference(
                        cluster.label,
                        scaleUpConfigType,
                        scaleDownConfigType,
                        currentConfig?.instance_config,
                        currentConfig?.zone_count
                    ) *
                    30
                ).toFixed(2)
            );
        }
    }, [
        scaleUpConfigType,
        scaleDownConfigType,
        scaleUpFrequency,
        scaleDownFrequency,
        currentConfig,
    ]);

    const handleSubmit = () => {
        if (values['slackChannelId'] === '') {
            alert('Provide Slack Channel Id');
            return;
        }
        if (
            parseConfig(scaleUpConfigType) <= parseConfig(scaleDownConfigType)
        ) {
            alert(
                'ScaleUp config value should be greater than ScaleDown config value'
            );
            return;
        }
        if (compareTime(scaleUpFrequency, scaleDownFrequency)) {
            alert(
                'Difference between ScaleUp and ScaleDown Frequency should be atleast greater than 30 min'
            );
            return;
        }

        onSubmit({
            ...values,
            ['clusterName']: cluster.label,
            ['deploymentId']: cluster.id,
            ['instanceConfig']: currentConfig.instance_config,
            ['zoneCount']: currentConfig.zone_count,
            ['creatorEmail']: user.email,
            ['approvedStatus']: false,
            ['scaleUpConfig']: scaleUpConfigType,
            ['scaleDownConfig']: scaleDownConfigType,
            ['scaleUpFrequency']: scaleUpFrequency.toISOString(),
            ['scaleDownFrequency']: scaleDownFrequency.toISOString(),
        });
        onClose();
    };

    return (
        <>
            <Dialog open={open}>
                <DialogTitle textAlign="center">Add New Job</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {
                                    xs: '300px',
                                    sm: '360px',
                                    md: '400px',
                                },
                                gap: '1.5rem',
                                marginTop: '21px',
                            }}
                        >
                            {(() => {
                                return (
                                    <>
                                        <div className="text-left relative z-1">
                                            <SearchDD
                                                label={'Cluster Name'}
                                                emptyLabel={'Select Cluster'}
                                                options={clusterList}
                                                value={cluster}
                                                onChange={setCluster}
                                                disabled={false}
                                            />
                                        </div>

                                        <TimePickerInput
                                            label={'Scale Up Frequency'}
                                            value={scaleUpFrequency}
                                            onChange={(item) => {
                                                setScaleUpFrequency(
                                                    new Date(item.toISOString())
                                                );
                                            }}
                                        />

                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={scaleUpConfigType}
                                                    name={
                                                        columns[5].accessorKey
                                                    }
                                                    placeholder={
                                                        columns[5].accessorKey
                                                    }
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setScaleUpConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {configList.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Scale Up Config
                                            </label>
                                        </div>

                                        <TimePickerInput
                                            label={'Scale Down Frequency'}
                                            value={scaleDownFrequency}
                                            onChange={(item) => {
                                                setScaleDownFrequency(
                                                    new Date(item.toISOString())
                                                );
                                            }}
                                        />

                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={scaleDownConfigType}
                                                    name={
                                                        columns[7].accessorKey
                                                    }
                                                    placeholder={
                                                        columns[7].accessorKey
                                                    }
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setScaleDownConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {configList.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Scale Down Config
                                            </label>
                                        </div>

                                        <TextField
                                            key={columns[9].accessorKey}
                                            label={columns[9].header}
                                            name={columns[9].accessorKey}
                                            onChange={(e) =>
                                                setValues({
                                                    ...values,
                                                    [e.target.name]:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                        <Typography
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: 'serif',
                                                fontWeight: 'bold',
                                                color: 'green',
                                                fontSize: '19.5px',
                                            }}
                                        >{`You are saving around ${estimatedSaving}$ per month`}</Typography>
                                    </>
                                );
                            })()}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        color="secondary"
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Add New Job
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={2000}>
                <Alert severity="warning" sx={{ width: '100%' }}>
                    Initially Scale Up and Scale Down config is set to default
                    cluster config!
                </Alert>
            </Snackbar>
        </>
    );
};

export const EditJob = ({ open, columns, onClose, onSubmit, row }) => {
    const axios = useAxios();
    const [configList, setConfigList] = useState([]);

    const [values, setValues] = useState({});

    const { user } = useAuth();
    const [cluster, setCluster] = useState(null);
    const [instanceConfig, setInstanceConfig] = useState(null);
    const [zone, setZone] = useState(null);
    const [scaleUpConfigType, setScaleUpConfigType] = useState(null);
    const [scaleDownConfigType, setScaleDownConfigType] = useState(null);
    const [slackChannelId, setSlackChannelId] = useState(null);
    const [scaleUpFrequency, setScaleUpFrequency] = useState(new Date());
    const [scaleDownFrequency, setScaleDownFrequency] = useState(new Date());
    const [estimatedSaving, setEstimatedSaving] = useState(0);

    useEffect(() => {
        if (row) {
            setCluster(row.getValue('clusterName'));
            setInstanceConfig(row.getValue('instanceConfig'));
            setZone(row.getValue('zoneCount'));
            setScaleUpFrequency(getTime(row.getValue('scaleUpFrequency')));
            setScaleDownFrequency(getTime(row.getValue('scaleDownFrequency')));
            setScaleUpConfigType(row.getValue('scaleUpConfig'));
            setScaleDownConfigType(row.getValue('scaleDownConfig'));
            setSlackChannelId(row.getValue('slackChannelId'));
        }
    }, [row]);

    useEffect(() => {
        if (cluster) {
            var oldcluster = Object.keys(oldElasticConfig);
            if (oldcluster.includes(cluster)) {
                var oldConfigList = Object.keys(
                    oldElasticClusterPerHour[cluster]
                );
                setConfigList(oldConfigList);
            } else {
                var minRam = parseConfig(row.getValue('scaleDownConfig'));
                var maxRam = parseConfig(row.getValue('scaleUpConfig'));
                var configMaxIndex =
                    distributionsIndex[maxRam] > 8
                        ? 9
                        : distributionsIndex[maxRam] + 1;
                var configMinIndex =
                    distributionsIndex[minRam] >= 3
                        ? distributionsIndex[minRam] - 3
                        : 0;

                setConfigList(
                    elasticConfigs[instanceConfig].slice(
                        configMinIndex,
                        configMaxIndex + 1
                    )
                );
            }
        }
    }, [cluster]);

    useEffect(() => {
        if (
            scaleUpConfigType &&
            scaleDownConfigType &&
            scaleUpFrequency &&
            scaleDownFrequency
        ) {
            setEstimatedSaving(
                (
                    getCronDifference(
                        getCron(scaleUpFrequency.toISOString()),
                        getCron(scaleDownFrequency.toISOString())
                    ) *
                    getPerHourCostDifference(
                        cluster,
                        scaleUpConfigType,
                        scaleDownConfigType,
                        instanceConfig,
                        zone
                    ) *
                    30
                ).toFixed(2)
            );
        }
    }, [
        scaleUpConfigType,
        scaleDownConfigType,
        scaleUpFrequency,
        scaleDownFrequency,
    ]);

    const handleSubmit = () => {
        if (row) {
            if (values['slackChannelId'] === '') {
                alert('Provide Slack Channel Id');
                return;
            }
            if (
                parseConfig(scaleUpConfigType) <=
                parseConfig(scaleDownConfigType)
            ) {
                alert(
                    'ScaleUp config value should be greater than ScaleDown config value'
                );
                return;
            }
            if (compareTime(scaleUpFrequency, scaleDownFrequency)) {
                alert(
                    'Difference between ScaleUp and ScaleDown Frequency should be atleast greater than 30 min'
                );
                return;
            }

            onSubmit(row.index, {
                ['clusterName']: row.getValue('clusterName'),
                ['deploymentId']: row.getValue('deploymentId'),
                ['instanceConfig']: row.getValue('instanceConfig'),
                ['zoneCount']: row.getValue('zoneCount'),
                ['creatorEmail']: row.getValue('creatorEmail'),
                ['approvedStatus']: row.getValue('approvedStatus'),
                ['scaleUpConfig']: scaleUpConfigType,
                ['scaleDownConfig']: scaleDownConfigType,
                ['scaleUpFrequency']: getCron(scaleUpFrequency.toISOString()),
                ['scaleDownFrequency']: getCron(
                    scaleDownFrequency.toISOString()
                ),
                ['slackChannelId']: row.getValue('slackChannelId'),
                ['scaleUpStatus']: row.getValue('scaleUpStatus'),
                ['scaleDownStatus']: row.getValue('scaleDownStatus'),
                ['scaleUpLastRun']: row.getValue('scaleUpLastRun'),
                ['scaleUpNextRun']: row.getValue('scaleUpNextRun'),
                ['scaleDownLastRun']: row.getValue('scaleDownLastRun'),
                ['scaleDownNextRun']: row.getValue('scaleDownNextRun'),
            });
            onClose();
        }
    };

    return (
        <>
            <Dialog open={open}>
                <DialogTitle textAlign="center">Edit Job</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        <Stack
                            sx={{
                                width: '100%',
                                minWidth: {
                                    xs: '300px',
                                    sm: '360px',
                                    md: '400px',
                                },
                                gap: '1.5rem',
                                marginTop: '21px',
                            }}
                        >
                            {(() => {
                                return (
                                    <>
                                        <TimePickerInput
                                            label={'Scale Up Frequency'}
                                            value={scaleUpFrequency}
                                            onChange={(item) => {
                                                setScaleUpFrequency(
                                                    new Date(item.toISOString())
                                                );
                                            }}
                                        />

                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={scaleUpConfigType}
                                                    name={
                                                        columns[5].accessorKey
                                                    }
                                                    placeholder={
                                                        columns[5].accessorKey
                                                    }
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setScaleUpConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {configList.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Scale Up Config
                                            </label>
                                        </div>

                                        <TimePickerInput
                                            label={'Scale Down Frequency'}
                                            value={scaleDownFrequency}
                                            onChange={(item) => {
                                                setScaleDownFrequency(
                                                    new Date(item.toISOString())
                                                );
                                            }}
                                        />

                                        <div className="text-left relative z-0 mt-4">
                                            <div className="mt-4">
                                                <Select
                                                    value={scaleDownConfigType}
                                                    name={
                                                        columns[7].accessorKey
                                                    }
                                                    placeholder={
                                                        columns[7].accessorKey
                                                    }
                                                    style={{ width: '400px' }}
                                                    onChange={(e) => {
                                                        setScaleDownConfigType(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    {configList.map(function (
                                                        object,
                                                        i
                                                    ) {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={object}
                                                            >
                                                                {object}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </div>
                                            <label
                                                style={{
                                                    color: 'black',
                                                    fontSize: '21px',
                                                    fontWeight: 'bolder',
                                                }}
                                                htmlFor="floating_role"
                                                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                            >
                                                Scale Down Config
                                            </label>
                                        </div>

                                        <TextField
                                            key={columns[9].accessorKey}
                                            label={columns[9].header}
                                            name={columns[9].accessorKey}
                                            value={slackChannelId}
                                            onChange={(e) =>
                                                setSlackChannelId(
                                                    e.target.value
                                                )
                                            }
                                        />
                                        <Typography
                                            style={{
                                                textAlign: 'center',
                                                fontFamily: 'serif',
                                                fontWeight: 'bold',
                                                color: 'green',
                                                fontSize: '19.5px',
                                            }}
                                        >{`You are saving around ${estimatedSaving}$ per month`}</Typography>
                                    </>
                                );
                            })()}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button
                        color="secondary"
                        onClick={handleSubmit}
                        variant="contained"
                    >
                        Edit Job
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={2000}>
                <Alert severity="warning" sx={{ width: '100%' }}>
                    Initially Scale Up and Scale Down config is set to default
                    cluster config!
                </Alert>
            </Snackbar>
        </>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default ElasticTbasTable;
