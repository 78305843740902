import React from 'react';
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/auth';
import Dashboard from './pages/dashboard';
import Security from './pages/security';
import Access from './pages/access';
import Login from './pages/login';
import Auth from './pages/auth';

function App() {
    const { loading, user } = useAuth();
    const location = useLocation();

    if (loading) {
        return <></>;
    }

    const ProtectedRoutes = () => {
        return !!user ? (
            <Outlet />
        ) : (
            <Navigate to={'/login'} state={{ location: location }} />
        );
    };

    const Redirect = () => {
        return !!user ? (
            <Navigate to={'/infra'} state={{ location: location }} />
        ) : (
            <Navigate to={'/login'} state={{ location: location }} />
        );
    };

    return (
        <>
            <Routes>
                <Route element={<ProtectedRoutes />}>
                    <Route path="/*" element={<Redirect />} />
                    <Route path="/infra/*" element={<Dashboard />} />
                    <Route path="/security/*" element={<Security />} />
                    <Route path="/access/*" element={<Access />} />
                </Route>
                <Route path="/auth" element={<Auth />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </>
    );
}

export default App;
