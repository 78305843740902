import React from 'react';

export default function LoadvsGcpDescription({ value }) {
    //const name = props.seriesName
    const descriptions = [
        '  GCP project id considered for costing: apnatime-fbc72.',
        '  GCP cost for the above graph does not include credits.',
        `  The above graph shows number of ${value} vs GCP Cost on a daily basis.`,
        `  The left Y axis denotes the ${value}.`,
        '  The Right Y axis denotes the Cost in $.',
        '  The graph has been generated with data points collected according to UTC timezone (+00:00).',
    ];

    const updatedNums = descriptions.map((description) => {
        return (
            <li class="ml-[30px]">
                {'\u2B22'} {description}
            </li>
        );
    });
    return <ol>{updatedNums}</ol>;
}
