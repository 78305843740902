import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import UnitCosting from './unit-costing';
import Loadgcpcost from './load-gcp-cost';
import TotalCosting from './total-costing';
import GCPUnitCosting from './gcp-costing';
const Costing = () => {
    return (
        <>
            <Routes>
                <Route index element={<Navigate to={'/home'} />} />
                <Route path="gcp-costing/*" element={<GCPUnitCosting />} />
                <Route path="unit-costing/*" element={<UnitCosting />} />
                <Route path="total-costing/*" element={<TotalCosting />} />
                <Route path="load-vs-gcp-cost/*" element={<Loadgcpcost/>} />
            </Routes>
        </>
    );
};

export default Costing;
