import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BlacklistingInfo from './blacklistingUserInfo';
const Blacklisting = () => {
    return (
        <>
            <Routes>
                <Route index element={<Navigate to={'/home'} />} />
                <Route
                    path="support/*"
                    element={<BlacklistingInfo/>}
                />
            </Routes>
        </>
    );
};

export default Blacklisting;
