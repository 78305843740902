import React from 'react';
import { Listbox } from '@headlessui/react';

const FilterPercentage = ({ label, value, onChange, disabled, minvalue }) => {
    return (
        <div
            className={`flex flex-col flex-1 w-full space-y-2 min-w-max1 ${
                disabled ? 'opacity-50' : 'opacity-100'
            }`}
        >
            <div className="flex flex-col flex-1 w-full space-y-2 min-w-max">
                <Listbox disabled={disabled}>
                    <Listbox.Label className="text-lg font-bold text-gray-700">
                        {label}
                    </Listbox.Label>
                    <div>
                        <input
                            style={{
                                width: '300px',
                                height: '53px',
                                padding: '2%',
                            }}
                            value={value}
                            onChange={onChange}
                            type="number"
                            min={minvalue}
                            className="drop-shadow rounded-lg"
                            disabled={disabled}
                        />
                    </div>
                </Listbox>
            </div>
        </div>
    );
};

export default FilterPercentage;
