import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function TextInput({
    placeHolder,
    label,
    value,
    emptyLabel,
    disabled,
    setId,
    width,
    marginTop,
}) {
    return (
        <div className={`flex flex-col flex-1 w-full space-y-2 min-w-max`} style={{ marginTop: marginTop }}>
            <TextField
                id="outlined-basic"
                label={label}
                variant="outlined"
                onChange={(event) => {
                    setId(event.target.value);
                }}
                disabled={disabled}
                value={value}
            />
        </div>
    );
}
