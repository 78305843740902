import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import AnomalyTable from './table';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextInput from '../TextInput';
import { useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Button, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';
import SearchDD from '../SearchDD';
import FlexRow from '../layout/FlexRow';
import DatePickerInput from '../DatePickerInput';
import ProjectTable from './projectTable';
import Alert from '@mui/material/Alert';
import { useSearchParams } from 'react-router-dom';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

export default function GcpIncidents() {
    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    const axios = useAxios();
    const [spikeData, setSpikeData] = useState([]);
    const [graphData, setGraphData] = useState({});
    const [spikes, setSpikes] = useState({});
    const [spikeLimit, setSpikeLimit] = useState(0);
    const [table, setTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [serviceLoading, setServiceLoading] = useState(false);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [checked, setChecked] = useState(false);
    const [filter, setFilter] = useState([]);
    const [daysDiff, setDaysDiff] = useState(0);
    let [isOpen, setIsOpen] = useState(false);
    const [service, setService] = useState({ id: 'All', label: 'All' });
    const [services, setServices] = useState([]);
    const [ok, setOk] = useState('true');

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const getSpikeSourceData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${BACKEND_URL}/gcp/services-spike?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&credit=${checked}&spike=${spikeLimit}`
            );
            setSpikeData(data);
            
        } catch (e) {
            console.error(e);
        }
    };

    //console.log(ok);

    const getServicesSourceData = async () => {
        setLoading(true);
        //setServiceLoading(true);
        try {
            const { data } = await axios.get(
                `${BACKEND_URL}/gcp/services-cost?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&credit=${checked}`
            );
            var rawProjects = Object.keys(data);
            var projects = [];
            projects.push('apnatime-fbc72');
            projects.push('apna-staging');
            projects.push('apnatime-onehouse');
            projects.push('apnatime-cloudside');
            for (var i = 0; i < rawProjects.length; i++) {
                if (
                    rawProjects[i] !== 'apnatime-fbc72' &&
                    rawProjects[i] !== 'apna-staging' &&
                    rawProjects[i] !== 'apnatime-onehouse' &&
                    rawProjects[i] !== 'apnatime-cloudside'
                ) {
                    projects.push(rawProjects[i]);
                }
            }
            setProjectList(projects);
            setGraphData(data);
            setLoading(false);
            setOk('true');
            //setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };
    //console.log(projectList);
    //console.log(loading);
    const getSourceData = async () => {
        if (spikeLimit === '' || spikeLimit < 0) {
            alert('Put some valid spike limit >=0');
            return;
        }
        getSpikeSourceData();
        getServicesSourceData();
    };

    //console.log(spikeData);
    useEffect(() => {
        if (spikeData.length > 0) {
            var tableData = [];
            var project = {};
            for (var i = 0; i < spikeData.length; i++) {
                project[spikeData[i].projectId] = get(
                    project,
                    spikeData[i].projectId,
                    {}
                );
                project[spikeData[i].projectId][
                    spikeData[i].service.split('/')[0]
                ] = get(
                    project[spikeData[i].projectId],
                    spikeData[i].service.split('/')[0],
                    { value: 0, spikeDates: {} }
                );
                var dateVal = spikeData[i].date.toString().split('T')[0];
                if (spikeData[i].cost - spikeData[i].mean > 0) {
                    project[spikeData[i].projectId][
                        spikeData[i].service.split('/')[0]
                    ]['value'] += spikeData[i].cost - spikeData[i].mean;
                    project[spikeData[i].projectId][
                        spikeData[i].service.split('/')[0]
                    ]['spikeDates'][dateVal] = get(
                        project[spikeData[i].projectId][
                            spikeData[i].service.split('/')[0]
                        ]['spikeDates'],
                        dateVal,
                        { posValue: 0, negValue: 0 }
                    );
                    project[spikeData[i].projectId][
                        spikeData[i].service.split('/')[0]
                    ]['spikeDates'][dateVal]['posValue'] += Math.abs(
                        spikeData[i].cost - spikeData[i].mean
                    );
                }
                if (spikeData[i].cost - spikeData[i].mean < 0) {
                    project[spikeData[i].projectId][
                        spikeData[i].service.split('/')[0]
                    ]['spikeDates'][dateVal] = get(
                        project[spikeData[i].projectId][
                            spikeData[i].service.split('/')[0]
                        ]['spikeDates'],
                        dateVal,
                        { posValue: 0, negValue: 0 }
                    );
                    project[spikeData[i].projectId][
                        spikeData[i].service.split('/')[0]
                    ]['spikeDates'][dateVal]['negValue'] += Math.abs(
                        spikeData[i].cost - spikeData[i].mean
                    );
                }
                tableData.push({
                    project: spikeData[i].projectId,
                    service: spikeData[i].service.split('/')[0],
                    object: spikeData[i].object,
                    sku: spikeData[i].skuId,
                    cost: spikeData[i].cost,
                    costSpike: spikeData[i].cost - spikeData[i].mean,
                    percentSpike:
                        spikeData[i].mean > 0
                            ? (((spikeData[i].cost - spikeData[i].mean) * 100) /
                              spikeData[i].mean).toFixed(2)
                            : 'KickStart',
                    date: dateVal,
                });
            }
            setTable(tableData);
            setSpikes(project);
        }
    }, [spikeData]);

    const getServiceList = async () => {
        setServiceLoading(true);
        try {
            const { data } = await axios.get(
                `${BACKEND_URL}/gcp/services?projectId=apnatime-fbc72`
            );
            var values = [{ label: 'All', id: 'All' }];
            for (var i = 0; i < data['services'].length; i++) {
                values.push({
                    label: data['services'][i].split('/')[0],
                    id: data['services'][i].split('/')[0],
                });
            }
            setServices(values);
            setServiceLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getServiceList();
    }, []);
    
    function closeModal() {
        setIsOpen(false);
    }

    const handleDescription = (filter) => {
        setFilter(filter);
        setIsOpen(true);
    };

    useEffect(() => {
        var diffTime = Math.abs(startDate.getTime() - endDate.getTime());
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)).toFixed(0);
        setDaysDiff(diffDays);
    }, [startDate, endDate]);

    useEffect(()=>{
        setGraphData({});
        setSpikeData([]);
        setSpikes({});
        setTable([]);

    },[startDate, endDate, spikeLimit, checked])

    return (
        <>
            <FlexRow>
                <DatePickerInput
                    label={'Start Date'}
                    value={startDate}
                    onChange={setStartDate}
                    maxDate={endDate}
                />
                <DatePickerInput
                    label={'End Date'}
                    value={endDate}
                    onChange={setEndDate}
                    maxDate={dateObj}
                />
                <TextInput
                    label={`Enter daily minimum spike in $`}
                    emptyLabel={'Enter daily minimum spike in $'}
                    value={spikeLimit}
                    setId={setSpikeLimit}
                    marginTop={'44px'}
                />
                <div style={{ marginTop: '40px' }}>
                    <Box
                        sx={{
                            borderColor: 'black',
                            width: '150px',
                            height: '50px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                        }}
                    >
                        <FormControl
                            style={{ marginTop: '5px' }}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={handleCheck}
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bolder',
                                                color: 'red',
                                            }}
                                        >
                                            Credit
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </div>
                <FlexRow>
                    <div className="mt-[40px]">
                        <Button
                            size={'large'}
                            color={'success'}
                            variant={'contained'}
                            sx={{
                                height: '55px',
                                width: '250px',
                                fontWeight: 'bold',
                            }}
                            onClick={getSourceData}
                            //disabled={buttonDisability}
                        >
                            Get Services
                        </Button>
                    </div>
                </FlexRow>
                
            </FlexRow>
            {Object.keys(graphData).length > 0 ? (
                <FlexRow>
                    <SearchDD
                        label={'Service Name'}
                        emptyLabel={'Select GCP Service'}
                        options={services}
                        value={service}
                        onChange={setService}
                        isLoading={serviceLoading}
                    />
                    <FlexRow />
                    <FlexRow />
                    <FlexRow />
                </FlexRow>
            ) : (
                <></>
            )}

            <Box
                sx={{
                    marginTop: 5,
                    display: 'flex',
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container xs={12} rowSpacing={3} columnSpacing={3}>
                    {(() => {
                        switch (ok) {
                            case 'false':
                                return <></>;
                            case 'true':
                                return (
                                    <>
                                        {loading ? (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    marginTop: '20vh',
                                                }}
                                            >
                                                <LinearProgress color="secondary" />
                                            </Box>
                                        ) : (
                                            <>
                                                <Grid xs={12}>
                                                    <Alert
                                                        severity="info"
                                                        style={{
                                                            fontWeight:
                                                                'bolder',
                                                            marginBottom: 5,
                                                        }}
                                                    >
                                                        {`Click on PROJECT NAME, SERVICE NAME or on the RED/GREEN BARS to know the exact reason behind positive or negative spike`}
                                                    </Alert>
                                                    <Alert severity="error">
                                                        {`Red and Green colour of bar shows that aleast one sku of service cost has crossed it's [mean ± 3std]. Where mean and std deviation are calculated based on past 100 days data for every date`}
                                                    </Alert>
                                                    <Alert severity="error">
                                                        {`In case of Kubernetes Engine it's spike may become greater than it's cost because kubernetes contains sku of various GCP services like compute engine, networking etc. `}
                                                    </Alert>
                                                </Grid>
                                                {projectList.map(function (
                                                    object,
                                                    i
                                                ) {
                                                    return graphData[object]
                                                        ?.length > 0 ? (
                                                        <Grid
                                                            xs={
                                                                daysDiff > 60
                                                                    ? 12
                                                                    : 6
                                                            }
                                                            key={i}
                                                        >
                                                            <div>
                                                                <ProjectTable
                                                                    data={
                                                                        graphData[
                                                                            object
                                                                        ]
                                                                    }
                                                                    spikes={get(
                                                                        spikes,
                                                                        object,
                                                                        {}
                                                                    )}
                                                                    title={
                                                                        object
                                                                    }
                                                                    handleDescription={
                                                                        handleDescription
                                                                    }
                                                                    filter={
                                                                        service ===
                                                                        null
                                                                            ? 'All'
                                                                            : service?.id
                                                                    }
                                                                />
                                                            </div>
                                                        </Grid>
                                                    ) : (
                                                        <></>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </>
                                );
                        }
                    })()}
                </Grid>
            </Box>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto mt-20">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-auto transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="mt-2">
                                        {
                                            <AnomalyTable
                                                data={table}
                                                filter={filter}
                                            />
                                        }
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
