import React, { useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Data from './data.json';

function getunitandsize(value) {
    var pow = Math.floor(Math.log10(value))+1;
    
    if(pow > 12)
    {
        return (value/Math.pow(10,12)).toFixed(3) + ' TB'
    }
    if(pow>9)
    {
        return (value/Math.pow(10,9)).toFixed(3) + ' GB'
    }
    if(pow>6)
    {
        return (value/Math.pow(10,6)).toFixed(3) + ' MB'
    }
    if(pow>3)
    {
        return (value/Math.pow(10,3)).toFixed(3) + ' KB'
    }
    return value.toString() + ' B'
}


const ActiveCostTypePill = ({ value }) => {

    return (
        <span
            className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap bg-red-100 text-red-700`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {getunitandsize(value)}
        </span>
    );
};

const LongTermCostTypePill = ({ value }) => {

    return (
        <span
            className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap bg-blue-100 text-blue-700`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {getunitandsize(value)}
        </span>
    );
};

const CostPill = ({ value }) => {
    return (
        <span
            className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap ${(() => {
                if (value > 100) {
                    return 'bg-red-100 text-red-700';
                }
                if (value > 30) {
                    return 'bg-yellow-100 text-yellow-700';
                }
                return 'bg-green-100 text-green-700';
            })()}`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {value.toFixed(3)}$
        </span>
    );
};

export default function BqStorageTable({
    tableData,
    loading,
    nextPageToken,
    getTableData
}) {
   
    const column = [{
        header: 'Project ID',
        accessorKey: 'projectId',
        Cell: ({ cell }) => (
            <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
        ),
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterFn: 'customFilterFn',
    },
    {
        header: 'Dataset ID',
        accessorKey: 'datasetId',
        Cell: ({ cell }) => (
            <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
        ),
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterFn: 'customFilterFn',
    },
    {
        header: 'Table ID',
        accessorKey: 'tableId',
        Cell: ({ cell }) => (
            <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
        ),
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterFn: 'customFilterFn',
    },
    {
        header: 'Last Usage Time',
        accessorKey: 'lastTime',
        Cell: ({ cell }) => (
            <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
        ),
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterFn: 'customFilterFn',
    },{
        header: 'Active Size',
        accessorKey: 'activeSize',
        Cell: ({ cell }) => <ActiveCostTypePill value={cell.getValue()} />,
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
    },
    {
        header: 'LongTerm Size',
        accessorKey: 'longTermSize',
        Cell: ({ cell }) => <LongTermCostTypePill value={cell.getValue()} />,
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
    },
    {
        header: 'Estimated Cost',
        accessorKey: 'cost',
        Cell: ({ cell }) => <CostPill value={cell.getValue()} />,
        muiTableHeadCellProps: {
            align: 'left',
        },
        muiTableBodyCellProps: {
            align: 'left',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
    }]

   /* useEffect(() => {
        setColumns([
            {
                header: 'Project ID',
                accessorKey: 'projectId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Dataset Id',
                accessorKey: 'datasetId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Table Id',
                accessorKey: 'tableId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Not Used Since',
                accessorKey: 'notUsed',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Pricing Type',
                accessorKey: 'pricingType',
               
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Total Storage Cost Since not used',
                accessorKey: 'totalCost',
                
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
        ]);
    });*/
    
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };

    return (
        <MaterialReactTable
            className="p-4 w-full mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
            columns={column}
            data={tableData}
            enableRowSelection
            enableStickyHeader
            enableStickyFooter
            enableDensityToggle={false}
            initialState={{
                density: 'compact',
                sorting: [
                    {
                        id: 'cost',
                        desc: true,
                    },
                ],
            }}
            state={{
                isLoading: loading,
            }}
            filterFns={{
                customFilterFn: (
                    row,
                    id,
                    filterValue
                ) => {
                    return row
                        .getValue(id)
                        .includes(filterValue);
                },
            }}
            positionToolbarAlertBanner="bottom"
            renderTopToolbarCustomActions={({ table }) => (
                <Box
                    sx={{
                        display: 'flex',
                        gap: '1rem',
                        p: '0.5rem',
                        flexWrap: 'wrap',
                    }}
                >
                    <Button
                        color="primary"
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        onClick={handleExportData}
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export All Data
                    </Button>
                    <Button
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() =>
                            handleExportRows(table.getRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Page Rows
                    </Button>
                    <Button
                        disabled={
                            !table.getIsSomeRowsSelected() &&
                            !table.getIsAllRowsSelected()
                        }
                        //only export selected rows
                        onClick={() =>
                            handleExportRows(table.getSelectedRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="contained"
                    >
                        Export Selected Rows
                    </Button>
                    <Button
                        color="warning"
                        onClick={getTableData}
                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                        startIcon={<ArrowForwardIosIcon />}
                        variant="contained"
                        disabled={nextPageToken === '' || loading}
                    >
                        Get Next Set of Data
                    </Button>
                </Box>
            )}
        />
    );
}
