import React from 'react';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../components/tabs/tabPanel';
import BackButton from '../../../components/navigation/back-button';
import HeaderTitle from '../../../components/typography/header-title';
import StorageCostAnalysis from '../../../components/bqtableStorage/bqTableStorageAnalytics';
import { useSearchParams } from 'react-router-dom';
import StorageCostAction from '../../../components/bqtableStorage/storageCostAction';
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function TableStorageCostAnalysis() {
    const [value, setValue] = useState(0);
    const [email, setEmail] = useState(null);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        var tab = searchParams.get('tab')?parseInt(searchParams.get('tab')):0;
        var email = searchParams.get('owner');
        setValue(tab)
        setEmail(email)
    }, [searchParams]);
    return (
        <>
            
            <HeaderTitle
                title={'Bigquery Table Storage Cost Analysis'}
                subtitle={
                    'Analytics Dashboard for Bigquery Table Storage and its Cost'
                }
            />
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="ANALYSIS" {...a11yProps(0)} />
                        <Tab label="ACTION" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <StorageCostAnalysis />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {"Coming Soon"/*<StorageCostAction email={email}/>*/}
                </TabPanel>
            </Box>
        </>
    );
}
