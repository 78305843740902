import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import MongoTagging from '../../../../components/table/mongoTaggingTable';

const MongoMetaData = () => {
    return (
        <>
            
            <HeaderTitle
                title={'Mongo Clusters Tagging'}
                subtitle={'Manage metadata for Mongo Clusters.'}
            />
            <MongoTagging />
        </>
    );
};

export default MongoMetaData;
