import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import ReblazeGraph from './reblazeGraph';
import DD2 from '../DD2';
import Button from '@mui/material/Button';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { TextField } from '@mui/material';
import DateTimePickerInput from '../DateTimePickerInput';
import SearchDD from '../SearchDD';
import FlexRow from '../layout/FlexRow';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';
import { useTheme } from '@mui/material/styles';
const ReblazeDash = () => {
    const theme = useTheme();
    const authAxios = useAxios();
    var dateObj = new Date('2024-03-31T05:00:00Z');
    dateObj.setDate(dateObj.getDate() - 1);
    var dateObj1 = new Date('2024-03-31T05:00:00Z');
    const domains = [
        { name: 'null', id: null },
        { name: 'production.apna.co', id: 0 },
        { name: 'api.production.infra.apna.co', id: 1 }
    ];
    //const [ok, setOk] = useState('true');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [uris, setUris] = useState([]);
    const [urisLoading, setUrisLoading] = useState(false);
    const [isps, setIsps] = useState([]);
    const [ispsLoading, setIspsLoading] = useState(false);
    const [call, setCall] = useState(false);
    const [domain, setDomain] = useState(domains[0]);
    const [endDate, setEndDate] = useState(dateObj1);
    const [startDate, setStartDate] = useState(dateObj);
    const [istEndDate, setISTEndDate] = useState(endDate);
    const [istStartDate, setISTStartDate] = useState(startDate);
    const [userIdentity, setUserIdentity] = useState(null);
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [ip, setIp] = useState(null);
    const [statusCode, setStatusCode] = useState(null);
    const [isp, setIsp] = useState(null);
    const [uri, setUri] = useState(null);
    const [geoIp, setGeoIp] = useState(null);
    const [timeGap, setTimeGap] = useState(1);
    const [buttonDisability, setButtonDisability] = useState(false);
    const availableTimeRange = [
        { name: 'Last 3 hours', min: 0, hr: 3 },
        { name: 'Last 6 hours', min: 0, hr: 6 },
        { name: 'Last 12 hours', min: 0, hr: 12 },
        { name: 'Last 24 hours', min: 0, hr: 24 },
        { name: 'Last 2 days', min: 0, hr: 48 },
        { name: 'Last 3 days', min: 0, hr: 72 },
        { name: 'Last 4 days', min: 0, hr: 96 },
        { name: 'Last 5 days', min: 0, hr: 120 },
        { name: 'Last 6 days', min: 0, hr: 144 },
        { name: 'Last 7 days', min: 0, hr: 168 },
        { name: 'Last 15 days', min: 0, hr: 360 },
    ];

    const handleTime = async (min, hr) => {
        setOpen(false);
        var startdate = new Date();
        var enddate = new Date();
        startdate.setMinutes(startdate.getMinutes() - min);
        startdate.setHours(startdate.getHours() - hr);
        setStartDate(startdate);
        setEndDate(enddate);
        setTimeout(getData, 1);
    };

    function renderRow(props) {
        const { index, style } = props;
        return (
            <>
                <ListItem
                    style={style}
                    key={index}
                    disablePadding
                >
                    <ListItemButton
                        disabled = {true}
                        onClick={() => {
                            handleTime(
                                availableTimeRange[index]?.min,
                                availableTimeRange[index]?.hr
                            );
                        }}
                    >
                        <ListItemText>
                            {availableTimeRange[index]?.name}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </>
        );
    }

    const handleClick = () => (event) => {
        setAnchorEl(event.currentTarget);
        if (open == true) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const isDateInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    const getIsps = async () => {
        setIspsLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/gcp/reblazeisp`
            );
            var ispList = [{ label: 'null', id: null }];
            for (var i = 0; i < data?.length; i++) {
                ispList.push({
                    label: data[i]['org_name'],
                    id: data[i]['org_id'],
                });
            }
            setIsps(ispList);
            setIspsLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const getUris = async () => {
        setUrisLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/gcp/reblazeuri`
            );
            var uriList = [{ label: 'null', id: null }];
            for (var i = 0; i < data?.length; i++) {
                uriList.push({
                    label: data[i]['uri'],
                    id: data[i]['uri'],
                });
            }
            setUris(uriList);
            setUrisLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        var startdate = new Date(startDate);
        var enddate = new Date(endDate);
        startdate.setHours(startdate.getHours() + 5);
        startdate.setMinutes(startdate.getMinutes() + 30);
        enddate.setHours(enddate.getHours() + 5);
        enddate.setMinutes(enddate.getMinutes() + 30);
        setISTEndDate(enddate);
        setISTStartDate(startdate);
        var differenceMs = Math.abs(endDate - startDate);
        var differenceHrs = differenceMs / (1000 * 60 * 60);
        setTimeGap(differenceHrs);
    }, [startDate,endDate]);

    useEffect(() => {
        if (userIdentity?.length > 10) {
            setToken(userIdentity);
        } else if (userIdentity?.length == 10) {
            setPhoneNumber(userIdentity);
        } else {
            setUserId(userIdentity);
        }
    }, [userIdentity]);

    function getData() {
        if (!isDateInputValid()) {
            return;
        }
        setCall(true);
        setOpen(false);
    }

    useEffect(() => {
        getIsps();
        getUris();
    }, []);

    useEffect(() => {
        if (initialized) {
            setCall(false);
        } else {
            setCall(true);
            setInitialized(true);
        }
        if (statusCode === '') {
            setStatusCode(null);
        }
        if (geoIp === '') {
            setGeoIp(null);
        }
        if (ip === '') {
            setIp(null);
        }
        if (userIdentity === '') {
            setUserIdentity(null);
            setUserId(null);
            setPhoneNumber(null);
            setToken(null);
        }
    }, [
        uri,
        domain,
        statusCode,
        geoIp,
        startDate,
        endDate,
        isp,
        ip,
        userIdentity,
    ]);

    return (
        <>
            <Box>
                <Popper
                    sx={{ zIndex: 2 }}
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    transition
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                            <Paper
                                level="300"
                                sx={{
                                    width: '900px',
                                    display: 'flex',
                                    zIndex: -1,
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: '20px',
                                        display: 'flex',
                                        width: '700px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div style={{ marginTop: '-20px' }}></div>
                                    <FlexRow>
                                        <DateTimePickerInput
                                            label={'Start Date'}
                                            value={startDate}
                                            minDate={
                                                new Date('2024-02-25T05:00:00Z')
                                            }
                                            maxDate={endDate}
                                            onChange={setStartDate}
                                            disabled={false}
                                        />
                                        <DateTimePickerInput
                                            label={'End Date'}
                                            value={endDate}
                                            minDate={startDate}
                                            maxDate={new Date('2024-03-31T05:00:00Z')}
                                            onChange={setEndDate}
                                            disabled={false}
                                        />
                                    </FlexRow>
                                    <div style={{ marginTop: '-20px' }}></div>
                                    <FlexRow>
                                        <SearchDD
                                            label={'Uri'}
                                            emptyLabel={'Select Uri'}
                                            options={uris}
                                            value={uri}
                                            onChange={setUri}
                                            isLoading={urisLoading}
                                            disabled={false}
                                        />
                                        <SearchDD
                                            label={'ISP'}
                                            emptyLabel={'Select ISP'}
                                            options={isps}
                                            value={isp}
                                            onChange={setIsp}
                                            isLoading={ispsLoading}
                                            disabled={false}
                                        />
                                    </FlexRow>
                                    <div style={{ marginTop: '-20px' }}></div>
                                    <FlexRow>
                                        <DD2
                                            label={'Domain Name'}
                                            emptyLabel={'Select Domain'}
                                            options={domains}
                                            value={domain}
                                            onChange={setDomain}
                                            displayProperty={'name'}
                                        />
                                        <div style={{ marginTop: '43px' }}>
                                            <TextField
                                                fullWidth={true}
                                                label={'UserId/Phone No./Token'}
                                                value={userIdentity}
                                                sx={{ width: 340 }}
                                                onChange={(e) => {
                                                    setUserIdentity(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </FlexRow>
                                    <FlexRow>
                                        <TextField
                                            fullWidth={true}
                                            label={'Status'}
                                            value={statusCode}
                                            //sx={{ width: 312 }}
                                            onChange={(e) => {
                                                setStatusCode(e.target.value);
                                            }}
                                        />
                                        <TextField
                                            fullWidth={true}
                                            label={'IP'}
                                            value={ip}
                                            //sx={{ width: 312 }}
                                            onChange={(e) => {
                                                setIp(e.target.value);
                                            }}
                                        />
                                    </FlexRow>
                                    <FlexRow>
                                        <TextField
                                            fullWidth={true}
                                            label={'GeoIp City'}
                                            value={geoIp}
                                            //sx={{ width: 312 }}
                                            onChange={(e) => {
                                                setGeoIp(e.target.value);
                                            }}
                                        />
                                        <Button
                                            children={'Get Result'}
                                            sx={{
                                                width: 'auto',
                                                minWidth: '340px',
                                                backgroundColor: '#0096FF',
                                                fontWeight: 'bold',
                                                color: 'white',
                                                height: 53,
                                                padding: 2,
                                                ':hover': {
                                                    backgroundColor: '#6F8FAF',
                                                    color: 'white',
                                                },
                                            }}
                                            onClick={() => {
                                                getData();
                                            }}
                                            disabled={buttonDisability}
                                        />
                                    </FlexRow>
                                </Box>

                                <Box sx={{ borderLeft: 0.5 }}>
                                    <FixedSizeList
                                        height={507}
                                        width={200}
                                        itemSize={46}
                                        itemCount={11}
                                        overscanCount={5}
                                    >
                                        {renderRow}
                                    </FixedSizeList>
                                </Box>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Button
                            style={{ fontWeight: 'bold' }}
                            color="primary"
                            variant="contained"
                            onClick={handleClick()}
                        >
                            {`${istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                )} to ${istEndDate
                                .toISOString()
                                .slice(
                                    0,
                                    istEndDate.toISOString().length - 5
                                )}`}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid container rowGap={3} columnSpacing={3}>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'timestamp'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'domain_name'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits on domain every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'user_id'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits from UserId every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'status'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits with status code every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'organization'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits from ISP every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'remote_addr'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits from Ip every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'uri'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits on uri every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
                <Grid xs={6}>
                    <ReblazeGraph
                        groupBy={'geoip_city'}
                        body={{
                            uri: uri?.id,
                            status: statusCode,
                            domain_name: domain?.id,
                            start_date: istStartDate
                                .toISOString()
                                .slice(
                                    0,
                                    istStartDate.toISOString().length - 5
                                ),
                            end_date: istEndDate
                                .toISOString()
                                .slice(0, istEndDate.toISOString().length - 5),
                            organization: isp?.id,
                            geoip_city: geoIp,
                            remote_addr: ip,
                            user_id: userId,
                            token: token,
                            phone_number: phoneNumber,
                        }}
                        call={call}
                        title={`Hits from GeoIp every ${
                            timeGap > 24 ? 'hour' : '5 minutes'
                        }`}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default ReblazeDash;
