import React from 'react';

const PrimaryButton = ({ title, onClick, disabled }) => {
    return (
        <button
            type="button"
            className="w-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            onClick={onClick}
            disabled={disabled}
        >
            {title}
        </button>
    );
};

export default PrimaryButton;
