import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HeaderTitle from '../../../../components/typography/header-title';
import Information from '../../../../components/userInformation/information';
import OTPStatus from '../../../../components/userInformation/otpstatus';
import UserFullInformation from '../../../../components/userInformation/userfullinfo';
import UserPrefrences from '../../../../components/userInformation/userPrefrences.js';
import TabPanel from '../../../../components/tabs/tabPanel.js';
import { useMediaQuery } from 'react-responsive';
// import { Start } from '@mui/icons-material';

function TabPanelVertical(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanelVertical.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const a11yPropsHorizontal = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const BlacklistingInfo = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HeaderTitle
                title={'Get User Information or OTP Status'}
                subtitle={
                    'Get User Information w.r.t UserId/Phone Number and get to know wether it is blacklisted or not and other info, Also get to know the OTP status w.r.t Phone Number'
                }
            />
            {isMobile ? (
                <Box
                    sx={{
                        width: '100%',
                        marginTop: '30px',
                    }}
                >
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label="user status"
                                {...a11yPropsHorizontal(0)}
                                wrapped={true}
                            />
                            <Tab
                                label="user profile"
                                {...a11yPropsHorizontal(1)}
                                wrapped={true}
                            />
                            <Tab
                                label="user prefrences"
                                {...a11yPropsHorizontal(2)}
                                wrapped={true}
                            />
                            <Tab
                                label="otp help"
                                {...a11yPropsHorizontal(3)}
                                wrapped={true}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Information />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <UserFullInformation />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <UserPrefrences />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <OTPStatus />
                    </TabPanel>
                </Box>
            ) : (
                <Box
                    sx={{
                        flexGrow: 1,
                        //bgcolor: 'background.paper',
                        display: 'flex',
                        height: 800,
                    }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{
                            borderRight: 1,
                            borderColor: 'divider',
                            justifyContent: 'left',
                            alignItems: 'start',
                            minWidth: '180px',
                        }}
                    >
                        <Tab
                            label="user status"
                            {...a11yProps(0)}
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                        />
                        <Tab
                            label="user profile"
                            {...a11yProps(1)}
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                        />
                        <Tab
                            label="user prefrences"
                            {...a11yProps(2)}
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                        />
                        <Tab
                            label="otp help"
                            {...a11yProps(3)}
                            sx={{ display: 'flex', alignItems: 'flex-start' }}
                        />
                    </Tabs>
                    <TabPanelVertical value={value} index={0}>
                        <Information />
                    </TabPanelVertical>
                    <TabPanelVertical value={value} index={1}>
                        <UserFullInformation />
                    </TabPanelVertical>
                    <TabPanelVertical value={value} index={2}>
                        <UserPrefrences />
                    </TabPanelVertical>
                    <TabPanelVertical value={value} index={3}>
                        <OTPStatus />
                    </TabPanelVertical>
                </Box>
            )}
        </>
    );
};

export default BlacklistingInfo;
