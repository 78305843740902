export default function Svg(props) {
    const mt = "80px"
    const ml = "40px"
    return (
        (() => {
            switch (props.type) {
                case "gcp":
                    return (<svg xmlns="http://www.w3.org/2000/svg" class="ml-[-60px]" viewBox="0 0 48 48" width="50px" height="50px"><path fill="#1976d2" d="M38.193,18.359c-0.771-2.753-2.319-5.177-4.397-7.03l-4.598,4.598 c1.677,1.365,2.808,3.374,3.014,5.648v1.508c0.026,0,0.05-0.008,0.076-0.008c2.322,0,4.212,1.89,4.212,4.212S34.61,31.5,32.288,31.5 c-0.026,0-0.05-0.007-0.076-0.008V31.5h-6.666H24V38h8.212v-0.004c0.026,0,0.05,0.004,0.076,0.004C38.195,38,43,33.194,43,27.288 C43,23.563,41.086,20.279,38.193,18.359z"/><path fill="#ffe082" d="M19.56,25.59l4.72-4.72c-0.004-0.005-0.008-0.009-0.011-0.013l-4.717,4.717 C19.554,25.579,19.557,25.584,19.56,25.59z" opacity=".5"/><path fill="#90caf9" d="M19.56,25.59l4.72-4.72c-0.004-0.005-0.008-0.009-0.011-0.013l-4.717,4.717 C19.554,25.579,19.557,25.584,19.56,25.59z" opacity=".5"/><path fill="#ff3d00" d="M24,7.576c-8.133,0-14.75,6.617-14.75,14.75c0,0.233,0.024,0.46,0.035,0.69h6.5 c-0.019-0.228-0.035-0.457-0.035-0.69c0-4.549,3.701-8.25,8.25-8.25c1.969,0,3.778,0.696,5.198,1.851l4.598-4.598 C31.188,9.003,27.761,7.576,24,7.576z"/><path fill="#90caf9" d="M15.712,31.5L15.712,31.5c-0.001,0-0.001,0-0.002,0c-0.611,0-1.188-0.137-1.712-0.373 l-4.71,4.71C11.081,37.188,13.301,38,15.71,38c0.001,0,0.001,0,0.002,0v0H24v-6.5H15.712z" opacity=".5"/><path fill="#4caf50" d="M15.712,31.5L15.712,31.5c-0.001,0-0.001,0-0.002,0c-0.611,0-1.188-0.137-1.712-0.373l-4.71,4.71 C11.081,37.188,13.301,38,15.71,38c0.001,0,0.001,0,0.002,0v0H24v-6.5H15.712z"/><path fill="#ffc107" d="M11.5,27.29c0-2.32,1.89-4.21,4.21-4.21c1.703,0,3.178,1.023,3.841,2.494l4.717-4.717 c-1.961-2.602-5.065-4.277-8.559-4.277C9.81,16.58,5,21.38,5,27.29c0,3.491,1.691,6.59,4.288,8.547l4.71-4.71 C12.53,30.469,11.5,28.999,11.5,27.29z"/></svg>)
                case "cloudbuild":
                    return (<svg xmlns="http://www.w3.org/2000/svg" class="m-[10px]" viewBox="0 0 24 24" width="50px" height="50px"><path d="m12.15 16.24 3.52-2.03v-4.06l-1.18-.69-3.52 6.1z" fill="#4285f4"/><path d="m8.63 10.15v4.06l1.18.68 3.53-6.09-1.19-.69z" fill="#669df6"/><g fill="#aecbfa"><path d="m11.46 17.45-4.22-2.44v-4.86l-3.75-2.17v9.2l7.97 4.6z"/><path d="m7.93 8.95 4.22-2.44 4.22 2.44 3.76-2.17-7.98-4.61-7.98 4.61z"/><path d="m17.06 15.01-4.22 2.44v4.33l7.98-4.6v-9.2l-3.76 2.17z"/></g></svg>)
                case "cloudrun":
                    return (<svg preserveAspectRatio="xMidYMid" class="m-[10px]" viewBox="0 0 256 231" width="50px" height="50px" xmlns="http://www.w3.org/2000/svg"><path d="m75.390147 0c-8.1937105.14424944-15.6975323 4.61940312-19.719412 11.7594655l-52.61797776 91.2363475c-4.07034299 7.083617-4.07034299 15.796169 0 22.879216l52.59802226 91.996935c3.9808285 7.23927 11.4641247 11.861524 19.7188419 12.180241h105.2165706c8.254717-.283938 15.751697-4.887376 19.739367-12.119804l52.598022-91.476953c2.049711-3.598254 3.074281-7.60417 3.074281-11.609515 0-4.005345-1.02457-8.01126-3.074281-11.610084l-52.598022-91.4763835c-4.02473-7.1372116-11.526272-11.61122496-19.719412-11.7594655z" fill="#4285f4"/><path d="m236.495178 155.027249-36.169619 62.905152c-3.98767 7.232428-11.48465 11.835866-19.739367 12.119804h-40.076034l-58.4720502-58.995058 17.2375234-55.583358-17.2375234-56.2196527 17.7050512 11.892882 23.805719 24.0189576-11.11975-35.9118396 83.375038 56.0075547z" fill-opacity=".07"/><path d="m82.0382788 59.2539082 17.7050513 11.8934522 13.8787349 44.6181206-13.6318574 43.277683-17.9519288 12.014326 17.3424321-55.796027zm45.3471782 19.8360089 8.59225 27.7916789h32.98922zm68.418709 36.1718309-83.375038 55.795456 17.342432-55.795456-17.342432-56.0081248z" fill="#fff"/></svg>)
            }
        })()
    );
}