import React from 'react';
import BackButton from '../../../components/navigation/back-button';
import HeaderTitle from '../../../components/typography/header-title';
import BigqueryCostComparator from '../../../components/bqanalysiscomparison/BigquerycostComparator';
export default function QueryCostSpike() {
    return (
        <>
           
            <HeaderTitle
                title={'Bigquery Analysis Cost Comparator'}
                subtitle={
                    'Visualize the reason behind the spike in bigquery analysis cost'
                }
            />
            <BigqueryCostComparator/>
        </>
    );
}
