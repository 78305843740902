export default function Loading() {
    return (
        <div
            style={{
                marginLeft: '600px',
                marginTop: '400px',
                position: 'absolute',
                borderBlockColor: 'green',
            }}
            className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
        />
    );
}
