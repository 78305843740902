import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const UserIAM = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isAdmin, isOwner, isAdminApprover } = useAuth();
    const owner = isOwner();
    const adminApprover = isAdminApprover();
    function call(url, data, method) {
        const requestOptions = {
            url: `${BACKEND_URL}/user/${url}`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/user/all` }).then(function (res) {
            var temp = [];
            var data = res.data;
            for (var i = 0; i < data.length; i++) {
                if (data[i]['role'] === 'admin' || data[i]['role'] === 'user') {
                    temp.push(data[i]);
                } else {
                    if (owner) {
                        temp.push(data[i]);
                    } else if (
                        adminApprover &&
                        data[i]['role'] === 'admin_approver'
                    ) {
                        temp.push(data[i]);
                    }
                }
            }
            setTableData(temp);
        });
    }, []);

    const handleCreateNewRow = (values) => {
        if (values.name.length <= 2) {
            alert('Please enter valid name, too few characters');
            return;
        }
        if (!validateEmail(values.email)) {
            alert('Please enter valid email');
            return;
        }
        if (values.role === '') {
            alert('Please select a iam policy');
            return;
        }
        tableData.push(values);
        setTableData([...tableData]);
        call(
            '',
            {
                name: values.name,
                email: values.email,
                role: values.role,
                team: values.team,
                managerEmail: values.managerEmail,
            },
            'POST'
        );
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();

            call(
                '',
                {
                    name: values.name,
                    email: values.email,
                    role: values.role,
                    team: values.team,
                    managerEmail: values.managerEmail,
                },
                'PUT'
            );
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue('name')}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            call(
                '',
                {
                    name: row.getValue('name'),
                    email: row.getValue('email'),
                    role: row.getValue('role'),
                    team: row.getValue('team'),
                    managerEmail: row.getValue('managerEmail'),
                },
                'DELETE'
            );
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Name',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'email',
                header: 'Email',
                enableEditing: false,
            },
            {
                accessorKey: 'team',
                header: 'Team',
                enableEditing: false,
            },
            {
                accessorKey: 'managerEmail',
                header: 'Manager',
                enableEditing: false,
            },
            owner
                ? {
                      accessorKey: 'role',
                      header: 'IAM-Policy',
                      muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                          children: [
                              'user',
                              'pii_data_viewer',
                              'admin',
                              'admin_approver',
                              'owner',
                              'itadmin',
                              'manager'
                          ].map((iam) => (
                              <MenuItem key={iam} value={iam}>
                                  {iam}
                              </MenuItem>
                          )),
                      },
                  }
                : adminApprover
                ? {
                      accessorKey: 'role',
                      header: 'IAM-Policy',
                      muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                          children: ['user', 'admin', 'admin_approver'].map(
                              (iam) => (
                                  <MenuItem key={iam} value={iam}>
                                      {iam}
                                  </MenuItem>
                              )
                          ),
                      },
                  }
                : {
                      accessorKey: 'role',
                      header: 'IAM-Policy',
                      muiTableBodyCellEditTextFieldProps: {
                          select: true, //change to select for a dropdown
                          children: ['user', 'admin'].map((iam) => (
                              <MenuItem key={iam} value={iam}>
                                  {iam}
                              </MenuItem>
                          )),
                      },
                  },
        ],
        [getCommonEditTextFieldProps, owner]
    );

    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                enableColumnOrdering
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{ density: 'compact' }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isAdmin()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="primary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                        disabled={!isAdmin()}
                    >
                        Add New User
                    </Button>
                )}
            />
            <AddNewUser
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
};

export const AddNewUser = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const [iam, setIam] = useState('');
    const { isOwner, isAdminApprover } = useAuth();
    var owner = isOwner();
    var adminApprover = isAdminApprover();
    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add New User</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {(() => {
                            return (
                                <>
                                    <TextField
                                        key={columns[0].accessorKey}
                                        label={columns[0].header}
                                        name={columns[0].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        key={columns[1].accessorKey}
                                        label={columns[1].header}
                                        name={columns[1].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        key={columns[2].accessorKey}
                                        label={columns[2].header}
                                        name={columns[2].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        key={columns[3].accessorKey}
                                        label={columns[3].header}
                                        name={columns[3].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                    <div className="text-left relative z-0">
                                        <div className="mt-4">
                                            <Select
                                                value={iam}
                                                name={columns[4].accessorKey}
                                                placeholder={
                                                    columns[4].accessorKey
                                                }
                                                style={{ width: '400px' }}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                    setIam(e.target.value);
                                                }}
                                            >
                                                {owner ? (
                                                    <>
                                                        <MenuItem
                                                            value={'user'}
                                                        >
                                                            user
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'admin_approver'
                                                            }
                                                        >
                                                            admin_approver
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'pii_data_viewer'
                                                            }
                                                        >
                                                            pii_data_viewer
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'admin'}
                                                        >
                                                            admin
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'manager'}
                                                        >
                                                            manager
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'itadmin'}
                                                        >
                                                            itadmin
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'owner'}
                                                        >
                                                            owner
                                                        </MenuItem>
                                                    </>
                                                ) : adminApprover ? (
                                                    <>
                                                        <MenuItem
                                                            value={'user'}
                                                        >
                                                            user
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'admin'}
                                                        >
                                                            admin
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={
                                                                'admin_approver'
                                                            }
                                                        >
                                                            admin_approver
                                                        </MenuItem>
                                                    </>
                                                ) : (
                                                    <>
                                                        <MenuItem
                                                            value={'user'}
                                                        >
                                                            user
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'admin'}
                                                        >
                                                            admin
                                                        </MenuItem>
                                                    </>
                                                )}
                                            </Select>
                                        </div>
                                        <label
                                            style={{
                                                color: 'black',
                                                fontSize: '18px',
                                                fontWeight: 'bolder',
                                            }}
                                            htmlFor="floating_role"
                                            className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            IAM-Policy
                                        </label>
                                    </div>
                                </>
                            );
                        })()}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add New User
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default UserIAM;
