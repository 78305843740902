import React from 'react';
import { useEffect, useState } from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import DD2 from '../../../../components/DD2';
import FlexRow from '../../../../components/layout/FlexRow';
import DateTimePickerInput from '../../../../components/DateTimePickerInput';
import PrimaryButton from '../../../../components/button/PrimaryButton';
import { useAxios } from '../../../../contexts/axios';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { BACKEND_URL } from '../../../../configs';

import ElasticEventsTable from '../../../../components/costing/elastic-event-table';

import { useSearchParams } from 'react-router-dom';

const oldElasticClusterPerHour = {
    'es-production-jobs': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-jobs-follower': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-production-search': {
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.2992,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 4.5984,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 9.1968,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 18.8268,
    },
    'es-production-users': {
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
        '512 GB storage | 64 GB RAM | Up to 15.1 vCPU': 6.1934,
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU': 12.3246,
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU': 18.8268,
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU': 24.958,
    },
    'es-staging': {
        '60 GB storage | 2 GB RAM | Up to 2.4 vCPU': 0.6148,
        '120 GB storage | 4 GB RAM | Up to 2.4 vCPU': 0.9016,
        '240 GB storage | 8 GB RAM | Up to 2.4 vCPU': 1.4752,
        '480 GB storage | 16 GB RAM | Up to 2.4 vCPU': 2.6224,
        '960 GB storage | 32 GB RAM | Up to 4.9 vCPU': 4.9168,
    },
    'production-job-search': {
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2538,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.4454,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 0.8286,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 1.595,
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU': 3.1278,
    },
    'production-suggester': {
        '8 GB storage | 1 GB RAM | Up to 3.7 vCPU': 0.1437,
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU': 0.2874,
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU': 0.5748,
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU': 1.1496,
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU': 2.299,
    },
};
const elasticCostPerHour = {
    'gcp.es.datahot.n2.68x32x45': {
        'zone 1': [
            0.0622, 0.1244, 0.2488, 0.4976, 0.9952, 1.9904, 3.9808, 7.9616,
            11.9424, 15.9232,
        ],
        'zone 2': [
            0.1866, 0.311, 0.5598, 1.0574, 2.0526, 4.043, 8.0238, 15.9854,
            24.6312, 32.5928,
        ],
        'zone 3': [
            0.1866, 0.3732, 0.7464, 1.4928, 2.9856, 5.9712, 11.9424, 24.6312,
            36.5736, 48.516,
        ],
    },
    'gcp.es.datahot.n2.68x10x45': {
        'zone 1': [
            0.0466, 0.092, 0.184, 0.368, 0.736, 1.472, 2.944, 5.888, 8.832,
            11.776,
        ],
        'zone 2': [
            0.1542, 0.2462, 0.4302, 0.7982, 1.5342, 3.0062, 5.9502, 11.8382,
            18.4104, 24.2984,
        ],
        'zone 3': [
            0.138, 0.276, 0.552, 1.104, 2.208, 4.416, 8.832, 18.4104, 27.2424,
            36.0744,
        ],
    },
    'gcp.es.datahot.n2.68x16x45': {
        'zone 1': [
            0.0503, 0.1006, 0.2012, 0.4024, 0.8048, 1.6096, 3.2192, 6.4384,
            9.6576, 12.8768,
        ],
        'zone 2': [
            0.1628, 0.2634, 0.4646, 0.867, 1.6718, 3.2814, 6.5006, 12.939,
            20.0616, 26.5,
        ],
        'zone 3': [
            0.1509, 0.3018, 0.6036, 1.2072, 2.4144, 4.8288, 9.6576, 20.0616,
            29.7192, 39.3768,
        ],
    },
    'gcp.es.datahot.n2.68x10x95': {
        'zone 1': [
            0.0479, 0.0958, 0.1916, 0.3832, 0.7664, 1.5328, 3.0656, 6.1312,
            9.1968, 12.2624,
        ],
        'zone 2': [
            0.158, 0.2538, 0.4454, 0.8286, 1.595, 3.1278, 6.1934, 12.3246,
            19.14, 25.2712,
        ],
        'zone 3': [
            0.1437, 0.2874, 0.5748, 1.1496, 2.2992, 4.5984, 9.1968, 19.14,
            28.3368, 37.5336,
        ],
    },
    'gcp.data.highcpu.1': {
        'zone 1': [
            0.0479, 0.0958, 0.1916, 0.3832, 0.7664, 1.5328, 3.0656, 6.1312,
            9.1968, 12.2624,
        ],
        'zone 2': [
            0.158, 0.2538, 0.4454, 0.8286, 1.595, 3.1278, 6.1934, 12.3246,
            18.8268, 24.958,
        ],
        'zone 3': [
            0.1437, 0.2874, 0.5748, 1.1496, 2.2992, 4.5984, 9.1968, 18.8268,
            28.0236, 37.2204,
        ],
    },
    'gcp.data.highio.1': {
        'zone 1': [
            0.3758, 0.4236, 0.5192, 0.7104, 1.0928, 1.8576, 3.3872, 6.4464,
            9.5056, 12.5648,
        ],
        'zone 2': [
            0.4858, 0.5814, 0.7726, 1.155, 1.9198, 3.4494, 6.5086, 12.627,
            19.1164, 25.2348,
        ],
        'zone 3': [
            0.4714, 0.6148, 0.9016, 1.4752, 2.6224, 4.9168, 9.5056, 19.1164,
            28.294, 37.4716,
        ],
    },
};

const distributionsIndex = {
    '1 GB RAM': 0,
    '2 GB RAM': 1,
    '4 GB RAM': 2,
    '8 GB RAM': 3,
    '16 GB RAM': 4,
    '32 GB RAM': 5,
    '64 GB RAM': 6,
    '128 GB RAM': 7,
    '192 GB RAM': 8,
    '256 GB RAM': 9,
};

const elasticConfigs = {
    'gcp.es.datahot.n2.68x32x45': [
        '45 GB storage | 1 GB RAM | Up to 8 vCPU',
        '90 GB storage | 2 GB RAM | Up to 8 vCPU',
        '180 GB storage | 4 GB RAM | Up to 8 vCPU',
        '360 GB storage | 8 GB RAM | Up to 8 vCPU',
        '720 GB storage | 16 GB RAM | Up to 8 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 16 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 32 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 64 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 96 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 128 vCPU',
    ],
    'gcp.es.datahot.n2.68x10x45': [
        '45 GB storage | 1 GB RAM | Up to 2.5 vCPU',
        '90 GB storage | 2 GB RAM | Up to 2.5 vCPU',
        '180 GB storage | 4 GB RAM | Up to 2.5 vCPU',
        '360 GB storage | 8 GB RAM | Up to 2.5 vCPU',
        '720 GB storage | 16 GB RAM | Up to 2.5 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 5 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 10 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 20 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 30 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 40 vCPU',
    ],
    'gcp.es.datahot.n2.68x16x45': [
        '45 GB storage | 1 GB RAM | Up to 4 vCPU',
        '90 GB storage | 2 GB RAM | Up to 4 vCPU',
        '180 GB storage | 4 GB RAM | Up to 4 vCPU',
        '360 GB storage | 8 GB RAM | Up to 4 vCPU',
        '720 GB storage | 16 GB RAM | Up to 4 vCPU',
        '1.41 TB storage | 32 GB RAM | Up to 8 vCPU',
        '2.81 TB storage | 64 GB RAM | Up to 16 vCPU',
        '5.63 TB storage | 128 GB RAM | Up to 32 vCPU',
        '8.44 TB storage | 192 GB RAM | Up to 48 vCPU',
        '11.25 TB storage | 256 GB RAM | Up to 64 vCPU',
    ],
    'gcp.es.datahot.n2.68x10x95': [
        '95 GB storage | 1 GB RAM | Up to 2.5 vCPU',
        '190 GB storage | 2 GB RAM | Up to 2.5 vCPU',
        '380 GB storage | 4 GB RAM | Up to 2.5 vCPU',
        '760 GB storage | 8 GB RAM | Up to 2.5 vCPU',
        '1.48 TB storage | 16 GB RAM | Up to 2.5 vCPU',
        '2.97 TB storage | 32 GB RAM | Up to 5 vCPU',
        '5.94 TB storage | 64 GB RAM | Up to 10 vCPU',
        '11.88 TB storage | 128 GB RAM | Up to 20 vCPU',
        '17.81 TB storage | 192 GB RAM | Up to 30 vCPU',
        '23.75 TB storage | 256 GB RAM | Up to 40 vCPU',
    ],
    'gcp.data.highcpu.1': [
        '8 GB storage | 1 GB RAM | Up to 3.7 vCPU',
        '16 GB storage | 2 GB RAM | Up to 3.7 vCPU',
        '32 GB storage | 4 GB RAM | Up to 3.7 vCPU',
        '64 GB storage | 8 GB RAM | Up to 3.7 vCPU',
        '128 GB storage | 16 GB RAM | Up to 3.7 vCPU',
        '256 GB storage | 32 GB RAM | Up to 7.5 vCPU',
        '512 TB storage | 64 GB RAM | Up to 15.1 vCPU',
        '1 TB storage | 128 GB RAM | Up to 30.3 vCPU',
        '1.5 TB storage | 192 GB RAM | Up to 45.5 vCPU',
        '2 TB storage | 256 GB RAM | Up to 60.6 vCPU',
    ],
    'gcp.data.highio.1': [
        '30 GB storage | 1 GB RAM | Up to 2.4 vCPU',
        '60 GB storage | 2 GB RAM | Up to 2.4 vCPU',
        '120 GB storage | 4 GB RAM | Up to 2.4 vCPU',
        '240 GB storage | 8 GB RAM | Up to 2.4 vCPU',
        '480 GB storage | 16 GB RAM | Up to 2.4 vCPU',
        '960 GB storage | 32 GB RAM | Up to 4.9 vCPU',
        '512 TB storage | 64 GB RAM | Up to 9.8 vCPU',
        '1.88 TB storage | 128 GB RAM | Up to 19.7 vCPU',
        '3.75 TB storage | 192 GB RAM | Up to 29.5 vCPU',
        '5.63 TB storage | 256 GB RAM | Up to 39.4 vCPU',
    ],
};

function getValue(value) {
    var result = value / 1024;
    result = result.toString() + ' GB RAM';
    return result;
}

const ElasticEvents = () => {
    var dateObj = new Date();
    //const [searchParams, setSearchParams] = useSearchParams();
    const [clusterEvents, setClusterEvents] = useState([]);
    const [clustersLoading, setClustersLoading] = useState(true);
    const [cluster, setCluster] = useState(null);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState('false');
    const [clusters, setClusters] = useState([]);
    const authAxios = useAxios();

    const getElasticClusterList = async () => {
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/elastic/deployments-metadata`
            );
            var clusterMetadata = [{ name: 'All Cluster', id: 'all_cluster' }];
            for (var i = 0; i < data.length; i++) {
                clusterMetadata.push({
                    name: data[i]['deployment_name'],
                    id: data[i]['deployment_id'],
                });
            }
            setClusters(clusterMetadata);
            setClustersLoading(false);
        } catch (e) {
            console.error(e);
        }
    };
    useEffect(() => {
        getElasticClusterList();
    }, []);

    const isInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };
    const getClusterEvents = async () => {
        if (!isInputValid() || loading) {
            return;
        }

        setOk('load');
        setLoading(true);
        try {
            const params = {
                deployment_id:
                    cluster?.id === 'all_cluster' ? null : cluster?.id,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            };
            const { data } = await authAxios.get(`/elastic/events`, { params });
            var metadata = data.data;
            var events = [];
            for (var i = 0; i < metadata.length; i++) {
                var dataPoint = {};
                var clusterName = metadata[i]['deployment_name'];
                dataPoint['deployment_id'] = metadata[i]['deployment_id'];
                dataPoint['deployment_name'] = clusterName;
                dataPoint['event_type'] = '';
                dataPoint['old_config'] = '';
                dataPoint['new_config'] = '';
                dataPoint['cost'] = 0;
                dataPoint['created_at'] = metadata[i]['created_at'];
                if (Object.keys(metadata[i]['old_config']).length === 0) {
                    dataPoint['event_type'] = 'CREATED';
                    dataPoint['old_config'] = 'NA';
                    var memory = getValue(metadata[i]['new_config']['memory']);

                    var config_ids = Object.keys(elasticConfigs);
                    var config_id =
                        metadata[i]['new_config']['instance_configuration_id'];
                    //console.log(metadata[i]["new_config"]['memory']);
                    var memory = getValue(metadata[i]['new_config']['memory']);
                    var zone =
                        'zone ' + metadata[i]['new_config']['zone'].toString();
                    if (config_ids.includes(config_id)) {
                        var configs = elasticConfigs[config_id];
                        for (var j = 0; j < configs.length; j++) {
                            if (configs[j].includes(memory)) {
                                dataPoint['new_config'] = configs[j];
                                dataPoint['cost'] =
                                    elasticCostPerHour[config_id][zone][
                                        distributionsIndex[memory]
                                    ];
                                break;
                            }
                        }
                    }
                } else if (
                    Object.keys(metadata[i]['new_config']).length === 0
                ) {
                    dataPoint['event_type'] = 'DELETED';
                    dataPoint['new_config'] = 'NA';
                    var memory = getValue(metadata[i]['old_config']['memory']);

                    var config_ids = Object.keys(elasticConfigs);
                    var config_id =
                        metadata[i]['old_config']['instance_configuration_id'];
                    var memory = getValue(metadata[i]['old_config']['memory']);
                    var zone =
                        'zone ' + metadata[i]['old_config']['zone'].toString();
                    if (config_ids.includes(config_id)) {
                        var configs = elasticConfigs[config_id];
                        for (var j = 0; j < configs.length; j++) {
                            if (configs[j].includes(memory)) {
                                dataPoint['old_config'] = configs[j];
                                dataPoint['cost'] =
                                    elasticCostPerHour[config_id][zone][
                                        distributionsIndex[memory]
                                    ];
                                break;
                            }
                        }
                    }
                } else {
                    if (
                        metadata[i]['old_config']['memory'] >
                        metadata[i]['new_config']['memory']
                    ) {
                        dataPoint['event_type'] = 'SCALE-DOWN';
                    } else {
                        dataPoint['event_type'] = 'SCALE-UP';
                    }
                    var memoryOld = getValue(
                        metadata[i]['old_config']['memory']
                    );
                    var memoryNew = getValue(
                        metadata[i]['new_config']['memory']
                    );
                    //console.log(memoryOld,memoryNew);
                    var oldCost = 0;
                    var newCost = 0;

                    var config_ids = Object.keys(elasticConfigs);
                    var config_idOld =
                        metadata[i]['old_config']['instance_configuration_id'];
                    var memoryOld = getValue(
                        metadata[i]['old_config']['memory']
                    );
                    //console.log(metadata[i]["old_config"]['zone']);
                    var zoneOld =
                        'zone ' + metadata[i]['old_config']['zone']?.toString();
                    var config_idNew =
                        metadata[i]['new_config']['instance_configuration_id'];
                    var memoryNew = getValue(
                        metadata[i]['new_config']['memory']
                    );
                    var zoneNew =
                        'zone ' + metadata[i]['new_config']['zone']?.toString();
                    if (config_ids.includes(config_idOld)) {
                        var configs = elasticConfigs[config_idOld];
                        for (var j = 0; j < configs.length; j++) {
                            if (configs[j].includes(memoryOld)) {
                                dataPoint['old_config'] = configs[j];
                                oldCost =
                                    elasticCostPerHour[config_idOld][zoneOld][
                                        distributionsIndex[memoryOld]
                                    ];
                                break;
                            }
                        }
                    }
                    if (config_ids.includes(config_idNew)) {
                        var configs = elasticConfigs[config_idNew];
                        for (var j = 0; j < configs.length; j++) {
                            if (configs[j].includes(memoryNew)) {
                                dataPoint['new_config'] = configs[j];
                                newCost =
                                    elasticCostPerHour[config_idNew][zoneNew][
                                        distributionsIndex[memoryNew]
                                    ];
                                break;
                            }
                        }
                    }
                    dataPoint['cost'] = Math.abs(newCost - oldCost);
                }
                events.push(dataPoint);
            }
            setClusterEvents(events);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            setOk('true');
        }
    };

    //console.log(clusterEvents);

    /*useEffect(() => {
        const cluster_id = searchParams.get('cluster_id');
        if (!!cluster_id) {
            if (clusters) {
                for (const c of clusters) {
                    if (c.id === cluster_id) {
                        setCluster(c);
                    }
                }
            } else {
                setCluster({ id: cluster_id });
            }
        }
    }, [searchParams, clusters, cluster]);*/

    useEffect(() => {
        setClusterEvents([]);
        setOk('false');
    }, [startDate, endDate, cluster]);

    return (
        <>
            <HeaderTitle
                title={'Elactic Events'}
                subtitle={
                    'Track Scaling up and Scaling down of Elastic Clusters'
                }
            />
            <FlexRow>
                <DD2
                    label={'Elastic Cluster'}
                    emptyLabel={'All Cluster'}
                    options={clusters}
                    value={cluster}
                    onChange={setCluster}
                    /*onChange={(item) => {
                        setSearchParams({
                            ...searchParams,
                            cluster_id: item.id,
                        });
                    }}*/
                    displayProperty={'name'}
                    idProperty={'id'}
                    disabled={false}
                    isLoading={clustersLoading}
                ></DD2>
            </FlexRow>
            <FlexRow>
                <DateTimePickerInput
                    label={'Start Date'}
                    value={startDate}
                    onChange={setStartDate}
                    maxDate={endDate}
                />
                <DateTimePickerInput
                    label={'End Date'}
                    value={endDate}
                    onChange={setEndDate}
                    maxDate={dateObj}
                />
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <div className="mt-8 mb-8">
                <PrimaryButton
                    title={'$ Get Elastic Events'}
                    onClick={getClusterEvents}
                />
            </div>

            {(() => {
                switch (ok) {
                    case 'false':
                        return <></>;
                    case 'load':
                        return <LinearProgress />;
                    case 'true':
                        return (
                            <ElasticEventsTable
                                data={clusterEvents}
                                loading={loading}
                            />
                        );
                }
            })()}
        </>
    );
};

export default ElasticEvents;
