import ReactApexChart from 'react-apexcharts';
import UppercaseFirstLetter from '../costing/cosmetic_functions';

export default function OvertimeDescription({ data, groupBy, groupByName, periodicity,totalCost }) {
    var key = [];
    if (periodicity === 'weekly') {
        var temp = Object.keys(data[0]);
        temp.shift();
        for (var i = 0; i < temp.length; i++) {
            key.push(temp[i].split(' ')[0]);
        }
    } else {
        key = Object.keys(data[0]);
        key.shift();
    }
    var series = [];
    var options = {};
    if (periodicity === 'daily' || groupBy === 'cluster') {
        series = [{ name: groupByName==="Dashboard (Count)"||groupByName==="Question (Count)"?'Count':'Cost', data: totalCost }];
        options = {
            chart: {
                type: 'area',
                height: 'auto',
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            title: {
                text:
                    'Total Cost vs Time',
            },
            xaxis: {
                categories: key,
            },
            yaxis: {
                title: {
                    text: `Total ${groupByName==="Dashboard (Count)"||groupByName==="Question (Count)"?"count":"cost"} on ${periodicity} basis`,
                },
                min:0,
                labels: {
                    formatter: function (val) {
                        return groupByName==="Dashboard (Count)"||groupByName==="Question (Count)"?val.toFixed(0):val.toFixed(2) + '  $';
                    },
                },
            },
        };
    } else {
        for (var i = 0; i < data.length; i++) {
            var dict = {};
            dict['name'] = data[i][groupBy];
            var values = Object.values(data[i]);
            values.shift();
            var temp = [];
            for (var j = 0; j < values.length; j++) {
                temp.push(values[j].toFixed(2));
            }
            dict['data'] = temp;
            series.push(dict);
        }

        options = {
            chart: {
                type: 'bar',
                height: 'auto',
                stacked: true,
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        total: {
                            enabled: true,

                            formatter: function (val) {
                                return val>1000?(val/1000).toFixed(2)+' k$':val+' $';
                            },

                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                            },
                        },
                        enabled: false,
                    },
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff'],
            },
            title: {
                text:
                    `Total ${groupByName==="Dashboard (Count)"||groupByName==="Question (Count)"?"count":"cost"} distributed by ${UppercaseFirstLetter(groupBy)} vs Time`,
            },
            xaxis: {
                categories: key,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(2) + '  $';
                    },
                },
            },
            yaxis: {
                title: {
                    text: `Total ${groupByName==="Dashboard (Count)"||groupByName==="Question (Count)"?"count":"cost"} on ${periodicity} basis`,
                },
                min:0
            },
            fill: {
                opacity: 4,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40,
            },
        };
    }

    return (
        <div>
            {periodicity === 'daily' || groupBy === 'cluster' ? (
                <ReactApexChart
                    options={options}
                    series={series}
                    type="area"
                    width={1300}
                />
            ) : (
                <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    height="auto"
                    width={1300}
                />
            )}
        </div>
    );
}
