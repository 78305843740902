import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SECURITY_BACKEND_URL } from '../../configs';
import { useAxios } from '../../contexts/axios';
import { Box, Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import AutomationTab from './controlPanelTab';
import TextInput from '../TextInput';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function Automation() {
    const nav = useNavigate();
    const axios = useAxios();
    const [loading, setLoading] = useState(false);
    const [loadingChild, setLoadingChild] = useState(false);
    const [data, setData] = useState([]);
    const [value, setValue] = useState(-1);
    const [value1, setValue1] = useState(-1);
    const [open, setOpen] = useState(false);
    const [openChild, setOpenChild] = useState(false);
    const [newTabName, setNewTabName] = useState(null);
    const [triggerNewtab, setTriggerNewtab] = useState(false);
    //const [newChildTabName, setNewChildTabName] = useState(null);
    const [tabNames, setTabNames] = useState([]);
    const [childTabNames, setChildTabNames] = useState([]);

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${SECURITY_BACKEND_URL}/automation`,
        };
        setLoading(true);
        axios(requestOptions).then(function (res) {
            setTabNames(res.data);
            setValue(0);
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (tabNames?.length > 0 || triggerNewtab) {
            const requestOptions = {
                method: 'GET',
                url: `${SECURITY_BACKEND_URL}/automation-entries?automation=${tabNames[value]?.value}`,
            };
            setLoadingChild(true);
            axios(requestOptions).then(function (res) {
                setData(res.data);
                var tabs = [];
                for (var i = 0; i < res.data.length; i++) {
                    tabs.push({ value: res.data[i].name });
                }
                setChildTabNames(tabs);
                setValue1(0);
                setTriggerNewtab(false);
                setLoadingChild(false);
            });
        }
    }, [value, triggerNewtab]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChange1 = (event, newValue) => {
        setValue1(newValue);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenChild(false);
    };

    const handleAdd = (event) => {
        var tabsValues = [];
        for (var i = 0; i < tabNames.length; i++) {
            tabsValues.push(tabNames[i]?.value);
        }
        if (tabsValues.includes(newTabName)) {
            alert('Enter unique Tab name');
            setNewTabName(null);
            return;
        }
        setTabNames([...tabNames, { value: newTabName }]);
        const requestOptions = {
            method: 'POST',
            url: `${SECURITY_BACKEND_URL}/automation`,
            data: { value: newTabName },
        };
        setLoading(true);
        axios(requestOptions).then(function (res) {
            setLoading(false);
        });
        handleClose();
        setNewTabName(null);
    };

    const handleRemove = (index) => {
        if (!window.confirm(`Are you sure you want to delete this tab`)) {
            return;
        }
        const requestOptions = {
            method: 'DELETE',
            url: `${SECURITY_BACKEND_URL}/automation`,
            data: { value: tabNames[index]?.value },
        };
        setLoading(true);
        axios(requestOptions).then(function (res) {
            setLoading(false);
        });
        const newdata = [...tabNames];
        newdata.splice(index, 1);
        setTabNames(newdata);
    };

    const handleAddChild = (event) => {
        var tabsValues = [];
        for (var i = 0; i < childTabNames.length; i++) {
            tabsValues.push(childTabNames[i]?.value);
        }
        if (tabsValues.includes(newTabName)) {
            alert('Enter unique Tab name');
            setNewTabName(null);
            return;
        }
        setChildTabNames([...childTabNames, { value: newTabName }]);
        var val = {};
        val['automation'] = tabNames[value]?.value;
        val['name'] = newTabName;
        val['description'] = '';
        val['request'] = '';
        val['url'] = '';
        val['data'] = [];
        val['header'] = [];
        //console.log(tableData);
        const requestOptions = {
            method: 'POST',
            url: `${SECURITY_BACKEND_URL}/automation-entries`,
            data: val,
        };
        setLoadingChild(true);
        axios(requestOptions).then(function (res) {
            setLoadingChild(false);
        });
        handleClose();
        setNewTabName(null);
    };

    const handleRemoveChild = (index) => {
        if (!window.confirm(`Are you sure you want to delete this tab`)) {
            return;
        }
        const requestOptions = {
            method: 'DELETE',
            url: `${SECURITY_BACKEND_URL}/automation-entries`,
            data: data[index],
        };
        setLoadingChild(true);
        axios(requestOptions).then(function (res) {
            setLoadingChild(false);
        });
        const newdata = [...childTabNames];
        newdata.splice(index, 1);
        setChildTabNames(newdata);
    };

    return (
        <>
            {loading ? (
                <Box sx={{ marginTop: '30vh', marginLeft: '45vw' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                        }}
                    >
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons={true}
                            >
                                {tabNames.map(function (object, i) {
                                    return (
                                        <Tab
                                            key={i}
                                            wrapped={true}
                                            style={{ textTransform: 'none' }}
                                            label={object.value}
                                            icon={
                                                <div className="flex flex-inline">
                                                    <span
                                                        style={{
                                                            marginLeft: '100px',
                                                        }}
                                                    >
                                                        <IconButton
                                                            onClick={() => {
                                                                handleRemove(i);
                                                            }}
                                                            aria-label="delete"
                                                            size="small"
                                                        >
                                                            <CloseIcon
                                                                color="error"
                                                                fontSize="inherit"
                                                            />
                                                        </IconButton>
                                                    </span>
                                                    <span
                                                        style={{
                                                            marginRight:
                                                                '-15px',
                                                        }}
                                                    >
                                                        <Divider
                                                            variant="fullWidth"
                                                            orientation="vertical"
                                                        />
                                                    </span>
                                                </div>
                                            }
                                            iconPosition="end"
                                            {...a11yProps(i)}
                                        />
                                    );
                                })}
                                <Tab
                                    key={tabNames.length + 1}
                                    style={{ textTransform: 'none' }}
                                    wrapped={true}
                                    icon={
                                        <>
                                            <IconButton
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                                aria-label="delete"
                                                size="medium"
                                            >
                                                <AddIcon
                                                    color="primary"
                                                    fontSize="inherit"
                                                />
                                            </IconButton>
                                        </>
                                    }
                                    disableFocusRipple={true}
                                />
                            </Tabs>
                        </Box>
                        {tabNames.map(function (object, i) {
                            return (
                                <TabPanel value={value} index={i}>
                                    {loadingChild ? (
                                        <Box sx={{ marginTop: '30vh' }}>
                                            <LinearProgress />
                                        </Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                width: '100%',
                                                marginTop: '30px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    borderBottom: 1,
                                                    borderColor: 'divider',
                                                }}
                                            >
                                                <Tabs
                                                    value={value1}
                                                    onChange={handleChange1}
                                                    aria-label="basic tabs example"
                                                    variant="scrollable"
                                                    scrollButtons={true}
                                                >
                                                    {childTabNames.map(
                                                        function (object1, i) {
                                                            return (
                                                                <Tab
                                                                    key={i}
                                                                    wrapped={
                                                                        true
                                                                    }
                                                                    style={{
                                                                        textTransform:
                                                                            'none',
                                                                    }}
                                                                    label={
                                                                        object1.value
                                                                    }
                                                                    icon={
                                                                        <div className="flex flex-inline">
                                                                            <span
                                                                                style={{
                                                                                    marginLeft:
                                                                                        '100px',
                                                                                }}
                                                                            >
                                                                                <IconButton
                                                                                    onClick={() => {
                                                                                        handleRemoveChild(
                                                                                            i
                                                                                        );
                                                                                    }}
                                                                                    aria-label="delete"
                                                                                    size="small"
                                                                                >
                                                                                    <CloseIcon
                                                                                        color="error"
                                                                                        fontSize="inherit"
                                                                                    />
                                                                                </IconButton>
                                                                            </span>
                                                                            <span
                                                                                style={{
                                                                                    marginRight:
                                                                                        '-15px',
                                                                                }}
                                                                            >
                                                                                <Divider
                                                                                    variant="fullWidth"
                                                                                    orientation="vertical"
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                    iconPosition="end"
                                                                    {...a11yProps(
                                                                        i
                                                                    )}
                                                                />
                                                            );
                                                        }
                                                    )}
                                                    <Tab
                                                        key={
                                                            childTabNames.length +
                                                            1
                                                        }
                                                        style={{
                                                            textTransform:
                                                                'none',
                                                        }}
                                                        wrapped={true}
                                                        icon={
                                                            <IconButton
                                                                onClick={() => {
                                                                    setOpenChild(
                                                                        true
                                                                    );
                                                                }}
                                                                aria-label="delete"
                                                                size="medium"
                                                            >
                                                                <AddIcon
                                                                    color="primary"
                                                                    fontSize="inherit"
                                                                />
                                                            </IconButton>
                                                        }
                                                        disableFocusRipple={
                                                            true
                                                        }
                                                    />
                                                </Tabs>
                                            </Box>
                                            {childTabNames.map(function (
                                                object1,
                                                i
                                            ) {
                                                return (
                                                    <TabPanel
                                                        value={value1}
                                                        index={i}
                                                    >
                                                        <AutomationTab
                                                            data={data[i]}
                                                            automation={object}
                                                            name={object1}
                                                            setLoadingChild={
                                                                setLoadingChild
                                                            }
                                                            setTriggerNewtab={
                                                                setTriggerNewtab
                                                            }
                                                        />
                                                    </TabPanel>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </TabPanel>
                            );
                        })}
                    </Box>
                    <Modal
                        open={open || openChild}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div style={{ padding: 15 }}>
                                <TextInput
                                    label={'New Tab Name'}
                                    value={newTabName}
                                    setId={setNewTabName}
                                />
                                <Button
                                    variant="contained"
                                    style={{ marginTop: '10px' }}
                                    onClick={open ? handleAdd : handleAddChild}
                                >
                                    Add New Tab
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                </>
            )}
        </>
    );
}
