import { Popover, Transition } from '@headlessui/react';
//import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Fragment, useState, useEffect } from 'react';
import { ACCESS_BACKEND_URL } from '../../configs';
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../contexts/axios';
import './bell.css';
export default function Notification() {
    const axios = useAxios();
    const [solutions, setsolutions] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/notifications`,
        };
        axios(requestOptions).then(function (res) {
            setsolutions(res.data);
        });
    }, []);
    return (
        <Popover className="relative mr-[25px]">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`
                    ${open ? '' : 'text-opacity-90'}
                    group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-200`}
                    >
                        <span class="relative inline-block">
                            <button class="highlight">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    fill="currentColor"
                                    class={`mt-2 bi bi-bell-fill ${
                                        solutions.length > 0 ? 'bell' : ''
                                    }`}
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                                </svg>
                                <span class="absolute top-1 right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                                    {solutions.length}
                                </span>
                            </button>
                        </span>
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel
                            style={{ zIndex: 100 }}
                            className="absolute mt-1 bg-white left-[40px] z-10 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-1xl"
                        >
                            <div
                                style={{ height: '380px' }}
                                className="overflow-scroll rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                            >
                                <div className="relative grid gap-8 p-7 lg:grid-cols-1">
                                    {solutions.map((item) => (
                                        <a
                                            key={item.heading}
                                            className="-m-3 highlight flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                        >
                                            <div className="flex h-10 w-10 shrink-0 items-center justify-center text-white sm:h-12 sm:w-12">
                                                <Svg image={item} />
                                            </div>
                                            <div className="ml-4">
                                                <p className="text-sm font-medium text-gray-900">
                                                    {item.heading}
                                                </p>
                                                <p className="text-sm text-gray-500">
                                                    {item.body}
                                                </p>
                                            </div>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}

function IconOne() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="orange"
            class="w-9 h-9"
        >
            <path
                fill-rule="evenodd"
                d="M12 2.25a.75.75 0 01.75.75v.756a49.106 49.106 0 019.152 1 .75.75 0 01-.152 1.485h-1.918l2.474 10.124a.75.75 0 01-.375.84A6.723 6.723 0 0118.75 18a6.723 6.723 0 01-3.181-.795.75.75 0 01-.375-.84l2.474-10.124H12.75v13.28c1.293.076 2.534.343 3.697.776a.75.75 0 01-.262 1.453h-8.37a.75.75 0 01-.262-1.453c1.162-.433 2.404-.7 3.697-.775V6.24H6.332l2.474 10.124a.75.75 0 01-.375.84A6.723 6.723 0 015.25 18a6.723 6.723 0 01-3.181-.795.75.75 0 01-.375-.84L4.168 6.241H2.25a.75.75 0 01-.152-1.485 49.105 49.105 0 019.152-1V3a.75.75 0 01.75-.75zm4.878 13.543l1.872-7.662 1.872 7.662h-3.744zm-9.756 0L5.25 8.131l-1.872 7.662h3.744z"
                clip-rule="evenodd"
            />
        </svg>
    );
}

function IconTwo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="green"
            class="w-9 h-9"
        >
            <path
                fill-rule="evenodd"
                d="M9.315 7.584C12.195 3.883 16.695 1.5 21.75 1.5a.75.75 0 01.75.75c0 5.056-2.383 9.555-6.084 12.436A6.75 6.75 0 019.75 22.5a.75.75 0 01-.75-.75v-4.131A15.838 15.838 0 016.382 15H2.25a.75.75 0 01-.75-.75 6.75 6.75 0 017.815-6.666zM15 6.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z"
                clip-rule="evenodd"
            />
            <path d="M5.26 17.242a.75.75 0 10-.897-1.203 5.243 5.243 0 00-2.05 5.022.75.75 0 00.625.627 5.243 5.243 0 005.022-2.051.75.75 0 10-1.202-.897 3.744 3.744 0 01-3.008 1.51c0-1.23.592-2.323 1.51-3.008z" />
        </svg>
    );
}

function IconThree() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="red"
            class="w-9 h-9"
        >
            <path d="M15.73 5.25h1.035A7.465 7.465 0 0118 9.375a7.465 7.465 0 01-1.235 4.125h-.148c-.806 0-1.534.446-2.031 1.08a9.04 9.04 0 01-2.861 2.4c-.723.384-1.35.956-1.653 1.715a4.498 4.498 0 00-.322 1.672V21a.75.75 0 01-.75.75 2.25 2.25 0 01-2.25-2.25c0-1.152.26-2.243.723-3.218C7.74 15.724 7.366 15 6.748 15H3.622c-1.026 0-1.945-.694-2.054-1.715A12.134 12.134 0 011.5 12c0-2.848.992-5.464 2.649-7.521.388-.482.987-.729 1.605-.729H9.77a4.5 4.5 0 011.423.23l3.114 1.04a4.5 4.5 0 001.423.23zM21.669 13.773c.536-1.362.831-2.845.831-4.398 0-1.22-.182-2.398-.52-3.507-.26-.85-1.084-1.368-1.973-1.368H19.1c-.445 0-.72.498-.523.898.591 1.2.924 2.55.924 3.977a8.959 8.959 0 01-1.302 4.666c-.245.403.028.959.5.959h1.053c.832 0 1.612-.453 1.918-1.227z" />
        </svg>
    );
}

function Svg(props) {
    const val = props.image;
    if (val.heading == 'Access Request') {
        if (val.status == 'approved') {
            return <IconTwo aria-hidden="true" />;
        } else if (val.status == 'pending') {
            return <IconOne aria-hidden="true" />;
        } else {
            return <IconThree aria-hidden="true" />;
        }
    }
}
