/*import React, { useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useAuth } from '../../contexts/auth';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { Delete, Edit } from '@mui/icons-material';

export default function MongoTagging() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isAdmin } = useAuth();
    function call(url, data, method) {
        const requestOptions = {
            url: `${BACKEND_URL}/mongo/something${url}`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/mongo/something` }).then(function (
            res
        ) {
            setTableData(res.data);
        });
    }, []);

    const handleCreateNewRow = (values) => {
        if (values.name.length <= 2) {
            alert('Please enter valid value, too few characters');
            return;
        }

        tableData.push(values);
        setTableData([...tableData]);
        call(
            '',
            {
                cluster_id: values.cluster_id,
                name: values.name,
                vertical: values.vertical,
                team: values.team,
                owner: values.owner,
                application: values.application,
            },
            'POST'
        );
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();

            call(
                '',
                {
                    cluster_id: values.cluster_id,
                    name: values.name,
                    vertical: values.vertical,
                    team: values.team,
                    owner: values.owner,
                    application: values.application,
                },
                'PUT'
            );
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue('name')}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            call(
                '',
                {
                    cluster_id: row.getValue('cluster_id'),
                    name: row.getValue('name'),
                    team: row.getValue('team'),
                    vertical: row.getValue('vertical'),
                    owner: row.getValue('owner'),
                    application: row.getValue('application'),
                },
                'DELETE'
            );
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                header: 'Project ID',
                accessorKey: 'projectId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Dataset ID',
                accessorKey: 'datasetId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Old Table ID',
                accessorKey: 'oldTableId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'New Table ID',
                accessorKey: 'newTableId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Owner',
                accessorKey: 'owner',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Last Usage Time',
                accessorKey: 'LastUsageTime',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'timeOfUpdate',
                accessorKey: 'Time of Update',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
            },
            {
                header: 'Estimated Per Day Cost',
                accessorKey: 'estimatedPerDayCost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
            {
                header: 'Response',
                accessorKey: 'response',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
            {
                header: 'Reason',
                accessorKey: 'reason',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
        ],
        [getCommonEditTextFieldProps]
    );
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal"
                enableColumnOrdering
                enableEditing
                enableStickyHeader
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions'],
                    },
                    density: 'compact',
                }}
                enableRowActions
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <KeyboardDoubleArrowLeftIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                disabled={!isAdmin()}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="success"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                            disabled={!isAdmin()}
                        >
                            Add New Cluster
                        </Button>
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
                onEditingRowSave={handleSaveRowEdits}
            />
            <AddNewUser
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
}*/

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useAuth } from '../../contexts/auth';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

export default function MongoTagging() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isAdmin } = useAuth();
    function call(url, data, method) {
        const requestOptions = {
            url: `${BACKEND_URL}/mongo/clusters-metadata${url}`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    useEffect(() => {
        axios({ url: `${BACKEND_URL}/mongo/clusters-metadata` }).then(function (
            res
        ) {
            setTableData(res.data);
        });
    }, []);

    const handleCreateNewRow = (values) => {
        if (values.name.length <= 2) {
            alert('Please enter valid value, too few characters');
            return;
        }

        tableData.push(values);
        setTableData([...tableData]);
        call(
            '',
            {
                cluster_id: values.cluster_id,
                name: values.name,
                vertical: values.vertical,
                team: values.team,
                owner: values.owner,
                application: values.application,
            },
            'POST'
        );
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();

            call(
                '',
                {
                    cluster_id: values.cluster_id,
                    name: values.name,
                    vertical: values.vertical,
                    team: values.team,
                    owner: values.owner,
                    application: values.application,
                },
                'PUT'
            );
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue('name')}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            call(
                '',
                {
                    cluster_id: row.getValue('cluster_id'),
                    name: row.getValue('name'),
                    team: row.getValue('team'),
                    vertical: row.getValue('vertical'),
                    owner: row.getValue('owner'),
                    application: row.getValue('application'),
                },
                'DELETE'
            );
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                header: 'Project ID',
                accessorKey: 'projectId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                accessorKey: 'cluster_id',
                header: 'CLUSTER ID',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Dataset ID',
                accessorKey: 'datasetId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                accessorKey: 'name',
                header: 'CLUSTER NAME',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Old Table ID',
                accessorKey: 'oldTableId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                accessorKey: 'team',
                header: 'TEAM',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'New Table ID',
                accessorKey: 'newTableId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                accessorKey: 'vertical',
                header: 'VERTICAL',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Owner',
                accessorKey: 'owner',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
                accessorKey: 'application',
                header: 'APPLICATION',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'Last Usage Time',
                accessorKey: 'LastUsageTime',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
                accessorKey: 'owner',
                header: 'OWNER',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                header: 'timeOfUpdate',
                accessorKey: 'Time of Update',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
            },
            {
                header: 'Estimated Per Day Cost',
                accessorKey: 'estimatedPerDayCost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
            {
                header: 'Response',
                accessorKey: 'response',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
            {
                header: 'Reason',
                accessorKey: 'reason',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
            {
                header: 'Delete Action',
                accessorKey: 'delete',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterVariant: 'range',
                filterFn: 'betweenInclusive',
            },
        ],
        [getCommonEditTextFieldProps]
    );
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal"
                enableColumnOrdering
                enableEditing
                enableStickyHeader
                enableStickyFooter
                enableDensityToggle={false}
                initialState={{
                    columnPinning: {
                        left: ['mrt-row-actions'],
                    },
                    density: 'compact',
                }}
                enableRowActions
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                disabled={!isAdmin()}
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="success"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained"
                            disabled={!isAdmin()}
                        >
                            Add New Cluster
                        </Button>
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
                onEditingRowSave={handleSaveRowEdits}
            />
            <AddNewUser
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
}

export const AddNewUser = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add Cluster Details</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {columns.map(function (object, i) {
                            return (
                                <TextField
                                    key={object.accessorKey}
                                    label={object.header}
                                    name={object.accessorKey}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            [e.target.name]: e.target.value,
                                        })
                                    }
                                />
                            );
                        })}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add New Cluster
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
