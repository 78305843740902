import React from 'react';
import { Column } from '@ant-design/plots';
//import { Circle } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//import { deepMix } from '@antv/util';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
export default function Graph({ data, handleDescription, project, service }) {
    const config = {
        data,
        height: 130,
        width: 200,
        isStack: true,
        xField: 'date',
        yField: 'cost',
        seriesField: 'type',
        xAxis: {
            offset:2,
            //range: [0, 1],
            //tickCount: 5,
        },
        legend: {
            marker: (type) => {
                const color =
                    type === 'posSpike'
                        ? '#F4664A'
                        : type === 'negSpike'
                        ? '#03ab41'
                        : '#1890ff';
                return {
                    style: {
                        fill: color,
                    },
                };
            },
            layout: 'horizontal',
            position: 'top'
        },
        tooltip: {
            showMarkers: false,
            enterable: true,
            domStyles: {
                'g2-tooltip': {
                    width: '150px',
                    padding: 0,
                },
            },
            customContent: (title, items) => {
                //console.log(items);
                return (
                    <Box sx={{ width: '100%'}}>
                        <Stack spacing={-1} padding={0.6}>
                            <div>
                                <Typography
                                    variant='caption'
                                    style={{ fontWeight: 'bold', marginLeft: '4px', marginBottom:'10px' }}
                                >
                                    {items[0]?.data?.date}
                                </Typography>
                            </div>
                            <div className="flex-inline">
                                <CircleIcon
                                    style={{
                                        fill: '#7ec2f3',
                                        fontSize: 'small',
                                    }}
                                />
                                <Typography
                                    variant='caption'
                                    style={{ fontWeight: 'bold', marginLeft: '4px' }}
                                >{`Cost ${
                                    (items[0]?.data?.cost +
                                    items[1]?.data?.cost +
                                    items[2]?.data?.cost).toFixed(2)
                                } $`}</Typography>
                            </div>
                            <div className="flex-inline">
                                <CircleIcon
                                    style={{
                                        fill: '#F4664A',
                                        fontSize: 'small',
                                    }}
                                />
                                <Typography
                                    variant='caption'
                                    style={{ fontWeight: 'bold', marginLeft: '4px' }}
                                >{`+ve spike ${items[1]?.data?.value.toFixed(2)} $`}</Typography>
                            </div>
                            <div className="flex-inline">
                                <CircleIcon
                                    style={{
                                        fill: '#03ab41',
                                        fontSize: 'small',
                                    }}
                                />
                                <Typography
                                    variant='caption'
                                    style={{ fontWeight: 'bold', marginLeft: '4px' }}
                                >{`-ve Spike ${items[2]?.data?.value.toFixed(2)} $`}</Typography>
                            </div>
                        </Stack>
                    </Box>
                );
            },
        },
        onReady: (plot) => {
            plot.on('interval:click', (e) => {
                //console.log(e.data.data)
                handleDescription([
                    { id: 'project', value: project },
                    { id: 'service', value: service },
                    { id: 'date', value: e.data.data.date },
                ]);
            });
        },
        columnStyle: ({ type }) => {
            //var color = get(spikeDates,date,0);
            //console.log(date,color);
            return {
                fill:
                    type === 'posSpike'
                        ? 'l(270) 0.5:#F4664A 1:#F4664A'
                        : type === 'negSpike'
                        ? 'l(270) 0.5:#03ab41 1:#03ab41'
                        : 'l(270) 0.5:#7ec2f3 1:#1890ff',
            };
        },
    };

    return <Column {...config} />;
}
