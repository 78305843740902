export default function VerticalDescription({ groupByValue, mappingData }) {
    var data = [];
    for (var i = 0; i < mappingData.length; i++) {
        if (mappingData[i]['vertical'] === groupByValue) {
            data.push([
                mappingData[i]['name'] || mappingData[i]['deployment_name'],
                mappingData[i]['owner'],
                mappingData[i]['team'],
            ]);
        }
    }
    return (
        <div
                style={{
                    marginLeft:'-150px',
                    minWidth: 'fit-content',
                    maxHeight: '300px',
                    maxWidth: 'auto',
                    height: 'fit-content',
                }}
                className="w-full block bg-white border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
                <h5 className="text-2xl p-2 text-center bg-slate-400 rounded-t-lg font-bold tracking-tight text-gray-900 dark:text-white">
                    Description
                </h5>
            {!!mappingData ? (
                <div
                    style={{ maxHeight: '200px', maxWidth: '550px'}}
                    className="overflow-x-scroll relative rounded-b-lg"
                >
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="sticky top-0 text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Name
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Owner Name
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Team Name
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(function (val, i) {
                                return (
                                    <tr
                                        key={i}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                    >
                                        <th
                                            scope="row"
                                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                        >
                                            {val[0]}
                                        </th>
                                        <td className="py-4 px-6">{val[1]}</td>
                                        <td className="py-4 px-6">{val[2]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
}
