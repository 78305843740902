import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState, useEffect } from 'react';
import { ACCESS_BACKEND_URL } from '../../configs';
import { useAxios } from '../../contexts/axios';
//import { BACKEND_URL } from '../configs'
import { useAuth } from '../../contexts/auth';
import { useNavigate } from 'react-router-dom';
import BackgroundLetterAvatars from '../../utils/avatar';
export default function Profile() {
    const nav = useNavigate();
    const { invalidateUser } = useAuth();
    const axios = useAxios();
    const [showModal, setShowModal] = useState(false);
    const [pos, setpos] = useState('');
    const [data, setdata] = useState({
        email: 'email',
        name: 'name user',
        profile: 'Please add one',
        team: 'Please add one',
        role: 'user',
        manageremail: 'Required for access request',
    });

    const handleposition = (e) => {
        setpos(e.target.value);
    };

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/profile?type=profile`,
        };
        axios(requestOptions).then(function (res) {
            setdata(res.data);
        });
    }, []);

    //console.log(data);
    //var profilename = data['name'].split(' ')[0];
    //console.log(data);
    return (
        <Popover className="relative mr-[100px]">
            {({ open }) => (
                <>
                    <Popover.Button
                        className={`
                ${open ? '' : 'text-opacity-90'}`}
                    >
                        <BackgroundLetterAvatars name={data['name']} />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute z-10 -translate-x-3/4 px-4 md:px-4 lg:max-w-3xl">
                            <div class="w-full justify-center">
                                <div
                                    style={{ marginTop: '14px' }}
                                    class="max-w-xs"
                                >
                                    <div class="bg-white shadow-xl rounded-lg py-3">
                                        <div class="p-2">
                                            <h3 class="text-center text-xl text-gray-900 font-medium leading-8">
                                                {data['name']}
                                            </h3>
                                            <div class="text-center text-gray-400 text-xs font-semibold">
                                                <div class="inline-flex">
                                                    <p>{data['profile']}</p>
                                                </div>
                                            </div>
                                            <table class="text-xs my-3">
                                                <tbody>
                                                    <tr>
                                                        <td class="px-2 py-2 text-gray-500 font-semibold">
                                                            Email
                                                        </td>
                                                        <td class="px-2 py-2 text-gray-800 font-semibold">
                                                            {data?.email}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-2 text-gray-500 font-semibold">
                                                            Team
                                                        </td>
                                                        <td class="px-2 py-2 text-gray-800 font-semibold">
                                                            {data?.team}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-2 text-gray-500 font-semibold">
                                                            Role
                                                        </td>
                                                        <td class="px-2 py-2 text-gray-800 font-semibold">
                                                            {data?.role}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="px-2 py-2 text-gray-500 font-semibold">
                                                            Manager Email
                                                        </td>
                                                        <td class="px-2 py-2 text-gray-800 font-semibold">
                                                            {data?.managerEmail}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <div class="text-center my-3">
                                                <a
                                                    onClick={invalidateUser}
                                                    class="text-xs text-indigo-500 italic hover:underline hover:text-indigo-600 font-medium"
                                                    href="#"
                                                >
                                                    Logout
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
}
