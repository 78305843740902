import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Alert from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FlexRow from '../layout/FlexRow';
import TagsInputList from '../tagAdder';
import { Box, Button, IconButton, Tooltip, Chip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import TextField from '@mui/material/TextField';

const BlacklistResetUser = () => {
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [buttonDisability, setButtonDisability] = useState(false);
    const [selected, setSelected] = useState([]);
    const [columnList, setColumnList] = useState([
        { label: 'User Id', id: 'user_id' },
    ]);
    const [columnName, setColumnName] = useState(columnList[0]);
    const [column, setColumn] = useState([]);
    const [loading, setLoading] = useState(false);
    const axios = useAxios();
    const [checked, setChecked] = useState(false);
    const [reason, setReason] = useState('');
    const [emails, setEmails] = useState([]);

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    function enterDetails() {
        var data = [];
        for (var i = 0; i < selected.length; i++) {
            data.push({ user_id: selected[i], reason: reason });
        }
        setTableData([...tableData, ...data]);
        setSelected([]);
        setReason('');
    }
    useEffect(() => {
        setColumn([
            {
                accessorKey: columnName?.id,
                header: columnName?.label,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'reason',
                header: 'Reason',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ]);
    }, [columnName]);

    function deleteToken() {
        if (tableData.length == 0) {
            alert('Please insert atleast one userId');
            return;
        }
        if (tableData.length > 100000) {
            alert('Cannot process more than 100000 data points');
            return;
        }
        setLoading(true);
        setButtonDisability(true);
        var data = { entities: [] };
        if (tableData?.length > 0) {
            var keys = Object.keys(tableData[0]);
            for (var i = 0; i < tableData?.length; i++) {
                data['entities'].push({
                    user_id: parseInt(tableData[i][keys[0]]),
                    reason: tableData[i][keys[1]],
                });
            }
        }
        console.log(data);
        const requestOptions = {
            url: `${BACKEND_URL}/gcp/manage-users/expire`,
            method: 'POST',
            data: data,
        };
        axios(requestOptions).then(function (res) {
            setTableData([]);
            setLoading(false)
            alert('User token is successfully expired')
        });
    }

    function blackListUser() {
        if (tableData.length == 0) {
            alert('Please insert atleast one userId');
            return;
        }
        if (tableData.length > 100000) {
            alert('Cannot process more than 100000 data points');
            return;
        }
        setLoading(true);
        setButtonDisability(true);
        var data = { entities: [] };

        if (tableData?.length > 0) {
            var keys = Object.keys(tableData[0]);
            for (var i = 0; i < tableData?.length; i++) {
                data['entities'].push({
                    user_id: parseInt(tableData[i][keys[0]]),
                    reason: tableData[i][keys[1]],
                });
            }
        }

        console.log(data);
        const requestOptions = {
            url: `${BACKEND_URL}/gcp/manage-users/blacklist`,
            method: 'POST',
            data: data,
        };
        axios(requestOptions).then(function (res) {
            setTableData([]);
            setLoading(false)
            alert('User is successfully blacklisted')
        });
    }

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        columnName?.id
                    )}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );

        const list = [];
        for (let i = 1; i < Math.min(dataStringLines.length, 100001); i++) {
            const row = dataStringLines[i].split(
                /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
            );
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"') d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter((x) => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setColumn(
            headers.map((c) => ({
                accessorKey: c,
                header: c,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            }))
        );
        setTableData(list);
    };

    // handle file upload
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    };

    const [csvOptions, setCsvOptions] = useState({});

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(tableData);
    };

    return (
        <>
            <FlexRow>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    disabled={buttonDisability}
                                    color="secondary"
                                    checked={checked}
                                    onChange={handleCheck}
                                    inputProps={{
                                        'aria-label': 'controlled',
                                    }}
                                />
                            }
                            label={
                                <Chip
                                    label={'UPLOAD CSV'}
                                    style={{ fontWeight: 'bold' }}
                                    color={
                                        buttonDisability ? 'default' : 'error'
                                    }
                                />
                            }
                            labelPlacement="start"
                        />
                    </FormGroup>
                </FormControl>
                <input
                    size={400}
                    disabled={buttonDisability || !checked}
                    type="file"
                    accept=".csv,.xlsx,.xls"
                    onChange={handleFileUpload}
                />
                <FlexRow></FlexRow>
            </FlexRow>
            <FlexRow>
                <TagsInputList
                    disabled={checked}
                    label={'Label'}
                    width={700}
                    height={'30px'}
                    selected={selected}
                    setSelected={setSelected}
                    placeHolder={`Enter ${columnName?.id}`}
                />
            </FlexRow>
            <FlexRow>
                <TextField
                    label={'Enter Reason'}
                    value={reason}
                    disabled={buttonDisability || checked}
                    sx={{ width: 700 }}
                    onChange={(e) => {
                        setReason(e.target.value);
                    }}
                />
                <FlexRow>
                    <div>
                        <Button
                            children={'Enter Details'}
                            sx={{
                                width: 200,
                                backgroundColor: '#0096FF',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            onClick={enterDetails}
                            disabled={buttonDisability || checked}
                        />
                    </div>
                </FlexRow>
                <FlexRow>
                    <div>
                        <Button
                            children={'Delete Token'}
                            sx={{
                                width: 200,
                                backgroundColor: '#ffa200',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            onClick={deleteToken}
                            disabled={buttonDisability}
                        />
                    </div>
                </FlexRow>
                <FlexRow>
                    <div>
                        <Button
                            children={'Blacklist User'}
                            sx={{
                                width: 200,
                                backgroundColor: '#ff3300',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            onClick={blackListUser}
                            disabled={buttonDisability}
                        />
                    </div>
                </FlexRow>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <Alert severity="warning">
                Don't upload csv containing more than 100000 data points.
            </Alert>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={column}
                data={tableData}
                editingMode="modal" //default
                enableColumnOrdering
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                state={{
                    isLoading: loading,
                }}
                initialState={{ density: 'compact' }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={buttonDisability}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={buttonDisability}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            zIndex: 5,
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            style={{
                                width: '200px',
                                height: '31px',
                                fontWeight: 'bold',
                            }}
                            color="success"
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
        </>
    );
};
const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default BlacklistResetUser;
