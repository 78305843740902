import React from 'react';
import BackButton from '../../../components/navigation/back-button';
import HeaderTitle from '../../../components/typography/header-title';
import MetabaseData from '../../../components/parsing_logs/metabaseQueries';
export default function MetabaseQuery() {
    return (
        <>           
            <HeaderTitle
                title={'Metabase Queries'}
                subtitle={
                    'See the user wise unique queries in metabase'
                }
            />
            <MetabaseData/>
        </>
    );
}
