export default function Svg({ serviceName }) {
    const svgIcon = {
        'project': (
            <svg data-icon-name="projectIcon" viewBox="0 0 18 18" width="30" height="30" aria-hidden="true"><path fill-rule="evenodd" d="M10.557 11.99l-1.71-2.966 1.71-3.015h3.42l1.71 3.01-1.71 2.964h-3.42zM4.023 16l-1.71-2.966 1.71-3.015h3.42l1.71 3.01L7.443 16h-3.42zm0-8.016l-1.71-2.966 1.71-3.015h3.42l1.71 3.015-1.71 2.966h-3.42z"></path></svg>
        ),
        'Cloud SQL': (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
                viewBox="0 0 24 24"
            >
                <defs></defs>
                <g data-name="Product Icons">
                    <g>
                        <polygon
                            class="cls-1"
                            points="4.67 10.44 4.67 13.45 12 17.35 12 14.34 4.67 10.44"
                        />
                        <polygon
                            class="cls-1"
                            points="4.67 15.09 4.67 18.1 12 22 12 18.99 4.67 15.09"
                        />
                        <polygon
                            class="cls-2"
                            points="12 17.35 19.33 13.45 19.33 10.44 12 14.34 12 17.35"
                        />
                        <polygon
                            class="cls-2"
                            points="12 22 19.33 18.1 19.33 15.09 12 18.99 12 22"
                        />
                        <polygon
                            class="cls-3"
                            points="19.33 8.91 19.33 5.9 12 2 12 5.01 19.33 8.91"
                        />
                        <polygon
                            class="cls-2"
                            points="12 2 4.67 5.9 4.67 8.91 12 5.01 12 2"
                        />
                        <polygon
                            class="cls-1"
                            points="4.67 5.87 4.67 8.89 12 12.79 12 9.77 4.67 5.87"
                        />
                        <polygon
                            class="cls-2"
                            points="12 12.79 19.33 8.89 19.33 5.87 12 9.77 12 12.79"
                        />
                    </g>
                </g>
            </svg>
        ),
        'Compute Engine': (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
                viewBox="0 0 24 24"
            >
                <g data-name="Product Icons">
                    <rect class="cls-1" x="9" y="9" width="6" height="6" />

                    <rect class="cls-2" x="11" y="2" width="2" height="4" />

                    <rect class="cls-2" x="7" y="2" width="2" height="4" />

                    <rect class="cls-2" x="15" y="2" width="2" height="4" />

                    <rect class="cls-3" x="11" y="18" width="2" height="4" />

                    <rect class="cls-3" x="7" y="18" width="2" height="4" />

                    <rect class="cls-3" x="15" y="18" width="2" height="4" />

                    <rect
                        class="cls-3"
                        x="19"
                        y="10"
                        width="2"
                        height="4"
                        transform="translate(8 32) rotate(-90)"
                    />

                    <rect
                        class="cls-3"
                        x="19"
                        y="14"
                        width="2"
                        height="4"
                        transform="translate(4 36) rotate(-90)"
                    />

                    <rect
                        class="cls-3"
                        x="19"
                        y="6"
                        width="2"
                        height="4"
                        transform="translate(12 28) rotate(-90)"
                    />

                    <rect
                        class="cls-2"
                        x="3"
                        y="10"
                        width="2"
                        height="4"
                        transform="translate(-8 16) rotate(-90)"
                    />

                    <rect
                        class="cls-2"
                        x="3"
                        y="14"
                        width="2"
                        height="4"
                        transform="translate(-12 20) rotate(-90)"
                    />

                    <rect
                        class="cls-2"
                        x="3"
                        y="6"
                        width="2"
                        height="4"
                        transform="translate(-4 12) rotate(-90)"
                    />

                    <path class="cls-1" d="M5,5V19H19V5ZM17,17H7V7H17Z" />

                    <polygon class="cls-2" points="9 15 15 15 12 12 9 15" />

                    <polygon class="cls-3" points="12 12 15 15 15 9 12 12" />
                </g>
            </svg>
        ),
        'Big Query': (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
                viewBox="0 0 24 24"
            >
                <g data-name="Product Icons">
                    <g>
                        <path
                            class="cls-1"
                            d="M6.73,10.83v2.63A4.91,4.91,0,0,0,8.44,15.2V10.83Z"
                        />

                        <path
                            class="cls-2"
                            d="M9.89,8.41v7.53A7.62,7.62,0,0,0,11,16,8,8,0,0,0,12,16V8.41Z"
                        />

                        <path
                            class="cls-1"
                            d="M13.64,11.86v3.29a5,5,0,0,0,1.7-1.82V11.86Z"
                        />

                        <path
                            class="cls-3"
                            d="M17.74,16.32l-1.42,1.42a.42.42,0,0,0,0,.6l3.54,3.54a.42.42,0,0,0,.59,0l1.43-1.43a.42.42,0,0,0,0-.59l-3.54-3.54a.42.42,0,0,0-.6,0"
                        />

                        <path
                            class="cls-2"
                            d="M11,2a9,9,0,1,0,9,9,9,9,0,0,0-9-9m0,15.69A6.68,6.68,0,1,1,17.69,11,6.68,6.68,0,0,1,11,17.69"
                        />
                    </g>
                </g>
            </svg>
        ),
        'Cloud Storage': (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35px"
                height="35px"
                viewBox="0 0 24 24"
            >
                <g data-name="Product Icons">
                    <rect class="cls-1" x="2" y="4" width="20" height="7" />

                    <rect class="cls-2" x="20" y="4" width="2" height="7" />

                    <polygon class="cls-3" points="22 4 20 4 20 11 22 4" />

                    <rect class="cls-2" x="2" y="4" width="2" height="7" />

                    <rect class="cls-4" x="6" y="7" width="6" height="1" />

                    <rect
                        class="cls-4"
                        x="15"
                        y="6"
                        width="3"
                        height="3"
                        rx="1.5"
                    />

                    <rect class="cls-1" x="2" y="13" width="20" height="7" />

                    <rect class="cls-2" x="20" y="13" width="2" height="7" />

                    <polygon class="cls-3" points="22 13 20 13 20 20 22 13" />

                    <rect class="cls-2" x="2" y="13" width="2" height="7" />

                    <rect class="cls-4" x="6" y="16" width="6" height="1" />

                    <rect
                        class="cls-4"
                        x="15"
                        y="15"
                        width="3"
                        height="3"
                        rx="1.5"
                    />
                </g>
            </svg>
        ),
        'Cloud Memorystore for Redis': (<svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 24 24"
        >
            <g data-name="Product Icons">
                <g>
                    <path
                        class="cls-1"
                        d="M6.73,10.83v2.63A4.91,4.91,0,0,0,8.44,15.2V10.83Z"
                    />

                    <path
                        class="cls-2"
                        d="M9.89,8.41v7.53A7.62,7.62,0,0,0,11,16,8,8,0,0,0,12,16V8.41Z"
                    />

                    <path
                        class="cls-1"
                        d="M13.64,11.86v3.29a5,5,0,0,0,1.7-1.82V11.86Z"
                    />

                    <path
                        class="cls-3"
                        d="M17.74,16.32l-1.42,1.42a.42.42,0,0,0,0,.6l3.54,3.54a.42.42,0,0,0,.59,0l1.43-1.43a.42.42,0,0,0,0-.59l-3.54-3.54a.42.42,0,0,0-.6,0"
                    />

                    <path
                        class="cls-2"
                        d="M11,2a9,9,0,1,0,9,9,9,9,0,0,0-9-9m0,15.69A6.68,6.68,0,1,1,17.69,11,6.68,6.68,0,0,1,11,17.69"
                    />
                </g>
            </g>
        </svg>),
        'Kubernetes Engine': (<svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 24 24"
        >
            <g data-name="Product Icons">
                <g>
                    <path
                        class="cls-1"
                        d="M6.73,10.83v2.63A4.91,4.91,0,0,0,8.44,15.2V10.83Z"
                    />

                    <path
                        class="cls-2"
                        d="M9.89,8.41v7.53A7.62,7.62,0,0,0,11,16,8,8,0,0,0,12,16V8.41Z"
                    />

                    <path
                        class="cls-1"
                        d="M13.64,11.86v3.29a5,5,0,0,0,1.7-1.82V11.86Z"
                    />

                    <path
                        class="cls-3"
                        d="M17.74,16.32l-1.42,1.42a.42.42,0,0,0,0,.6l3.54,3.54a.42.42,0,0,0,.59,0l1.43-1.43a.42.42,0,0,0,0-.59l-3.54-3.54a.42.42,0,0,0-.6,0"
                    />

                    <path
                        class="cls-2"
                        d="M11,2a9,9,0,1,0,9,9,9,9,0,0,0-9-9m0,15.69A6.68,6.68,0,1,1,17.69,11,6.68,6.68,0,0,1,11,17.69"
                    />
                </g>
            </g>
        </svg>)
    };
    return svgIcon[serviceName];
}
