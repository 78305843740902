import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
//import MongoTagging from '../../../../components/table/mongoTaggingTable';
import TaskManagement from '../../../../components/administration/taskManager';
import AuditPiiDownloadTable from '../../../../components/AuditPii/AuditPIIDownload';
import AuditPIIuserIdjobId from '../../../../components/AuditPii/AuditPIIuserIdjobId';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../../components/tabs/tabPanel';
import { useState } from 'react';

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const TaskManager = () => {
    const [value, setValue] = useState(0);
    const [value1, setValue1] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChange1 = (event, newValue) => {
        setValue1(newValue);
    };
    return (
        <>
            <HeaderTitle
                title={'Audit PII Data Download'}
                subtitle={'Monitor info related to PII Data download'}
            />
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            wrapped={true}
                            label="Audit PII data download from UPS"
                            {...a11yProps(0)}
                        />
                        <Tab
                            wrapped={true}
                            label="Get audit data based on phone number/job Id"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: '30px',
                        }}
                    >
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                            }}
                        >
                            <Tabs
                                value={value1}
                                onChange={handleChange1}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    wrapped={true}
                                    label="Count of data fetched"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    wrapped={true}
                                    label="Get the users who downloaded data corresponding to userId/jobId"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={value1} index={0}>
                            <AuditPiiDownloadTable />
                        </TabPanel>
                        <TabPanel value={value1} index={1}>
                           <AuditPIIuserIdjobId/>
                        </TabPanel>
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TaskManagement />
                </TabPanel>
            </Box>
        </>
    );
};

export default TaskManager;
