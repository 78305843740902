import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import MongoCluster from '../../../../components/platform/mongoCluster';
const Mongo = () => {
    return (
        <>
           
            <HeaderTitle
                title={'Mongo DB'}
                subtitle={'Add request to Create a mongo cluster'}
            />
            <MongoCluster/>
        </>
    );
};

export default Mongo;
