import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import UserIAMTable from '../../../../components/administration/usertable';

const UsersIAM = () => {
    return (
        <>
            
            <HeaderTitle
                title={'UPS Users'}
                subtitle={'Manage IAM Permission for UPS Users'}
            />
            <UserIAMTable />
        </>
    );
};

export default UsersIAM;
