//import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../../components/typography/header-title';
//import Home from '../../components/securityComponent/home';
export default function ManagerDashboard() {
    return (
        <>
            <HeaderTitle
                title={'Manager Dashboard'}
                subtitle={
                    'Manager dashboard'
                }
            />
            <div>Coming Soon</div>
        </>
    );
}
