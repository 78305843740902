import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { SECURITY_BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const SecurityUserIAM = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isSecurityAdmin } = useAuth();

    function call(url, data, method) {
        const requestOptions = {
            url: `${SECURITY_BACKEND_URL}/user${url}`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    useEffect(() => {
        axios({ url: `${SECURITY_BACKEND_URL}/user/all` }).then(function (res) {
            setTableData(res.data);
        });
    }, []);

    const handleCreateNewRow = (values) => {
        if (values.name.length <= 2) {
            alert('Please enter valid name, too few characters');
            return;
        }
        if (!validateemail(values.email)) {
            alert('Please enter valid email');
            return;
        }
        if (values.role === '') {
            alert('Please select a iam policy');
            return;
        }
        tableData.push(values);
        setTableData([...tableData]);
        call(
            '',
            { name: values.name, email: values.email, managerEmail: values.managerEmail, team:values.team, role:values.role },
            'POST'
        );
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();

            call(
                '',
                { name: values.name, email: values.email, managerEmail: values.managerEmail, team:values.team, role:values.role },
                'PUT'
            );
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue('name')}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
            call(
                '',
                { name: row.getValue('name'), email: row.getValue('email'), managerEmail: row.getValue('managerEmail'), team: row.getValue('team'), role:row.getValue('role') },
                'DELETE'
            );
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateemail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'name',
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'email',
                header: 'email',
                enableEditing: false,
            },
            {
                accessorKey: 'managerEmail',
                header: 'managerEmail',
                enableEditing: false,
            },
            {
                accessorKey: 'team',
                header: 'team',
                enableEditing: false,
            },
            {
                accessorKey: 'role',
                header: 'role',
                enableEditing: false,
            },
        ],
        [getCommonEditTextFieldProps]
    );

    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                enableColumnOrdering
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{ density: 'compact' }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isSecurityAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isSecurityAdmin()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="primary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                        disabled={!isSecurityAdmin()}
                    >
                        Add New User
                    </Button>
                )}
            />
            <AddNewUser
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
};

export const AddNewUser = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const [iam, setIam] = useState('');

    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add New User</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {(() => {
                            return (
                                <>
                                    {columns.map(function (object, i) {
                                        if(i<4)
                                        {
                                            return (
                                                <TextField
                                                key={object.accessorKey}
                                                label={object.header}
                                                name={object.accessorKey}
                                                onChange={(e) =>
                                                    setValues({
                                                        ...values,
                                                        [e.target.name]: e.target.value,
                                                    })
                                                }
                                            />
                                            );
                                        }
                                    })}
                                    <div classname="text-left relative z-0">
                                    <label
                                            style={{
                                                color: 'black',
                                                fontSize: '18px',
                                                fontWeight: 'bolder',
                                            }}
                                            htmlFor="floating_role"
                                            classname="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Role
                                        </label>
                                        <div classname="mt-4">
                                            <Select
                                                value={iam}
                                                name={columns[4].accessorKey}
                                                placeholder={
                                                    columns[4].accessorKey
                                                }
                                                style={{ width: '400px' }}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        [e.target.name]:
                                                            e.target.value,
                                                    });
                                                    setIam(e.target.value);
                                                }}
                                            >
                                                <MenuItem
                                                    value={'security_admin'}
                                                >
                                                    security_admin
                                                </MenuItem>
                                            </Select>
                                        </div>
                                        
                                    </div>
                                </>
                            );
                        })()}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add New User
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateemail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default SecurityUserIAM;
