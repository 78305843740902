import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import GcpIncidents from '../../../../components/gcpIncidents/incidents';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../../components/tabs/tabPanel';
import { useState } from 'react';
import GcpOrgIncidents from '../../../../components/gcpIncidents/orgIncident';

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function GCPIncidents() {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HeaderTitle
                title={'GCP Incidents'}
                subtitle={
                    'Track anomalies/incidents in GCP services across all projects'
                }
            />        
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="Organization View" {...a11yProps(0)} />
                        <Tab label="Project Wise View" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {<GcpOrgIncidents/>}  
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <GcpIncidents/>
                </TabPanel>
                </Box>
        </>
    );
}
