import ReactApexChart from 'react-apexcharts';
import UppercaseFirstLetter from '../costing/cosmetic_functions';

export default function TotalDescription({
    data,
    groupBy,
    groupByName,
    tool,
    startDate,
    endDate,
}) {
    //console.log(tool)
    var values = [];
    var keys = [];
    var total = 0;
    for (var i = 0; i < data.length; i++) {
        keys.push(data[i][groupBy]);
        values.push(data[i]['total']);
        total = total + data[i]['total'];
    }
    var other = 0;
    var vals = [];
    var label = [];
    for (var i = 0; i < keys.length; i++) {
        if (values[i] / total > 0.01) {
            label.push(keys[i]);
            vals.push(values[i]);
        } else {
            other = other + values[i];
        }
    }
    label.push('Others');
    vals.push(other);
    //var series = [44, 55, 41, 17, 15];
    var title = `${groupByName==="Dashboard (Count)"?'No. of times dashboards runned in':groupByName==="Question (Count)"?'No. of times question runned in':'Cost of'} ${tool} distributed over different ${groupBy}`
    var options = {
        chart: {
            width: 300,
            type: 'pie',
        },
        labels: label,
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 270,
                dataLabels: {
                    minAngleToShowLabel: 10,
                },
            },
        },
        dataLabels: {
            enabled: true,
        },
        fill: {
            type: 'gradient',
        },

        title: {
            text: title,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };
    var series = vals;
    return (
        <div>
            <ReactApexChart
                options={options}
                series={series}
                type="pie"
                width={900}
            />
        </div>
    );
}
