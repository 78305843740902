import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MetabaseMetadata from './metabase_metadata';
const Info = () => {
    return (
        <>
            <Routes>
                <Route path="metabase-metadata/*" element={<MetabaseMetadata />} />
            </Routes>
        </>
    );
};

export default Info;
