function dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export default function Description({
    rawByteSize,
    startDate,
    endDate,
    logEntrySum,
    metricType,
}) {
    let rawByteSizeInGIB = rawByteSize / 1024 / 1024 / 1024;
    let rawByteSizeInTB = rawByteSizeInGIB / 1024;
    let rawCost = rawByteSizeInGIB * 0.5;
    var numFormatter = require('number_formatter');
    var totalNoOfDays = dateDiffInDays(startDate, endDate) + 1;
    return (
        <div className="mt-[20px]">
            <span>
                <b>Graph description and stats</b>:
            </span>
            <ul>
                {metricType.name == 'Log Entry Count' ? (
                    <li>
                        Total sum of log entries is{' '}
                        {logEntrySum >= 1000000 ? (
                            <strong>
                                {numFormatter(
                                    (logEntrySum / 1000000).toFixed(2)
                                )}
                                M
                            </strong>
                        ) : (
                            <strong>{logEntrySum}</strong>
                        )}
                        .
                    </li>
                ) : (
                    ''
                )}
                {(() => {
                    if (rawByteSizeInGIB.toFixed(2) > 0) {
                        if (rawByteSizeInGIB < 1024) {
                            return (
                                <li>
                                    Total log size is{' '}
                                    <strong>
                                        {rawByteSizeInGIB.toFixed(2)}GB
                                    </strong>
                                    .
                                </li>
                            );
                        } else {
                            return (
                                <li>
                                    Total log size is{' '}
                                    <strong>
                                        {rawByteSizeInTB.toFixed(2)}TB
                                    </strong>
                                    .
                                </li>
                            );
                        }
                    } else {
                        return (
                            <li>
                                Total log size is{' '}
                                <strong>
                                    {rawByteSizeInGIB.toFixed(15)}GB
                                </strong>
                                .
                            </li>
                        );
                    }
                })()}
                {(() => {
                    if (rawCost.toFixed(2) > 0) {
                        return (
                            <li>
                                Estimated total log cost according to the log
                                size is{' '}
                                <strong>
                                    {numFormatter(rawCost.toFixed(2))}
                                </strong>
                                $.
                            </li>
                        );
                    } else {
                        return (
                            <li>
                                Estimated total log cost according to the log
                                size is{' '}
                                <strong>
                                    {numFormatter(rawCost.toFixed(15))}
                                </strong>
                                $.
                            </li>
                        );
                    }
                })()}
                {(() => {
                    if (rawCost.toFixed(2) > 0) {
                        return (
                            <li>
                                Average daily cost according to the log size is{' '}
                                <strong>
                                    {numFormatter(
                                        (rawCost / totalNoOfDays).toFixed(2)
                                    )}
                                </strong>
                                $.
                            </li>
                        );
                    } else {
                        return (
                            <li>
                                Average daily cost according to the log size is{' '}
                                <strong>
                                    {numFormatter(
                                        (rawCost / totalNoOfDays).toFixed(15)
                                    )}
                                </strong>
                                $.
                            </li>
                        );
                    }
                })()}
                <li>The left Y axis denotes the Log Entry Sum.</li>
                <li>
                    The graph has been generated as per your local time zone.
                </li>
            </ul>
        </div>
    );
}
