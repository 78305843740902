import React, { useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import {
    LineChart,
    Line,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { Dialog } from '@headlessui/react';
import Change from './change';
export default function AreaChartCard({
    data,
    graphColor,
    textColor,
    graphHeading,
    liveInfo,
    liveCost,
    periodicity,
}) {
   
    var liveinfokeys = Object.keys(liveInfo);
    const [isOpen, setIsOpen] = useState(false);
    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    function CustomTooltip({ payload, label, active }) {
        if (active) {
            //console.log(payload)
            return (
                <div
                    className="custom-tooltip"
                    style={{
                        padding: 5,
                        backgroundColor: 'Highlight',
                        color: 'black',
                    }}
                >
                    <p className="label">{payload[0].payload.time}</p>
                    <p className="label">{`${payload[0].dataKey} : ${payload[0].value}`}</p>
                </div>
            );
        }

        return null;
    }

    return (
        <>
            <Paper
                elevation={3}
                style={{
                    marginBottom: 10,
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    backgroundColor: 'white',
                }}
            >
                <div
                    className="flex flex-inline"
                    style={{
                        justifyContent: 'space-between',
                        paddingTop: 10,
                        paddingLeft: 15,
                        paddingRight: 15,
                    }}
                >
                    <div className="flex-row" style={{ textAlign: 'left' }}>
                        <Typography
                            variant="overline"
                            style={{
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                        >
                            {graphHeading}
                        </Typography>
                        <Button
                            color="primary"
                            children={<InfoIcon />}
                            onClick={openModal}
                        />
                        <div style={{ textAlign: 'left' }}>
                            <div>
                            {liveinfokeys.includes('count') ? (
                                typeof liveInfo['count'] == 'object' ? (
                                    <div
                                        className="inline-flex"
                                        style={{ textAlign: 'left' }}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            style={{
                                                fontWeight: 'bold',
                                                color: 'gray',
                                            }}
                                        >
                                            {liveInfo['count']['value']}{' '}
                                            {' Tables '}
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            style={{
                                                fontWeight: 'bold',
                                                color: textColor,
                                                marginLeft: '5px',
                                            }}
                                        >
                                            <Change
                                                value={parseFloat(
                                                    liveInfo['count'][
                                                        'change'
                                                    ].toFixed(2)
                                                )}
                                            />
                                        </Typography>
                                    </div>
                                ) : (
                                    liveInfo['count'] + ' Tables'
                                )
                            ) : (
                                ''
                            )}
                            </div>
                            <div>
                            {typeof liveInfo['size'] == 'object' ? (
                                <div
                                    className="inline-flex"
                                    style={{ textAlign: 'left' }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontWeight: 'bold',
                                            color: 'gray',
                                        }}
                                    >
                                        {liveInfo['size']['size'] +
                                            ' ' +
                                            liveInfo['size']['unit']}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        style={{
                                            fontWeight: 'bold',
                                            color: textColor,
                                            marginLeft: '5px',
                                        }}
                                    >
                                        <Change
                                            value={parseFloat(
                                                liveInfo['size'][
                                                    'change'
                                                ].toFixed(2)
                                            )}
                                        />
                                    </Typography>
                                </div>
                            ) : (
                                liveInfo['size'] +
                                ' ' +
                                liveInfo['unit']
                            )}
                        </div>
                        </div>
                    </div>

                    <div
                        className="flex-row"
                        style={{ textAlign: 'right', marginRight: '5px' }}
                    >
                        <Typography
                            variant="h5"
                            style={{
                                fontWeight: 'bold',
                                color: textColor,
                            }}
                        >
                            ${parseFloat(liveCost['value'].toFixed(2))}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            style={{
                                fontWeight: 'bold',
                                color: textColor,
                            }}
                        >
                            {typeof liveCost['change'] == 'string' ? (
                                liveCost['change']
                            ) : (
                                <Change
                                    value={parseFloat(
                                        liveCost['change'].toFixed(2)
                                    )}
                                />
                            )}
                        </Typography>
                        <Typography
                            variant="overline"
                            style={{
                                fontWeight: 'bold',
                                color: textColor,
                            }}
                        >
                            {typeof liveCost['change'] == 'string'
                                ? `from this ${periodicity}`
                                : `from last ${periodicity}`}
                        </Typography>
                    </div>
                </div>
                <div style={{ width: 'auto', height: 300 }}>
                    <ResponsiveContainer>
                        <AreaChart
                            data={data['cost']}
                            margin={{
                                top: 5,
                            }}
                        >
                            <Tooltip content={<CustomTooltip />} />
                            <Area
                                yAxisId="left"
                                type="monotone"
                                dataKey="cost"
                                stroke={textColor}
                                fillOpacity={1}
                                fill={graphColor}
                                strokeWidth={2}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </Paper>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[1050px] transform overflow-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        {`Details of Tables(${graphHeading})`}
                                    </Dialog.Title>
                                    <div
                                        style={{ overflow: 'auto'}}
                                        className="flex-row mt-2"
                                        
                                    >
                                        <AreaChart
                                            width={1000}
                                            height={300}
                                            data={data['cost']}
                                            margin={{
                                                left: 20,
                                                top: 20,
                                                right: 52,
                                                bottom: 5,
                                            }}
                                        >
                                            <defs>
                                                <linearGradient
                                                    x1="0"
                                                    y1="0"
                                                    x2="0"
                                                    y2="1"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor={graphColor}
                                                        stopOpacity={0.5}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor={graphColor}
                                                        stopOpacity={0}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <Legend />

                                            <Tooltip
                                                content={<CustomTooltip />}
                                            />
                                            <XAxis
                                                dataKey="time"
                                                stroke={'black'}
                                            />
                                            <YAxis
                                                yAxisId="left"
                                                orientation="left"
                                                stroke={textColor}
                                                unit={'$'}
                                            />
                                            <Area
                                                yAxisId="left"
                                                type="monotone"
                                                dataKey="cost"
                                                stroke={textColor}
                                                fillOpacity={1}
                                                fill={graphColor}
                                                strokeWidth={2.5}
                                            />
                                        </AreaChart>
                                        {data['count'] ? (
                                            <AreaChart
                                                width={1000}
                                                height={300}
                                                data={data['count']}
                                                margin={{
                                                    left: 20,
                                                    top: 20,
                                                    right: 52,
                                                    bottom: 5,
                                                }}
                                            >
                                                <defs>
                                                    <linearGradient
                                                        id="colorUv"
                                                        x1="0"
                                                        y1="0"
                                                        x2="0"
                                                        y2="1"
                                                    >
                                                        <stop
                                                            offset="0%"
                                                            stopColor={
                                                                graphColor
                                                            }
                                                            stopOpacity={1}
                                                        />
                                                        <stop
                                                            offset="100%"
                                                            stopColor={
                                                                graphColor
                                                            }
                                                            stopOpacity={0}
                                                        />
                                                    </linearGradient>
                                                </defs>
                                                <Legend />

                                                <Tooltip
                                                    content={<CustomTooltip />}
                                                />
                                                <XAxis
                                                    dataKey="time"
                                                    stroke={'black'}
                                                />
                                                <YAxis
                                                    yAxisId="left"
                                                    orientation="left"
                                                    stroke={textColor}
                                                />
                                                <Area
                                                    yAxisId="left"
                                                    type="monotone"
                                                    dataKey="count"
                                                    stroke={textColor}
                                                    fillOpacity={1}
                                                    fill={graphColor}
                                                    strokeWidth={2.5}
                                                />
                                            </AreaChart>
                                        ) : (
                                            <></>
                                        )}
                                        <AreaChart
                                            width={1000}
                                            height={300}
                                            data={data['size']}
                                            margin={{
                                                left: 20,
                                                top: 20,
                                                right: 52,
                                                bottom: 5,
                                            }}
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="colorUv"
                                                    x1="0"
                                                    y1="0"
                                                    x2="0"
                                                    y2="1"
                                                >
                                                    <stop
                                                        offset="0%"
                                                        stopColor={graphColor}
                                                        stopOpacity={1}
                                                    />
                                                    <stop
                                                        offset="100%"
                                                        stopColor={graphColor}
                                                        stopOpacity={0}
                                                    />
                                                </linearGradient>
                                            </defs>
                                            <Legend />

                                            <Tooltip
                                                content={<CustomTooltip />}
                                            />
                                            <XAxis
                                                dataKey="time"
                                                stroke={'black'}
                                            />
                                            <YAxis
                                                yAxisId="left"
                                                orientation="left"
                                                stroke={textColor}
                                                unit={'TB'}
                                                allowDecimals={false}
                                            />
                                            <Area
                                                yAxisId="left"
                                                type="monotone"
                                                dataKey="size"
                                                stroke={textColor}
                                                fillOpacity={1}
                                                fill={graphColor}
                                                strokeWidth={2.5}
                                            />
                                        </AreaChart>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
