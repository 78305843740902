import React from 'react';
import Automation from '../../components/securityComponent/controlPanel';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import HeaderTitle from '../../components/typography/header-title';
export default function Controlpanel() {
    return (
        <>
            <HeaderTitle
                title={'Control Panel'}
                subtitle={'Run security checks'}
            />
            <Automation />
        </>
    );
}
