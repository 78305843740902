import Wazuh from './wazuh';
import Controlpanel from './controlpanel';
import { Grid } from '@mui/material';
import Sidebar from '../../components/navigation/sidebar';
import SecurityHome from './home';
import SecurityTeam from './team';
import SecurityPolicies from './policies';
import { Route, Routes, Navigate } from 'react-router-dom';
import SecurityAdminRoute from '../../components/navigation/securityAdmin';

function Security() {
    return (
        <Grid container>
            <Grid xs={12}>
                <Sidebar />
            </Grid>
            <Grid xs={12}>
                <div className="py-6 px-8" style={{ marginTop: '70px' }}>
                    <Routes>
                        <Route index element={<Navigate to={'home'} />} />
                        <Route path="home" element={<SecurityHome />} />
                        <Route path="team" element={<SecurityTeam />} />
                        <Route path="policies" element={<SecurityPolicies />} />
                        <Route path="wazuh" element={<Wazuh />} />
                        <Route element={<SecurityAdminRoute />}>
                            <Route
                                path="controlpanel"
                                element={<Controlpanel />}
                            />
                        </Route>
                    </Routes>
                </div>
            </Grid>
        </Grid>
    );
}

export default Security;
