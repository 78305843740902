import './mongotable-cell.css';
import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DetailsOutlinedIcon from '@mui/icons-material/DetailsOutlined';
import { ExportToCsv } from 'export-to-csv';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import Loading from '../costing/Loading';
import RowDescription from '../costing/rowdescription';
import UppercaseFirstLetter from '../costing/cosmetic_functions';
import { FRONTEND_URL } from '../../configs';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
//Icons Imports
import { AccountCircle, Send } from '@mui/icons-material';

//processing data
function ProcessCost(groupByName, data) {
    //console.log(groupByName);
    if (data === null) {
        data = 0;
    }
    if (
        groupByName === 'Dashboard (Count)' ||
        groupByName === 'Question (Count)'
    ) {
        return data;
    } else {
        if (data >= 1000) {
            data = data / 1000;
            data = data.toFixed(3);
            data = data.toString() + 'k $';
        } else {
            data = data.toFixed(3);
            data = data.toString() + ' $';
        }
        return data;
    }
}

function ProcessIncrement(data) {
    if (data === null) {
        data = 0;
    }
    if (data !== 'kickstart') {
        data = data.toFixed(2);
        data = data.toString() + '%';
    }

    return data;
}

function ParseDate(data) {
    var date = data.split('-');
    return date[0] + '/' + date[1] + " '" + date[2][2] + date[2][3];
}
//*************************** Building tags *****************************************
const TableDataCell = ({ groupByName, cost, increment }) => {
    //console.log(groupByName);
    return (
        <div
            style={{ fontSize: '16px' }}
            className="w-[240px] h-[87px] py-4 font-medium text-gray-900 text-left diagonal-split-background"
        >
            <span className="sup">{ProcessCost(groupByName, cost)}</span>
            {increment > 0 ? (
                <span className="inf" style={{ color: 'red' }}>
                    {'+' + ProcessIncrement(increment)}
                </span>
            ) : (
                <span className="inf" style={{ color: 'green' }}>
                    {ProcessIncrement(increment)}
                </span>
            )}
        </div>
    );
};

const TableFooterDataCell = ({ groupByName, cost, increment }) => {
    return (
        <div
            style={{ fontSize: '16px', fontWeight: 900 }}
            className="w-[240px] h-[87px] py-4 text-sm font-medium text-white text-left diagonal-footer-split-background"
        >
            <span className="sup font-bold">
                {ProcessCost(groupByName, cost)}
            </span>
            {increment > 0 ? (
                <span className="inf font-bold" style={{ color: 'red' }}>
                    {'+' + ProcessIncrement(increment)}
                </span>
            ) : (
                <span className="inf font-bold" style={{ color: 'green' }}>
                    {ProcessIncrement(increment)}
                </span>
            )}
        </div>
    );
};

const TableFirstDataCell = ({ children, toolName }) => {
    return (
        <div
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            className="min-w-auto max-w-[335px] p-2 text-sm font-bold text-gray-900 text-center"
        >
            <h6>{children}</h6>
        </div>
    );
};

//material-react-table -> https://www.material-react-table.com/
const MongoTable = ({
    data,
    mappingData,
    groupBy,
    groupByName,
    periodicity,
    setTotalData,
    toolName,
    startDate,
    endDate,
}) => {
    const [ok, setOk] = useState('false');
    const [descriptionData, setDescriptionData] = useState([]);
    const [descriptionCategories, setDescriptionCategories] = useState([]);
    const [rowIndex, setRowIndex] = useState(0);
    const [column, setColumn] = useState([]);
    const [header, setHeader] = useState([]);
    const [totalCost, setTotalCost] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [groupName, seGroupName] = useState(groupByName);
    const clusteridMap = {};
    //console.log(groupBy, groupByName);
    for (var i = 0; i < mappingData.length; i++) {
        if (Object.keys(mappingData[i]).includes('cluster_id') === true) {
            clusteridMap[mappingData[i]['name']] = mappingData[i]['cluster_id'];
        } else {
            clusteridMap[mappingData[i]['name']] = '';
        }
    }
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    const handleDescription = (closeMenu, index) => {
        closeMenu();
        setRowIndex(index);
        var data = Object.values(finalData[index]);
        var categories = Object.keys(finalData[index]);
        data.shift();
        categories.shift();
        for (var i = 0; i < data.length; i++) {
            data[i] = data[i][1].toFixed(2);
            if (periodicity === 'weekly') {
                categories[i] = categories[i].split(' ')[0];
            }
        }
        setDescriptionData(data);
        setDescriptionCategories(categories);
        setIsOpen(true);
    };
    useEffect(() => {
        if (data.length > 0) {
            setOk('load');
            const head = Object.keys(data[0]);

            setHeader(head);
            //Getting cost and increment of services per day/week/month
            const Cost = [];
            for (var i = 0; i < data.length; i++) {
                var ServiceCostData = [];

                ServiceCostData.push(data[i][groupBy]); //use useState
                ServiceCostData.push([0, data[i][head[1]]]); // isme value aaega-->
                for (var j = 2; j < head.length; j++) {
                    ServiceCostData.push([
                        Math.round(data[i][head[j - 1]]) !== 0
                            ? ((data[i][head[j]] - data[i][head[j - 1]]) *
                                  100) /
                              data[i][head[j - 1]]
                            : data[i][head[j]] !== 0
                            ? 'kickstart'
                            : 0,
                        data[i][head[j]],
                    ]);
                }
                Cost.push(ServiceCostData);
            }

            //Getting total cost and avg increment of services per day/week/month
            const total = [];
            let totalCost = [];
            total.push('Total');
            for (var i = 1; i < Cost[0].length; i++) {
                var TotalServiceCost = 0;
                var TotalServiceCostPrevDay = 0;
                if (i > 1) {
                    for (var j = 0; j < Cost.length; j++) {
                        TotalServiceCost = TotalServiceCost + Cost[j][i][1];
                        TotalServiceCostPrevDay =
                            TotalServiceCostPrevDay + Cost[j][i - 1][1];
                    }
                    total.push([
                        TotalServiceCostPrevDay !== 0
                            ? ((TotalServiceCost - TotalServiceCostPrevDay) *
                                  100) /
                              TotalServiceCostPrevDay
                            : TotalServiceCost !== 0
                            ? 'kickstart'
                            : 0,
                        TotalServiceCost,
                    ]);
                } else {
                    for (var j = 0; j < Cost.length; j++) {
                        TotalServiceCost = TotalServiceCost + Cost[j][i][1];
                    }
                    total.push([0, TotalServiceCost]);
                }
                totalCost.push(TotalServiceCost);
            }
            setTotalCost(total);
            setTotalData(totalCost);
            //data definitions...
            const finalRows = [];
            for (var i = 0; i < Cost.length; i++) {
                var rowData = {};
                for (var j = 0; j < head.length; j++) {
                    rowData[head[j]] = Cost[i][j];
                }
                finalRows.push(rowData);
            }
            setFinalData(finalRows);
            setOk('true');
        }
    }, []);

    useEffect(() => {
        if (header.length > 0 && totalCost.length > 0 && data.length > 0) {
            var cols = [
                {
                    accessorKey: header[0],
                    header: header[0],
                    Header: (
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '15px',
                            }}
                        >
                            {header[0].toUpperCase()}
                        </div>
                    ),
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    Cell: ({ cell }) => (
                        <TableFirstDataCell toolName={toolName}>
                            {cell.getValue().toUpperCase()}
                        </TableFirstDataCell>
                    ),
                    Footer: (
                        <TableFirstDataCell>{'TOTAL COST'}</TableFirstDataCell>
                    ),
                },
            ];
            if (periodicity == 'weekly') {
                const customHeader = [];
                for (var i = 0; i < header.length; i++) {
                    customHeader.push(header[i].split(' '));
                }

                for (var i = 1; i < header.length; i++) {
                    cols.push({
                        accessorKey: header[i],
                        header: header[i],
                        Header: (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                }}
                                className="grid grid-rows-2 grid-flow-col gap-1"
                            >
                                <div>
                                    {'Week ' + customHeader[i][0].split('-')[1]}
                                </div>
                                <div>
                                    {ParseDate(customHeader[i][1]) +
                                        ' - ' +
                                        ParseDate(customHeader[i][2])}
                                </div>
                            </div>
                        ),
                        sortingFn: 'myCustomSortingFn',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                        Cell: ({ cell }) => (
                            <TableDataCell
                                groupByName={groupName}
                                cost={cell.getValue()[1]}
                                increment={cell.getValue()[0]}
                            ></TableDataCell>
                        ),
                        Footer: (
                            <TableFooterDataCell
                                groupByName={groupName}
                                cost={totalCost[i][1]}
                                increment={totalCost[i][0]}
                            ></TableFooterDataCell>
                        ),
                    });
                }
            } else {
                for (var i = 1; i < header.length; i++) {
                    cols.push({
                        accessorKey: header[i],
                        header: header[i],
                        Header: (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                {header[i].toUpperCase()}
                            </div>
                        ),
                        sortingFn: 'myCustomSortingFn',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                        Cell: ({ cell }) => (
                            <TableDataCell
                                groupByName={groupName}
                                cost={cell.getValue()[1]}
                                increment={cell.getValue()[0]}
                            ></TableDataCell>
                        ),
                        Footer: (
                            <TableFooterDataCell
                                groupByName={groupName}
                                cost={totalCost[i][1]}
                                increment={totalCost[i][0]}
                            ></TableFooterDataCell>
                        ),
                    });
                }
            }
            setColumn(cols);
        }
    }, [totalCost, groupName]);

    return (
        <>
            {(() => {
                switch (ok) {
                    case 'false':
                        return <></>;
                    case 'load':
                        return <Loading />;
                    case 'true':
                        return (
                            <div style={{ marginTop: '30px' }}>
                                <MaterialReactTable
                                    data={finalData}
                                    columns={column}
                                    // enableStickyFooter
                                    enableDensityToggle={false}
                                    enableFullScreenToggle
                                    enableRowActions
                                    enableStickyFooter
                                    enableStickyHeader
                                    muiTableBodyProps={{
                                        sx: { marginTop: '100px' },
                                    }}
                                    sortingFns={{
                                        //will add a new sorting function to the list of other sorting functions already available
                                        myCustomSortingFn: (
                                            rowA,
                                            rowB,
                                            columnId
                                        ) =>
                                            rowA.getValue(columnId)[1] >
                                            rowB.getValue(columnId)[1]
                                                ? 1
                                                : -1,
                                    }}
                                    initialState={{
                                        columnPinning: {
                                            left: [
                                                'mrt-row-select',
                                                'mrt-row-actions',
                                            ],
                                            right: [groupBy],
                                        }, //use useState
                                        density: 'compact',
                                    }}
                                    positionToolbarAlertBanner="bottom"
                                    renderTopToolbarCustomActions={({
                                        table,
                                    }) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '1rem',
                                                p: '0.5rem',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                onClick={handleExportData}
                                                startIcon={<FileDownloadIcon />}
                                                variant="contained"
                                            >
                                                Export All Data
                                            </Button>
                                        </Box>
                                    )}
                                    renderRowActionMenuItems={({
                                        closeMenu,
                                        row,
                                    }) => [
                                        <MenuItem
                                            key={0}
                                            onClick={() => {
                                                // View profile logic...
                                                closeMenu();
                                            }}
                                            sx={{ m: 0 }}
                                            disabled={
                                                ((clusteridMap[
                                                    data[row.index]['cluster']
                                                ] !== '' &&
                                                    groupBy === 'cluster') ===
                                                    false &&
                                                    toolName !== 'bigquery') ||
                                                (toolName === 'bigquery' &&
                                                    groupBy === 'team')
                                            }
                                        >
                                            <ListItemIcon>
                                                <AccountCircle />
                                            </ListItemIcon>
                                            <a
                                                target="_blank"
                                                href={(() => {
                                                    switch (toolName) {
                                                        case 'mongo':
                                                            return `${FRONTEND_URL}/infra/events/mongo-db-events?cluster_id=${
                                                                clusteridMap[
                                                                    data[
                                                                        row
                                                                            .index
                                                                    ]['cluster']
                                                                ]
                                                            }`;
                                                        case 'bigquery':
                                                            return groupBy ===
                                                                'dashboard'
                                                                ? data[
                                                                      row.index
                                                                  ][
                                                                      'dashboard'
                                                                  ] ===
                                                                  'AdHoc Query'
                                                                    ? 'https://metabase.infra.apna.co/search?type=card'
                                                                    : `${FRONTEND_URL}/infra/info/metabase-metadata?tab=0&dashboard=${
                                                                          data[
                                                                              row
                                                                                  .index
                                                                          ][
                                                                              'dashboard'
                                                                          ]
                                                                      }`
                                                                : groupBy ===
                                                                  'question'
                                                                ? `${FRONTEND_URL}/infra/info/metabase-metadata?tab=1&question=${
                                                                      data[
                                                                          row
                                                                              .index
                                                                      ][
                                                                          'question'
                                                                      ]
                                                                  }`
                                                                : `${FRONTEND_URL}/infra/observability/query-log-threshold-exceeded-info?${groupBy}_id=${
                                                                      data[
                                                                          row
                                                                              .index
                                                                      ][groupBy]
                                                                  }&startDate=${startDate}&endDate=${endDate}&view=${'Previous Days'}`;
                                                    }
                                                })()}
                                            >
                                                {'Go to ' + groupBy}
                                            </a>
                                        </MenuItem>,
                                        <MenuItem
                                            key={1}
                                            disabled={toolName === 'bigquery'}
                                            onClick={() => {
                                                handleDescription(
                                                    closeMenu,
                                                    row.index
                                                );
                                            }}
                                            sx={{ m: 0 }}
                                        >
                                            <ListItemIcon>
                                                <DetailsOutlinedIcon />
                                            </ListItemIcon>
                                            {'Get ' + groupBy + ' description'}
                                        </MenuItem>,
                                        toolName === 'mongo' ? (
                                            <MenuItem
                                                key={2}
                                                disabled={
                                                    ((clusteridMap[
                                                        data[row.index][
                                                            'cluster'
                                                        ]
                                                    ] !== '' &&
                                                        groupBy ===
                                                            'cluster') ===
                                                        false &&
                                                        toolName !==
                                                            'bigquery') ||
                                                    (toolName === 'bigquery' &&
                                                        groupBy === 'team')
                                                }
                                            >
                                                <ListItemIcon>
                                                    <AccessAlarmsIcon />
                                                </ListItemIcon>
                                                <a
                                                    target="_blank"
                                                    href={`${FRONTEND_URL}/infra/tbas/mongo`}
                                                >
                                                    {'Create Tbas Scheduler'}
                                                </a>
                                            </MenuItem>
                                        ) : (
                                            <></>
                                        ),
                                    ]}
                                />
                            </div>
                        );
                }
            })()}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    //style={{ overflow: 'scroll' }}
                                    className="transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                                >
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg text-center font-medium leading-6 text-gray-900"
                                    >
                                        {UppercaseFirstLetter(groupBy) +
                                            ' Description'}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        {finalData.length > 0 ? (
                                            <RowDescription
                                                groupByName={groupBy}
                                                data={descriptionData}
                                                categories={
                                                    descriptionCategories
                                                }
                                                mappingData={mappingData}
                                                groupByValue={
                                                    finalData[rowIndex][groupBy]
                                                }
                                                periodicity={periodicity}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
export default MongoTable;
