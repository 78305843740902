import React from 'react';
import { useState } from 'react';
import { ACCESS_BACKEND_URL } from '../../configs';
import LoadingSpinner from './loader';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useAxios } from '../../contexts/axios';

export default function Removeuserform() {
    const axios = useAxios();
    const [type, settype] = useState('');
    const [primaryemail, setprimaryemail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let [isOpen, setIsOpen] = useState(false);
    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    function closeModal() {
        setIsOpen(false);
    }
    const openModal = (i) => {
        setIsOpen(true);
    };
    const handleprimaryemail = (e) => {
        setprimaryemail(e.target.value);
    };
    const handleclickactivate = () => {
        settype('activate');
        openModal();
    };
    const handleclicksuspend = () => {
        settype('suspend');
        openModal();
    };
    const handleclickdelete = () => {
        settype('delete');
        openModal();
    };

    const handleclick = (event) => {
        event.preventDefault();
        if (!validateEmail(primaryemail)) {
            alert('enter valid email');
            closeModal();
            return;
        }
        setIsLoading(true);
        if (type === 'activate') {
            const requestOptions = {
                method: 'PUT',
                url: `${ACCESS_BACKEND_URL}/api/Onboarding/suspend`,
                data: {
                    primaryemail: primaryemail,
                    suspended: false,
                },
            };
            axios(requestOptions).then(function (res) {
                setIsLoading(false);
            });
        } else if (type === 'suspend') {
            const requestOptions = {
                method: 'PUT',
                url: `${ACCESS_BACKEND_URL}/api/Onboarding/suspend`,
                data: {
                    primaryemail: primaryemail,
                    suspended: true,
                },
            };
            axios(requestOptions).then(function (res) {
                setIsLoading(false);
            });
        } else {
            const requestOptions = {
                method: 'DELETE',
                url: `${ACCESS_BACKEND_URL}/api/Onboarding`,
                data: {
                    primaryemail: primaryemail,
                },
            };
            axios(requestOptions).then(function (res) {
                setIsLoading(false);
            });
        }
        closeModal();
    };
    return (
        <>
            <div class="max-w-5xl text-left">
                <div class="block rounded-lg shadow-lg bg-white max-w-5xl text-center">
                    <div
                        style={{ display: 'flex' }}
                        class="font-bold rounded-t-lg bg-blue-200 text-left py-2 px-6 border-b border-gray-300"
                    >
                        <div>Update User Status</div>
                        <div style={{ marginLeft: '790px' }}>
                            {isLoading ? <LoadingSpinner /> : ''}
                        </div>
                    </div>
                    <div class="max-w-5xl text-left">
                        <div
                            style={{
                                marginLeft: '20px',
                                marginRight: '20px',
                                marginTop: '10px',
                                marginBottom: '20px',
                                textAlign: 'center',
                            }}
                            class="grid md:grid-cols-2 md:gap-6"
                        >
                            <div class="text-left relative z-0 mb-6 w-full group">
                                <input
                                    type="email"
                                    name="floating_phone"
                                    id="primaryEmail"
                                    onChange={(e) => {
                                        handleprimaryemail(e);
                                    }}
                                    class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                    required={true}
                                />
                                <label
                                    for="floating_phone"
                                    class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    Email *
                                </label>
                            </div>
                            <div>
                                <button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleclickactivate();
                                    }}
                                    class="ml-20 mb-3 mt-2 text-black bg-green-300 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                                >
                                    Activate
                                </button>
                                <button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleclicksuspend();
                                    }}
                                    class="mb-3 ml-8 mt-2 text-black bg-yellow-300 hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                                >
                                    Suspend
                                </button>
                                <button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleclickdelete();
                                    }}
                                    class="mb-3 ml-8 mt-2 text-black bg-red-500 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Caution
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            {(() => {
                                                switch (type) {
                                                    case 'activate':
                                                        return 'Are you sure that you want to activate the user?';
                                                    case 'suspend':
                                                        return 'Are you sure that you want to suspend the user?';
                                                    case 'delete':
                                                        return 'Are you sure that you want to delete the user? If you proceed every data related to user will be permanently deleted';
                                                }
                                            })()}
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium ${(() => {
                                                switch (type) {
                                                    case 'activate':
                                                        return 'bg-green-300 text-green-900 hover:bg-green-200';
                                                    case 'suspend':
                                                        return ' bg-yellow-300 text-yellow-900 hover:bg-yellow-200';
                                                    case 'delete':
                                                        return ' bg-red-300 text-red-900 hover:bg-red-200';
                                                }
                                            })()}`}
                                            onClick={handleclick}
                                        >
                                            {(() => {
                                                switch (type) {
                                                    case 'activate':
                                                        return 'Activate User';
                                                    case 'suspend':
                                                        return 'Suspend User';
                                                    case 'delete':
                                                        return 'Delete User';
                                                }
                                            })()}
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
