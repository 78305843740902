import React from 'react';
import { Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
const Home = () => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return (
        <div style={{ textAlign: 'center'}}>
            <Typography variant={isMobile?"h4":"h2"} style={{fontFamily:'serif',marginBottom:'20px', color:'red'}}>
                Welcome to UPS - Unified Platform Services!
            </Typography>
            <Typography variant={isMobile?"h6":"h5"} style={{fontFamily:'serif',marginBottom:'20px', color:'black'}}>
                Gain real-time monitoring, observability, and cost analysis for
                your cloud resources on GCP, Mongo, Elastic. Utilise the
                powerful BigQuery analysis capabilities to uncover valuable
                insights, and make data-driven decisions for costing. Experience
                UPS - Unified Platform Services now and elevate your cloud
                experience.
            </Typography>
            <Typography variant={isMobile?"h7":"h6"}>
                Need assistance? Join our{' '}
                <a
                    style={{color:'blue',fontWeight:'bold'}}
                    target="__blank"
                    href="https://470-workspace.slack.com/archives/C058HFDN709"
                >
                    Slack channel.
                </a>{' '}
                for prompt support
            </Typography>
        </div>
    );
};

export default Home;
