import React from 'react';
import UnitCostingTable from '../../../../components/costing/unit-costing';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';

export default function UnitCosting() {
    return (
        <>
            <HeaderTitle
                title={'Unit Costing'}
                subtitle={
                    'Cost segregation for different tools in different verticals'
                }
            />
            <UnitCostingTable />
        </>
    );
}
