import Adduserform from './adduserform';
import Updateuserform from './updateuserform';
import Removeuserform from './removeuserform';
export default function Usermanagement() {
    return (
        <div
            style={{
                position: 'absolute',
                marginLeft: '400px',
                marginTop: '100px',
                zIndex: -1,
                height: '100px',
            }}
            class="d-flex flex-column justify-content-center"
        >
            <div class="p-2">
                {' '}
                <Adduserform />
            </div>
            <div style={{ marginTop: '100px' }} class="p-2">
                <Updateuserform />
            </div>
            <div style={{ marginTop: '100px' }} class="p-2">
                <Removeuserform />
            </div>
            <div style={{ marginTop: '100px' }} class="p-2" />
            <div style={{ marginTop: '100px' }} class="p-2" />
        </div>
    );
}
