import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from '../contexts/auth';

const Auth = () => {
    const navigate = useNavigate();
    const { setAccessToken, getRedirectPath } = useAuth();
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const token = query.get('access_token');
        if (token) {
            setAccessToken(token);
            return navigate(getRedirectPath());
        }
    });

    return <></>;
};

export default Auth;
