export default function ClusterDescription({ groupByValue, mappingData }) {
    var clusterMetadata =
        mappingData.filter((obj) => {
            return obj.name === groupByValue;
        })[0] || null;

    return (
        <div
                style={{
                    //minWidth: 'fit-content',
                    maxHeight: '300px',
                    maxWidth: 'auto',
                    height: 'fit-content',
                }}
                className="w-full block bg-white border border-gray-200 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"
            >
                <h5 className="text-2xl p-2 text-center bg-slate-400 rounded-t-lg font-bold tracking-tight text-gray-900 dark:text-white">
                    Description
                </h5>
            {!!mappingData ? (
                <div className="overflow-x-auto relative">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th
                                    scope="row"
                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    Name
                                </th>
                                <td className="py-4 px-6">{groupByValue}</td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th
                                    scope="row"
                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    Owner
                                </th>
                                <td className="py-4 px-6">
                                    {clusterMetadata?.owner || 'Unknown'}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th
                                    scope="row"
                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    Team
                                </th>
                                <td className="py-4 px-6">
                                    {clusterMetadata?.team || 'Unknown'}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th
                                    scope="row"
                                    className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    Vertical
                                </th>
                                <td className="py-4 px-6">
                                    {clusterMetadata?.vertical || 'Unknown'}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ) : (
                <></>
            )}
       </div>
    );
}
