import { React, useState, useEffect, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { BACKEND_URL } from '../../configs';
import Grid from '@mui/material/Unstable_Grid2';
import FlexRow from '../layout/FlexRow';
import { useAxios } from '../../contexts/axios';
import TextField from '@mui/material/TextField';
import DatePickerInput from '../DatePickerInput';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ExportToCsv } from 'export-to-csv';
import Tabs from '@mui/material/Tabs';
import * as XLSX from 'xlsx';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import InternalTable from './internalTable';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Chip, IconButton, Tooltip } from '@mui/material';
import TagsInputList from '../tagAdder';
import DD2 from '../DD2';
import { Delete, Edit } from '@mui/icons-material';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

function processDate(date) {
    var res = date.split('-');
    return res[0] + '-' + res[1];
}
function processDateReblaze(date) {
    var res = date.split('-');
    return 'reqCount-' + res[0] + '-' + res[1];
}

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default function MaliciousUserData() {
    const filters = [
        { name: 'All user data', id: 'all' },
        { name: 'Specific UserId', id: 'userId' },
        { name: 'Specific Number', id: 'phoneNumber' },
    ];
    const dataTypeOption = [
        { name: 'Processed Data', id: 'processedData' },
        { name: 'AAID Processed Data', id: 'aaidProcessedData' },
        { name: 'Processed and Reblaze Data', id: 'processedReblaze' },
        { name: 'Per Minute Request Count', id: 'perMinuteRequestCount' },
        { name: 'Processed Status Data', id: 'status' },
    ];

    const tempDataTypeOption = [
        { name: 'Processed Data', id: 'processedData' },
    ];

    const perMinuteColumn = [
        'user_id',
        'user_name',
        'user_email',
        'user_phone',
        'deps_count',
        'unique_departments',
        'is_internal_user',
        'last_login',
    ];

    const reblazeProcessedColumnOrder = [
        'user_id',
        'user_name',
        'user_email',
        'user_phone',
        'total_leads',
        'deps_count',
        'unique_departments',
        'is_internal_user',
        'last_login',
    ];

    const aaidColumn = [
        'aaid',
        'aaid_count',
        'user_ids',
        'phone_numbers',
        'full_names',
        'emails',
        'no_of_user_ids',
        'user_id_unique_strings',
        'device_model',
        'device_name',
        'department_unique_count',
        'department_unique_strings',
        'total',
    ];

    const internal = [
        'total',
        'ip_count',
        'isp_count',
        'unique_ip',
        'unique_isp',
    ];

    const distColumn = [
        'total',
        'ip_count',
        'isp_count',
        'unique_ip',
        'unique_isp',
        'user_id',
        'user_email',
        'user_name',
        'user_phone',
    ];

    const [dataType, setDataType] = useState(dataTypeOption[0]);
    const [tempDataType, setTempDataType] = useState(tempDataTypeOption[0]);
    const [selected, setSelected] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [checked, setChecked] = useState(false);
    const [column, setColumn] = useState([]);
    var dateObj = new Date();
    const [filter, setFilter] = useState(filters[0]);
    const [leadsCreated, setLeadsCreated] = useState(10);
    const [uniqueDepartments, setUniqueDepartments] = useState(5);
    const [associatedUserId, setAssociatedUserId] = useState(1);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [data1, setData1] = useState([]);
    const [dictData1, setDictData1] = useState({});
    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState('false');
    const [data2, setData2] = useState([]);
    const [dictData2, setDictData2] = useState({});
    const [data3, setData3] = useState([]);
    const [internalData3, setInternalData3] = useState([]);
    const [distributedData3, setDistributedData3] = useState([]);
    const [dictData3, setDictData3] = useState({});
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [internalData5, setInternalData5] = useState([]);
    const [distributedData5, setDistributedData5] = useState([]);
    const [dictData5, setDictData5] = useState({});
    const [column1, setColumn1] = useState([]);
    const [column2, setColumn2] = useState([]);
    const [column3, setColumn3] = useState([]);
    const [column4, setColumn4] = useState([]);
    const [column5, setColumn5] = useState([]);
    const [requestCount, setRequestCount] = useState(10);
    const [distributedColumn3, setDistributedColumn3] = useState([]);
    const [internalColumn3, setInternalColumn3] = useState([]);
    const [distributedColumn5, setDistributedColumn5] = useState([]);
    const [internalColumn5, setInternalColumn5] = useState([]);
    const [buttonDisability, setButtonDisability] = useState(false);
    const [nextPageToken, setNextPageToken] = useState('');
    const [value, setValue] = useState(0);
    const authAxios = useAxios();

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    //useEffect(() => {}, [filter]);

    useEffect(() => {
        if (filter?.id !== 'all') {
            setColumn([
                {
                    accessorKey: filter?.id,
                    header: filter?.name,
                    size: 140,
                    muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                        ...getCommonEditTextFieldProps(cell),
                    }),
                },
            ]);
            var data = [];
            var ide = filter?.id;
            if (ide === 'userId') {
                for (var i = 0; i < selected.length; i++) {
                    data.push({ userId: selected[i] });
                }
            } else if (ide === 'phoneNumber') {
                for (var i = 0; i < selected.length; i++) {
                    data.push({ phoneNumber: selected[i] });
                }
            }
            setTableData(data);
        }
    }, [selected, filter]);

    //console.log(column, tableData);

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to delete ${row.getValue(
                        filter?.id
                    )}`
                )
            ) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(
            /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
        );

        const list = [];
        for (let i = 1; i < Math.min(dataStringLines.length, 100001); i++) {
            const row = dataStringLines[i].split(
                /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
            );
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"') d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }
                if (Object.values(obj).filter((x) => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        setColumn(
            headers.map((c) => ({
                accessorKey: c,
                header: c,
                size: 140,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            }))
        );
        setTableData(list);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert keyValues of keyValuess */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
        };
        reader.readAsBinaryString(file);
    };

    const getProcessedData = async (
        url,
        setData1,
        setColumn1,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var processedUserDetailKeys = Object.keys(
                processedData[0]['user_details']
            );
            var processedCols = [];
            for (var i = 0; i < processedUserDetailKeys.length - 3; i++) {
                processedCols.push({
                    header: processedUserDetailKeys[i],
                    accessorKey: processedUserDetailKeys[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 0; i < processedKeys.length; i++) {
                if (processedKeys[i] != 'user_details') {
                    processedCols.push({
                        header: processDate(processedKeys[i]),
                        accessorKey: processDate(processedKeys[i]),
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                }
            }
            processedCols.push({
                header: 'total',
                accessorKey: 'total',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'dep_count',
                accessorKey: 'deps_count',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            for (
                var i = processedUserDetailKeys.length - 3;
                i < processedUserDetailKeys.length;
                i++
            ) {
                if (processedUserDetailKeys[i] !== 'deps_count') {
                    processedCols.push({
                        header: processedUserDetailKeys[i],
                        accessorKey: processedUserDetailKeys[i],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        //size: 50,
                        muiTableBodyCellProps: muitablebodycellprops,
                    });
                }
            }
            setColumn1(processedCols);
            var info = {};
            for (var i = 0; i < processedData?.length; i++) {
                var userId = get(
                    processedData[i]['user_details'],
                    'user_id',
                    0
                );
                info[userId] = processedData[i];
            }
            setDictData1(info);
            var strKeys = Object.keys(info);
            var keys = [];
            for (var i = 0; i < strKeys.length; i++) {
                keys.push(parseInt(strKeys[i]));
            }
            var processedDataVal = [];
            for (var i = 0; i < keys.length; i++) {
                var dataPoint = {};
                var totalVal = 0;
                for (var j = 0; j < processedUserDetailKeys.length - 3; j++) {
                    dataPoint[processedUserDetailKeys[j]] =
                        info[keys[i]]['user_details'][
                            processedUserDetailKeys[j]
                        ];
                }
                for (var j = 0; j < processedKeys.length; j++) {
                    if (processedKeys[j] != 'user_details') {
                        totalVal = totalVal + info[keys[i]][processedKeys[j]];
                        var processeddate = processDate(processedKeys[j]);
                        dataPoint[processeddate] =
                            info[keys[i]][processedKeys[j]];
                    }
                }
                dataPoint['total'] = totalVal;
                dataPoint['deps_count'] = get(
                    info[keys[i]]['user_details'],
                    'deps_count',
                    ''
                );
                for (
                    var j = processedUserDetailKeys.length - 3;
                    j < processedUserDetailKeys.length;
                    j++
                ) {
                    if (processedUserDetailKeys[j] !== 'deps_count') {
                        dataPoint[processedUserDetailKeys[j]] =
                            info[keys[i]]['user_details'][
                                processedUserDetailKeys[j]
                            ]?.toString();
                    }
                }
                processedDataVal.push(dataPoint);
            }
            setData1(processedDataVal);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getProcessedData2 = async (url, setData1, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var processedUserDetailKeys = Object.keys(
                processedData[0]['user_details']
            );
            var info = dictData1;
            var strInfoKeys = Object.keys(info);
            var infoKeys = [];
            for (var i = 0; i < strInfoKeys.length; i++) {
                infoKeys.push(parseInt(strInfoKeys[i]));
            }
            var dates = Object.keys(processedData[0]);
            var index = dates.indexOf('user_details');
            if (index > -1) {
                dates.splice(index, 1);
            }
            for (var i = 0; i < processedData?.length; i++) {
                var userId = get(
                    processedData[i]['user_details'],
                    'user_id',
                    0
                );
                if (infoKeys.includes(userId)) {
                    for (var j = 0; j < dates.length; j++) {
                        info[userId][dates[j]] += processedData[i][dates[j]];
                    }
                } else {
                    info[userId] = processedData[i];
                }
            }
            setDictData1(info);
            var strKeys = Object.keys(info);
            var keys = [];
            for (var i = 0; i < strKeys.length; i++) {
                keys.push(parseInt(strKeys[i]));
            }
            var processedDataVal = [];
            for (var i = 0; i < keys.length; i++) {
                var dataPoint = {};
                var totalVal = 0;
                for (var j = 0; j < processedUserDetailKeys.length - 3; j++) {
                    dataPoint[processedUserDetailKeys[j]] =
                        info[keys[i]]['user_details'][
                            processedUserDetailKeys[j]
                        ];
                }
                for (var j = 0; j < processedKeys.length; j++) {
                    if (processedKeys[j] != 'user_details') {
                        totalVal = totalVal + info[keys[i]][processedKeys[j]];
                        var processeddate = processDate(processedKeys[j]);
                        dataPoint[processeddate] =
                            info[keys[i]][processedKeys[j]];
                    }
                }
                dataPoint['total'] = totalVal;
                dataPoint['deps_count'] = get(
                    info[keys[i]]['user_details'],
                    'deps_count',
                    ''
                );
                for (
                    var j = processedUserDetailKeys.length - 3;
                    j < processedUserDetailKeys.length;
                    j++
                ) {
                    if (processedUserDetailKeys[j] !== 'deps_count') {
                        dataPoint[processedUserDetailKeys[j]] =
                            info[keys[i]]['user_details'][
                                processedUserDetailKeys[j]
                            ]?.toString();
                    }
                }
                processedDataVal.push(dataPoint);
            }
            setData1(processedDataVal);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getAAIDProcessedData = async (
        url,
        setData2,
        setColumn2,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var processedCols = [
                {
                    header: 'AAID',
                    accessorKey: 'aaid',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'UserId count(Postgres)',
                    accessorKey: 'aaid_count',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'UserId (Postgres)',
                    accessorKey: 'user_ids',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'Phone Nos (Postgres)',
                    accessorKey: 'phone_numbers',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'Names (Postgres)',
                    accessorKey: 'full_names',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'Emails (Postgres)',
                    accessorKey: 'emails',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'UserId Count(Mongo)',
                    accessorKey: 'no_of_user_ids',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'UserId (Mongo)',
                    accessorKey: 'user_id_unique_strings',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'Device Model',
                    accessorKey: 'device_model',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
                {
                    header: 'Device Name',
                    accessorKey: 'device_name',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
            ];
            var aaidkeys = aaidColumn;
            for (var i = 0; i < processedKeys.length; i++) {
                if (aaidkeys.includes(processedKeys[i]) === false) {
                    processedCols.push({
                        header: processDate(processedKeys[i]),
                        accessorKey: processDate(processedKeys[i]),
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                }
            }
            processedCols.push({
                header: 'Total',
                accessorKey: 'total',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'Dep Count',
                accessorKey: 'department_unique_count',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: muitablebodycellprops,
                size: 50,
            });
            processedCols.push({
                header: 'Unique Departments',
                accessorKey: 'department_unique_strings',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: muitablebodycellprops,
                size: 50,
            });
            setColumn2(processedCols);
            var info = {};
            for (var i = 0; i < processedData?.length; i++) {
                var aaid = get(processedData[i], 'aaid', '');
                info[aaid] = processedData[i];
            }
            setDictData2(info);
            var keys = Object.keys(info);
            var processedDataVal = [];
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] !== '') {
                    var dataPoint = {};
                    var totalVal = 0;
                    dataPoint['aaid'] = keys[i];
                    dataPoint['aaid_count'] = info[keys[i]]['aaid_count'];
                    dataPoint['user_ids'] = info[keys[i]]['user_ids'];
                    dataPoint['phone_numbers'] = info[keys[i]]['phone_numbers'];
                    dataPoint['full_names'] = info[keys[i]]['full_names'];
                    dataPoint['emails'] = info[keys[i]]['emails'];
                    dataPoint['no_of_user_ids'] =
                        info[keys[i]]['no_of_user_ids'];
                    dataPoint['user_id_unique_strings'] =
                        info[keys[i]]['user_id_unique_strings'];
                    dataPoint['device_model'] = info[keys[i]]['device_model'];
                    dataPoint['device_name'] = info[keys[i]]['device_name'];
                    for (var j = 0; j < processedKeys.length; j++) {
                        if (aaidkeys.includes(processedKeys[j]) === false) {
                            totalVal =
                                totalVal + info[keys[i]][processedKeys[j]];
                            var processeddate = processDate(processedKeys[j]);
                            dataPoint[processeddate] =
                                info[keys[i]][processedKeys[j]];
                        }
                    }
                    dataPoint['total'] = totalVal;
                    dataPoint['department_unique_count'] =
                        info[keys[i]]['department_unique_count'];
                    dataPoint['department_unique_strings'] =
                        info[keys[i]]['department_unique_strings'];
                    processedDataVal.push(dataPoint);
                }
            }
            setData2(processedDataVal);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getAAIDProcessedData2 = async (url, setData2, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var aaidkeys = aaidColumn;
            var info = dictData2;
            var infoKeys = Object.keys(info);
            var dates = Object.keys(processedData[0]);
            for (var i = 0; i < aaidkeys?.length; i++) {
                var index = dates.indexOf(aaidkeys[i]);
                if (index > -1) {
                    dates.splice(index, 1);
                }
            }
            for (var i = 0; i < processedData?.length; i++) {
                var aaid = get(processedData[i], 'aaid', 0);
                if (infoKeys.includes(aaid)) {
                    for (var j = 0; j < dates.length; j++) {
                        info[aaid][dates[j]] += processedData[i][dates[j]];
                    }
                } else {
                    info[aaid] = processedData[i];
                }
            }
            setDictData2(info);
            var keys = Object.keys(info);
            var processedDataVal = [];
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] !== '') {
                    var dataPoint = {};
                    var totalVal = 0;
                    dataPoint['aaid'] = keys[i];
                    dataPoint['aaid_count'] = info[keys[i]]['aaid_count'];
                    dataPoint['user_ids'] = info[keys[i]]['user_ids'];
                    dataPoint['phone_numbers'] = info[keys[i]]['phone_numbers'];
                    dataPoint['full_names'] = info[keys[i]]['full_names'];
                    dataPoint['emails'] = info[keys[i]]['emails'];
                    dataPoint['no_of_user_ids'] =
                        info[keys[i]]['no_of_user_ids'];
                    dataPoint['user_id_unique_strings'] =
                        info[keys[i]]['user_id_unique_strings'];
                    dataPoint['device_model'] = info[keys[i]]['device_model'];
                    dataPoint['device_name'] = info[keys[i]]['device_name'];
                    for (var j = 0; j < processedKeys.length; j++) {
                        if (aaidkeys.includes(processedKeys[j]) === false) {
                            totalVal =
                                totalVal + info[keys[i]][processedKeys[j]];
                            var processeddate = processDate(processedKeys[j]);
                            dataPoint[processeddate] =
                                info[keys[i]][processedKeys[j]];
                        }
                    }
                    dataPoint['total'] = totalVal;
                    dataPoint['department_unique_count'] =
                        info[keys[i]]['department_unique_count'];
                    dataPoint['department_unique_strings'] =
                        info[keys[i]]['department_unique_strings'];
                    processedDataVal.push(dataPoint);
                }
            }
            setData2(processedDataVal);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getProcessedReblazeData = async (
        url,
        setData3,
        setColumn3,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var reblazeDetailKeys = Object.keys(
                processedData[0]['user_details']['reblaze_details']
            );
            var processedCols = [];
            var internalCols = [
                {
                    header: 'uri',
                    accessorKey: 'uri',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
            ];
            var distCols = [
                {
                    header: 'uri',
                    accessorKey: 'uri',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
            ];
            for (var i = 0; i < 4; i++) {
                processedCols.push({
                    header: reblazeProcessedColumnOrder[i],
                    accessorKey: reblazeProcessedColumnOrder[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 0; i < reblazeDetailKeys.length; i++) {
                processedCols.push({
                    header: processDateReblaze(reblazeDetailKeys[i]),
                    accessorKey: processDateReblaze(reblazeDetailKeys[i]),
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 0; i < processedKeys.length; i++) {
                if (processedKeys[i] !== 'user_details') {
                    processedCols.push({
                        header: processDate(processedKeys[i]),
                        accessorKey: processDate(processedKeys[i]),
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                    internalCols.push({
                        header: processedKeys[i],
                        accessorKey: processedKeys[i],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                    distCols.push({
                        header: processedKeys[i],
                        accessorKey: processedKeys[i],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                }
            }
            for (var i = 0; i < internal.length; i++) {
                internalCols.push({
                    header: internal[i],
                    accessorKey: internal[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 0; i < distColumn.length; i++) {
                distCols.push({
                    header: distColumn[i],
                    accessorKey: distColumn[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 4; i < reblazeProcessedColumnOrder.length; i++) {
                processedCols.push({
                    header: reblazeProcessedColumnOrder[i],
                    accessorKey: reblazeProcessedColumnOrder[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    //size: 50,
                    muiTableBodyCellProps: muitablebodycellprops,
                });
            }
            setColumn3(processedCols);
            setInternalColumn3(internalCols);
            setDistributedColumn3(distCols);
            var info = {};
            for (var i = 0; i < processedData?.length; i++) {
                var userId = get(
                    processedData[i]['user_details'],
                    'user_id',
                    0
                );
                info[userId] = processedData[i];
            }
            setDictData3(info);
            var strKeys = Object.keys(info);
            var keys = [];
            for (var i = 0; i < strKeys.length; i++) {
                keys.push(parseInt(strKeys[i]));
            }
            var processedDataVal = [];
            var internalData = [];
            var distData = [];
            for (var i = 0; i < keys.length; i++) {
                var dataPoint = {};
                var inteData = [];
                var reblazeUriDetailsKeys = Object.keys(
                    info[keys[i]]['user_details']['reblaze_uri_details']
                );
                for (var j = 0; j < reblazeUriDetailsKeys.length; j++) {
                    var point = {};
                    var distPoint = {};
                    point['uri'] = reblazeUriDetailsKeys[j];
                    distPoint['uri'] = reblazeUriDetailsKeys[j];
                    var total = 0;
                    for (var k = 0; k < processedKeys.length; k++) {
                        if (processedKeys[k] !== 'user_details') {
                            var val = get(
                                info[keys[i]]['user_details'][
                                    'reblaze_uri_details'
                                ][reblazeUriDetailsKeys[j]],
                                processedKeys[k],
                                0
                            );
                            point[processedKeys[k]] = val;
                            distPoint[processedKeys[k]] = val;
                            total += val;
                        }
                    }
                    point['total'] = total;
                    distPoint['total'] = total;
                    for (var k = 1; k < internal.length; k++) {
                        point[internal[k]] = get(
                            info[keys[i]]['user_details'][
                                'reblaze_uri_details'
                            ][reblazeUriDetailsKeys[j]],
                            internal[k],
                            ''
                        );
                    }
                    for (var k = 1; k < distColumn.length; k++) {
                        if (k < 5) {
                            distPoint[distColumn[k]] = get(
                                info[keys[i]]['user_details'][
                                    'reblaze_uri_details'
                                ][reblazeUriDetailsKeys[j]],
                                distColumn[k],
                                ''
                            );
                        } else {
                            distPoint[distColumn[k]] = get(
                                info[keys[i]]['user_details'],
                                distColumn[k],
                                ''
                            );
                        }
                    }
                    inteData.push(point);
                    distData.push(distPoint);
                }
                internalData.push(inteData);
                var totalVal = 0;
                for (var j = 0; j < 4; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ];
                }
                for (var j = 0; j < reblazeDetailKeys?.length; j++) {
                    var reblazeprocesseddate = processDateReblaze(
                        reblazeDetailKeys[j]
                    );
                    dataPoint[reblazeprocesseddate] =
                        info[keys[i]]['user_details']['reblaze_details'][
                            reblazeDetailKeys[j]
                        ];
                }
                for (var j = 0; j < processedKeys.length; j++) {
                    if (processedKeys[j] != 'user_details') {
                        totalVal = totalVal + info[keys[i]][processedKeys[j]];
                        var processeddate = processDate(processedKeys[j]);
                        dataPoint[processeddate] =
                            info[keys[i]][processedKeys[j]];
                    }
                }
                dataPoint['total_leads'] = totalVal;
                dataPoint['deps_count'] = get(
                    info[keys[i]]['user_details'],
                    'deps_count',
                    ''
                );
                for (var j = 6; j < reblazeProcessedColumnOrder.length; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ]?.toString();
                }
                processedDataVal.push(dataPoint);
            }
            setData3(processedDataVal);
            setInternalData3(internalData);
            setDistributedData3(distData);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getProcessedReblazeData2 = async (url, setData3, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var reblazeDetailKeys = Object.keys(
                processedData[0]['user_details']['reblaze_details']
            );
            var info = dictData3;
            var strInfoKeys = Object.keys(info);
            var infoKeys = [];
            for (var i = 0; i < strInfoKeys.length; i++) {
                infoKeys.push(parseInt(strInfoKeys[i]));
            }
            var dates = Object.keys(processedData[0]);
            var index = dates.indexOf('user_details');
            if (index > -1) {
                dates.splice(index, 1);
            }
            for (var i = 0; i < processedData?.length; i++) {
                var userId = get(
                    processedData[i]['user_details'],
                    'user_id',
                    0
                );
                if (infoKeys.includes(userId)) {
                    for (var j = 0; j < dates.length; j++) {
                        info[userId][dates[j]] += processedData[i][dates[j]];
                    }
                } else {
                    info[userId] = processedData[i];
                }
            }
            setDictData3(info);
            var strKeys = Object.keys(info);
            var keys = [];
            for (var i = 0; i < strKeys.length; i++) {
                keys.push(parseInt(strKeys[i]));
            }
            var processedDataVal = [];
            var internalData = [];
            var distData = [];
            for (var i = 0; i < keys.length; i++) {
                var dataPoint = {};
                var inteData = [];
                var reblazeUriDetailsKeys = Object.keys(
                    info[keys[i]]['user_details']['reblaze_uri_details']
                );
                for (var j = 0; j < reblazeUriDetailsKeys.length; j++) {
                    var point = {};
                    var distPoint = {};
                    point['uri'] = reblazeUriDetailsKeys[j];
                    distPoint['uri'] = reblazeUriDetailsKeys[j];
                    var total = 0;
                    for (var k = 0; k < processedKeys.length; k++) {
                        if (processedKeys[k] !== 'user_details') {
                            var val = get(
                                info[keys[i]]['user_details'][
                                    'reblaze_uri_details'
                                ][reblazeUriDetailsKeys[j]],
                                processedKeys[k],
                                0
                            );
                            point[processedKeys[k]] = val;
                            distPoint[processedKeys[k]] = val;
                            total += val;
                        }
                    }
                    point['total'] = total;
                    distPoint['total'] = total;
                    for (var k = 1; k < internal.length; k++) {
                        point[internal[k]] = get(
                            info[keys[i]]['user_details'][
                                'reblaze_uri_details'
                            ][reblazeUriDetailsKeys[j]],
                            internal[k],
                            ''
                        );
                    }
                    for (var k = 1; k < distColumn.length; k++) {
                        if (k < 5) {
                            distPoint[distColumn[k]] = get(
                                info[keys[i]]['user_details'][
                                    'reblaze_uri_details'
                                ][reblazeUriDetailsKeys[j]],
                                distColumn[k],
                                ''
                            );
                        } else {
                            distPoint[distColumn[k]] = get(
                                info[keys[i]]['user_details'],
                                distColumn[k],
                                ''
                            );
                        }
                    }
                    inteData.push(point);
                    distData.push(distPoint);
                }
                internalData.push(inteData);
                var totalVal = 0;
                for (var j = 0; j < 4; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ];
                }
                for (var j = 0; j < reblazeDetailKeys?.length; j++) {
                    var reblazeprocesseddate = processDateReblaze(
                        reblazeDetailKeys[j]
                    );
                    dataPoint[reblazeprocesseddate] =
                        info[keys[i]]['user_details']['reblaze_details'][
                            reblazeDetailKeys[j]
                        ];
                }
                for (var j = 0; j < processedKeys.length; j++) {
                    if (processedKeys[j] != 'user_details') {
                        totalVal = totalVal + info[keys[i]][processedKeys[j]];
                        var processeddate = processDate(processedKeys[j]);
                        dataPoint[processeddate] =
                            info[keys[i]][processedKeys[j]];
                    }
                }
                dataPoint['total_leads'] = totalVal;
                dataPoint['deps_count'] = get(
                    info[keys[i]]['user_details'],
                    'deps_count',
                    ''
                );
                for (var j = 6; j < reblazeProcessedColumnOrder.length; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ]?.toString();
                }
                processedDataVal.push(dataPoint);
            }
            setData3(processedDataVal);
            setInternalData3(internalData);
            setDistributedData3(distData);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getProcessedStatusData = async (
        url,
        setData5,
        setColumn5,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var reblazeDetailKeys = Object.keys(
                processedData[0]['user_details']['reblaze_details']
            );
            var processedCols = [];
            var internalCols = [
                {
                    header: 'status',
                    accessorKey: 'uri',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
            ];
            var distCols = [
                {
                    header: 'status',
                    accessorKey: 'uri',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                },
            ];
            for (var i = 0; i < 4; i++) {
                processedCols.push({
                    header: reblazeProcessedColumnOrder[i],
                    accessorKey: reblazeProcessedColumnOrder[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 0; i < reblazeDetailKeys?.length; i++) {
                processedCols.push({
                    header: processDateReblaze(reblazeDetailKeys[i]),
                    accessorKey: processDateReblaze(reblazeDetailKeys[i]),
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 0; i < processedKeys.length; i++) {
                if (processedKeys[i] !== 'user_details') {
                    processedCols.push({
                        header: processDate(processedKeys[i]),
                        accessorKey: processDate(processedKeys[i]),
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                    internalCols.push({
                        header: processedKeys[i],
                        accessorKey: processedKeys[i],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                    distCols.push({
                        header: processedKeys[i],
                        accessorKey: processedKeys[i],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: muitablebodycellprops,
                        size: 50,
                    });
                }
            }
            for (var i = 0; i < internal.length; i++) {
                internalCols.push({
                    header: internal[i],
                    accessorKey: internal[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }

            for (var i = 0; i < distColumn?.length; i++) {
                distCols.push({
                    header: distColumn[i],
                    accessorKey: distColumn[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                    size: 50,
                });
            }
            for (var i = 4; i < reblazeProcessedColumnOrder.length; i++) {
                processedCols.push({
                    header: reblazeProcessedColumnOrder[i],
                    accessorKey: reblazeProcessedColumnOrder[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    //size: 50,
                    muiTableBodyCellProps: muitablebodycellprops,
                });
            }
            setColumn5(processedCols);
            setInternalColumn5(internalCols);
            setDistributedColumn5(distCols);
            var info = {};
            for (var i = 0; i < processedData?.length; i++) {
                var userId = get(
                    processedData[i]['user_details'],
                    'user_id',
                    0
                );
                info[userId] = processedData[i];
            }
            setDictData5(info);
            var strKeys = Object.keys(info);
            var keys = [];
            for (var i = 0; i < strKeys.length; i++) {
                keys.push(parseInt(strKeys[i]));
            }
            var processedDataVal = [];
            var internalData = [];
            var distData = [];
            for (var i = 0; i < keys.length; i++) {
                var dataPoint = {};
                var inteData = [];
                var reblazeUriDetailsKeys = Object.keys(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    )
                );
                var ipCount = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'ip_count',
                    0
                );
                var ispCount = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'isp_count',
                    0
                );
                var uniqueIp = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'unique_ip',
                    ''
                );
                var uniqueIsp = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'unique_isp',
                    ''
                );

                for (var j = 0; j < reblazeUriDetailsKeys?.length; j++) {
                    if (internal.includes(reblazeUriDetailsKeys[j]) === false) {
                        var point = {};
                        var distPoint = {};
                        point['uri'] = reblazeUriDetailsKeys[j];
                        distPoint['uri'] = reblazeUriDetailsKeys[j];
                        var total = 0;
                        for (var k = 0; k < processedKeys.length; k++) {
                            if (processedKeys[k] !== 'user_details') {
                                var val = get(
                                    info[keys[i]]['user_details'][
                                        'reblaze_uri_status_details'
                                    ]['/api/v2/applied-jobs/'][
                                        reblazeUriDetailsKeys[j]
                                    ],
                                    processedKeys[k],
                                    0
                                );
                                point[processedKeys[k]] = val;
                                distPoint[processedKeys[k]] = val;
                                total += val;
                            }
                        }
                        point['total'] = total;
                        distPoint['total'] = total;
                        point['ip_count'] = ipCount;
                        point['isp_count'] = ispCount;
                        point['unique_ip'] = uniqueIp;
                        point['unique_isp'] = uniqueIsp;
                        distPoint['ip_count'] = ipCount;
                        distPoint['isp_count'] = ispCount;
                        distPoint['unique_ip'] = uniqueIp;
                        distPoint['unique_isp'] = uniqueIsp;
                        for (var k = 5; k < distColumn?.length; k++) {
                            distPoint[distColumn[k]] = get(
                                info[keys[i]]['user_details'],
                                distColumn[k],
                                ''
                            );
                        }
                        inteData.push(point);
                        distData.push(distPoint);
                    }
                }

                internalData.push(inteData);
                var totalVal = 0;
                for (var j = 0; j < 4; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ];
                }
                for (var j = 0; j < reblazeDetailKeys?.length; j++) {
                    var reblazeprocesseddate = processDateReblaze(
                        reblazeDetailKeys[j]
                    );
                    dataPoint[reblazeprocesseddate] =
                        info[keys[i]]['user_details']['reblaze_details'][
                            reblazeDetailKeys[j]
                        ];
                }
                for (var j = 0; j < processedKeys.length; j++) {
                    if (processedKeys[j] != 'user_details') {
                        totalVal = totalVal + info[keys[i]][processedKeys[j]];
                        var processeddate = processDate(processedKeys[j]);
                        dataPoint[processeddate] =
                            info[keys[i]][processedKeys[j]];
                    }
                }
                dataPoint['total_leads'] = totalVal;
                dataPoint['deps_count'] = get(
                    info[keys[i]]['user_details'],
                    'deps_count',
                    ''
                );
                for (var j = 6; j < reblazeProcessedColumnOrder.length; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ]?.toString();
                }
                processedDataVal.push(dataPoint);
            }
            setData5(processedDataVal);
            setInternalData5(internalData);
            setDistributedData5(distData);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getProcessedStatusData2 = async (url, setData5, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedKeys = Object.keys(processedData[0]);
            var reblazeDetailKeys = Object.keys(
                processedData[0]['user_details']['reblaze_details']
            );
            var info = dictData5;
            var strInfoKeys = Object.keys(info);
            var infoKeys = [];
            for (var i = 0; i < strInfoKeys.length; i++) {
                infoKeys.push(parseInt(strInfoKeys[i]));
            }
            var dates = Object.keys(processedData[0]);
            var index = dates.indexOf('user_details');
            if (index > -1) {
                dates.splice(index, 1);
            }
            for (var i = 0; i < processedData?.length; i++) {
                var userId = get(
                    processedData[i]['user_details'],
                    'user_id',
                    0
                );
                if (infoKeys.includes(userId)) {
                    for (var j = 0; j < dates.length; j++) {
                        info[userId][dates[j]] += processedData[i][dates[j]];
                    }
                } else {
                    info[userId] = processedData[i];
                }
            }
            setDictData5(info);
            var strKeys = Object.keys(info);
            var keys = [];
            for (var i = 0; i < strKeys.length; i++) {
                keys.push(parseInt(strKeys[i]));
            }
            var processedDataVal = [];
            var internalData = [];
            var distData = [];
            for (var i = 0; i < keys.length; i++) {
                var dataPoint = {};
                var inteData = [];
                var reblazeUriDetailsKeys = Object.keys(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    )
                );
                var ipCount = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'ip_count',
                    0
                );
                var ispCount = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'isp_count',
                    0
                );
                var uniqueIp = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'unique_ip',
                    ''
                );
                var uniqueIsp = get(
                    get(
                        info[keys[i]]['user_details'][
                            'reblaze_uri_status_details'
                        ],
                        '/api/v2/applied-jobs/',
                        {}
                    ),
                    'unique_isp',
                    ''
                );

                for (var j = 0; j < reblazeUriDetailsKeys?.length; j++) {
                    if (internal.includes(reblazeUriDetailsKeys[j]) === false) {
                        var point = {};
                        var distPoint = {};
                        point['uri'] = reblazeUriDetailsKeys[j];
                        distPoint['uri'] = reblazeUriDetailsKeys[j];
                        var total = 0;
                        for (var k = 0; k < processedKeys.length; k++) {
                            if (processedKeys[k] !== 'user_details') {
                                var val = get(
                                    info[keys[i]]['user_details'][
                                        'reblaze_uri_status_details'
                                    ]['/api/v2/applied-jobs/'][
                                        reblazeUriDetailsKeys[j]
                                    ],
                                    processedKeys[k],
                                    0
                                );
                                point[processedKeys[k]] = val;
                                distPoint[processedKeys[k]] = val;
                                total += val;
                            }
                        }
                        point['total'] = total;
                        distPoint['total'] = total;
                        point['ip_count'] = ipCount;
                        point['isp_count'] = ispCount;
                        point['unique_ip'] = uniqueIp;
                        point['unique_isp'] = uniqueIsp;
                        distPoint['ip_count'] = ipCount;
                        distPoint['isp_count'] = ispCount;
                        distPoint['unique_ip'] = uniqueIp;
                        distPoint['unique_isp'] = uniqueIsp;
                        for (var k = 5; k < distColumn?.length; k++) {
                            distPoint[distColumn[k]] = get(
                                info[keys[i]]['user_details'],
                                distColumn[k],
                                ''
                            );
                        }
                        inteData.push(point);
                        distData.push(distPoint);
                    }
                }

                internalData.push(inteData);
                var totalVal = 0;
                for (var j = 0; j < 4; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ];
                }
                for (var j = 0; j < reblazeDetailKeys?.length; j++) {
                    var reblazeprocesseddate = processDateReblaze(
                        reblazeDetailKeys[j]
                    );
                    dataPoint[reblazeprocesseddate] =
                        info[keys[i]]['user_details']['reblaze_details'][
                            reblazeDetailKeys[j]
                        ];
                }
                for (var j = 0; j < processedKeys.length; j++) {
                    if (processedKeys[j] != 'user_details') {
                        totalVal = totalVal + info[keys[i]][processedKeys[j]];
                        var processeddate = processDate(processedKeys[j]);
                        dataPoint[processeddate] =
                            info[keys[i]][processedKeys[j]];
                    }
                }
                dataPoint['total_leads'] = totalVal;
                dataPoint['deps_count'] = get(
                    info[keys[i]]['user_details'],
                    'deps_count',
                    ''
                );
                for (var j = 6; j < reblazeProcessedColumnOrder.length; j++) {
                    dataPoint[reblazeProcessedColumnOrder[j]] =
                        info[keys[i]]['user_details'][
                            reblazeProcessedColumnOrder[j]
                        ]?.toString();
                }
                processedDataVal.push(dataPoint);
            }
            setData5(processedDataVal);
            setInternalData5(internalData);
            setDistributedData5(distData);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getPerMinuteData = async (
        url,
        setData4,
        setColumn4,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedCols = [];
            processedCols.push({
                header: 'uri',
                accessorKey: 'uri',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'date',
                accessorKey: 'date',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'requests_count',
                accessorKey: 'requests_count',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'avg_time',
                accessorKey: 'avg_time',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'std_time_seconds',
                accessorKey: 'std_time_seconds',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'max_time',
                accessorKey: 'max_time',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'min_time',
                accessorKey: 'min_time',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            processedCols.push({
                header: 'total_requests_count',
                accessorKey: 'total_requests_count',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                size: 50,
                muiTableBodyCellProps: muitablebodycellprops,
            });
            for (var i = 0; i < perMinuteColumn?.length; i++) {
                processedCols.push({
                    header: perMinuteColumn[i],
                    accessorKey: perMinuteColumn[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    size: 50,
                    muiTableBodyCellProps: muitablebodycellprops,
                });
            }
            setColumn4(processedCols);
            var processedDat = [];
            for (var i = 0; i < processedData?.length; i++) {
                var uris = Object.keys(processedData[i]);
                for (var j = 0; j < uris?.length; j++) {
                    if (uris[j] !== 'user_details') {
                        var dates = Object.keys(processedData[i][uris[j]]);
                        for (var k = 0; k < dates?.length; k++) {
                            var info =
                                processedData[i][uris[j]][dates[k]]['info'];
                            var tot =
                                processedData[i][uris[j]][dates[k]]['total'];
                            for (var l = 0; l < info?.length; l++) {
                                var dataPoint = {};
                                dataPoint['uri'] = uris[j];
                                dataPoint['date'] = dates[k];
                                dataPoint['requests_count'] =
                                    info[l]['requests_count'];
                                dataPoint['avg_time'] = info[l]['avg_time'];
                                dataPoint['std_time_seconds'] =
                                    info[l]['std_time_seconds'];
                                dataPoint['max_time'] = info[l]['max_time'];
                                dataPoint['min_time'] = info[l]['min_time'];
                                dataPoint['total_requests_count'] = tot;
                                for (
                                    var m = 0;
                                    m < perMinuteColumn?.length;
                                    m++
                                ) {
                                    dataPoint[perMinuteColumn[m]] =
                                        processedData[i]['user_details'][
                                            perMinuteColumn[m]
                                        ];
                                }
                                processedDat.push(dataPoint);
                            }
                        }
                    }
                }
            }
            setData4([...data4, ...processedDat]);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const getPerMinuteData2 = async (url, setData4, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var processedData = get(data, 'entries', []);
            var processedDat = [];
            for (var i = 0; i < processedData?.length; i++) {
                var uris = Object.keys(processedData[i]);
                for (var j = 0; j < uris?.length; j++) {
                    if (uris[j] !== 'user_details') {
                        var dates = Object.keys(processedData[i][uris[j]]);
                        for (var k = 0; k < dates?.length; k++) {
                            var info =
                                processedData[i][uris[j]][dates[k]]['info'];
                            var tot =
                                processedData[i][uris[j]][dates[k]]['total'];
                            for (var l = 0; l < info?.length; l++) {
                                var dataPoint = {};
                                dataPoint['uri'] = uris[j];
                                dataPoint['date'] = dates[k];
                                dataPoint['requests_count'] =
                                    info[l]['requests_count'];
                                dataPoint['avg_time'] = info[l]['avg_time'];
                                dataPoint['std_time_seconds'] =
                                    info[l]['std_time_seconds'];
                                dataPoint['max_time'] = info[l]['max_time'];
                                dataPoint['min_time'] = info[l]['min_time'];
                                dataPoint['total_requests_count'] = tot;
                                for (
                                    var m = 0;
                                    m < perMinuteColumn?.length;
                                    m++
                                ) {
                                    dataPoint[perMinuteColumn[m]] =
                                        processedData[i]['user_details'][
                                            perMinuteColumn[m]
                                        ];
                                }
                                processedDat.push(dataPoint);
                            }
                        }
                    }
                }
            }
            setData4([...data4, ...processedDat]);
            setNextPageToken(get(data, 'nextPageToken', ''));
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    function getPIIData() {
        if (tableData.length == 0) {
            alert('Please insert atleast one userId');
            return;
        }
        if (tableData.length > 100000) {
            alert('Cannot process more than 100000 data points');
            return;
        }
        if (checked && filter === null) {
            alert('Please choose key corresponding to which you want PII data');
            return;
        }
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        //setLoading(true);
        //setButtonDisability(true);
        var dataList = [];
        if (checked) {
            for (var i = 0; i < tableData.length; i++) {
                dataList.push(tableData[i][column[0]?.accessorKey]);
            }
        } else {
            for (var i = 0; i < selected.length; i++) {
                dataList.push(selected[i]);
            }
        }
        if (dataType.id === 'processedData') {
            var uri = `${BACKEND_URL}/gcp/user-job-applied-processed-data?startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${endDate.toISOString().split('T')[0]}`;
            if (filter?.id === 'userId') {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&userId=${dataList[i]}`;
                }
            } else {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&phoneNumber=${dataList[i]}`;
                }
            }
            getProcessedData(uri, setData1, setColumn1, setLoading, {
                align: 'left',
            });
        } else if (dataType.id === 'aaidProcessedData') {
            var uri = `${BACKEND_URL}/gcp/user-job-applied-processed-aaid-data?startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${endDate.toISOString().split('T')[0]}&userIdCount=1`;
            if (filter?.id === 'userId') {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&userId=${dataList[i]}`;
                }
            } else {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&phoneNumber=${dataList[i]}`;
                }
            }
            getAAIDProcessedData(uri, setData2, setColumn2, setLoading, {
                align: 'left',
            });
        } else if (dataType.id === 'processedReblaze') {
            var uri = `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-data?startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${endDate.toISOString().split('T')[0]}`;
            if (filter?.id === 'userId') {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&userId=${dataList[i]}`;
                }
            } else {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&phoneNumber=${dataList[i]}`;
                }
            }
            getProcessedReblazeData(uri, setData3, setColumn3, setLoading, {
                align: 'left',
            });
        } else if (dataType.id === 'perMinuteRequestCount') {
            var uri = `${BACKEND_URL}/gcp/user-job-applied-processed-requests-per-minute-data?startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${endDate.toISOString().split('T')[0]}&requests_count=1`;
            if (filter?.id === 'userId') {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&userId=${dataList[i]}`;
                }
            } else {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&phoneNumber=${dataList[i]}`;
                }
            }
            getPerMinuteData(uri, setData4, setColumn4, setLoading, {
                align: 'left',
            });
        } else {
            var uri = `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-status-data?startDate=${
                startDate.toISOString().split('T')[0]
            }&endDate=${endDate.toISOString().split('T')[0]}`;
            if (filter?.id === 'userId') {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&userId=${dataList[i]}`;
                }
            } else {
                for (var i = 0; i < dataList?.length; i++) {
                    uri += `&phoneNumber=${dataList[i]}`;
                }
            }
            getProcessedStatusData(uri, setData5, setColumn5, setLoading, {
                align: 'left',
            });
        }
    }

    const getData = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (dataType?.id === 'processedData') {
            getProcessedData(
                `${BACKEND_URL}/gcp/user-job-applied-processed-data?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}`,
                setData1,
                setColumn1,
                setLoading,
                {
                    align: 'left',
                }
            );
        } else if (dataType?.id === 'aaidProcessedData') {
            getAAIDProcessedData(
                `${BACKEND_URL}/gcp/user-job-applied-processed-aaid-data?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&userIdCount=${associatedUserId}`,
                setData2,
                setColumn2,
                setLoading,
                {
                    align: 'left',
                }
            );
        } else if (dataType?.id === 'processedReblaze') {
            getProcessedReblazeData(
                `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-data?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}`,
                setData3,
                setColumn3,
                setLoading,
                {
                    align: 'left',
                }
            );
        } else if (dataType?.id === 'perMinuteRequestCount') {
            getPerMinuteData(
                `${BACKEND_URL}/gcp/user-job-applied-processed-requests-per-minute-data?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&requests_count=${requestCount}`,
                setData4,
                setColumn4,
                setLoading,
                {
                    align: 'left',
                }
            );
        } else {
            getProcessedStatusData(
                `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-status-data?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}`,
                setData5,
                setColumn5,
                setLoading,
                {
                    align: 'left',
                }
            );
        }
    };

    //console.log(nextPageToken);

    const csvOptions1 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'Processed Data',
        headers: column1.map((c) => c.header),
    };

    const csvOptions2 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'AAID Processed Data',
        headers: column2.map((c) => c.header),
    };
    const csvOptions3 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'Processed and Reblaze Data',
        headers: column3.map((c) => c.header),
    };
    const csvOptionsDist3 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'Reblaze Data',
        headers: distributedColumn3.map((c) => c.header),
    };
    const csvOptions4 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'Per min Request Count Data',
        headers: column4.map((c) => c.header),
    };
    const csvOptions5 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'Processed and status Data',
        headers: column5.map((c) => c.header),
    };
    const csvOptionsDist5 = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: 'Status Data',
        headers: distributedColumn5.map((c) => c.header),
    };

    const csvExporter1 = new ExportToCsv(csvOptions1);
    const csvExporter2 = new ExportToCsv(csvOptions2);
    const csvExporter3 = new ExportToCsv(csvOptions3);
    const csvExporterDist3 = new ExportToCsv(csvOptionsDist3);
    const csvExporter4 = new ExportToCsv(csvOptions4);
    const csvExporter5 = new ExportToCsv(csvOptions5);
    const csvExporterDist5 = new ExportToCsv(csvOptionsDist5);

    const handleExportData1 = () => {
        csvExporter1.generateCsv(data1);
    };
    const handleExportData2 = () => {
        csvExporter2.generateCsv(data2);
    };
    const handleExportData3 = () => {
        csvExporter3.generateCsv(data3);
    };
    const handleExportDistData3 = () => {
        csvExporterDist3.generateCsv(distributedData3);
    };
    const handleExportData4 = () => {
        csvExporter4.generateCsv(data4);
    };
    const handleExportDistData5 = () => {
        csvExporterDist5.generateCsv(distributedData5);
    };
    const handleExportData5 = () => {
        csvExporter5.generateCsv(data5);
    };

    useEffect(() => {
        setData1([]);
        setDictData1({});
        setData2([]);
        setDictData2({});
        setData3([]);
        setDictData3({});
        setData5([]);
        setDictData5({});
        setData4([]);
        setColumn1([]);
        setColumn2([]);
        setColumn3([]);
        setColumn4([]);
        setColumn5([]);
        setTableData([]);
        setColumn([]);
        setDistributedColumn3([]);
        setDistributedData3([]);
        setDistributedColumn5([]);
        setDistributedData5([]);
        setOk('false');
        setButtonDisability(false);
        setChecked(false);
        setSelected([]);
        setValidationErrors({});
        setNextPageToken('');
    }, [
        filter,
        startDate,
        endDate,
        leadsCreated,
        uniqueDepartments,
        dataType,
        associatedUserId,
        requestCount,
    ]);

    return (
        <>
            <FlexRow>
                <DD2
                    label={'Filter'}
                    emptyLabel={'filter'}
                    options={filters}
                    value={filter}
                    onChange={setFilter}
                    displayProperty={'name'}
                />

                <DD2
                    label={'Data Type'}
                    emptyLabel={'Select a data type'}
                    options={dataTypeOption}
                    value={dataType}
                    onChange={setDataType}
                    displayProperty={'name'}
                />

                <DatePickerInput
                    label={'Start Date'}
                    value={startDate}
                    onChange={setStartDate}
                    maxDate={endDate}
                />
                <DatePickerInput
                    label={'End Date'}
                    value={endDate}
                    onChange={setEndDate}
                    maxDate={dateObj}
                />
                {filter?.id !== 'all' ? (
                    <div style={{ marginTop: '42px' }}>
                        <FlexRow>
                            <FormControl
                                component="fieldset"
                                variant="standard"
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={buttonDisability}
                                                color="secondary"
                                                checked={checked}
                                                onChange={handleCheck}
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                            />
                                        }
                                        label={
                                            <Chip
                                                label={'UPLOAD CSV'}
                                                style={{ fontWeight: 'bold' }}
                                                color={
                                                    buttonDisability
                                                        ? 'default'
                                                        : 'error'
                                                }
                                            />
                                        }
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                            <input
                                size={300}
                                disabled={buttonDisability || !checked}
                                type="file"
                                accept=".csv,.xlsx,.xls"
                                onChange={handleFileUpload}
                            />
                            <FlexRow></FlexRow>
                        </FlexRow>
                    </div>
                ) : (
                    <div style={{ marginTop: '42px' }}>
                        <FlexRow>
                            <TextField
                                label={'Leads Created >='}
                                value={leadsCreated}
                                sx={{ width: 200 }}
                                onChange={(e) => {
                                    setLeadsCreated(e.target.value);
                                }}
                            />
                            <TextField
                                label={'Unique Departments >='}
                                value={uniqueDepartments}
                                sx={{ width: 180 }}
                                onChange={(e) => {
                                    setUniqueDepartments(e.target.value);
                                }}
                            />
                            {dataType?.id === 'aaidProcessedData' ? (
                                <TextField
                                    label={'AssociatedUserIds >='}
                                    value={associatedUserId}
                                    sx={{ width: 180 }}
                                    onChange={(e) => {
                                        setAssociatedUserId(e.target.value);
                                    }}
                                />
                            ) : dataType?.id === 'perMinuteRequestCount' ? (
                                <TextField
                                    label={'Request Count per min'}
                                    value={requestCount}
                                    sx={{ width: 180 }}
                                    onChange={(e) => {
                                        setRequestCount(e.target.value);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                        </FlexRow>
                    </div>
                )}
                <div style={{ marginTop: '42px' }}>
                    <Button
                        children={'Get Data'}
                        sx={{
                            width: 180,
                            backgroundColor: '#0096FF',
                            fontWeight: 'bold',
                            color: 'white',
                            height: 53,
                            padding: 2,
                            ':hover': {
                                backgroundColor: '#6F8FAF',
                                color: 'white',
                            },
                        }}
                        onClick={() => {
                            filter?.id !== 'all' ? getPIIData() : getData();
                        }}
                        disabled={buttonDisability}
                    />
                </div>
            </FlexRow>
            {filter?.id !== 'all' ? (
                <FlexRow>
                    <TagsInputList
                        disabled={checked}
                        label={'Label'}
                        width={'1400px'}
                        selected={selected}
                        setSelected={setSelected}
                        placeHolder={`Enter ${filter?.id}`}
                    />
                </FlexRow>
            ) : (
                <></>
            )}

            {filter?.id !== 'all' && buttonDisability === false ? (
                <MaterialReactTable
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'left',
                            },
                            size: 120,
                        },
                    }}
                    columns={column}
                    data={tableData}
                    editingMode="modal" //default
                    enableStickyFooter
                    enableStickyHeader
                    enableEditing
                    enableDensityToggle={false}
                    state={{
                        isLoading: loading,
                    }}
                    initialState={{ density: 'compact' }}
                    onEditingRowSave={handleSaveRowEdits}
                    renderRowActions={({ row, table }) => (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1rem',
                            }}
                        >
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton
                                    disabled={buttonDisability}
                                    onClick={() => table.setEditingRow(row)}
                                >
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton
                                    color="error"
                                    onClick={() => handleDeleteRow(row)}
                                    disabled={buttonDisability}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            ) : (
                <>
                    {(() => {
                        switch (ok) {
                            case 'false':
                                return <></>;
                            case 'true':
                                return (
                                    <>
                                        {dataType?.id === 'processedReblaze' ||
                                        dataType?.id === 'status' ? (
                                            <>
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        marginTop: '30px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            borderBottom: 1,
                                                            borderColor:
                                                                'divider',
                                                        }}
                                                    >
                                                        <Tabs
                                                            value={value}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            aria-label="basic tabs example"
                                                        >
                                                            <Tab
                                                                label={
                                                                    dataType?.id ===
                                                                    'processedReblaze'
                                                                        ? 'Reblaze and Processed'
                                                                        : 'Status and Processed'
                                                                }
                                                                {...a11yProps(
                                                                    0
                                                                )}
                                                            />
                                                            <Tab
                                                                label={
                                                                    dataType?.id ===
                                                                    'processedReblaze'
                                                                        ? 'Reblaze Logs'
                                                                        : 'Status'
                                                                }
                                                                {...a11yProps(
                                                                    1
                                                                )}
                                                            />
                                                        </Tabs>
                                                    </Box>
                                                    <TabPanel
                                                        value={value}
                                                        index={0}
                                                    >
                                                        <>
                                                            <div className="p-1">
                                                                <MaterialReactTable
                                                                    data={
                                                                        dataType?.id ===
                                                                        'processedReblaze'
                                                                            ? data3
                                                                            : data5
                                                                    }
                                                                    columns={
                                                                        dataType?.id ===
                                                                        'processedReblaze'
                                                                            ? column3
                                                                            : column5
                                                                    }
                                                                    enableStickyFooter
                                                                    enableStickyHeader
                                                                    initialState={{
                                                                        sorting:
                                                                            [
                                                                                {
                                                                                    id: 'total_leads',
                                                                                    desc: true,
                                                                                },
                                                                            ],
                                                                        columnVisibility:
                                                                            {
                                                                                last_login: false,
                                                                                is_internal_user: false,
                                                                            },
                                                                        columnPinning:
                                                                            {
                                                                                left: [
                                                                                    'mrt-row-select',
                                                                                    'mrt-row-expand',
                                                                                    'mrt-row-actions',
                                                                                ],
                                                                            },
                                                                        density:
                                                                            'compact',
                                                                    }}
                                                                    enableHiding={
                                                                        false
                                                                    }
                                                                    state={{
                                                                        isLoading:
                                                                            loading,
                                                                    }}
                                                                    enableDensityToggle={
                                                                        false
                                                                    }
                                                                    renderTopToolbarCustomActions={({
                                                                        table,
                                                                    }) => (
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                                gap: '1rem',
                                                                                p: '0.5rem',
                                                                                flexWrap:
                                                                                    'wrap',
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                color="primary"
                                                                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                                                onClick={
                                                                                    dataType?.id ===
                                                                                    'processedReblaze'
                                                                                        ? handleExportData3
                                                                                        : handleExportData5
                                                                                }
                                                                                startIcon={
                                                                                    <FileDownloadIcon />
                                                                                }
                                                                                variant="contained"
                                                                            >
                                                                                Export
                                                                                All
                                                                                Data
                                                                            </Button>
                                                                            <Button
                                                                                color="warning"
                                                                                onClick={() => {
                                                                                    dataType?.id ===
                                                                                    'processedReblaze'
                                                                                        ? getProcessedReblazeData2(
                                                                                              `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-data?startDate=${
                                                                                                  startDate
                                                                                                      .toISOString()
                                                                                                      .split(
                                                                                                          'T'
                                                                                                      )[0]
                                                                                              }&endDate=${
                                                                                                  endDate
                                                                                                      .toISOString()
                                                                                                      .split(
                                                                                                          'T'
                                                                                                      )[0]
                                                                                              }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&next_page_token=${nextPageToken}`,
                                                                                              setData3,
                                                                                              setLoading
                                                                                          )
                                                                                        : getProcessedStatusData2(
                                                                                              `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-status-data?startDate=${
                                                                                                  startDate
                                                                                                      .toISOString()
                                                                                                      .split(
                                                                                                          'T'
                                                                                                      )[0]
                                                                                              }&endDate=${
                                                                                                  endDate
                                                                                                      .toISOString()
                                                                                                      .split(
                                                                                                          'T'
                                                                                                      )[0]
                                                                                              }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&next_page_token=${nextPageToken}`,
                                                                                              setData5,
                                                                                              setLoading
                                                                                          );
                                                                                }}
                                                                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                                startIcon={
                                                                                    <ArrowForwardIosIcon />
                                                                                }
                                                                                variant="contained"
                                                                                disabled={
                                                                                    nextPageToken ===
                                                                                        '' ||
                                                                                    loading
                                                                                }
                                                                            >
                                                                                Get
                                                                                Next
                                                                                Set
                                                                                of
                                                                                Data
                                                                            </Button>
                                                                        </Box>
                                                                    )}
                                                                    renderDetailPanel={({
                                                                        row,
                                                                    }) => (
                                                                        <Box
                                                                            sx={{
                                                                                display:
                                                                                    'flex',
                                                                                marginLeft:
                                                                                    '10px',
                                                                                width: '1200px',
                                                                            }}
                                                                        >
                                                                            <InternalTable
                                                                                columns={
                                                                                    dataType?.id ===
                                                                                    'processedReblaze'
                                                                                        ? internalColumn3
                                                                                        : internalColumn5
                                                                                }
                                                                                data={
                                                                                    dataType?.id ===
                                                                                    'processedReblaze'
                                                                                        ? internalData3[
                                                                                              row
                                                                                                  .index
                                                                                          ]
                                                                                        : internalData5[
                                                                                              row
                                                                                                  .index
                                                                                          ]
                                                                                }
                                                                                title={
                                                                                    'data'
                                                                                }
                                                                                loading={
                                                                                    loading
                                                                                }
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                />
                                                            </div>
                                                        </>
                                                    </TabPanel>
                                                    <TabPanel
                                                        value={value}
                                                        index={1}
                                                    >
                                                        <div className="p-1">
                                                            <MaterialReactTable
                                                                className="p-4 w-full mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                                                                columns={
                                                                    dataType?.id ===
                                                                    'processedReblaze'
                                                                        ? distributedColumn3
                                                                        : distributedColumn5
                                                                }
                                                                data={
                                                                    dataType?.id ===
                                                                    'processedReblaze'
                                                                        ? distributedData3
                                                                        : distributedData5
                                                                }
                                                                enableStickyHeader
                                                                enableDensityToggle={
                                                                    false
                                                                }
                                                                state={{
                                                                    isLoading:
                                                                        loading,
                                                                }}
                                                                initialState={{
                                                                    sorting: [
                                                                        {
                                                                            id: 'total',
                                                                            desc: true,
                                                                        },
                                                                    ],
                                                                    columnPinning:
                                                                        {
                                                                            left: [
                                                                                'mrt-row-select',
                                                                                'mrt-row-actions',
                                                                            ],
                                                                        },
                                                                    density:
                                                                        'compact',
                                                                }}
                                                                positionToolbarAlertBanner="bottom"
                                                                renderTopToolbarCustomActions={({
                                                                    table,
                                                                }) => (
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            gap: '1rem',
                                                                            p: '0.5rem',
                                                                            flexWrap:
                                                                                'wrap',
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={
                                                                                dataType?.id ===
                                                                                'processedReblaze'
                                                                                    ? handleExportDistData3
                                                                                    : handleExportDistData5
                                                                            }
                                                                            startIcon={
                                                                                <FileDownloadIcon />
                                                                            }
                                                                            variant="contained"
                                                                        >
                                                                            Export
                                                                            All
                                                                            Data
                                                                        </Button>
                                                                        <Button
                                                                            color="warning"
                                                                            onClick={() => {
                                                                                dataType?.id ===
                                                                                'processedReblaze'
                                                                                    ? getProcessedReblazeData2(
                                                                                          `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-data?startDate=${
                                                                                              startDate
                                                                                                  .toISOString()
                                                                                                  .split(
                                                                                                      'T'
                                                                                                  )[0]
                                                                                          }&endDate=${
                                                                                              endDate
                                                                                                  .toISOString()
                                                                                                  .split(
                                                                                                      'T'
                                                                                                  )[0]
                                                                                          }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&next_page_token=${nextPageToken}`,
                                                                                          setData3,
                                                                                          setLoading
                                                                                      )
                                                                                    : getProcessedStatusData2(
                                                                                          `${BACKEND_URL}/gcp/user-job-applied-processed-reblaze-status-data?startDate=${
                                                                                              startDate
                                                                                                  .toISOString()
                                                                                                  .split(
                                                                                                      'T'
                                                                                                  )[0]
                                                                                          }&endDate=${
                                                                                              endDate
                                                                                                  .toISOString()
                                                                                                  .split(
                                                                                                      'T'
                                                                                                  )[0]
                                                                                          }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&next_page_token=${nextPageToken}`,
                                                                                          setData5,
                                                                                          setLoading
                                                                                      );
                                                                            }}
                                                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                            startIcon={
                                                                                <ArrowForwardIosIcon />
                                                                            }
                                                                            variant="contained"
                                                                            disabled={
                                                                                nextPageToken ===
                                                                                    '' ||
                                                                                loading
                                                                            }
                                                                        >
                                                                            Get
                                                                            Next
                                                                            Set
                                                                            of
                                                                            Data
                                                                        </Button>
                                                                    </Box>
                                                                )}
                                                            />
                                                        </div>
                                                    </TabPanel>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid xs={12}>
                                                        <h5
                                                            style={{
                                                                marginTop:
                                                                    '15px',
                                                                textAlign:
                                                                    'center',
                                                                fontSize:
                                                                    '18px',
                                                                fontWeight:
                                                                    'bolder',
                                                                color: 'red',
                                                            }}
                                                        >
                                                            {dataType?.id ===
                                                            'processedData'
                                                                ? 'Processsed Data'
                                                                : dataType?.id ===
                                                                  'aaidProcessedData'
                                                                ? 'AAID Processed Data'
                                                                : 'Per Minute Request Count'}
                                                        </h5>
                                                        <MaterialReactTable
                                                            className="p-4 w-auto mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                                                            columns={
                                                                dataType?.id ===
                                                                'processedData'
                                                                    ? column1
                                                                    : dataType?.id ===
                                                                      'aaidProcessedData'
                                                                    ? column2
                                                                    : column4
                                                            }
                                                            data={
                                                                dataType?.id ===
                                                                'processedData'
                                                                    ? data1
                                                                    : dataType?.id ===
                                                                      'aaidProcessedData'
                                                                    ? data2
                                                                    : data4
                                                            }
                                                            enableStickyHeader
                                                            state={{
                                                                isLoading:
                                                                    loading,
                                                            }}
                                                            enableDensityToggle={
                                                                false
                                                            }
                                                            initialState={{
                                                                density:
                                                                    'compact',
                                                                columnVisibility:
                                                                    {
                                                                        last_login: false,
                                                                        is_internal_user: false,
                                                                        sub_department: false,
                                                                        title: false,
                                                                    },
                                                                columnPinning: {
                                                                    left: [
                                                                        /*'user_id','user_email','user_name','user_phone','last_login'*/
                                                                    ],
                                                                },
                                                                sorting: [
                                                                    {
                                                                        id: 'total',
                                                                        desc: true,
                                                                    },
                                                                ],
                                                            }}
                                                            positionToolbarAlertBanner="bottom"
                                                            renderTopToolbarCustomActions={({
                                                                table,
                                                            }) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        gap: '1rem',
                                                                        p: '0.5rem',
                                                                        flexWrap:
                                                                            'wrap',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                                        onClick={
                                                                            dataType?.id ===
                                                                            'processedData'
                                                                                ? handleExportData1
                                                                                : dataType?.id ===
                                                                                  'aaidProcessedData'
                                                                                ? handleExportData2
                                                                                : handleExportData4
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        All Data
                                                                    </Button>
                                                                    <Button
                                                                        color="warning"
                                                                        onClick={() => {
                                                                            dataType?.id ===
                                                                            'processedData'
                                                                                ? getProcessedData2(
                                                                                      `${BACKEND_URL}/gcp/user-job-applied-processed-data?startDate=${
                                                                                          startDate
                                                                                              .toISOString()
                                                                                              .split(
                                                                                                  'T'
                                                                                              )[0]
                                                                                      }&endDate=${
                                                                                          endDate
                                                                                              .toISOString()
                                                                                              .split(
                                                                                                  'T'
                                                                                              )[0]
                                                                                      }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&next_page_token=${nextPageToken}`,
                                                                                      setData1,
                                                                                      setLoading
                                                                                  )
                                                                                : dataType?.id ===
                                                                                  'aaidProcessedData'
                                                                                ? getAAIDProcessedData2(
                                                                                      `${BACKEND_URL}/gcp/user-job-applied-processed-aaid-data?startDate=${
                                                                                          startDate
                                                                                              .toISOString()
                                                                                              .split(
                                                                                                  'T'
                                                                                              )[0]
                                                                                      }&endDate=${
                                                                                          endDate
                                                                                              .toISOString()
                                                                                              .split(
                                                                                                  'T'
                                                                                              )[0]
                                                                                      }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&userIdCount=${associatedUserId}&next_page_token=${nextPageToken}`,
                                                                                      setData2,
                                                                                      setLoading
                                                                                  )
                                                                                : getPerMinuteData2(
                                                                                      `${BACKEND_URL}/gcp/user-job-applied-processed-requests-per-minute-data?startDate=${
                                                                                          startDate
                                                                                              .toISOString()
                                                                                              .split(
                                                                                                  'T'
                                                                                              )[0]
                                                                                      }&endDate=${
                                                                                          endDate
                                                                                              .toISOString()
                                                                                              .split(
                                                                                                  'T'
                                                                                              )[0]
                                                                                      }&uniqueDepartment=${uniqueDepartments}&leadsCreated=${leadsCreated}&requests_count=${requestCount}&next_page_token=${nextPageToken}`,
                                                                                      setData4,

                                                                                      setLoading
                                                                                  );
                                                                        }}
                                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                        startIcon={
                                                                            <ArrowForwardIosIcon />
                                                                        }
                                                                        variant="contained"
                                                                        disabled={
                                                                            nextPageToken ===
                                                                                '' ||
                                                                            loading
                                                                        }
                                                                    >
                                                                        Get Next
                                                                        Set of
                                                                        Data
                                                                    </Button>
                                                                </Box>
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                    </>
                                );
                        }
                    })()}
                </>
            )}
        </>
    );
}
