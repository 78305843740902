import { useState, useEffect, useCallback } from 'react';
import { ACCESS_TOKEN_LOCALSTORAGE_KEY } from '../configs';
import jwt from 'jwt-decode';

const Auth = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const setUserFromLocalStorage = useCallback(() => {
        setLoading(true);
        try {
            const token = localStorage.getItem(ACCESS_TOKEN_LOCALSTORAGE_KEY);
            if (!!token) {
                const user = decodeUserFromToken(token);
                let currentDate = new Date();
                if (user.exp * 1000 > currentDate.getTime()) {
                    setUser(user);
                } else {
                    setUser(null);
                }
            } else {
                setUser(null);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }, [setLoading, setUser]);

    useEffect(() => {
        setUserFromLocalStorage();
    }, [setUserFromLocalStorage]);

    const decodeUserFromToken = (token) => {
        var user = jwt(token);
        return user;
    };

    const setAccessToken = (token) => {
        setLoading(true);
        try {
            localStorage.setItem(ACCESS_TOKEN_LOCALSTORAGE_KEY, token);
            const user = decodeUserFromToken(token);
            console.log(user);
            setUser(user);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const getAccessToken = () => {
        return localStorage.getItem(ACCESS_TOKEN_LOCALSTORAGE_KEY);
    };

    const invalidateUser = () => {
        setLoading(true);
        try {
            localStorage.removeItem(ACCESS_TOKEN_LOCALSTORAGE_KEY);
            setUser(null);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const isAdmin = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }

        try {
            const role = user['role'];
            var admin = role === 'admin' || role === 'owner' || role === 'admin_approver' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isPIIDataViewer = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }

        try {
            const role = user['role'];
            var admin = role === 'pii_data_viewer' || role === 'owner' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isAdminApprover = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }

        try {
            const role = user['role'];
            var admin = role === 'admin_approver' || role === 'owner' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isSecurityAdmin = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }
        try {
            const role = user['role'];
            var admin = role === 'security_admin' || role === 'owner' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isManager = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }
        try {
            const role = user['role'];
            var admin = role === 'manager' || role === 'owner' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isItadmin = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }
        try {
            const role = user['role'];
            var admin = role === 'itadmin' || role === 'owner' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isOwner = () => {
        if (getAccessToken === null) {
            invalidateUser();
            return false;
        }
        try {
            const role = user['role'];
            var admin = role === 'owner' ? true : false;
            return admin;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const setRedirectPath = (path) => {
        try {
            localStorage.setItem('redirect_path', path);
        } catch (e) {
            console.log(e);
        }
    };

    const getRedirectPath = () => {
        const path = localStorage.getItem('redirect_path');
        if (!!path) {
            return path;
        }

        return '/infra';
    };

    return {
        user,
        loading,
        setAccessToken,
        getAccessToken,
        invalidateUser,
        isAdmin,
        isOwner,
        isAdminApprover,
        isSecurityAdmin,
        setRedirectPath,
        getRedirectPath,
        isPIIDataViewer,
        isManager,
        isItadmin
    };
};

export default Auth;
