import React from 'react';
import MaterialReactTable from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';

const CostPill = ({ value }) => {
    return (
        <span
            className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap ${(() => {
                if (value > 100) {
                    return 'bg-red-400 text-red-900';
                } else if (value > 30) {
                    return 'bg-red-300 text-red-900';
                } else if (value > 0) {
                    return 'bg-red-100 text-red-900';
                } else if (value > -30) {
                    return 'bg-green-100 text-green-900';
                } else if (value > -100) {
                    return 'bg-green-300 text-green-900';
                } else {
                    return 'bg-green-400 text-green-900';
                }
            })()}`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {value.toFixed(2)}$
        </span>
    );
};

const SpikePill = ({ value }) => {
    return (
        <span
            className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap ${(() => {
                if (value > 50) {
                    return 'bg-yellow-400 text-yellow-900';
                }
                if (value > 30) {
                    return 'bg-yellow-300 text-yellow-900';
                }
                return 'bg-yellow-100 text-yellow-900';
            })()}`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {value}%
        </span>
    );
};

const SkuPill = ({ value }) => {
    return (
        <span
            className={`px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap ${(() => {
                return 'bg-emerald-200 text-emerald-800';
            })()}`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {value}
        </span>
    );
};

const InstancePill = ({ type, value }) => {
    return (
        <span
            className={`px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap ${(() => {
                if (type === 'Cloud SQL') {
                    return 'bg-sky-300 text-sky-800';
                } else if (type === 'Cloud Storage') {
                    return 'bg-orange-300 text-orange-800';
                } else if (type === 'Cloud Memorystore for Redis') {
                    return 'bg-pink-300 text-pink-800';
                } else if (type === 'Kubernetes Engine') {
                    return 'bg-lime-300 text-lime-800';
                } else return 'bg-slate-400 text-slate-900';
            })()}`}
            style={{ fontWeight: 'bolder', fontSize: '15px' }}
        >
            {value}
        </span>
    );
};

export default function AnomalyTable({ data, filter }) {
    const columns = [
        {
            header: 'Project',
            accessorKey: 'project',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            header: 'Service',
            accessorKey: 'service',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
        {
            header: 'Instance/Bucket/Cluster',
            accessorKey: 'object',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
        },
        {
            header: 'SKU',
            accessorKey: 'sku',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
            //Cell: ({ cell }) => <SkuPill value={cell.getValue()} />,
        },
        {
            header: 'Cost',
            accessorKey: 'cost',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bold' }}>
                    {cell.getValue().toFixed(2)}
                </div>
            ),
            //Cell: ({ cell }) => <CostPill value={cell.getValue()} />,
        },
        {
            header: 'Cost Spike',
            accessorKey: 'costSpike',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => <CostPill value={cell.getValue()} />,
        },
        {
            header: 'Percent Spike',
            accessorKey: 'percentSpike',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => <SpikePill value={cell.getValue()} />,
        },
        {
            header: 'Date',
            accessorKey: 'date',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        },
    ];

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportData = () => {
        var filteredData = [];
        for (var i = 0; i < data.length; i++) {
            var add = true;
            for (var j = 0; j < filter.length; j++) {
                if (filter[j].value !== data[i][filter[j].id]) add = false;
            }
            if (add == true) {
                filteredData.push(data[i]);
            }
        }
        csvExporter.generateCsv(filteredData);
    };

    return (
        <>
            <MaterialReactTable
                className="p-4 w-auto max-h-[300px] mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                columns={columns}
                data={data}
                enableStickyHeader
                enableFilterMatchHighlighting={false}
                muiTableContainerProps={{ sx: { maxHeight: 325 } }}
                filterFns={{
                    customFilterFn: (row, id, filterValue) => {
                        return row.getValue(id).includes(filterValue);
                    },
                }}
                enableDensityToggle={false}
                enableFullScreenToggle={false}
                //enablePagination={false}
                initialState={{
                    density: 'compact',
                    columnFilters: filter,
                    columnVisibility: { project: false },
                    sorting: [
                        {
                            id: 'costSpike',
                            desc: true,
                        },
                    ],
                    //pagination:{pageIndex:0,pageSize:3}
                }}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                        {filter.map(function (object, i) {
                            return (
                                <Typography
                                    key={i}
                                    fontFamily={'serif'}
                                    variant="h6"
                                    style={{
                                        fontWeight: 'bold',
                                        marginLeft: '5px',
                                        color: '#0E86D4',
                                    }}
                                >
                                    {i > 0
                                        ? `-->${object.value}`
                                        : object.value}
                                </Typography>
                            );
                        })}
                    </Box>
                )}
            />
        </>
    );
}
