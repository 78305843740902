//import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../../components/typography/header-title';
//import Home from '../../components/securityComponent/home';
export default function AllotTeam() {
    return (
        <>
            <HeaderTitle
                title={'Allot Team'}
                subtitle={
                    'Allot team'
                }
            />
            <div>Coming Soon</div>
        </>
    );
}
