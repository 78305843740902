import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GCPlogmetrics from './gcp_log_metrics';
import GCPResourceUtilization from './gcp_resource_utilisation';
const Infrastructure = () => {
    return (
        <>
            <Routes>
                <Route index element={<Navigate to={'/home'} />} />
                <Route path="gcp-log-metrics/*" element={<GCPlogmetrics />} />
                <Route
                    path="gcp-resource-utilization/*"
                    element={<GCPResourceUtilization />}
                />
            </Routes>
        </>
    );
};

export default Infrastructure;
