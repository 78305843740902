import React from 'react';
import { ACCESS_BACKEND_URL } from '../../configs';
import LoadingSpinner from './loader';
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAxios } from '../../contexts/axios';
//import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
function Updateuserform() {
    const axios = useAxios();
    const [people, setPeople] = useState([{ name: 'select' }]);
    const [givenname, setgivenname] = useState('');
    const [familyname, setfamilyname] = useState('');
    const [primaryemail, setprimaryemail] = useState('');
    const [primaryEmail, setprimaryEmail] = useState('');
    const [manager, setmanager] = useState([{ name: 'select' }]);
    const [team, setteam] = useState([{ name: 'select' }]);
    const [selected, setSelected] = useState(people[0]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedteam, setSelectedteam] = useState(team[0]);
    const [selectedman, setSelectedman] = useState(manager[0]);
    const handlegivenname = (e) => {
        setgivenname(e.target.value);
    };
    // function to update state of age with value
    // enter by user in form
    const handlefamilyname = (e) => {
        setfamilyname(e.target.value);
    };
    // function to update state of email with value
    // enter by user in form
    const handleprimaryemail = (e) => {
        setprimaryemail(e.target.value);
    };
    const handleprimaryEmail = (e) => {
        setprimaryEmail(e.target.value);
    };

    const handleclick = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const requestOptions = {
            method: 'PUT',
            url: `${ACCESS_BACKEND_URL}/api/Onboarding`,
            data: {
                primaryemail: primaryemail,
                primaryEmail: primaryEmail,
                name: { givenName: givenname, familyName: familyname },
                managerEmail: selectedman?.name,
                team: selectedteam?.name,
                role: selected?.name,
            },
        };
        axios(requestOptions).then(function (res) {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/profile?type=role`,
        };
        axios(requestOptions).then(function (res) {
            var values = [];
            if(res?.data?.length>0){
                for(var i=0; i<res.data.length; i++){
                    values.push({name:res.data[i]});
                }
            }
            var temp = [{ name: 'select' }, ...values];
            setPeople(temp);
        });
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/profile?type=manager`,
        };
        axios(requestOptions).then(function (res) {
            var values = [];
            if(res?.data?.length>0){
                for(var i=0; i<res.data.length; i++){
                    values.push({name:res.data[i].email});
                }
            }
            var temp = [{ name: 'select' }, ...values];
            setmanager(temp);
        });
    }, []);

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${ACCESS_BACKEND_URL}/api/profile?type=team`,
        };
        axios(requestOptions).then(function (res) {
            var values = [];
            if(res?.data?.length>0){
                for(var i=0; i<res.data.length; i++){
                    values.push({name:res.data[i]});
                }
            }
            var temp = [{ name: 'select' }, ...values];
            setteam(temp);
        });
    }, []);

    return (
        <>
            <div class="max-w-5xl text-left ">
                <div class="block rounded-lg shadow-lg bg-white max-w-5xl text-center">
                    <div
                        style={{ display: 'flex' }}
                        class="font-bold bg-blue-200 rounded-t-lg text-left py-2 px-6 border-b border-gray-300"
                    >
                        <div>Update User Information</div>
                        <div style={{ marginLeft: '750px' }}>
                            {isLoading ? <LoadingSpinner /> : ''}
                        </div>
                    </div>
                    <div class="max-w-5xl text-left">
                        <form
                            onSubmit={handleclick}
                            style={{
                                marginLeft: '20px',
                                marginRight: '20px',
                                marginTop: '10px',
                                marginBottom: '20px',
                                textAlign: 'center',
                            }}
                        >
                            <div class="grid md:grid-cols-2 md:gap-6">
                                <div class="text-left relative z-0 mb-6 w-full group">
                                    <input
                                        type="text"
                                        name="floating_first_name"
                                        id="primaryemail"
                                        onChange={(e) => {
                                            handleprimaryemail(e);
                                        }}
                                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                        placeholder=" "
                                        required={true}
                                    />
                                    <label
                                        for="floating_first_name"
                                        class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        Old Email *
                                    </label>
                                </div>
                                <div class="text-left relative z-0 mb-6 w-full group">
                                    <input
                                        type="text"
                                        name="floating_last_name"
                                        id="primaryEmail"
                                        onChange={(e) => {
                                            handleprimaryEmail(e);
                                        }}
                                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                        placeholder=" "
                                        required=""
                                    />
                                    <label
                                        for="floating_last_name"
                                        class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        New Email
                                    </label>
                                </div>
                            </div>
                            <div class="grid md:grid-cols-2 md:gap-6">
                                <div class="text-left relative z-0 mb-6 w-full group">
                                    <input
                                        type="text"
                                        name="floating_first_name"
                                        id="givenName"
                                        onChange={(e) => {
                                            handlegivenname(e);
                                        }}
                                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                        placeholder=" "
                                        required=""
                                    />
                                    <label
                                        for="floating_first_name"
                                        class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        First name
                                    </label>
                                </div>
                                <div class="text-left relative z-0 mb-6 w-full group">
                                    <input
                                        type="text"
                                        name="floating_last_name"
                                        id="familyName"
                                        onChange={(e) => {
                                            handlefamilyname(e);
                                        }}
                                        class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-gray-500 focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                        placeholder=" "
                                        required=""
                                    />
                                    <label
                                        for="floating_last_name"
                                        class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                    >
                                        Last name
                                    </label>
                                </div>
                            </div>
                            <div class="grid md:grid-cols-2 md:gap-6">
                                <div
                                    style={{ zIndex: 2 }}
                                    class="grid md:grid-cols-3 md:gap-6"
                                >
                                    <div class="text-left relative z-0 mb-6 w-full group">
                                        <div class="mb-6">
                                            <div>
                                                <Listbox
                                                    value={selected}
                                                    onChange={setSelected}
                                                >
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                            <span className="block truncate">
                                                                {selected.name}
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ArrowDropDownIcon />
                                                            </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {people.map(
                                                                    (
                                                                        person,
                                                                        personIdx
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                personIdx
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                                    active
                                                                                        ? 'bg-amber-100 text-amber-900'
                                                                                        : 'text-gray-900'
                                                                                }`
                                                                            }
                                                                            value={
                                                                                person
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={`block truncate ${
                                                                                            selected
                                                                                                ? 'font-medium'
                                                                                                : 'font-normal'
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            person.name
                                                                                        }
                                                                                    </span>
                                                                                    {selected ? (
                                                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                            <ArrowDropDownIcon />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        </div>
                                        <label
                                            for="floating_role"
                                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Role
                                        </label>
                                    </div>
                                    <div class="text-left relative z-0 mb-6 w-full group">
                                        <div class="mb-6">
                                            <div>
                                                <Listbox
                                                    value={selectedman}
                                                    onChange={setSelectedman}
                                                >
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                            <span className="block truncate">
                                                                {
                                                                    selectedman.name
                                                                }
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ArrowDropDownIcon />
                                                            </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {manager.map(
                                                                    (
                                                                        person,
                                                                        personIdx
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                personIdx
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                                    active
                                                                                        ? 'bg-amber-100 text-amber-900'
                                                                                        : 'text-gray-900'
                                                                                }`
                                                                            }
                                                                            value={
                                                                                person
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={`block truncate ${
                                                                                            selected
                                                                                                ? 'font-medium'
                                                                                                : 'font-normal'
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            person.name
                                                                                        }
                                                                                    </span>
                                                                                    {selected ? (
                                                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                            <ArrowDropDownIcon />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        </div>

                                        <label
                                            for="floating_role"
                                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Manager
                                        </label>
                                    </div>
                                    <div class="text-left relative z-0 mb-6 w-full group">
                                        <div class="mb-6">
                                            <div>
                                                <Listbox
                                                    value={selectedteam}
                                                    onChange={setSelectedteam}
                                                >
                                                    <div className="relative mt-2">
                                                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                                            <span className="block truncate">
                                                                {
                                                                    selectedteam.name
                                                                }
                                                            </span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ArrowDropDownIcon />
                                                            </span>
                                                        </Listbox.Button>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {team.map(
                                                                    (
                                                                        person,
                                                                        personIdx
                                                                    ) => (
                                                                        <Listbox.Option
                                                                            key={
                                                                                personIdx
                                                                            }
                                                                            className={({
                                                                                active,
                                                                            }) =>
                                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                                                    active
                                                                                        ? 'bg-amber-100 text-amber-900'
                                                                                        : 'text-gray-900'
                                                                                }`
                                                                            }
                                                                            value={
                                                                                person
                                                                            }
                                                                        >
                                                                            {({
                                                                                selected,
                                                                            }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={`block truncate ${
                                                                                            selected
                                                                                                ? 'font-medium'
                                                                                                : 'font-normal'
                                                                                        }`}
                                                                                    >
                                                                                        {
                                                                                            person.name
                                                                                        }
                                                                                    </span>
                                                                                    {selected ? (
                                                                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                                            <ArrowDropDownIcon />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    )
                                                                )}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </Listbox>
                                            </div>
                                        </div>

                                        <label
                                            for="floating_role"
                                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                        >
                                            Team
                                        </label>
                                    </div>
                                </div>
                                <div class="text-left relative z-0 mb-6 w-full group">
                                    <button
                                        disabled={isLoading}
                                        type="submit"
                                        style={{ width: '480px' }}
                                        class="mb-3 mt-3 text-white bg-blue-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        UPDATE USER
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Updateuserform;
