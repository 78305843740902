import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from '../../components/navigation/sidebar';
import Costing from './costing';
import Platform from './platform';
import Observability from '../observability';
import AdminRoute from '../../components/navigation/AdminRoute';
import Administration from './administration';
import Tbas from './tbas';
import Info from './info';
import Events from './events';
import Infrastructure from './infrastructure';
import Grid from '@mui/material/Unstable_Grid2';
import Home from './home/home';
import Audit from './audit';
import Blacklisting from './blacklistingInfo';
import PIIDataViewerRoute from '../../components/navigation/PIIDataViewerRoute';
const Dashboard = () => {
    return (
        <Grid container>
            <Grid xs={12}>
                <Sidebar />
            </Grid>
            <Grid xs={12}>
                <div className="py-6 px-8" style={{ marginTop: '70px' }}>
                    <Routes>
                        <Route index element={<Navigate to={'home'} />} />
                        <Route path="home/*" element={<Home />} />
                        <Route path="info/*" element={<Info />} />
                        <Route path="events/*" element={<Events />} />
                        <Route path="platform/*" element={<Platform />} />
                        <Route
                            path="infrastructure/*"
                            element={<Infrastructure />}
                        />
                        <Route path="costing/*" element={<Costing />} />
                        <Route
                            path="observability/*"
                            element={<Observability />}
                        />
                        <Route path="tbas/*" element={<Tbas />} />
                        <Route path="user-info/*" element={<Blacklisting />} />
                        <Route element={<AdminRoute />}>
                            <Route
                                path="administration/*"
                                element={<Administration />}
                            />
                        </Route>
                        <Route element={<PIIDataViewerRoute />}>
                            <Route path="audit/*" element={<Audit />} />
                        </Route>
                    </Routes>
                </div>
            </Grid>
        </Grid>
    );
};

export default Dashboard;
