import React, { useEffect, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import ReactJson from 'react-json-view';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import InfoIcon from '@mui/icons-material/Info';

export default function LogDatatable({ columns, data, title }) {
    const [index, setIndex] = useState(0);
    let [isOpen, setIsOpen] = useState(false);
    const [finalData, setFinalData] = useState([])
    const [log, setLog] = useState([])
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        filename: title,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };
    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(finalData);
    };
    const handleclick = (ind) => {
        setIndex(ind);
        openModal();
    };

    useEffect(()=>{
        var logs = []
        var Data = []
        for(var i=0; i<data.length; i++){
            var dict = {}
            for( var j=0; j<columns.length; j++){
                dict[columns[j].accessorKey] = data[i][columns[j].accessorKey]
            }
            logs.push(data[i].log)
            Data.push(dict)
        }
        setFinalData(Data)
        setLog(logs)
    },[data])

    return (
        <>
            <MaterialReactTable
                className="p-4 w-full mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                columns={columns}
                data={finalData}
                enableRowSelection
                enableStickyHeader
                enableRowActions
                enableDensityToggle={false}
                filterFns={{
                    customFilterFn: (
                        row,
                        id,
                        filterValue
                    ) => {
                        return row
                            .getValue(id)
                            .includes(filterValue);
                    },
                }}
                initialState={{
                    sorting: [
                        {
                            id: 'totalcost',
                            desc: true,
                        },
                    ],
                    columnPinning: {
                        left: [
                            'mrt-row-select',
                            'mrt-row-actions',
                        ],
                    },
                    density: 'compact',
                }}
                positionToolbarAlertBanner="bottom"
                displayColumnDefOptions={{
                    'mrt-row-actions':
                        {
                            header: 'Info', //change header text
                            //size: 30, //make actions column wider
                        },
                }}
                renderRowActions={({ row }) => (
                    <Box>
                        <Button
                            onClick={() => {
                                handleclick(row.index);
                            }}
                        >
                            <InfoIcon />
                        </Button>
                    </Box>
                )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                        <Button
                            disabled={table.getRowModel().rows.length === 0}
                            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                            onClick={() =>
                                handleExportRows(table.getRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export Page Rows
                        </Button>
                        <Button
                            disabled={
                                !table.getIsSomeRowsSelected() &&
                                !table.getIsAllRowsSelected()
                            }
                            //only export selected rows
                            onClick={() =>
                                handleExportRows(
                                    table.getSelectedRowModel().rows
                                )
                            }
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export Selected Rows
                        </Button>
                    </Box>
                )}
            />
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[1000px] transform overflow-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        METADATA
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <ReactJson
                                            collapseStringsAfterLength={200}
                                            src={log[index]}
                                            displayDataTypes={false}
                                            name={false}
                                        />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
