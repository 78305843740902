import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/plots';
import { Card, Col, Row } from 'antd';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { param } from 'jquery';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const ReblazeGraph = ({ groupBy, body, call, title }) => {
    const authAxios = useAxios();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const getData = async (url, body, setData, setLoading) => {
        setLoading(true);
        try {
            const { data } = await authAxios.get(url, { params: body });
            setData(data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (call === true) {
            getData(
                `${BACKEND_URL}/gcp/reblaze/query/${groupBy}`,
                body,
                setData,
                setLoading
            );
        }
    }, [call]);

    const soothingColors = [
        '#4A90E2',
        '#48C9B0',
        '#9B59B6',
        '#F39C12',
        '#D35400',
        '#2ECC71',
        '#3498DB',
        '#8E44AD',
        '#27AE60',
        '#E74C3C',
        '#1ABC9C',
        '#34495E',
        '#F1C40F',
        '#E67E22',
        '#95A5A6',
        '#16A085',
        '#3498DB',
        '#E74C3C',
        '#2ECC71',
        '#8E44AD',
    ];

    // Get unique categories
    const categories = [...new Set(data.map((item) => item.category))];

    // Create an object to store colors for each category
    const categoryColors = {};
    categories.forEach((category, index) => {
        categoryColors[category] =
            soothingColors[index % soothingColors.length];
    });

    const config = {
        data,
        xField: 'timestamp',
        yField: 'value',
        seriesField: 'category',
        tooltip: {
            position:'top'
        },
        yAxis: {
            label: {
                formatter: (v) =>
                    `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
        xAxis: {
            label: {
                rotate: -45,
                offset: 50,
            },
        },
        color: ({ category }) => categoryColors[category],
    };

    return (
        <Card title={title} bordered={false} loading={loading}>
            {call === false ? (
                <AutoGraphIcon
                    style={{
                        marginLeft: '350px',
                        width: '50px',
                        height: '50px',
                        color: 'ThreeDLightShadow',
                    }}
                />
            ) : (
                <Line {...config} height={300} />
            )}
        </Card>
    );
};

export default ReblazeGraph;
