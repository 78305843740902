import React, { useState, useEffect } from 'react';
import { Bar } from '@ant-design/plots';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import Grid from '@mui/material/Unstable_Grid2';

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function QueryCostComparator({
    agentsCost,
    agentsCount,
    metabaseCost,
    metabaseCount,
    height,
}) {
    const [metabaseDashboardCost, setMetabaseDashboardCost] = useState([]);
    const [metabaseDashboardCount, setMetabaseDashboardCount] = useState([]);
    const [metabaseQuestionCost, setMetabaseQuestionCost] = useState([]);
    const [metabaseQuestionCount, setMetabaseQuestionCount] = useState([]);
    const [agentCost, setAgentCost] = useState([]);
    //const [metaCost, setMetaCost] = useState([]);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        var agents = [];
        var metaDashboardCost = [];
        var metaDashboardCount = [];
        var metaQuestionCost = [];
        var metaQuestionCount = [];
        for (var i = 0; i < agentsCost.length; i++) {
            agents.push({
                type: agentsCost[i]['type'],
                value: parseFloat(agentsCost[i]['value'].toFixed(2)),
                label: agentsCost[i]['label'],
            });
        }
        setAgentCost(agents);
        for (var i = 0; i < metabaseCost.length; i++) {
            if (metabaseCost[i]['label'].includes('__#Question#__:')) {
                metaQuestionCost.push({
                    type: metabaseCost[i]['type'],
                    value: parseFloat(metabaseCost[i]['value'].toFixed(2)),
                    label: metabaseCost[i]['label'].split('__#Question#__:')[1],
                });
                metaQuestionCount.push({
                    type: metabaseCount[i]['type'],
                    value: metabaseCount[i]['value'],
                    label: metabaseCount[i]['label'].split(
                        '__#Question#__:'
                    )[1],
                });
            } else {
                metaDashboardCost.push({
                    type: metabaseCost[i]['type'],
                    value: parseFloat(metabaseCost[i]['value'].toFixed(2)),
                    label: metabaseCost[i]['label'],
                });
                metaDashboardCount.push({
                    type: metabaseCount[i]['type'],
                    value: metabaseCount[i]['value'],
                    label: metabaseCount[i]['label'],
                });
            }
        }
        setMetabaseDashboardCost(metaDashboardCost);
        setMetabaseDashboardCount(metaDashboardCount);
        setMetabaseQuestionCost(metaQuestionCost);
        setMetabaseQuestionCount(metaQuestionCount);
    }, [agentsCost, metabaseCost]);

    const dashboardCostConfig = {
        data: metabaseDashboardCost,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        color: ['#4169e1', '#FFE5B4'],
        seriesField: 'type',
        marginRatio: 0,
        label: {
            position: 'right',
            offset: 4,
        },
    };
    const dashboardCountConfig = {
        data: metabaseDashboardCount,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        color: ['#4169e1', '#FFE5B4'],
        seriesField: 'type',
        marginRatio: 0,
        label: {
            position: 'right',
            offset: 4,
        },
    };
    const questionCostConfig = {
        data: metabaseQuestionCost,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        color: ['#4169e1', '#FFE5B4'],
        seriesField: 'type',
        marginRatio: 0,
        label: {
            position: 'right',
            offset: 4,
        },
    };
    const questionCountConfig = {
        data: metabaseQuestionCount,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        color: ['#4169e1', '#FFE5B4'],
        seriesField: 'type',
        marginRatio: 0,
        label: {
            position: 'right',
            offset: 4,
        },
    };
    const agentsCostConfig = {
        data: agentCost,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        color: ['#D2042D', '#ffc1cc'],
        seriesField: 'type',
        marginRatio: 0,
        label: {
            //label
            position: 'right',
            offset: 4,
        },
    };
    const agentsCountConfig = {
        data: agentsCount,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        xAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            label: {
                style: {
                    fill: 'black',
                    fontWeight: 'bold',
                },
            },
        },
        color: ['#D2042D', '#ffc1cc'],
        seriesField: 'type',
        marginRatio: 0,
        label: {
            //label
            position: 'right',
            offset: 4,
        },
    };
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container rowGap={3} columnSpacing={3}>
                    <Grid xs={12}>
                        <h5
                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: 'bolder',
                            }}
                        >
                            Agents
                        </h5>
                    </Grid>

                    <Grid xs={6}>
                        <h5
                            style={{
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: 'bolder',
                                color: 'red',
                            }}
                        >
                            {'Cost ($) '}
                        </h5>
                        <Bar {...agentsCostConfig} />
                    </Grid>
                    <Grid xs={6}>
                        <h5
                            style={{
                                textAlign: 'center',
                                fontSize: '18px',
                                fontWeight: 'bolder',
                                color: 'red',
                            }}
                        >
                            Count
                        </h5>
                        <Bar {...agentsCountConfig} />
                    </Grid>
                    <Grid xs={12}>
                        <h5
                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                fontWeight: 'bolder',
                            }}
                        >
                            Metabase
                        </h5>
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    marginTop: '10px',
                }}
            >
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="DASHBOARDS" {...a11yProps(0)} />
                        <Tab label="QUESTIONS" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid container rowGap={3} columnSpacing={3}>
                        <Grid xs={6}>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bolder',
                                    color: 'red',
                                }}
                            >
                                {'Cost ($)'}
                            </h5>
                            <Bar height={height} {...dashboardCostConfig} />
                        </Grid>
                        <Grid xs={6}>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bolder',
                                    color: 'red',
                                }}
                            >
                                Count
                            </h5>
                            <Bar height={height} {...dashboardCountConfig} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container rowGap={3} columnSpacing={3}>
                        <Grid xs={6}>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bolder',
                                    color: 'red',
                                }}
                            >
                                {'Cost ($)'}
                            </h5>
                            <Bar height={height+300} {...questionCostConfig} />
                        </Grid>
                        <Grid xs={6}>
                            <h5
                                style={{
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    fontWeight: 'bolder',
                                    color: 'red',
                                }}
                            >
                                Count
                            </h5>
                            <Bar height={height+300} {...questionCountConfig} />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        </>
    );
}
