import React from 'react';
import GCPUnitCostingTable from '../../../../components/costing/gcp-costing';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';

export default function GCPUnitCosting() {
    return (
        <>
            <HeaderTitle
                title={'GCP Costing'}
                subtitle={
                    'Cost segregation for GCP for different Services'
                }
            />
            <GCPUnitCostingTable />
        </>
    );
}
