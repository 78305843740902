import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import ElasticTagging from '../../../../components/table/elasticTaggingTable';

const ElasticMetaData = () => {
    return (
        <>
           
            <HeaderTitle
                title={'Elastic Clusters Tagging'}
                subtitle={'Manage metadata for Elastic Clusters.'}
            />
            <ElasticTagging/>
        </>
    );
};

export default ElasticMetaData;
