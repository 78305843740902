import { React, useState, useEffect, useCallback } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { BACKEND_URL } from '../../configs';
import { ExportToCsv } from 'export-to-csv';
import LaunchIcon from '@mui/icons-material/Launch';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import TaskTable from '../table/taskTablse';
//import TaskTable from '../costing/TaskTable';
import Grid from '@mui/material/Unstable_Grid2';
import FlexRow from '../layout/FlexRow';
import DatePickerInput from '../DatePickerInput';
import { useAxios } from '../../contexts/axios';
import { useSearchParams } from 'react-router-dom';
import SearchDD from '../SearchDD';
import TextField from '@mui/material/TextField';
import DateTimePickerInput from '../DateTimePickerInput';

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function TaskManagement() {
    var dateObj = new Date();
    //dateObj.setDate(dateObj.getDate() - 1);
    const [jobId, setJobId] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [usersLoading, setUsersLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [data1, setData1] = useState([]);
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [loading3, setLoading3] = useState(false);
    const [loading4, setLoading4] = useState(false);
    const [loading5, setLoading5] = useState(false);
    const [ok, setOk] = useState('false');
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);
    const [column1, setColumn1] = useState([]);
    const [column2, setColumn2] = useState([]);
    const [column3, setColumn3] = useState([]);
    const [column4, setColumn4] = useState([]);
    const [column5, setColumn5] = useState([]);
    const [buttonDisability, setButtonDisability] = useState(false);
    const [value, setValue] = useState(0);
    const authAxios = useAxios();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getUserList = async () => {
        setUsersLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/gcp/bigquery-metabase-users`
            );
            var allUser = [];
            if (data?.length > 0) {
                for (var i = 0; i < data.length; i++) {
                    allUser.push({
                        label: data[i]['email'],
                        id: data[i]['email'],
                    });
                }
            }
            setUsersData(allUser);
            setUsersLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getUserList();
    }, []);

    const getdata = async (
        url,
        setdata,
        setcolumn,
        setloading,
        muitablebodycellprops
    ) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(url);
            var keys = Object.keys(data[0]);
            var cols = [];
            for (var i = 0; i < keys.length; i++) {
                cols.push({
                    header:
                        keys[i] === 'timestamp'
                            ? keys[i].toUpperCase() + '(IST)'
                            : keys[i].toUpperCase(),
                    accessorKey: keys[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: muitablebodycellprops,
                });
            }
            setcolumn(cols);
            setdata(data);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const isInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    const getData = () => {
        if (!isInputValid()) {
            return;
        }
        setOk('true');
        setButtonDisability(true);
        if (
            jobId !== '' 
        ) {
            getdata(
                `${BACKEND_URL}/gcp/manage-task?type=jobfeed&value=${jobId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                setData1,
                setColumn1,
                setLoading1,
                {
                    align: 'left',
                }
            );
            getdata(
                `${BACKEND_URL}/gcp/manage-task?type=internal&value=${jobId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                setData2,
                setColumn2,
                setLoading2,
                {
                    align: 'left',
                }
            );
        }
        if (phoneNumber !== '' ) {
            getdata(
                `${BACKEND_URL}/gcp/manage-task?type=phone&value=${phoneNumber}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                setData3,
                setColumn3,
                setLoading3,
                {
                    align: 'left',
                }
            );
        }
        if (data4.length === 0 || column4.length === 0) {
            getdata(
                `${BACKEND_URL}/gcp/manage-query?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                setData4,
                setColumn4,
                setLoading4,
                {
                    align: 'left',
                    sx: {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    },
                }
            );
        }
        if (user !== null && (data5.length === 0 || column5.length === 0)) {
            getdata(
                `${BACKEND_URL}/gcp/manage-query-new?email=${
                    user.id
                }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
                setData5,
                setColumn5,
                setLoading5,
                {
                    align: 'left',
                    sx: {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                    },
                }
            );
        }
        setButtonDisability(false);
    };
    //console.log(loading);

    //console.log(startDate, endDate);
    useEffect(() => {
        setData1([]);
        setData2([]);
        setColumn1([]);
        setColumn2([]);
    }, [jobId]);

    useEffect(() => {
        setData3([]);
        setColumn3([]);
    }, [phoneNumber]);

    useEffect(() => {
        setData5([]);
        setColumn5([]);
    }, [user]);

    useEffect(() => {
        setData1([]);
        setData2([]);
        setData3([]);
        setData4([]);
        setData5([]);
        setColumn1([]);
        setColumn2([]);
        setColumn3([]);
        setColumn4([]);
        setColumn5([]);
        setOk('false');
    }, [startDate, endDate]);

    return (
        <>
            <FlexRow>
                <DateTimePickerInput
                    label={'Start Date'}
                    value={startDate}
                    onChange={setStartDate}
                    maxDate={endDate}
                />

                <DateTimePickerInput
                    label={'End Date'}
                    value={endDate}
                    onChange={setEndDate}
                    maxDate={dateObj}
                />
                <SearchDD
                    label={'User Email'}
                    emptyLabel={'Select email'}
                    options={usersData}
                    value={user}
                    onChange={setUser}
                    isLoading={usersLoading}
                />
                <FlexRow />
            </FlexRow>
            <FlexRow>
                <TextField
                    label={'Enter Job Id'}
                    value={jobId}
                    sx={{ width: 358 }}
                    onChange={(e) => {
                        setJobId(e.target.value);
                    }}
                />
                <TextField
                    label={'Enter Phone Number'}
                    value={phoneNumber}
                    sx={{ width: 358 }}
                    onChange={(e) => {
                        setPhoneNumber(e.target.value);
                    }}
                />
                <FlexRow>
                    <div>
                        <Button
                            children={'Get logs data'}
                            sx={{
                                width: 358,
                                backgroundColor: '#0096FF',
                                fontWeight: 'bold',
                                color: 'white',
                                height: 53,
                                padding: 2,
                                ':hover': {
                                    backgroundColor: '#6F8FAF',
                                    color: 'white',
                                },
                            }}
                            onClick={getData}
                            disabled={buttonDisability}
                        />
                    </div>
                </FlexRow>
            </FlexRow>
            {(() => {
                switch (ok) {
                    case 'false':
                        return <></>;
                    case 'true':
                        return (
                            <>
                                <Box sx={{ width: '100%', marginTop: '30px' }}>
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                        }}
                                    >
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="basic tabs example"
                                            variant='fullWidth'
                                            indicatorColor='primary'
                                        >
                                            <Tab
                                                label="GET USER INFO BASED ON JOBID OR PHONE NUMBER"
                                                {...a11yProps(0)}
                                                wrapped
                                                style={{fontWeight:'bold'}}
                                            />
                                            <Tab
                                                label="UNIQUE USER WHO RAN QUERY ON HR_NUMBER"
                                                {...a11yProps(1)}
                                                wrapped
                                                style={{fontWeight:'bold'}}
                                            />
                                            <Tab
                                                label="ALL QUERY RUN BY A USER ON HR_NUMBER"
                                                {...a11yProps(2)}
                                                wrapped
                                                style={{fontWeight:'bold'}}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12}>
                                                <h5
                                                    style={{
                                                        marginTop: '15px',
                                                        textAlign: 'center',
                                                        fontSize: '18px',
                                                        fontWeight: 'bolder',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {
                                                        'Filter: /api/jobfeed/v4/jobs/{jobId}'
                                                    }
                                                </h5>
                                                <TaskTable
                                                    data={data1}
                                                    columns={column1}
                                                    loading={loading1}
                                                />
                                            </Grid>
                                            <Grid xs={12}>
                                                <h5
                                                    style={{
                                                        marginTop: '15px',
                                                        textAlign: 'center',
                                                        fontSize: '18px',
                                                        fontWeight: 'bolder',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {
                                                        'Filter: /api/internal/v4/jobs/{jobId}'
                                                    }
                                                </h5>
                                                <TaskTable
                                                    data={data2}
                                                    columns={column2}
                                                    loading={loading2}
                                                />
                                            </Grid>
                                            <Grid xs={12}>
                                                <h5
                                                    style={{
                                                        marginTop: '15px',
                                                        textAlign: 'center',
                                                        fontSize: '18px',
                                                        fontWeight: 'bolder',
                                                        color: 'red',
                                                    }}
                                                >
                                                    {
                                                        'Filter: api/internal/v4/jobs/?phone={phone_number}'
                                                    }
                                                </h5>
                                                <TaskTable
                                                    data={data3}
                                                    columns={column3}
                                                    loading={loading3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <TaskTable
                                            data={data4}
                                            columns={column4}
                                            loading={loading4}
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <TaskTable
                                            data={data5}
                                            columns={column5}
                                            loading={loading5}
                                        />
                                    </TabPanel>
                                </Box>
                            </>
                        );
                }
            })()}
        </>
    );
}
