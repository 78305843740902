import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import ElasticTbasTable from '../../../../components/tbas/elastictable';
//import MongoTbasTable from '../../../../components/tbas/mongotable';
const ElasticTbas = () => {
    return (
        <>
           
            <HeaderTitle
                title={'Time Based Auto Scaling of Elastic Clusters'}
                subtitle={'Minimize Cost of elastic clusters by scaling it on time based monitoring'}
            />
            <ElasticTbasTable/>
        </>
    );
};

export default ElasticTbas;
