import HeaderTitle from '../../components/typography/header-title';
import Policies from '../../components/securityComponent/policies';
export default function SecurityPolicies() {
    return (
        <>
            <HeaderTitle
                title={'Apna Security Policies'}
                subtitle={'List of Security Policies followed in Apna'}
            />
            <Policies />
        </>
    );
}
