import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
    Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
const autoscaling = ['disabled', 'enabled'];

const SentryTable = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [editLoader, setEditLoader] = useState([]);
    const [approveLoader, setApproveLoader] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    //const [projectLoader, setProjectLoader] = useState(false);
    const axios = useAxios();
    const { isOwner } = useAuth();

    async function call(url, data, method, setloading, index) {
        if (index === -1) {
            setloading(true);
        } else {
            if (method === 'GET') {
                approveLoader[index] = true;
                setloading(approveLoader);
            } else {
                editLoader[index] = true;
                setloading(editLoader);
            }
        }
        var requestOptions = {};
        if (method === 'GET') {
            requestOptions = {
                url: `${BACKEND_URL}/${url}`,
                method: method,
            };
        } else {
            requestOptions = {
                url: `${BACKEND_URL}/${url}`,
                method: method,
                data: data,
            };
        }
        await axios(requestOptions).then(() => {
            if (index === -1) {
                setloading(false);
            } else {
                if (method === 'GET') {
                    approveLoader[index] = false;
                    setloading(approveLoader);
                } else {
                    editLoader[index] = false;
                    setloading(editLoader);
                }
                window.location.reload(false);
            }
        });
    }

    async function getData() {
        setDataLoader(true);
        var requestOptions = {
            url: `${BACKEND_URL}/platform/sentry`,
            method: 'GET',
        };
        await axios(requestOptions).then(function (res) {
            var data = res.data;
            var tempLoader1 = [];
            var tempLoader2 = [];
            for (var i = 0; i < data?.length; i++) {
                //tempLoader1.push(false);
                tempLoader2.push(false);
            }
            setTableData(data);
            //setEditLoader(tempLoader1);
            setApproveLoader(tempLoader2);
            setDataLoader(false);
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const handleApprove = useCallback(
        (row) => {
            if (
                !window.confirm(
                    `Are you sure you want to Approve ${row.getValue('name')}`
                )
            ) {
                return;
            }
            tableData[row.index] = {
                name: row.getValue('name'),
                owner: row.getValue('owner'),
                type: row.getValue('type'),
                purpose: row.getValue('purpose'),
                request_time: row.getValue('request_time'),
                manager_email: row.getValue('manager_email'),
                approval_status: row.getValue('approval_status'),
                sentry_username: row.getValue('sentry_username'),
                sentry_password: row.getValue('sentry_password'),
                sentry_dns: row.getValue('sentry_dns'),
            };
            call(
                'platform/sentry/cluster',
                {
                    name: row.getValue('name'),
                    owner: row.getValue('owner'),
                    type: row.getValue('type'),
                    purpose: row.getValue('purpose'),
                    request_time: row.getValue('request_time'),
                    manager_email: row.getValue('manager_email'),
                    approval_status: 'True',
                    sentry_username: row.getValue('sentry_username'),
                    sentry_password: row.getValue('sentry_password'),
                    sentry_dns: row.getValue('sentry_dns'),
                },
                'POST',
                setApproveLoader,
                row.index
            );
            setTableData([...tableData]);
        },
        [tableData]
    );

    const columns = [
        {
            accessorKey: 'name',
            header: 'service name',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'owner',
            header: 'owner',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'type',
            header: 'type',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'purpose',
            header: 'purpose',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'request_time',
            header: 'request time',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'manager_email',
            header: 'manager',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'approval_status',
            header: 'is approved',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'sentry_username',
            header: 'sentry username',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'sentry_password',
            header: 'sentry password',
            enableEditing: false,
            size: 5,
        },
        {
            accessorKey: 'sentry_dns',
            header: 'sentry dns',
            enableEditing: false,
            size: 5,
        },
    ];

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        var data = [];
        for (var i = 0; i < tableData.length; i++) {
            var dict = {};
            dict['name'] = tableData[i]['name'];
            dict['owner'] = tableData[i]['owner'];
            dict['type'] = tableData[i]['type'];
            dict['purpose'] = tableData[i]['purpose'];
            dict['request_time'] = tableData[i]['request_time'];
            dict['manager_email'] = tableData[i]['manager_email'];
            dict['approval_status'] = tableData[i]['approval_status'];
            dict['sentry_username'] = tableData[i]['sentry_username'];
            dict['sentry_password'] = tableData[i]['sentry_password'];
            dict['sentry_dns'] = tableData[i]['sentry_dns'];
            data.push(dict);
        }
        csvExporter.generateCsv(data);
    };
    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{
                    /*columnPinning: {
                        left: ['mrt-row-actions', 'clusterName'],
                    },*/
                    density: 'compact',
                    columnVisibility: {
                        approval_status: false,
                    },
                }}
                state={{
                    isLoading: dataLoader,
                    sorting: [
                        {
                            id: 'approvedStatus',
                            desc: false,
                        },
                    ],
                }}
                renderRowActions={({ row, table }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            fontWeight: 'bold',
                        }}
                    >

                        {approveLoader[row.index] ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    fontWeight: 'bold',
                                }}
                            >
                                <CircularProgress size={5} />
                            </Box>
                        ) : (
                            <Tooltip
                                arrow
                                placement="right"
                                title={
                                    row.getValue('approvedStatus') === 'True'
                                        ? 'Reject'
                                        : 'Approve'
                                }
                            >
                                {row.getValue('approvedStatus') === 'True' ? (
                                    <IconButton
                                        color="warning"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isOwner()}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        color="success"
                                        onClick={() => handleApprove(row)}
                                        disabled={!isOwner()}
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                            </Tooltip>
                        )}
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            p: '0.5rem',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownloadIcon />}
                            variant="contained"
                        >
                            Export All Data
                        </Button>
                    </Box>
                )}
            />
        </>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default SentryTable;
