import { React, useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import OvertimeDescription from '../table/overtimeDescription';
import TotalDescription from '../table/totalDescription';
import TotalDescriptionTable from '../table/totalDescriptionTable';
import DD2 from '../DD2';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PrimaryButton from '../button/PrimaryButton';
import DatePickerInput from '../DatePickerInput';
import FlexRow from '../layout/FlexRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DetailsOutlinedIcon from '@mui/icons-material/DetailsOutlined';
import { ExportToCsv } from 'export-to-csv';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import Loading from '../costing/Loading';
import UppercaseFirstLetter from '../costing/cosmetic_functions';
import TabPanel from '../tabs/tabPanel';
import DescriptionTable from '../table/descriptionTable';
import { LinearProgress } from '@mui/material';
import SearchDD from '../SearchDD';
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
//processing data
function ProcessCost(data) {
    if (data === null) {
        data = 0;
    }

    if (data >= 1000) {
        data = data / 1000;
        data = data.toFixed(3);
        data = data.toString() + 'k $';
    } else {
        data = data.toFixed(3);
        data = data.toString() + ' $';
    }
    return data;
}
function reverse(datevalue) {
    var datelst = datevalue.split('-');
    return datelst[2] + '-' + datelst[1] + '-' + datelst[0];
}
function ProcessIncrement(data) {
    if (data === null) {
        data = 0;
    }
    if (data !== 'kickstart') {
        data = data.toFixed(2);
        data = data.toString() + '%';
    }

    return data;
}

function ParseDate(data) {
    var date = data.split('-');
    return date[0] + '/' + date[1] + " '" + date[2][2] + date[2][3];
}
//*************************** Building tags *****************************************
const TableDataCell = ({ cost, increment }) => {
    return (
        <div
            style={{ fontSize: '16px' }}
            className="w-[240px] h-[87px] py-4 font-medium text-gray-900 text-left diagonal-split-background"
        >
            <span className="sup">{ProcessCost(cost)}</span>
            {increment > 0 ? (
                <span className="inf" style={{ color: 'red' }}>
                    {'+' + ProcessIncrement(increment)}
                </span>
            ) : (
                <span className="inf" style={{ color: 'green' }}>
                    {ProcessIncrement(increment)}
                </span>
            )}
        </div>
    );
};

const TableFooterDataCell = ({ cost, increment }) => {
    return (
        <div
            style={{ fontSize: '16px', fontWeight: 900 }}
            className="w-[240px] h-[87px] py-4 text-sm font-medium text-white text-left diagonal-footer-split-background"
        >
            <span className="sup font-bold">{ProcessCost(cost)}</span>
            {increment > 0 ? (
                <span className="inf font-bold" style={{ color: 'red' }}>
                    {'+' + ProcessIncrement(increment)}
                </span>
            ) : (
                <span className="inf font-bold" style={{ color: 'green' }}>
                    {ProcessIncrement(increment)}
                </span>
            )}
        </div>
    );
};

const TableFirstDataCell = ({ children }) => {
    return (
        <div
            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            className="min-w-auto max-w-[335px] p-1 text-sm font-bold text-gray-900 text-center"
        >
            <h6>{children}</h6>
        </div>
    );
};

export default function GCPUnitCostingTable() {
    const projectServices = [
        { label: 'Project', id: 'Project' },
        { label: 'Service', id: 'Service' },
    ];
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState([]);
    const [data, setData] = useState([]);
    const [nextPageToken, setNextPageToken] = useState('');
    const [finalData, setFinalData] = useState([]);
    const [totalData, setTotalData] = useState(null);
    const [total, setTotal] = useState(0);
    const [ok, setOk] = useState(false);
    const authAxios = useAxios();
    const [serviceData, setServiceData] = useState({ services: [] });
    const [projectData, setProjectData] = useState([]);
    const [projectService, setProjectService] = useState(projectServices[0]);
    const [projects, setProjects] = useState([
        { label: 'apnatime-fbc72', id: 'apnatime-fbc72' },
    ]);
    const [gcpServices, setGcpServices] = useState([]);
    const [serviceLoading, setServiceLoading] = useState(false);
    const [projectLoading, setProjectLoading] = useState(false);
    const [mapping, setMapping] = useState({});
    const getProjectList = async () => {
        setProjectLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/gcp/projects-from-mongo`
            );
            setProjectData(data);
        } catch (e) {
            console.error(e);
        }
    };

    const getServiceToProjectMapping = async () => {
        setServiceLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/gcp/all-services`
            );
            setMapping(data);
            var servicesFirst = [];
            var keys = Object.keys(data);
            for (var i = 0; i < keys.length; i++) {
                servicesFirst.push({
                    label: keys[i].split('/')[0],
                    id: keys[i],
                });
            }
            setGcpServices(servicesFirst);
            setService(servicesFirst[0]);
            setServiceLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const views = [
        { name: 'Overtime', id: 'overtime' },
        { name: 'Total', id: 'total' },
    ];
    const periodicities = [
        { name: 'Daily', id: 'daily' },
        { name: 'Weekly', id: 'weekly' },
        { name: 'Monthly', id: 'monthly' },
    ];

    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    const [service, setService] = useState(null);
    const [project, setProject] = useState(null);
    const [instanceSkuGroupBy, setInstanceSkuGroupBy] = useState({
        name: 'Sku',
        id: 'skuId',
    });
    const [periodicity, setPeriodicity] = useState(periodicities[0]);
    const [view, setView] = useState({ name: 'Overtime', id: 'overtime' });
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [checked, setChecked] = useState(false);

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (projectService?.id === 'Project') {
            getProjectList();
        } else {
            setService(null);
            getServiceToProjectMapping();
        }
    }, [projectService]);

    //console.log(mapping);

    useEffect(() => {
        if (projectService?.id === 'Project') {
            getServiceList();
        }
    }, [project, projectService]);

    useEffect(() => {
        if (projectService?.id === 'Project') {
            var proj = [];
            for (var i = 0; i < projectData.length; i++) {
                proj.push({
                    label: projectData[i]['projectId'],
                    id: projectData[i]['projectId'],
                });
            }
            setProjects(proj);
            setProjectLoading(false);
        }
    }, [projectData, projectService]);

    const getServiceList = async () => {
        if (project) {
            setServiceLoading(true);
            try {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/gcp/services?projectId=${project?.id}`
                );
                setServiceData(data);
            } catch (e) {
                console.error(e);
            }
        }
    };

    useEffect(() => {
        if (projectService?.id === 'Project') {
            var services = [{ label: 'All', id: 'all' }];
            for (var i = 0; i < serviceData['services'].length; i++) {
                services.push({
                    label: serviceData['services'][i].split('/')[0],
                    id: serviceData['services'][i],
                });
            }
            setGcpServices(services);
            setServiceLoading(false);
        }
    }, [serviceData, projectService]);

    useEffect(() => {
        if (projectService?.id === 'Service' && service !== null && service?.id!=='all') {
            setProjectLoading(true);
            var projects = [{label:'All',id:'all'}];
            var values = mapping[service?.id];
            //console.log(values, service);
            for (var i = 0; i < values?.length; i++) {
                projects.push({
                    label: values[i],
                    id: values[i],
                });
            }
            setProjects(projects);
            setProjectLoading(false);
        }
    }, [projectService, service]);

    const [gcpGroupBy, setGcpGroupBy] = useState([]);
    useEffect(() => {
        if (project?.label ==='All'){
            setGcpGroupBy([{ name: 'Project', id: 'projectId' }]);
            setInstanceSkuGroupBy({ name: 'Project', id: 'projectId' });
        }
        else{
            if (service?.label === 'All') {
                setGcpGroupBy([{ name: 'Service', id: 'service' }]);
                setInstanceSkuGroupBy({ name: 'Service', id: 'service' });
            } else if (
                service?.label === 'Cloud SQL' ||
                service?.label === 'Cloud Memorystore for Redis'
            ) {
                setGcpGroupBy([
                    { name: 'Instance', id: 'instance_id' },
                    { name: 'Sku', id: 'skuId' },
                ]);
                setInstanceSkuGroupBy({ name: 'Instance', id: 'instance_id' });
            } else if (service?.label === 'Cloud Storage') {
                setGcpGroupBy([
                    { name: 'Bucket', id: 'bucket_id' },
                    { name: 'Sku', id: 'skuId' },
                ]);
                setInstanceSkuGroupBy({ name: 'Bucket', id: 'bucket_id' });
            } else if (service?.label === 'Kubernetes Engine') {
                setGcpGroupBy([
                    { name: 'Cluster', id: 'clusterName' },
                    { name: 'Sku', id: 'skuDescription' },
                ]);
                setInstanceSkuGroupBy({ name: 'Cluster', id: 'clusterName' });
            } else {
                setGcpGroupBy([{ name: 'Sku', id: 'skuId' }]);
                setInstanceSkuGroupBy({ name: 'Sku', id: 'skuId' });
            }
        }
    }, [service,project]);

    const isInputValid = () => {
        if (!!!periodicity) {
            alert('Please select a periodicity for data');
            return;
        }
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    const getGcpUnitCostingData = async () => {
        if (!isInputValid() || loading) {
            return;
        }

        setLoading(true);
        try {
            if (nextPageToken !== '') {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/unitcosting/gcp?toolName=${
                        service?.id
                    }&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&periodicity=${
                        !!!periodicity ? 'none' : periodicity.id
                    }&view=${view.id}&projectId=${
                        project?.id
                    }&next_page_token=${nextPageToken}&groupBy=${
                        instanceSkuGroupBy?.id
                    }&credit=${checked}`
                );
                setInfo([...info, ...data['entries']]);
                setNextPageToken(get(data, 'nextPageToken', ''));
            } else {
                const { data } = await authAxios.get(
                    `${BACKEND_URL}/unitcosting/gcp?toolName=${
                        service?.id
                    }&startDate=${
                        startDate.toISOString().split('T')[0]
                    }&endDate=${
                        endDate.toISOString().split('T')[0]
                    }&periodicity=${
                        !!!periodicity ? 'none' : periodicity.id
                    }&view=${view.id}&projectId=${project?.id}&groupBy=${
                        instanceSkuGroupBy?.id
                    }&credit=${checked}`
                );
                setInfo([...info, ...data['entries']]);
                setNextPageToken(get(data, 'nextPageToken', ''));
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    //console.log(info);

    useEffect(() => {
        if (info.length > 0) {
            if (view.id === 'overtime') {
                var dataDict = {};
                for (var i = 0; i < info.length; i++) {
                    var keys = Object.keys(info[i]);
                    if (
                        Object.keys(dataDict).includes(
                            info[i][instanceSkuGroupBy.id]
                        ) === false
                    ) {
                        dataDict[info[i][instanceSkuGroupBy.id]] = {};
                    }
                    for (var j = 0; j < keys.length; j++) {
                        if (keys[j] !== instanceSkuGroupBy.id) {
                            if (
                                get(
                                    dataDict[info[i][instanceSkuGroupBy.id]],
                                    keys[j],
                                    0
                                ) === 0
                            ) {
                                dataDict[info[i][instanceSkuGroupBy.id]][
                                    keys[j]
                                ] = info[i][keys[j]];
                            } else {
                                dataDict[info[i][instanceSkuGroupBy.id]][
                                    keys[j]
                                ] += info[i][keys[j]];
                            }
                        }
                    }
                }
                var keys = Object.keys(dataDict);
                var result = [];
                for (var k = 0; k < keys.length; k++) {
                    var tempDict = {};
                    tempDict[instanceSkuGroupBy.id] = keys[k];
                    var tempKeys = Object.keys(dataDict[keys[k]]);
                    for (var l = 0; l < tempKeys.length; l++) {
                        tempDict[tempKeys[l]] = dataDict[keys[k]][tempKeys[l]];
                    }
                    result.push(tempDict);
                }
                //console.log(result);
                setData(result);
            } else {
                setData(info);
            }
        }
    }, [info, view]);

    //console.log(data);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [rowIndex, setRowIndex] = useState(0);
    const [column, setColumn] = useState([]);
    const [header, setHeader] = useState([]);
    const [totalCost, setTotalCost] = useState([]);

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    };
    let [isOpen, setIsOpen] = useState(false);

    function closeModal() {
        setIsOpen(false);
    }

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    const handleDescription = (closeMenu, index) => {
        closeMenu();
        setRowIndex(index);
        setIsOpen(true);
    };

    const handleRedirect = (closeMenu, index) => {
        closeMenu();
        setRowIndex(index);
    };

    //console.log(periodicity)
    useEffect(() => {
        if (data?.length > 0) {
            setOk('load');
            if (view?.id === 'overtime') {
                const head = Object.keys(data[0]);
                setHeader(head);
                //Getting cost and increment of services per day/week/month
                const Cost = [];
                for (var i = 0; i < data.length; i++) {
                    var ServiceCostData = [];
                    ServiceCostData.push(data[i][instanceSkuGroupBy.id]); //use useState
                    ServiceCostData.push([0, data[i][head[1]]]); // isme value aaega-->
                    for (var j = 2; j < head.length; j++) {
                        ServiceCostData.push([
                            data[i][head[j - 1]] !== 0
                                ? ((data[i][head[j]] - data[i][head[j - 1]]) *
                                      100) /
                                  data[i][head[j - 1]]
                                : data[i][head[j]] !== 0
                                ? 'kickstart'
                                : 0,
                            data[i][head[j]],
                        ]);
                    }
                    Cost.push(ServiceCostData);
                }

                //Getting total cost and avg increment of services per day/week/month
                const total = [];
                let totalCost = [];
                total.push('Total');
                for (var i = 1; i < Cost[0].length; i++) {
                    var TotalServiceCost = 0;
                    var TotalServiceCostPrevDay = 0;
                    if (i > 1) {
                        for (var j = 0; j < Cost.length; j++) {
                            TotalServiceCost = TotalServiceCost + Cost[j][i][1];
                            TotalServiceCostPrevDay =
                                TotalServiceCostPrevDay + Cost[j][i - 1][1];
                        }
                        total.push([
                            TotalServiceCostPrevDay !== 0
                                ? ((TotalServiceCost -
                                      TotalServiceCostPrevDay) *
                                      100) /
                                  TotalServiceCostPrevDay
                                : TotalServiceCost !== 0
                                ? 'kickstart'
                                : 0,
                            TotalServiceCost,
                        ]);
                    } else {
                        for (var j = 0; j < Cost.length; j++) {
                            TotalServiceCost = TotalServiceCost + Cost[j][i][1];
                        }
                        total.push([0, TotalServiceCost]);
                    }
                    totalCost.push(TotalServiceCost);
                }
                setTotalCost(total);
                setTotalData(totalCost);
                //data definitions...
                const finalRows = [];
                for (var i = 0; i < Cost.length; i++) {
                    var rowData = {};
                    for (var j = 0; j < head.length; j++) {
                        rowData[head[j]] = Cost[i][j];
                    }
                    finalRows.push(rowData);
                }
                setFinalData(finalRows);
            } else {
                var col = Object.keys(data[0]);
                setColumn([
                    {
                        header: col[0].toUpperCase(),
                        accessorKey: col[0],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: {
                            align: 'left',
                        },
                    },

                    {
                        header: col[1].toUpperCase(),
                        accessorKey: col[1],
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        muiTableBodyCellProps: {
                            align: 'left',
                        },
                    },
                ]);
                var totalCost = 0;
                for (var i = 0; i < data.length; i++) {
                    totalCost += data[i]['total'];
                }
                setTotal(totalCost);
                setFinalData(data);
            }

            setOk('true');
        }
    }, [data, view]);

    useEffect(() => {
        if (header.length > 0 && totalCost.length > 0 && data.length > 0) {
            var cols = [
                {
                    accessorKey: header[0],
                    header: header[0],
                    Header: (
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: '15px',
                            }}
                        >
                            {header[0].toUpperCase()}
                        </div>
                    ),
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    Cell: ({ cell }) => (
                        <TableFirstDataCell toolName={'gcp'}>
                            {cell.getValue()}
                        </TableFirstDataCell>
                    ),
                    Footer: (
                        <TableFirstDataCell>{'TOTAL COST'}</TableFirstDataCell>
                    ),
                },
            ];
            if (periodicity?.id === 'weekly') {
                const customHeader = [];
                for (var i = 0; i < header.length; i++) {
                    customHeader.push(header[i].split(' '));
                }

                for (var i = 1; i < header.length; i++) {
                    cols.push({
                        accessorKey: header[i],
                        header: header[i],
                        Header: (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                }}
                                className="grid grid-rows-2 grid-flow-col gap-1"
                            >
                                <div>
                                    {'Week ' + customHeader[i][0].split('-')[1]}
                                </div>
                                <div>
                                    {ParseDate(customHeader[i][1]) +
                                        ' - ' +
                                        ParseDate(customHeader[i][2])}
                                </div>
                            </div>
                        ),
                        sortingFn: 'myCustomSortingFn',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                        Cell: ({ cell }) => (
                            <TableDataCell
                                cost={cell.getValue()[1]}
                                increment={cell.getValue()[0]}
                            ></TableDataCell>
                        ),
                        Footer: (
                            <TableFooterDataCell
                                cost={totalCost[i][1]}
                                increment={totalCost[i][0]}
                            ></TableFooterDataCell>
                        ),
                    });
                }
            } else {
                //console.log(periodicity);
                for (var i = 1; i < header.length; i++) {
                    cols.push({
                        accessorKey: header[i],
                        header: header[i],
                        Header: (
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: '15px',
                                }}
                            >
                                {header[i].toUpperCase()}
                            </div>
                        ),
                        sortingFn: 'myCustomSortingFn',
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        muiTableBodyCellProps: {
                            align: 'center',
                        },
                        Cell: ({ cell }) => (
                            <TableDataCell
                                cost={cell.getValue()[1]}
                                increment={cell.getValue()[0]}
                            ></TableDataCell>
                        ),
                        Footer: (
                            <TableFooterDataCell
                                cost={totalCost[i][1]}
                                increment={totalCost[i][0]}
                            ></TableFooterDataCell>
                        ),
                    });
                }
            }
            setColumn(cols);
        }
    }, [totalCost]);

    /*useEffect(()=>{
        if(projectService?.id==='Project' && project!==null && project?.id!=='all' && service!==null){
            var values = serviceData['services'];
            if(values?.includes(service?.id)===false){
                setService(null);
            }
        }
        else if(projectService?.id==='Service' && service!==null && service?.id!=='all' && project!==null){
            var values = mapping[service?.id];
            if(values?.includes(project?.id)===false){
                setProject(null);
            }
        }

    },[project,service,projectService,serviceData,mapping])*/

    useEffect(() => {
        setOk(false);
        setFinalData([]);
        setTotalCost([]);
        setHeader([]);
        setColumn([]);
        setData([]);
        setInfo([]);
        setNextPageToken('');
        //setInstanceSkuGroupBy()
    }, [
        startDate,
        endDate,
        periodicity,
        service,
        instanceSkuGroupBy,
        view,
        checked,
        project,
        projectService,
    ]);
    return (
        <>
            {!!loading ? (
                <Box sx={{ width: '100%', marginTop: '40vh' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <>
                    <FlexRow>
                        <SearchDD
                            label={'Project/Service'}
                            emptyLabel={'Select Project/Service'}
                            options={projectServices}
                            value={projectService}
                            onChange={setProjectService}
                            disabled={false}
                        />
                        {projectService?.id === 'Project' ? (
                            <>
                                <SearchDD
                                    label={'Project Name'}
                                    emptyLabel={'Select Project'}
                                    options={projects}
                                    value={project}
                                    onChange={setProject}
                                    isLoading={projectLoading}
                                    disabled={false}
                                />
                                <SearchDD
                                    label={'Service Name'}
                                    emptyLabel={'Select GCP Service'}
                                    options={gcpServices}
                                    value={service}
                                    onChange={setService}
                                    isLoading={serviceLoading}
                                    disabled={!!!project}
                                />
                            </>
                        ) : (
                            <>
                                <SearchDD
                                    label={'Service Name'}
                                    emptyLabel={'Select GCP Service'}
                                    options={gcpServices}
                                    value={service}
                                    onChange={setService}
                                    isLoading={serviceLoading}
                                    disabled={false}
                                />
                                <SearchDD
                                    label={'Project Name'}
                                    emptyLabel={'Select Project'}
                                    options={projects}
                                    value={project}
                                    onChange={setProject}
                                    isLoading={projectLoading}
                                    disabled={!!!service}
                                />
                            </>
                        )}
                        <DD2
                            label={'Group By'}
                            emptyLabel={'Select a way to Group By'}
                            options={gcpGroupBy}
                            value={instanceSkuGroupBy}
                            onChange={setInstanceSkuGroupBy}
                            displayProperty={'name'}
                            disabled={!!!service}
                        />
                        <DD2
                            label={'View'}
                            emptyLabel={'Select a view type'}
                            options={views}
                            value={view}
                            onChange={setView}
                            displayProperty={'name'}
                        />
                        <DD2
                            label={'Periodicity'}
                            emptyLabel={'Select a periodicity'}
                            options={periodicities}
                            value={periodicity}
                            onChange={setPeriodicity}
                            displayProperty={'name'}
                            disabled={view.id === 'total'}
                        />
                        <div style={{ marginTop: '40px' }}>
                            <Box
                                sx={{
                                    borderColor: 'black',
                                    width: '150px',
                                    height: '50px',
                                    borderRadius: '5px',
                                    backgroundColor: 'white',
                                }}
                            >
                                <FormControl
                                    style={{ marginTop: '5px' }}
                                    component="fieldset"
                                    variant="standard"
                                >
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={checked}
                                                    onChange={handleCheck}
                                                    inputProps={{
                                                        'aria-label':
                                                            'controlled',
                                                    }}
                                                />
                                            }
                                            label="Credits"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        </div>

                        <FlexRow />
                    </FlexRow>
                    <FlexRow>
                        <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                        />
                        <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            onChange={setEndDate}
                            maxDate={dateObj}
                        />
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                    </FlexRow>
                    <div className="mt-8">
                        <PrimaryButton
                            title={'$ Get Unit Costing Data'}
                            onClick={getGcpUnitCostingData}
                        />
                    </div>
                    {data.length > 0 ? (
                        <Box sx={{ width: '100%', marginTop: '30px' }}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab label="TABLE" {...a11yProps(0)} />
                                    <Tab label="GRAPH" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                {(() => {
                                    switch (ok) {
                                        case 'false':
                                            return <></>;
                                        case 'load':
                                            return <Loading />;
                                        case 'true':
                                            return (
                                                <div
                                                    style={{
                                                        marginTop: '30px',
                                                    }}
                                                >
                                                    {view.id === 'total' ? (
                                                        <div className="inline flex">
                                                            <svg
                                                                aria-hidden="true"
                                                                className="flex-shrink-0 inline w-5 h-5 mr-3"
                                                                fill="currentColor"
                                                                viewBox="0 0 20 20"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                                    clipRule="evenodd"
                                                                ></path>
                                                            </svg>
                                                            <h3
                                                                style={{
                                                                    marginBottom:
                                                                        '15px',
                                                                    fontWeight:
                                                                        'bold',
                                                                    color: 'red',
                                                                }}
                                                            >{`Total cost of ${
                                                                service?.id
                                                            } for the selected date range ${
                                                                checked
                                                                    ? 'including'
                                                                    : 'excluding'
                                                            } credit is $${total.toFixed(
                                                                2
                                                            )} `}</h3>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <MaterialReactTable
                                                        data={finalData}
                                                        columns={column}
                                                        enableDensityToggle={
                                                            false
                                                        }
                                                        enableFullScreenToggle
                                                        enableStickyFooter
                                                        enableStickyHeader
                                                        enableRowActions
                                                        muiTableBodyCellProps={{
                                                            sx: {
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '14px',
                                                            },
                                                        }}
                                                        muiTableBodyProps={{
                                                            sx: {
                                                                marginTop:
                                                                    '100px',
                                                            },
                                                        }}
                                                        sortingFns={{
                                                            //will add a new sorting function to the list of other sorting functions already available
                                                            myCustomSortingFn: (
                                                                rowA,
                                                                rowB,
                                                                columnId
                                                            ) =>
                                                                rowA.getValue(
                                                                    columnId
                                                                )[1] >
                                                                rowB.getValue(
                                                                    columnId
                                                                )[1]
                                                                    ? 1
                                                                    : -1,
                                                        }}
                                                        initialState={
                                                            view.id ===
                                                            'overtime'
                                                                ? {
                                                                      columnPinning:
                                                                          {
                                                                              left: [
                                                                                  'mrt-row-select',
                                                                                  'mrt-row-actions',
                                                                              ],
                                                                              right: [
                                                                                  instanceSkuGroupBy.id,
                                                                              ],
                                                                          }, //use useState
                                                                      density:
                                                                          'compact',
                                                                  }
                                                                : {
                                                                      sorting: [
                                                                          {
                                                                              id: 'total',
                                                                              desc: true,
                                                                          },
                                                                      ],
                                                                      density:
                                                                          'compact',
                                                                  }
                                                        }
                                                        positionToolbarAlertBanner="bottom"
                                                        renderTopToolbarCustomActions={({
                                                            table,
                                                        }) => (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    gap: '1rem',
                                                                    p: '0.5rem',
                                                                    flexWrap:
                                                                        'wrap',
                                                                }}
                                                            >
                                                                <Button
                                                                    color="primary"
                                                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                                    onClick={
                                                                        handleExportData
                                                                    }
                                                                    startIcon={
                                                                        <FileDownloadIcon />
                                                                    }
                                                                    variant="contained"
                                                                >
                                                                    Export All
                                                                    Data
                                                                </Button>

                                                                <Button
                                                                    color="warning"
                                                                    onClick={
                                                                        getGcpUnitCostingData
                                                                    }
                                                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                    startIcon={
                                                                        <ArrowForwardIosIcon />
                                                                    }
                                                                    variant="contained"
                                                                    disabled={
                                                                        nextPageToken ===
                                                                        ''
                                                                    }
                                                                >
                                                                    Get Next Set
                                                                    of Data
                                                                </Button>
                                                            </Box>
                                                        )}
                                                        renderRowActionMenuItems={({
                                                            closeMenu,
                                                            row,
                                                        }) => [
                                                            <MenuItem
                                                                key={1}
                                                                disabled={
                                                                    !(
                                                                        (service?.label ===
                                                                            'Cloud SQL' ||
                                                                            service?.label ===
                                                                                'Cloud Storage' ||
                                                                            service?.label ===
                                                                                'Cloud Memorystore for Redis' ||
                                                                            service?.label ===
                                                                                'Kubernetes Engine') &&
                                                                        instanceSkuGroupBy.id !==
                                                                            'skuId'
                                                                    )
                                                                }
                                                                onClick={() => {
                                                                    handleDescription(
                                                                        closeMenu,
                                                                        row.index
                                                                    );
                                                                }}
                                                                sx={{
                                                                    m: 0,
                                                                }}
                                                            >
                                                                <ListItemIcon>
                                                                    <DetailsOutlinedIcon />
                                                                </ListItemIcon>
                                                                {'Get ' +
                                                                    instanceSkuGroupBy.id +
                                                                    ' description'}
                                                            </MenuItem>,
                                                        ]}
                                                    />
                                                </div>
                                            );
                                    }
                                })()}
                                <Transition appear show={isOpen} as={Fragment}>
                                    <Dialog
                                        as="div"
                                        className="relative z-10"
                                        onClose={closeModal}
                                    >
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 overflow-y-auto mt-20">
                                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                                <Transition.Child
                                                    as={Fragment}
                                                    enter="ease-out duration-300"
                                                    enterFrom="opacity-0 scale-95"
                                                    enterTo="opacity-100 scale-100"
                                                    leave="ease-in duration-200"
                                                    leaveFrom="opacity-100 scale-100"
                                                    leaveTo="opacity-0 scale-95"
                                                >
                                                    <Dialog.Panel className="w-auto transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                                        <Dialog.Title
                                                            as="h3"
                                                            className="text-lg text-center font-medium leading-6 text-gray-900"
                                                        >
                                                            {
                                                                'SKU wise cost breakdown'
                                                            }
                                                        </Dialog.Title>
                                                        <div className="mt-2">
                                                            {finalData.length >
                                                            0 ? (
                                                                view.id ===
                                                                'overtime' ? (
                                                                    <DescriptionTable
                                                                        resource_key={
                                                                            instanceSkuGroupBy.id
                                                                        }
                                                                        resource_value={
                                                                            finalData[
                                                                                rowIndex
                                                                            ][
                                                                                instanceSkuGroupBy
                                                                                    .id
                                                                            ]
                                                                        }
                                                                        toolName={
                                                                            service?.id
                                                                        }
                                                                        view={
                                                                            'overtime'
                                                                        }
                                                                        startDate={
                                                                            startDate
                                                                        }
                                                                        endDate={
                                                                            endDate
                                                                        }
                                                                        periodicity={
                                                                            periodicity.id
                                                                        }
                                                                        projectId={
                                                                            project?.id
                                                                        }
                                                                        credit={
                                                                            checked
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <TotalDescriptionTable
                                                                        resource_key={
                                                                            instanceSkuGroupBy.id
                                                                        }
                                                                        resource_value={
                                                                            finalData[
                                                                                rowIndex
                                                                            ]
                                                                                ? finalData[
                                                                                      rowIndex
                                                                                  ][
                                                                                      instanceSkuGroupBy
                                                                                          .id
                                                                                  ]
                                                                                : ''
                                                                        }
                                                                        tool={
                                                                            service?.id
                                                                        }
                                                                        startDate={
                                                                            startDate
                                                                        }
                                                                        endDate={
                                                                            endDate
                                                                        }
                                                                        project={
                                                                            project?.id
                                                                        }
                                                                        credit={
                                                                            checked
                                                                        }
                                                                    />
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>

                                                        <div className="mt-4">
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                onClick={
                                                                    closeModal
                                                                }
                                                            >
                                                                Got it, thanks!
                                                            </button>
                                                        </div>
                                                    </Dialog.Panel>
                                                </Transition.Child>
                                            </div>
                                        </div>
                                    </Dialog>
                                </Transition>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {view.id === 'overtime' ? (
                                    <div className="mt-8 min-w-[1500px] p-4">
                                        <OvertimeDescription
                                            data={data}
                                            groupBy={instanceSkuGroupBy?.id}
                                            periodicity={periodicity?.id}
                                            totalCost={totalData}
                                        />
                                    </div>
                                ) : (
                                    <div className="mt-8 w-full">
                                        <TotalDescription
                                            data={data}
                                            groupBy={instanceSkuGroupBy?.id}
                                            tool={service?.name}
                                            startDate={
                                                startDate
                                                    .toISOString()
                                                    .split('T')[0]
                                            }
                                            endDate={
                                                endDate
                                                    .toISOString()
                                                    .split('T')[0]
                                            }
                                        />
                                    </div>
                                )}
                            </TabPanel>
                        </Box>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
}
