//import React, { useState, useEffect } from 'react';
import HeaderTitle from '../../components/typography/header-title';
import Tool from '../../components/accessComponents/tool';
import Requestpopup from '../../components/accessComponents/requestpopup';
//import Home from '../../components/securityComponent/home';
export default function Tools() {
    return (
        <>
            <HeaderTitle
                title={'Tools'}
                subtitle={
                    'Request access for different tools'
                }
            />
            <Requestpopup/>
            <Tool/>
        </>
    );
}
