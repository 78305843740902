import { Grid } from '@mui/material';
import Sidebar from '../../components/navigation/sidebar';
import { Route, Routes, Navigate } from 'react-router-dom';
import Tools from './tools';
import ApnaTechstack from './apnaTechstack';
import Manager from './manager';
import Admin from './admin';
import ItAdminRoute from '../../components/navigation/itAdminRoute';
import ManagerRoute from '../../components/navigation/managerRoute';

function Access() {
    return (
        <Grid container>
            <Grid xs={12}>
                <Sidebar />
            </Grid>
            <Grid xs={12}>
                <div className="py-6 px-8" style={{ marginTop: '70px' }}>
                    <Routes>
                        <Route index element={<Navigate to={'tools'} />} />
                        <Route path="tools/*" element={<Tools />} />
                        <Route element={<ItAdminRoute />}>
                            <Route path="admin/*" element={<Admin />} />
                        </Route>
                        <Route element={<ManagerRoute />}>
                            <Route path="manager/*" element={<Manager />} />
                        </Route>

                        <Route
                            path="apnatechstack/*"
                            element={<ApnaTechstack />}
                        />
                    </Routes>
                </div>
            </Grid>
        </Grid>
    );
}

export default Access;
