import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ReactJson from 'react-json-view';
import { LinearProgress } from '@mui/material';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Fragment } from 'react';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DatePickerInput from '../DatePickerInput';
import FlexRow from '../layout/FlexRow';
import PrimaryButton from '../button/PrimaryButton';
import DD2 from '../DD2';
import TimePickerInput from '../costing/TimePickerInput';
import dayjs from 'dayjs';
import FilterPercentage from '../costing/filter-percentage';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../tabs/tabPanel';
import InfoIcon from '@mui/icons-material/Info';
import Datatable from '../costing/Datatable';
import { useSearchParams } from 'react-router-dom';
import LogDatatable from '../costing/logDatatable';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
function reverse(datevalue) {
    var datelst = datevalue.split('-');
    return datelst[2] + '-' + datelst[1] + '-' + datelst[0];
}
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const DashboardQuestion = ({ value }) => {
    return (
        <span
            className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm whitespace-nowrap ${(() => {
                if (value.includes('__#Question#__:')) {
                    return 'bg-red-100 text-red-700';
                }
                return 'bg-green-100 text-green-700';
            })()}`}
            style={{ fontWeight: 'bolder', fontSize: '12px' }}
        >
            {value.includes('__#Question#__:')
                ? value.split('__#Question#__:')[1]
                : value}
        </span>
    );
};

export default function QueryLogsTable() {
    var dateObj = new Date();
    var dateObj1 = new Date();
    dateObj1.setDate(dateObj1.getDate() - 1);
    const views = [
        { name: 'Previous Days', id: 'overtime' },
        { name: 'Real Time', id: 'realtime' },
    ];
    const [filterOperators, setFilterOperators] = useState([
        { value: 'GreaterThan', name: '>' },
        { value: 'GreaterThanEqualsTo', name: '>=' },
    ]);
    const [searchParams, setSearchParams] = useSearchParams({'view':'Previous Days'});
    const [principal, setPrincipal] = useState(null);
    const [view, setView] = useState(views[0]);
    const [filterOperator, setFilterOperator] = useState(null);
    const [filterPercentage, setFilterPercentage] = useState(25);
    const [endDate, setEndDate] = useState(dateObj1);
    const [startDate, setStartDate] = useState(dateObj1);
    const [endTime, setEndTime] = useState(dayjs(dateObj.toISOString()));
    const [startTime, setStartTime] = useState(dayjs(dateObj.toISOString()));
    const [nextPageToken, setNextPageToken] = useState('');
    const [distribution, setDistribution] = useState([]);
    const [dist, setDist] = useState([]);
    const [title, setTitle] = useState([]);
    const [totTitle, setTotTitle] = useState([]);
    const [log, setLog] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [agentFilterList, setAgentFilterList] = useState([]);
    const [agent, setAgent] = useState(null);
    const [columnFilter, setColumnFilter] = useState([]);
    const [realtimeColumnFilter, setRealtimeColumnFilter] = useState([]);
    const [buttonDisability, setButtonDisability] = useState(false);
    var count = 0;

    useEffect(() => {
        if (count === 0) {
            var viewName = searchParams.get('view');
            var principal_id = searchParams.get('principal_id');
            var agent_id = searchParams.get('agent_id');
            var start_date = searchParams.get('startDate');
            var end_date = searchParams.get('endDate');
            var start_time = searchParams.get('startTime');
            var end_time = searchParams.get('endTime');
            //console.log(viewName);
            if (!!start_date) {
                setStartDate(new Date(start_date));
            }
            if (!!end_date) {
                setEndDate(new Date(end_date));
            }
            if (!!start_time) {
                setStartTime(dayjs(start_time));
            }
            if (!!end_time) {
                setEndTime(dayjs(end_time));
            }
            if (viewName === 'Previous Days') {
                setView({ name: 'Previous Days', id: 'overtime' });
                if (!!principal_id && !!start_date && !!end_date) {
                    setPrincipal(principal_id);
                    setColumnFilter([
                        {
                            id: 'userId',
                            value: principal_id,
                        },
                    ]);
                    setOk('true');
                    setButtonDisability(true);
                    getdata(
                        `${BACKEND_URL}/gcp/bigquery-previous-logs?start_time=${
                            start_date.split('T')[0]
                        }&end_time=${end_date.split('T')[0]}`,
                        setData,
                        setLoading
                    );
                    setValue(1);
                }
                if (!!agent_id && !!start_date && !!end_date) {
                    setAgent(agent_id);
                    setColumnFilter([
                        {
                            id: 'agent',
                            value: [agent_id],
                        },
                    ]);
                    setOk('true');
                    setButtonDisability(true);
                    getdata(
                        `${BACKEND_URL}/gcp/bigquery-previous-logs?start_time=${
                            start_date.split('T')[0]
                        }&end_time=${end_date.split('T')[0]}`,
                        setData,
                        setLoading
                    );
                    setValue(1);
                }
            }
            if (viewName === 'Real Time') {
                setView({ name: 'Real Time', id: 'realtime' });
                setFilterOperator({ value: 'GreaterThanEqualsTo', name: '>=' });
                setFilterPercentage(200);
                if (!!principal_id && !!start_time && !!end_time) {
                    setPrincipal(principal_id);
                    setRealtimeColumnFilter([
                        {
                            id: 'userId',
                            value: principal_id,
                        },
                    ]);
                    setOk('true');
                    setButtonDisability(true);
                    getdata(
                        `${BACKEND_URL}/gcp/bigquery-latest-logs?start_time=${start_time}&end_time=${end_time}&next_page_token=${nextPageToken}&filter_operator=GreaterThanEqualsTo&filter=200`,
                        setRealtimeData,
                        setLoading
                    );
                }
                if (!!agent_id && !!start_time && !!end_time) {
                    setAgent(agent_id);
                    setRealtimeColumnFilter([
                        {
                            id: 'agent',
                            value: [agent_id],
                        },
                    ]);
                    setOk('true');
                    setButtonDisability(true);
                    getdata(
                        `${BACKEND_URL}/gcp/bigquery-latest-logs?start_time=${start_time}&end_time=${end_time}&next_page_token=${nextPageToken}&filter_operator=GreaterThanEqualsTo&filter=200`,
                        setRealtimeData,
                        setLoading
                    );
                }
            }
        }
        count = 1;
    }, [searchParams]);

    function closeModal() {
        setIsOpen(false);
    }

    function openModal() {
        setIsOpen(true);
    }
    const [index, setIndex] = useState(0);
    const [column, setColumn] = useState([]);
    const [distColumn, setDistColumn] = useState([]);

    var distributionColumn = [
        {
            header: 'Email ID',
            accessorKey: 'emailId',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Dashboard/Question',
            accessorKey: 'dashboard',
            Cell: ({ cell }) => <DashboardQuestion value={cell.getValue()} />,
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Count',
            accessorKey: 'count',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        },
        {
            header: 'Total Cost ($)',
            accessorKey: 'totalcost',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>
                    {cell.getValue().toFixed(3)}
                </div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        },
        {
            header: 'Average Cost',
            accessorKey: 'averagecost',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
    ];

    var distDistributionColumn = [
        {
            header: 'Dashboard/Question',
            accessorKey: 'dashboard',
            Cell: ({ cell }) => <DashboardQuestion value={cell.getValue()} />,
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
        {
            header: 'Count',
            accessorKey: 'count',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        },
        {
            header: 'Total Cost ($)',
            accessorKey: 'totalcost',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>
                    {cell.getValue().toFixed(3)}
                </div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        },
        {
            header: 'Average Cost',
            accessorKey: 'averagecost',
            Cell: ({ cell }) => (
                <div style={{ fontWeight: 'bolder' }}>{cell.getValue()}</div>
            ),
            muiTableHeadCellProps: {
                align: 'left',
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            filterFn: 'customFilterFn',
        },
    ];

    const [columnRealtime, setColumnRealtime] = useState([]);

    const [loading, setLoading] = useState(false);

    const [Data, setData] = useState({
        entries: [],
        nextPageToken: '',
    });
    const [realtimeData, setRealtimeData] = useState({
        entries: [],
        nextPageToken: '',
    });
    const [finalData, setFinalData] = useState([]);
    const [finalDistributedData, setFinalDistributedData] = useState([]);
    const [finalRealtimeData, setFinalRealtimeData] = useState([]);
    const [ok, setOk] = useState('false');
    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        setButtonDisability(true);
        try {
            const { data } = await authAxios.get(url);
            setdata(data);
            if (data.entries.length == 0) {
                setloading(false);
            }
        } catch (e) {
            console.error(e);
        }
    };
    const isDateInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };
    const isTimeInputValid = () => {
        if (startTime > endTime) {
            alert('Please enter valid start and end time');
            return;
        }
        if (!!!filterOperator) {
            alert('Choose filter Operator');
            return;
        }
        if (filterPercentage < 10) {
            alert('Choose filterPercentage >= 10');
            return;
        }
        return true;
    };
    const getQueryCostingData = () => {
        if (!isDateInputValid() || loading) {
            return;
        }
        setOk('true');

        getdata(
            `${BACKEND_URL}/gcp/bigquery-previous-logs?start_time=${
                startDate.toISOString().split('T')[0]
            }&end_time=${
                endDate.toISOString().split('T')[0]
            }&next_page_token=${nextPageToken}`,
            setData,
            setLoading
        );
    };

    const getRealtimeQueryCostingData = () => {
        if (!isTimeInputValid() || loading) {
            return;
        }
        setOk('true');
        getdata(
            `${BACKEND_URL}/gcp/bigquery-latest-logs?start_time=${startTime.toISOString()}&end_time=${endTime.toISOString()}&next_page_token=${nextPageToken}&filter_operator=${
                filterOperator.value
            }&filter=${filterPercentage}`,
            setRealtimeData,
            setLoading
        );
    };
    const [csvOptions, setCsvOptions] = useState({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        filename: 'Total Table',
        useKeysAsHeaders: false,
        headers: column.map((c) => c.header),
    });
    const [value, setValue] = useState(0);
    useEffect(() => {
        if (view.name === 'Previous Days') {
            if (value === 0) {
                setCsvOptions({
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    useBom: true,
                    filename: 'Total Table',
                    useKeysAsHeaders: false,
                    headers: column.map((c) => c.header),
                });
            } else {
                setCsvOptions({
                    fieldSeparator: ',',
                    quoteStrings: '"',
                    decimalSeparator: '.',
                    showLabels: true,
                    useBom: true,
                    filename: 'Distributed Table',
                    useKeysAsHeaders: false,
                    headers: column.map((c) => c.header),
                });
            }
        } else {
            setCsvOptions({
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                filename: 'Realtime Data',
                useBom: true,
                useKeysAsHeaders: false,
                headers: columnRealtime.map((c) => c.header),
            });
        }
    }, [view, value]);

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        if (view.name === 'Previous Days') {
            csvExporter.generateCsv(finalData);
        } else {
            csvExporter.generateCsv(finalRealtimeData);
        }
    };

    const handleclick = (ind) => {
        setIndex(ind);
        openModal();
    };
    const globalTheme = useTheme();

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === 'light'
                                ? 'rgb(254,255,244)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '0.9rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1.1rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );
    useEffect(() => {
        setColumn([
            {
                header: 'User ID',
                accessorKey: 'userId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Agent',
                accessorKey: 'agent',
                filterVariant: 'multi-select',
                filterSelectOptions: agentFilterList,
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Count',
                accessorKey: 'count',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Total Cost ($)',
                accessorKey: 'totalcost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Average Cost ($)',
                accessorKey: 'averagecost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {(() => {
                            var cost = cell.getValue();
                            var pow = Math.floor(Math.log10(cost));
                            var val = cost / Math.pow(10, pow);
                            val = val.toFixed(2);
                            return val.toString() + `E${pow}`;
                        })()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Date',
                accessorKey: 'date',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {reverse(cell.getValue())}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
        ]);
        setDistColumn([
            {
                header: 'User ID',
                accessorKey: 'userId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Agent',
                accessorKey: 'agent',
                filterVariant: 'multi-select',
                filterSelectOptions: agentFilterList,
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Count',
                accessorKey: 'count',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Total Cost ($)',
                accessorKey: 'totalcost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Average Cost ($)',
                accessorKey: 'averagecost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {(() => {
                            var cost = cell.getValue();
                            var pow = Math.floor(Math.log10(cost));
                            var val = cost / Math.pow(10, pow);
                            val = val.toFixed(2);
                            return val.toString() + `E${pow}`;
                        })()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Date',
                accessorKey: 'date',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {reverse(cell.getValue())}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
        ]);
        setColumnRealtime([
            {
                header: 'User ID',
                accessorKey: 'userId',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
            {
                header: 'Billed Bytes (GB)',
                accessorKey: 'billedBytes',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
            {
                header: 'Processed Bytes (GB)',
                accessorKey: 'processedBytes',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
            {
                header: 'Cost ($)',
                accessorKey: 'cost',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
            {
                header: 'Caller Ip',
                accessorKey: 'callerIp',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
            {
                header: 'Agent',
                accessorKey: 'agent',
                filterVariant: 'multi-select',
                filterSelectOptions: agentFilterList,
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: 'Date',
                accessorKey: 'date',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {reverse(cell.getValue())}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
            {
                header: 'Timestamp',
                accessorKey: 'timestamp',
                Cell: ({ cell }) => (
                    <div style={{ fontWeight: 'bolder' }}>
                        {cell.getValue()}
                    </div>
                ),
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                filterFn: 'customFilterFn',
            },
        ]);
    }, [agentFilterList]);
    useEffect(() => {
        var data = get(Data, 'entries', []);
        if (data.length > 0) {
            var temp = [];
            var tempDistributed = [];
            var totalDist = [];
            var totaldist = [];
            var titles = [];
            var totTitles = [];
            const agentVals = new Set();
            for (var i = 0; i < data.length; i++) {
                var agentVal = data[i]['agent'];
                agentVal =
                    agentVal.includes('Tableau') === true
                        ? 'Tableau Server'
                        : agentVal.indexOf('/') === -1
                        ? agentVal
                        : agentVal.split('/')[0];
                agentVals.add(agentVal);
                var dict = {
                    userId: data[i]['principal'],
                    agent: agentVal,
                    count: data[i]['count'],
                    totalcost: data[i]['cost'].toFixed(2),
                    averagecost: data[i]['cost'] / data[i]['count'],
                    date: data[i]['date'].split('T')[0],
                    //distribution: [],
                };
                totTitles.push(
                    data[i]['principal'] +
                        '/' +
                        agentVal +
                        '/' +
                        'cost-' +
                        data[i]['cost'].toFixed(2).toString() +
                        '/' +
                        data[i]['date'].split('T')[0]
                );
                var totalDistribution = [];
                var distributionIds = Object.keys(data[i]['distribution']);
                //titles = titles.concat(distributionIds)
                for (var j = 0; j < distributionIds.length; j++) {
                    titles.push(
                        distributionIds[j] + ' ' + data[i]['date'].split('T')[0]
                    );
                    var dashboardIds = Object.keys(
                        data[i]['distribution'][distributionIds[j]]
                    );
                    var ids = distributionIds[j];
                    var totCost = 0;
                    var totCount = 0;
                    var tempDict = {};
                    tempDict['userId'] = ids;
                    tempDict['agent'] = agentVal;
                    var distList = [];
                    for (var k = 0; k < dashboardIds.length; k++) {
                        var roundedIds = Object.keys(
                            data[i]['distribution'][distributionIds[j]][
                                dashboardIds[k]
                            ]
                        );
                        for (var l = 0; l < roundedIds.length; l++) {
                            var dashboardName = dashboardIds[k];

                            var totcost =
                                data[i]['distribution'][ids][dashboardName][
                                    roundedIds[l]
                                ]['cost'];
                            var cnt =
                                data[i]['distribution'][ids][dashboardName][
                                    roundedIds[l]
                                ]['count'];
                            var avgcost = (totcost / cnt).toFixed(4);

                            var logVal =
                                data[i]['distribution'][ids][dashboardName][
                                    roundedIds[l]
                                ]['log'];
                            if (
                                ids !== 'Metabase with no id' &&
                                dashboardName === 'Not Valid'
                            ) {
                                dashboardName = 'ad hoc Query';
                            }
                            totalDistribution.push({
                                emailId: ids,
                                dashboard: dashboardName,
                                count: cnt,
                                totalcost: totcost,
                                averagecost: avgcost,
                                log: logVal,
                            });
                            totCost = totCost + totcost;
                            totCount = totCount + cnt;
                            distList.push({
                                dashboard: dashboardName,
                                count: cnt,
                                totalcost: totcost,
                                averagecost: avgcost,
                                log: logVal,
                            });
                        }
                    }
                    tempDict['count'] = totCount;
                    tempDict['totalcost'] = totCost;
                    tempDict['averagecost'] = (totCost / totCount).toFixed(4);
                    tempDict['date'] = data[i]['date'].split('T')[0];
                    //tempDict['distribution'] = distList;
                    totaldist.push(distList);
                    tempDistributed.push(tempDict);
                }
                totalDist.push(totalDistribution);
                temp.push(dict);
            }
            setTotTitle([...totTitle, ...totTitles]);
            setTitle([...title, ...titles]);
            setDist([...dist, ...totaldist]);
            setDistribution([...distribution, ...totalDist]);
            setAgentFilterList([...agentVals]);
            setFinalData([...finalData, ...temp]);
            setFinalDistributedData([
                ...finalDistributedData,
                ...tempDistributed,
            ]);
            setNextPageToken(get(Data, 'nextPageToken', ''));
            setLoading(false);
        }
    }, [Data]);

    useEffect(() => {
        var realTimeData = get(realtimeData, 'entries', []);
        if (realTimeData.length > 0) {
            const agentVals = new Set();
            var temp = [];
            for (var i = 0; i < realTimeData.length; i++) {
                var agentVal = get(
                    get(realTimeData[i].log.protoPayload, 'requestMetadata', {
                        callerSuppliedUserAgent: 'No Agent Found',
                    }),
                    'callerSuppliedUserAgent',
                    'No Agent Found'
                );
                agentVal =
                    agentVal.includes('Tableau') === true
                        ? 'Tableau Server'
                        : agentVal.indexOf('/') === -1
                        ? agentVal
                        : agentVal.split('/')[0];
                agentVals.add(agentVal);
                var callerIpVal = get(
                    get(realTimeData[i].log.protoPayload, 'requestMetadata', {
                        callerIp: 'No Ip Found',
                    }),
                    'callerIp',
                    'No Ip Found'
                );
                temp.push({
                    userId: realTimeData[i].principal,
                    billedBytes: (
                        realTimeData[i].log.protoPayload.serviceData
                            .jobCompletedEvent.job.jobStatistics
                            .totalBilledBytes / 1000000000
                    ).toFixed(2),
                    processedBytes: (
                        realTimeData[i].log.protoPayload.serviceData
                            .jobCompletedEvent.job.jobStatistics
                            .totalProcessedBytes / 1000000000
                    ).toFixed(2),
                    cost: (
                        (realTimeData[i].log.protoPayload.serviceData
                            .jobCompletedEvent.job.jobStatistics
                            .totalBilledBytes /
                            1000000000000) *
                        (54.06 / 9.91)
                    ).toFixed(2),
                    callerIp: callerIpVal,
                    agent: agentVal,
                    date: realTimeData[i].log.timestamp.split('T')[0],
                    timestamp: realTimeData[i].log.timestamp,
                });
            }
            setLog([...log, ...realTimeData]);
            setAgentFilterList([...agentVals]);
            setFinalRealtimeData([...finalRealtimeData, ...temp]);
            setNextPageToken(get(realtimeData, 'nextPageToken', ''));
            setLoading(false);
        }
    }, [realtimeData]);

    useEffect(() => {
        if (view.name === 'Previous Days') {
            setFilterOperator(null);
        }
    }, [view]);
    useEffect(() => {
        //setOk('false');
        setFinalRealtimeData([]);
        setDist([]);
        setDistribution([]);
        setLog([]);
        setRealtimeData({
            entries: [],
            nextPageToken: '',
        });
        setData({
            entries: [],
            nextPageToken: '',
        });
        setFinalData([]);
        setNextPageToken('');
        setButtonDisability(false);
    }, [
        startDate,
        endDate,
        filterOperator,
        filterPercentage,
        startTime,
        endTime,
        view,
    ]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <FlexRow>
                <FlexRow>
                    <DD2
                        label={'View Name'}
                        emptyLabel={'Select a View'}
                        options={views}
                        value={view}
                        onChange={(item) => {
                            setSearchParams({
                                'view':item.name
                            });
                        }}
                        displayProperty={'name'}
                        disabled={false}
                    />
                </FlexRow>
                {view.name !== 'Previous Days' ? (
                    <>
                        <FlexRow>
                            <DD2
                                label={'Filter Operator'}
                                emptyLabel={'Select a Filter Operator'}
                                options={filterOperators}
                                value={filterOperator}
                                onChange={setFilterOperator}
                                displayProperty={'name'}
                                isLoading={false}
                            />
                        </FlexRow>
                        <FlexRow>
                            <FilterPercentage
                                label={'Filter By GB'}
                                value={filterPercentage}
                                minvalue={10}
                                onChange={(e) =>
                                    setFilterPercentage(e.target.value)
                                }
                            />
                        </FlexRow>
                        <FlexRow></FlexRow>
                    </>
                ) : (
                    <>
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                    </>
                )}
            </FlexRow>
            <FlexRow>
                {view.name === 'Previous Days' ? (
                    <>
                        <FlexRow>
                            <DatePickerInput
                                label={'Start Date'}
                                value={startDate}
                                minDate={new Date('2023-02-01T00:00:00.000Z')}
                                maxDate={endDate}
                                onChange={(item) => {
                                    setSearchParams({
                                        startDate: item.toISOString(),
                                        endDate: endDate.toISOString(),
                                        view: 'Previous Days'
                                    });
                                }}
                            />
                        </FlexRow>
                        <FlexRow>
                            <DatePickerInput
                                label={'End Date'}
                                value={endDate}
                                maxDate={dateObj1}
                                onChange={(item) => {
                                    setSearchParams({
                                        startDate: startDate.toISOString(),
                                        endDate: item.toISOString(),
                                        view: 'Previous Days'
                                    });
                                }}
                            />
                        </FlexRow>
                    </>
                ) : (
                    <>
                        <FlexRow>
                            <TimePickerInput
                                label={'Start Time'}
                                value={startTime}
                                onChange={(item) => {
                                    setSearchParams({
                                        startTime: item.toISOString(),
                                        endTime: endTime.toISOString(),
                                        view: 'Real Time'
                                    });
                                }}
                                maxTime={endTime}
                            />
                        </FlexRow>
                        <FlexRow>
                            <TimePickerInput
                                label={'End Time'}
                                value={endTime}
                                onChange={(item) => {
                                    setSearchParams({
                                        startTime: startTime.toISOString(),
                                        endTime: item.toISOString(),
                                        view: 'Real Time'
                                    });
                                }}
                            />
                        </FlexRow>
                    </>
                )}
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <div className="mt-8">
                <PrimaryButton
                    title={'$ Get Threshold Exceeded Query'}
                    onClick={
                        view.name === 'Previous Days'
                            ? getQueryCostingData
                            : getRealtimeQueryCostingData
                    }
                    disabled={buttonDisability}
                />
            </div>

            <div className="grid grid-rows-2 p-4">
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <>
                                    {view.name === 'Previous Days' ? (
                                        <>
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        borderBottom: 1,
                                                        borderColor: 'divider',
                                                    }}
                                                >
                                                    <Tabs
                                                        value={value}
                                                        onChange={handleChange}
                                                        aria-label="basic tabs example"
                                                    >
                                                        <Tab
                                                            label="TOTAL"
                                                            {...a11yProps(0)}
                                                        />
                                                        <Tab
                                                            label="DISTRIBUTED"
                                                            {...a11yProps(1)}
                                                        />
                                                    </Tabs>
                                                </Box>
                                                <TabPanel
                                                    value={value}
                                                    index={0}
                                                >
                                                    <>
                                                        <div className="p-1">
                                                            <MaterialReactTable
                                                                data={finalData}
                                                                columns={column}
                                                                enableStickyFooter
                                                                enableStickyHeader
                                                                initialState={{
                                                                    sorting: [
                                                                        {
                                                                            id: 'totalcost',
                                                                            desc: true,
                                                                        },
                                                                    ],
                                                                    columnPinning:
                                                                        {
                                                                            left: [
                                                                                'mrt-row-select',
                                                                                'mrt-row-expand',
                                                                                'mrt-row-actions',
                                                                            ],
                                                                        },
                                                                    density:
                                                                        'compact',
                                                                }}
                                                                muiTableBodyRowProps={({
                                                                    row,
                                                                }) => ({
                                                                    sx: {
                                                                        backgroundColor:
                                                                            row.getValue(
                                                                                'totalcost'
                                                                            ) >
                                                                            10
                                                                                ? '#c06565'
                                                                                : row.getValue(
                                                                                      'totalcost'
                                                                                  ) >
                                                                                  3
                                                                                ? '#ebb64e'
                                                                                : '#9eb46c',
                                                                    },
                                                                })}
                                                                filterFns={{
                                                                    customFilterFn:
                                                                        (
                                                                            row,
                                                                            id,
                                                                            filterValue
                                                                        ) => {
                                                                            return row
                                                                                .getValue(
                                                                                    id
                                                                                )
                                                                                .includes(
                                                                                    filterValue
                                                                                );
                                                                        },
                                                                }}
                                                                enableHiding={
                                                                    false
                                                                }
                                                                state={{
                                                                    isLoading:
                                                                        loading,
                                                                }}
                                                                enableRowSelection
                                                                enableDensityToggle={
                                                                    false
                                                                }
                                                                positionToolbarAlertBanner="bottom"
                                                                renderTopToolbarCustomActions={({
                                                                    table,
                                                                }) => (
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            gap: '1rem',
                                                                            p: '0.5rem',
                                                                            flexWrap:
                                                                                'wrap',
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            color="primary"
                                                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                                            onClick={
                                                                                handleExportData
                                                                            }
                                                                            startIcon={
                                                                                <FileDownloadIcon />
                                                                            }
                                                                            variant="contained"
                                                                        >
                                                                            Export
                                                                            All
                                                                            Data
                                                                        </Button>
                                                                        <Button
                                                                            disabled={
                                                                                table.getRowModel()
                                                                                    .rows
                                                                                    .length ===
                                                                                0
                                                                            }
                                                                            //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                                            onClick={() =>
                                                                                handleExportRows(
                                                                                    table.getRowModel()
                                                                                        .rows
                                                                                )
                                                                            }
                                                                            startIcon={
                                                                                <FileDownloadIcon />
                                                                            }
                                                                            variant="contained"
                                                                        >
                                                                            Export
                                                                            Page
                                                                            Rows
                                                                        </Button>
                                                                        <Button
                                                                            disabled={
                                                                                !table.getIsSomeRowsSelected() &&
                                                                                !table.getIsAllRowsSelected()
                                                                            }
                                                                            //only export selected rows
                                                                            onClick={() =>
                                                                                handleExportRows(
                                                                                    table.getSelectedRowModel()
                                                                                        .rows
                                                                                )
                                                                            }
                                                                            startIcon={
                                                                                <FileDownloadIcon />
                                                                            }
                                                                            variant="contained"
                                                                        >
                                                                            Export
                                                                            Selected
                                                                            Rows
                                                                        </Button>
                                                                        <Button
                                                                            color="warning"
                                                                            onClick={
                                                                                getQueryCostingData
                                                                            }
                                                                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                            startIcon={
                                                                                <ArrowForwardIosIcon />
                                                                            }
                                                                            variant="contained"
                                                                            disabled={
                                                                                nextPageToken ===
                                                                                    '' ||
                                                                                loading
                                                                            }
                                                                        >
                                                                            Get
                                                                            Next
                                                                            Set
                                                                            of
                                                                            Data
                                                                        </Button>
                                                                    </Box>
                                                                )}
                                                                renderDetailPanel={({
                                                                    row,
                                                                }) => (
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                'flex',
                                                                            marginLeft:
                                                                                '250px',
                                                                            width: '1200px',
                                                                        }}
                                                                    >
                                                                        <LogDatatable
                                                                            columns={
                                                                                distributionColumn
                                                                            }
                                                                            data={
                                                                                distribution[
                                                                                    row
                                                                                        .index
                                                                                ]
                                                                            }
                                                                            title={
                                                                                totTitle[
                                                                                    row
                                                                                        .index
                                                                                ]
                                                                            }
                                                                        />
                                                                    </Box>
                                                                )}
                                                            />
                                                            <Alert
                                                                severity="info"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                Green code
                                                                denotes
                                                                "Dashboard"
                                                            </Alert>
                                                            <Alert
                                                                severity="info"
                                                                style={{
                                                                    marginTop:
                                                                        '10px',
                                                                }}
                                                            >
                                                                Red code denotes
                                                                "Question"
                                                            </Alert>
                                                        </div>
                                                    </>
                                                </TabPanel>
                                                <TabPanel
                                                    value={value}
                                                    index={1}
                                                >
                                                    <div className="p-1">
                                                        <MaterialReactTable
                                                            data={
                                                                finalDistributedData
                                                            }
                                                            columns={distColumn}
                                                            enableStickyFooter
                                                            enableStickyHeader
                                                            initialState={{
                                                                sorting: [
                                                                    {
                                                                        id: 'totalcost',
                                                                        desc: true,
                                                                    },
                                                                ],
                                                                columnFilters:
                                                                    columnFilter,
                                                                columnPinning: {
                                                                    left: [
                                                                        'mrt-row-select',
                                                                        'mrt-row-expand',
                                                                    ],
                                                                },
                                                                density:
                                                                    'compact',
                                                            }}
                                                            filterFns={{
                                                                customFilterFn:
                                                                    (
                                                                        row,
                                                                        id,
                                                                        filterValue
                                                                    ) => {
                                                                        return row
                                                                            .getValue(
                                                                                id
                                                                            )
                                                                            .includes(
                                                                                filterValue
                                                                            );
                                                                    },
                                                            }}
                                                            muiTableBodyRowProps={({
                                                                row,
                                                            }) => ({
                                                                sx: {
                                                                    backgroundColor:
                                                                        row.getValue(
                                                                            'totalcost'
                                                                        ) > 10
                                                                            ? '#c06565'
                                                                            : row.getValue(
                                                                                  'totalcost'
                                                                              ) >
                                                                              3
                                                                            ? '#ebb64e'
                                                                            : '#9eb46c',
                                                                },
                                                            })}
                                                            state={{
                                                                isLoading:
                                                                    loading,
                                                            }}
                                                            enableRowSelection
                                                            enableHiding={false}
                                                            displayColumnDefOptions={{
                                                                'mrt-row-actions':
                                                                    {
                                                                        header: 'Info', //change header text
                                                                        //size: 30, //make actions column wider
                                                                    },
                                                            }}
                                                            enableDensityToggle={
                                                                false
                                                            }
                                                            positionToolbarAlertBanner="bottom"
                                                            renderTopToolbarCustomActions={({
                                                                table,
                                                            }) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        gap: '1rem',
                                                                        p: '0.5rem',
                                                                        flexWrap:
                                                                            'wrap',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                                        onClick={
                                                                            handleExportData
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        All Data
                                                                    </Button>
                                                                    <Button
                                                                        disabled={
                                                                            table.getRowModel()
                                                                                .rows
                                                                                .length ===
                                                                            0
                                                                        }
                                                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                                        onClick={() =>
                                                                            handleExportRows(
                                                                                table.getRowModel()
                                                                                    .rows
                                                                            )
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        Page
                                                                        Rows
                                                                    </Button>
                                                                    <Button
                                                                        disabled={
                                                                            !table.getIsSomeRowsSelected() &&
                                                                            !table.getIsAllRowsSelected()
                                                                        }
                                                                        //only export selected rows
                                                                        onClick={() =>
                                                                            handleExportRows(
                                                                                table.getSelectedRowModel()
                                                                                    .rows
                                                                            )
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        Selected
                                                                        Rows
                                                                    </Button>
                                                                    <Button
                                                                        color="warning"
                                                                        onClick={
                                                                            getQueryCostingData
                                                                        }
                                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                        startIcon={
                                                                            <ArrowForwardIosIcon />
                                                                        }
                                                                        variant="contained"
                                                                        disabled={
                                                                            nextPageToken ===
                                                                            ''
                                                                        }
                                                                    >
                                                                        Get Next
                                                                        Set of
                                                                        Data
                                                                    </Button>
                                                                </Box>
                                                            )}
                                                            renderDetailPanel={({
                                                                row,
                                                            }) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        marginLeft:
                                                                            '250px',
                                                                        width: '1200px',
                                                                    }}
                                                                >
                                                                    <LogDatatable
                                                                        columns={
                                                                            distDistributionColumn
                                                                        }
                                                                        data={
                                                                            dist[
                                                                                row
                                                                                    .index
                                                                            ]
                                                                        }
                                                                        title={
                                                                            title[
                                                                                row
                                                                                    .index
                                                                            ]
                                                                        }
                                                                    />
                                                                </Box>
                                                            )}
                                                        />
                                                        <Alert
                                                            severity="info"
                                                            style={{
                                                                marginTop:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Green code denotes
                                                            "Dashboard"
                                                        </Alert>
                                                        <Alert
                                                            severity="info"
                                                            style={{
                                                                marginTop:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Red code denotes
                                                            "Question"
                                                        </Alert>
                                                    </div>
                                                </TabPanel>
                                            </Box>
                                            <Transition
                                                appear
                                                show={isOpen}
                                                as={Fragment}
                                            >
                                                <Dialog
                                                    as="div"
                                                    className="relative z-10"
                                                    onClose={closeModal}
                                                >
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0"
                                                        enterTo="opacity-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                                                    </Transition.Child>

                                                    <div className="fixed inset-0 overflow-y-auto mt-20">
                                                        <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                                                            <Transition.Child
                                                                as={Fragment}
                                                                enter="ease-out duration-300"
                                                                enterFrom="opacity-0 scale-95"
                                                                enterTo="opacity-100 scale-100"
                                                                leave="ease-in duration-200"
                                                                leaveFrom="opacity-100 scale-100"
                                                                leaveTo="opacity-0 scale-95"
                                                            >
                                                                <Dialog.Panel className="w-full max-w-[1000px] transform overflow-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                                                    <Dialog.Title
                                                                        as="h3"
                                                                        className="text-lg font-medium leading-6 text-gray-900 mt-20"
                                                                    >
                                                                        METADATA
                                                                    </Dialog.Title>
                                                                    <div className="mt-20">
                                                                        <ReactJson
                                                                            collapseStringsAfterLength={
                                                                                200
                                                                            }
                                                                            src={
                                                                                finalDistributedData[
                                                                                    index
                                                                                ]
                                                                                    ?.log
                                                                            }
                                                                            displayDataTypes={
                                                                                false
                                                                            }
                                                                            name={
                                                                                false
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Dialog.Panel>
                                                            </Transition.Child>
                                                        </div>
                                                    </div>
                                                </Dialog>
                                            </Transition>
                                        </>
                                    ) : (
                                        <>
                                            {!!loading ? (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        marginTop: '20vh',
                                                    }}
                                                >
                                                    <LinearProgress />
                                                </Box>
                                            ) : (
                                                <>
                                                    <div className="p-1">
                                                        <MaterialReactTable
                                                            data={
                                                                finalRealtimeData
                                                            }
                                                            columns={
                                                                columnRealtime
                                                            }
                                                            enableStickyFooter
                                                            enableStickyHeader
                                                            initialState={{
                                                                sorting: [
                                                                    {
                                                                        id: 'cost',
                                                                        desc: true,
                                                                    },
                                                                ],
                                                                columnFilters:
                                                                    realtimeColumnFilter,
                                                                columnPinning: {
                                                                    left: [
                                                                        'mrt-row-select',
                                                                        'mrt-row-actions',
                                                                    ],
                                                                },
                                                                density:
                                                                    'compact',
                                                            }}
                                                            filterFns={{
                                                                customFilterFn:
                                                                    (
                                                                        row,
                                                                        id,
                                                                        filterValue
                                                                    ) => {
                                                                        return row
                                                                            .getValue(
                                                                                id
                                                                            )
                                                                            .includes(
                                                                                filterValue
                                                                            );
                                                                    },
                                                            }}
                                                            muiTableBodyRowProps={({
                                                                row,
                                                            }) => ({
                                                                sx: {
                                                                    backgroundColor:
                                                                        row.getValue(
                                                                            'cost'
                                                                        ) > 5
                                                                            ? '#c06565'
                                                                            : row.getValue(
                                                                                  'cost'
                                                                              ) >
                                                                              1
                                                                            ? '#ebb64e'
                                                                            : '#9eb46c',
                                                                },
                                                            })}
                                                            enableRowActions
                                                            state={{
                                                                isLoading:
                                                                    loading,
                                                            }}
                                                            enableRowSelection
                                                            enableDensityToggle={
                                                                false
                                                            }
                                                            displayColumnDefOptions={{
                                                                'mrt-row-actions':
                                                                    {
                                                                        header: 'Info', //change header text
                                                                    },
                                                            }}
                                                            renderRowActions={({
                                                                row,
                                                            }) => (
                                                                <Box>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleclick(
                                                                                row.index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <InfoIcon />
                                                                    </Button>
                                                                </Box>
                                                            )}
                                                            positionToolbarAlertBanner="bottom"
                                                            renderTopToolbarCustomActions={({
                                                                table,
                                                            }) => (
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        gap: '1rem',
                                                                        p: '0.5rem',
                                                                        flexWrap:
                                                                            'wrap',
                                                                    }}
                                                                >
                                                                    <Button
                                                                        color="primary"
                                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                                                        onClick={
                                                                            handleExportData
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        All Data
                                                                    </Button>
                                                                    <Button
                                                                        disabled={
                                                                            table.getRowModel()
                                                                                .rows
                                                                                .length ===
                                                                            0
                                                                        }
                                                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                                                        onClick={() =>
                                                                            handleExportRows(
                                                                                table.getRowModel()
                                                                                    .rows
                                                                            )
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        Page
                                                                        Rows
                                                                    </Button>
                                                                    <Button
                                                                        disabled={
                                                                            !table.getIsSomeRowsSelected() &&
                                                                            !table.getIsAllRowsSelected()
                                                                        }
                                                                        //only export selected rows
                                                                        onClick={() =>
                                                                            handleExportRows(
                                                                                table.getSelectedRowModel()
                                                                                    .rows
                                                                            )
                                                                        }
                                                                        startIcon={
                                                                            <FileDownloadIcon />
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Export
                                                                        Selected
                                                                        Rows
                                                                    </Button>
                                                                    <Button
                                                                        color="warning"
                                                                        onClick={
                                                                            getRealtimeQueryCostingData
                                                                        }
                                                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

                                                                        startIcon={
                                                                            <ArrowForwardIosIcon />
                                                                        }
                                                                        variant="contained"
                                                                        disabled={
                                                                            nextPageToken ===
                                                                                '' ||
                                                                            loading
                                                                        }
                                                                    >
                                                                        Get Next
                                                                        Set of
                                                                        Data
                                                                    </Button>
                                                                </Box>
                                                            )}
                                                        />
                                                    </div>
                                                    <Transition
                                                        appear
                                                        show={isOpen}
                                                        as={Fragment}
                                                    >
                                                        <Dialog
                                                            as="div"
                                                            className="relative z-10"
                                                            onClose={closeModal}
                                                        >
                                                            <Transition.Child
                                                                as={Fragment}
                                                                enter="ease-out duration-300"
                                                                enterFrom="opacity-0"
                                                                enterTo="opacity-100"
                                                                leave="ease-in duration-200"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                                                            </Transition.Child>

                                                            <div className="fixed inset-0 overflow-y-auto mt-20">
                                                                <div className="flex min-h-full items-center justify-center p-4 text-center min-w-max">
                                                                    <Transition.Child
                                                                        as={
                                                                            Fragment
                                                                        }
                                                                        enter="ease-out duration-300"
                                                                        enterFrom="opacity-0 scale-95"
                                                                        enterTo="opacity-100 scale-100"
                                                                        leave="ease-in duration-200"
                                                                        leaveFrom="opacity-100 scale-100"
                                                                        leaveTo="opacity-0 scale-95"
                                                                    >
                                                                        <Dialog.Panel className="w-full max-w-[1000px] transform overflow-scroll rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                                                            <Dialog.Title
                                                                                as="h3"
                                                                                className="text-lg font-medium leading-6 text-gray-900"
                                                                            >
                                                                                METADATA
                                                                            </Dialog.Title>
                                                                            <div className="mt-2">
                                                                                <ReactJson
                                                                                    collapseStringsAfterLength={
                                                                                        200
                                                                                    }
                                                                                    src={
                                                                                        log[
                                                                                            index
                                                                                        ]
                                                                                    }
                                                                                    displayDataTypes={
                                                                                        false
                                                                                    }
                                                                                    name={
                                                                                        false
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </Dialog.Panel>
                                                                    </Transition.Child>
                                                                </div>
                                                            </div>
                                                        </Dialog>
                                                    </Transition>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            );
                    }
                })()}
            </div>
        </>
    );
}
