import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

const PIIDataViewerRoute = () => {
    const { isPIIDataViewer} = useAuth();
    if (isPIIDataViewer()) {
        return <Outlet />;
    } else {
        <Navigate to={'/home'} />;
    }
};

export default PIIDataViewerRoute;
