import MongoTable from '../table/mongotable';
import { React, useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import OvertimeDescription from '../table/overtimeDescription';
import TotalDescription from '../table/totalDescription';
import { styled } from '@mui/material/styles';
import DD2 from '../DD2';
import PrimaryButton from '../button/PrimaryButton';
import DatePickerInput from '../DatePickerInput';
import FlexRow from '../layout/FlexRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '../tabs/tabPanel';
import { useMongoClustersMetadata } from '../../hooks/costing/useMongo';
import { useElasticDeploymentsMetadata } from '../../hooks/costing/useElastic';
import { LinearProgress } from '@mui/material';
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export default function UnitCostingTable() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [finalData, setFinalData] = useState([]);
    const [totalData, setTotalData] = useState(null);
    const [ok, setOk] = useState(false);
    const authAxios = useAxios();
    const { data: mongoMappingData } = useMongoClustersMetadata();
    const { data: elasticMappingData } = useElasticDeploymentsMetadata();
    const [metabaseMappingData, setMetabaseMappingData] = useState({});
    const [value, setValue] = useState(0);

    const tools = [
        { name: 'Mongo', id: 'mongo' },
        { name: 'Elastic', id: 'elastic' },
        { name: 'Bigquery Analysis', id: 'bigquery' },
        { name: 'Bigquery Analysis (Metabase)', id: 'bigquery' },
    ];
    const periodicities = [
        { name: 'Daily', id: 'daily' },
        { name: 'Weekly', id: 'weekly' },
        { name: 'Monthly', id: 'monthly' },
    ];

    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);

    const [tool, setTool] = useState(tools[0]);
    const [groupBy, setGroupBy] = useState(null);
    const [periodicity, setPeriodicity] = useState(null);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);

    var groupbys = {
        Mongo: [
            { name: 'Cluster', id: 'cluster' },
            { name: 'Vertical', id: 'vertical' },
            { name: 'Team', id: 'team' },
        ],
        Elastic: [
            { name: 'Deployment', id: 'deployment' },
            { name: 'Vertical', id: 'vertical' },
            { name: 'Team', id: 'team' },
        ],
        'Bigquery Analysis': [
            { name: 'User Id', id: 'principal' },
            { name: 'Agent', id: 'agent' },
            { name: 'Team', id: 'team' },
        ],
        'Bigquery Analysis (Metabase)': [
            { name: 'Dashboard (Cost)', id: 'dashboard' },
            { name: 'Dashboard (Count)', id: 'dashboard' },
            { name: 'Question (Cost)', id: 'question' },
            { name: 'Question (Count)', id: 'question' },
        ],
    };

    const isInputValid = () => {
        if (!!!tool) {
            alert('Please select a tool');
            return false;
        }
        if (!!!groupBy) {
            alert('Please select a way to group by');
            return false;
        }
        if (!!!periodicity) {
            alert('Please select a periodicity for data');
            return;
        }
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

   
    const getUnitCostingData = async () => {
        if (!isInputValid() || loading) {
            return;
        }

        setLoading(true);
        try {
            await authAxios.get(
                `${BACKEND_URL}/unitcosting/?toolName=${tool.id}&groupBy=${
                    tool.name === 'Bigquery Analysis (Metabase)'
                        ? groupBy.name === 'Dashboard (Cost)'
                            ? 'dashboardCost'
                            : groupBy.name === 'Dashboard (Count)'
                            ? 'dashboardCount'
                            : groupBy.name === 'Question (Cost)'
                            ? 'questionCost'
                            : 'questionCount'
                        : groupBy.id
                }&startDate=${startDate.toISOString().split('T')[0]}&endDate=${
                    endDate.toISOString().split('T')[0]
                }&periodicity=${
                    !!!periodicity ? 'none' : periodicity.id
                }&view=overtime`
            ).then(response=>{
                var data=response.data;
                if(tool?.name === 'Bigquery Analysis (Metabase)'){
                    var tempData=[];
                    var keys=[]
                    if(data?.length>0){
                        keys=Object.keys(data[0]);
                        var temp=keys[keys.length-1];
                        keys.pop();
                        keys.unshift(temp);
                    }
                    for(var i=0; i<data?.length; i++){
                        var tempDatapoint={}
                        for(var j=0; j<keys.length; j++){
                            tempDatapoint[keys[j]]=data[i][keys[j]];
                        }
                        tempData.push(tempDatapoint);
                    }
                    data=tempData;
                }
                setData(data);
            });
            
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(()=>{
        var finalList = []
        if(data?.length>0){
            if((groupBy.name).includes("Question")){
                for(var i=0; i<data.length; i++){
                    finalList.push(data[i]);
                    finalList[i]["question"]= finalList[i]["question"].split("__#Question#__:")[1];
                }
                setFinalData(finalList)
            }
            else{
                setFinalData(data);
            }
        }
    },[data])
    // use effect to update the group by options when the tool changes.
    useEffect(() => {
        /*if (tool.name === 'Bigquery Analysis (Metabase)') {
            getMetabaseData();
        }*/
        setGroupBy(groupbys[tool.name][0]);
    }, [tool]);

    useEffect(() => {
        setData(null);
        setFinalData([]);
        setValue(0);
    }, [startDate, endDate, groupBy, periodicity]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    //console.log(data)
    return (
        <>
            {!!loading ? (
                <Box sx={{ width: '100%', marginTop: '40vh' }}>
                    <LinearProgress />
                </Box>
            ) : (
                <>
                    <FlexRow>
                        <DD2
                            label={'Tool Name'}
                            emptyLabel={'Select a Tool Type'}
                            options={tools}
                            value={tool}
                            onChange={setTool}
                            displayProperty={'name'}
                            disabled={false}
                        />
                        <DD2
                            label={'Group By'}
                            emptyLabel={'Select a way to Group By'}
                            options={groupbys[tool.name]}
                            value={groupBy}
                            onChange={setGroupBy}
                            displayProperty={'name'}
                            disabled={!!!tool}
                        />

                        <DD2
                            label={'Periodicity'}
                            emptyLabel={'Select a periodicity'}
                            options={periodicities}
                            value={periodicity}
                            onChange={setPeriodicity}
                            displayProperty={'name'}
                            disabled={!!!groupBy}
                        />

                        <FlexRow />
                    </FlexRow>
                    <FlexRow>
                        <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                            minDate={
                                groupBy?.name.includes('Dashboard')
                                    ? new Date('2023-04-12T00:00:00Z')
                                    : groupBy?.name.includes('Question')
                                    ? new Date('2023-05-02T00:00:00Z')
                                    : new Date('2023-01-01T00:00:00Z')
                            }
                        />
                        <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            onChange={setEndDate}
                            maxDate={dateObj}
                            minDate={startDate}
                        />
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                    </FlexRow>
                    <div className="mt-8">
                        <PrimaryButton
                            title={'$ Get Unit Costing Data'}
                            onClick={getUnitCostingData}
                        />
                    </div>
                    {!!data ? (
                        <Box sx={{ width: '100%', marginTop: '30px' }}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab label="TABLE" {...a11yProps(0)} />
                                    <Tab label="GRAPH" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                {finalData.length>0 ? tool?.id === 'mongo' ? (
                                    <MongoTable
                                        data={finalData}
                                        mappingData={mongoMappingData}
                                        groupBy={groupBy?.id}
                                        groupByName={groupBy?.name}
                                        periodicity={periodicity?.id}
                                        setTotalData={setTotalData}
                                        toolName={tool.id}
                                    />
                                ) : tool?.id === 'elastic' ? (
                                    <MongoTable
                                        data={finalData}
                                        mappingData={elasticMappingData}
                                        groupBy={groupBy?.id}
                                        groupByName={groupBy?.name}
                                        periodicity={periodicity?.id}
                                        setTotalData={setTotalData}
                                        toolName={tool.id}
                                    />
                                ) : tool.id === 'bigquery' ? (
                                    <MongoTable
                                        data={finalData}
                                        mappingData={[]}
                                        groupBy={groupBy?.id}
                                        groupByName={groupBy?.name}
                                        periodicity={periodicity?.id}
                                        setTotalData={setTotalData}
                                        toolName={tool.id}
                                        startDate={startDate.toISOString()}
                                        endDate={endDate.toISOString()}
                                    />
                                ) : (
                                    <MongoTable
                                        data={finalData}
                                        mappingData={[]}
                                        groupBy={groupBy?.id}
                                        groupByName={groupBy?.name}
                                        periodicity={periodicity?.id}
                                        setTotalData={setTotalData}
                                        toolName={tool.id}
                                        startDate={startDate.toISOString()}
                                        endDate={endDate.toISOString()}
                                    />
                                ):<></>}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="mt-8 min-w-[1500px] p-4">
                                    <OvertimeDescription
                                        data={data}
                                        groupBy={groupBy?.id}
                                        groupByName={groupBy?.name}
                                        periodicity={periodicity?.id}
                                        totalCost={totalData}
                                    />
                                </div>
                            </TabPanel>
                        </Box>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
}
