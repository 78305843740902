import { useEffect, useState } from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import Chart from 'react-apexcharts';
import Datatable from '../../../../components/costing/Datatable';
import { BACKEND_URL } from '../../../../configs';
import Description from '../../../../components/costing/Description/description';
import DatePickerInput from '../../../../components/DatePickerInput';
import { useAxios } from '../../../../contexts/axios';
import DD2 from '../../../../components/DD2';
import DD3 from '../../../../components/DD3';
import PrimaryButton from '../../../../components/button/PrimaryButton';
import {
    useGCPProjects,
    useGCPClusters,
} from '../../../../hooks/costing/useGCP';
import Grid from '@mui/material/Unstable_Grid2';
export default function GCPlogmetrics() {
    var numFormatter = require('number_formatter');

    const authAxios = useAxios();

    const getdata = async (url, setdata, setloading) => {
        setloading(true);
        try {
            const { data } = await authAxios.get(`${BACKEND_URL}/gcp/${url}`);
            setdata(data);
        } catch (e) {
            console.error(e);
        } finally {
            setloading(false);
        }
    };

    const [dataLoading, setDataLoading] = useState(false);
    const { data: projects, isLoading: projectsLoading } = useGCPProjects();
    const [resources] = useState([{ name: 'GKE Cluster' }]);
    const [logTypes] = useState([
        { name: 'Container Logs', value: 'k8s_container' },
    ]);
    const [metricTypes] = useState([
        {
            name: 'Log Entry Count',
            value: 'logging.googleapis.com/log_entry_count',
        },
        { name: 'Byte Count', value: 'logging.googleapis.com/byte_count' },
    ]);
    const [groupByes] = useState([
        {
            index: 1,
            label: 'Namespace Name',
            value: 'resource.labels.namespace_name',
        },
        {
            index: 2,
            label: 'Container Name',
            value: 'resource.labels.container_name',
        },
        { index: 3, label: 'Severity', value: 'metric.label.severity' },
        {
            index: 4,
            label: 'Service Name',
            value: 'metadata.system_labels.service_name',
        },
    ]);
    const chartTypes = [{ name: 'line' }, { name: 'area' }, { name: 'table' }];
    const [chartType, setChartType] = useState(chartTypes[0]);
    const [dictArrayYAxis, setdictArrayYAxis] = useState([]);
    const [dictArrayYAxisdata, setdictArrayYAxisdata] = useState([]);
    const [filteredSeries, setfilteredSeries] = useState([]);
    const [filteredSeriesdata, setfilteredSeriesdata] = useState([]);
    const [project, setProject] = useState(null);
    const [resource, setResource] = useState(null);
    const [subType, setSubType] = useState(null);
    const [logType, setLogType] = useState(null);
    const [metricType, setMetricType] = useState(null);
    const [groupBy, setGroupBy] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [ok, setOk] = useState('false');
    const [load, setLoad] = useState(false);
    const [yAxisTitle, setyAxisTitle] = useState(null);
    const [legendPosition, setlegendPosition] = useState(null);
    const [options, setOptions] = useState(null);
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [rawData, setRawData] = useState(null);
    const [rawDataByte, setRawDataByte] = useState(null);
    const [rawByteSize, setrawByteSize] = useState(0);
    const [logEntrySum, setLogEntrySum] = useState(0);
    
    const { data: subTypes, isLoading: subTypeLoading } = useGCPClusters(
        project,
        resource
    );

    useEffect(() => {
        if (dictArrayYAxis.length > 20) {
            setlegendPosition('right');
        } else {
            setlegendPosition('bottom');
        }
    }, [dictArrayYAxis]);

    useEffect(() => {
        if (metricType) {
            if (metricType.name === 'Log Entry Count') {
                setyAxisTitle('Logs Entry Sum');
            } else if (metricType.name === 'Byte Count') {
                setyAxisTitle('Logs Byte Sum');
            }
        }
    }, [metricType]);

    useEffect(() => {
        var tempGBy = '';
        for (var i = 0; i < groupBy.length; i++) {
            if (i < groupBy.length - 1) {
                tempGBy += groupBy[i] + ' & ';
            } else {
                tempGBy += groupBy[i];
            }
        }
        if (chartType.name === 'line') {
            setOptions({
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: true,
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 100,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 3,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 2,
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    text: logType
                        ? logType.name + ' Grouped By ' + tempGBy
                        : '',
                    align: 'left',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            if (metricType.name === 'Log Entry Count') {
                                return numFormatter(value);
                            } else if (metricType.name === 'Byte Count') {
                                let rawByteSizeInGIB =
                                    value / 1024 / 1024 / 1024;

                                if (rawByteSizeInGIB.toFixed(5) > 0) {
                                    if (rawByteSizeInGIB < 1024) {
                                        return (
                                            rawByteSizeInGIB.toFixed(5) + ' GB'
                                        );
                                    } else {
                                        return (
                                            rawByteSizeInGIB.toFixed(5) + ' GB'
                                        );
                                    }
                                } else {
                                    return rawByteSizeInGIB.toFixed(5) + ' GB';
                                }
                            }
                        },
                    },
                    title: {
                        text: yAxisTitle,
                        style: {
                            color: '#FF1654',
                        },
                    },
                },
                legend: {
                    position: legendPosition,
                },
                tooltip: {
                    shared: false,
                    intersect: true,
                    x: {
                        show: false,
                    },
                },
                markers: {
                    size: 6,
                },
            });
        } else if (chartType.name === 'area') {
            setOptions({
                chart: {
                    height: 350,
                    type: 'area',
                    zoom: {
                        enabled: true,
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 10,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    opacity: 0.5,
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    text: logType.name + ' Grouped By ' + tempGBy,
                    align: 'left',
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            if (metricType.name === 'Log Entry Count') {
                                return numFormatter(value);
                            } else if (metricType.name === 'Byte Count') {
                                let rawByteSizeInGIB =
                                    value / 1024 / 1024 / 1024;

                                if (rawByteSizeInGIB.toFixed(5) > 0) {
                                    if (rawByteSizeInGIB < 1024) {
                                        return (
                                            rawByteSizeInGIB.toFixed(5) + ' GB'
                                        );
                                    } else {
                                        return (
                                            rawByteSizeInGIB.toFixed(5) + ' GB'
                                        );
                                    }
                                } else {
                                    return rawByteSizeInGIB.toFixed(15) + ' GB';
                                }
                            }
                        },
                    },
                },
                legend: {
                    position: legendPosition,
                },
                tooltip: {
                    shared: false,
                    intersect: true,
                    x: {
                        show: false,
                    },
                },
                markers: {
                    size: 6,
                },
            });
        }
    }, [chartType, dictArrayYAxis]);



    useEffect(() => {
        var newdata = [];
        if (filteredSeries.length > 0) {
            for (var i = 0; i < filteredSeries.length; i++) {
                var ind;
                for (var j = 0; j < dictArrayYAxisdata.length; j++) {
                    if (filteredSeries[i] == dictArrayYAxisdata[j].name) {
                        ind = j;
                        break;
                    }
                }
                newdata.push(dictArrayYAxisdata[ind]);
            }
            setdictArrayYAxis(newdata);
        } else {
            setdictArrayYAxis(dictArrayYAxisdata);
        }
    }, [filteredSeries, dictArrayYAxisdata]);

    function CalculateTotalLogSize() {
        if (ok === 'true' && rawDataByte !== null) {
            var dataJsonObj = rawDataByte;

            var logByteSum = 0;
            dataJsonObj.forEach(function (row) {
                let seriesName = '';
                for (var i = 0; i < groupBy.length; i++) {
                    if (groupBy[i] === 'Namespace Name') {
                        seriesName +=
                            row['resource']['labels']['namespace_name'] + '-';
                    } else if (groupBy[i] === 'Container Name') {
                        seriesName +=
                            row['resource']['labels']['container_name'] + '-';
                    } else if (groupBy[i] === 'Severity') {
                        seriesName += row['metric']['labels']['severity'] + '-';
                    } else if (groupBy[i] === 'Service Name') {
                        seriesName +=
                            row['metadata']['systemLabels']['service_name'] +
                            '-';
                    }
                }
                if (filteredSeries.length > 0) {
                    if (filteredSeries.includes(seriesName)) {
                        row.points.forEach(function (point) {
                            logByteSum += parseInt(point.value.int64Value);
                        });
                    }
                } else {
                    row.points.forEach(function (point) {
                        logByteSum += parseInt(point.value.int64Value);
                    });
                }
            });

            return logByteSum;
        } else {
            return 0;
        }
    }

    useEffect(() => {
        setOk('false');
        setLoad(false);
        setRawData(null);
        setRawDataByte(null);
    }, [
        project,
        resource,
        subType,
        logType,
        metricType,
        groupBy,
        startDate,
        endDate,
    ]);

    useEffect(() => {
        if (metricType) {
            var columns;
            var data = [];

            if (metricType.name == 'Log Entry Count') {
                columns = [
                    {
                        accessorKey: 'title', //access nested data with dot notation
                        header: 'Name',
                    },
                    {
                        accessorKey: 'entries',
                        header: 'Sum of Log Entries',
                    },
                ];
            } else if (metricType.name == 'Byte Count') {
                columns = [
                    {
                        accessorKey: 'title', //access nested data with dot notation
                        header: 'Name',
                    },
                    {
                        accessorKey: 'entries',
                        header: 'Sum of Log Bytes (In GB)',
                    },
                ];
            }
            setColumns(columns);
            for (var i = 0; i < dictArrayYAxis.length; i++) {
                let row = dictArrayYAxis[i];
                if (metricType.name == 'Log Entry Count') {
                    data.push({
                        id: i + 1,
                        title: row.name,
                        entries: row.data.reduce(
                            (partialSum, a) => partialSum + a.y,
                            0
                        ),
                    });
                } else if (metricType.name == 'Byte Count') {
                    let rawByteSizeInGIB =
                        row.data.reduce(
                            (partialSum, a) => partialSum + a.y,
                            0
                        ) /
                        1024 /
                        1024 /
                        1024;

                    if (rawByteSizeInGIB.toFixed(5) > 0) {
                        if (rawByteSizeInGIB < 1024) {
                            data.push({
                                id: i + 1,
                                title: row.name,
                                entries: rawByteSizeInGIB.toFixed(5),
                            });
                        } else {
                            data.push({
                                id: i + 1,
                                title: row.name,
                                entries: rawByteSizeInGIB.toFixed(5),
                            });
                        }
                    } else {
                        data.push({
                            id: i + 1,
                            title: row.name,
                            entries: rawByteSizeInGIB.toFixed(15),
                        });
                    }
                }
            }
            setData(data);
        }
    }, [dictArrayYAxis]);

    function handleload() {
        if (project.name === 'select') {
            alert('Please select a project');
            return;
        }
        if (resource.name === 'select') {
            alert('Please select a resource');
            return;
        }
        if (subType.name === 'select') {
            alert('Please select a sub type');
            return;
        }
        if (logType.name === 'select') {
            alert('Please select a log type');
            return;
        }
        if (metricType.name === 'select') {
            alert('Please select a metric type');
            return;
        }
        if (groupBy.length === 0) {
            alert('Please select at least one way to group by');
            return;
        }
        if (startDate > endDate) {
            alert('Please select a valid time to show data');
            return;
        }
        setLoad(true);
        setOk('load');
        if (!load) {
            let group = '';
            for (var i = 0; i < groupBy.length; i++) {
                for (var j = 0; j < groupByes.length; j++) {
                    if (groupBy[i] === groupByes[j].label) {
                        group += `&groupBy=${groupByes[j].value}`;
                        break;
                    }
                }
            }
            var url =
                `gke-logmetrics?project_id=${
                    project.id
                }&metricType=${'logging.googleapis.com/log_entry_count'}&clusterName=${
                    subType.name
                }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&logType=${
                    logType.value
                }` + group;
            getdata(url, setRawData, setDataLoading);
            url =
                `gke-logmetrics?project_id=${
                    project.id
                }&metricType=${'logging.googleapis.com/byte_count'}&clusterName=${
                    subType.name
                }&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&logType=${
                    logType.value
                }` + group;
            getdata(url, setRawDataByte, setDataLoading);
        }
    }
    useEffect(() => {
        if (load && rawData !== null && rawDataByte !== null) {
            var dataJsonObj;
            if (metricType.name === 'Log Entry Count') {
                dataJsonObj = rawData;
            } else {
                dataJsonObj = rawDataByte;
            }
            var filtertemp = [];
            var dictemp = [];

            for (var i = 0; i < dataJsonObj.length; i++) {
                let seriesName = '';
                let row = dataJsonObj[i];

                for (var j = 0; j < groupBy.length; j++) {
                    if (groupBy[j] === 'Namespace Name') {
                        seriesName +=
                            row['resource']['labels']['namespace_name'] + '-';
                    } else if (groupBy[j] === 'Container Name') {
                        seriesName +=
                            row['resource']['labels']['container_name'] + '-';
                    } else if (groupBy[j] === 'Severity') {
                        seriesName += row['metric']['labels']['severity'] + '-';
                    } else if (groupBy[j] === 'Service Name') {
                        seriesName +=
                            row['metadata']['systemLabels']['service_name'] +
                            '-';
                    }
                }

                if (filteredSeries.length > 0) {
                    if (
                        filteredSeries.includes({
                            value: i + 1,
                            label: seriesName,
                        })
                    ) {
                        let dataArr = [];
                        row.points.forEach(function (point) {
                            dataArr.push({
                                x: new Date(Date.parse(point.interval.endTime)),
                                y: parseInt(point.value.int64Value),
                            });
                        });

                        dictemp.push({
                            name: seriesName,
                            data: dataArr,
                        });
                    }
                } else {
                    let dataArr = [];
                    row.points.forEach(function (point) {
                        dataArr.push({
                            x: new Date(Date.parse(point.interval.endTime)),
                            y: parseInt(point.value.int64Value),
                        });
                    });
                    filtertemp.push({ value: i + 1, label: seriesName });
                    dictemp.push({
                        name: seriesName,
                        data: dataArr,
                    });
                }
            }
            setfilteredSeriesdata(filtertemp);
            setdictArrayYAxisdata(dictemp);
            setOk('true');
        }
    }, [rawData, rawDataByte]);

    useEffect(() => {
        if (load) {
            var dataJsonObj = rawData;
            var logentrySum = 0;
            if (filteredSeries.length > 0) {
                for (var i = 0; i < filteredSeries.length; i++) {
                    for (var j = 0; j < dataJsonObj.length; j++) {
                        let seriesName = '';
                        let row = dataJsonObj[j];
                        for (var k = 0; k < groupBy.length; k++) {
                            if (groupBy[k] === 'Namespace Name') {
                                seriesName +=
                                    row['resource']['labels'][
                                        'namespace_name'
                                    ] + '-';
                            } else if (groupBy[k] === 'Container Name') {
                                seriesName +=
                                    row['resource']['labels'][
                                        'container_name'
                                    ] + '-';
                            } else if (groupBy[k] === 'Severity') {
                                seriesName +=
                                    row['metric']['labels']['severity'] + '-';
                            } else if (groupBy[k] === 'Service Name') {
                                seriesName +=
                                    row['metadata']['systemLabels'][
                                        'service_name'
                                    ] + '-';
                            }
                        }
                        if (filteredSeries[i] === seriesName) {
                            row.points.forEach(function (point) {
                                logentrySum += parseInt(point.value.int64Value);
                            });
                        }
                    }
                }
            } else {
                for (var i = 0; i < filteredSeriesdata.length; i++) {
                    for (var j = 0; j < dataJsonObj.length; j++) {
                        let seriesName = '';
                        let row = dataJsonObj[j];
                        for (var k = 0; k < groupBy.length; k++) {
                            if (groupBy[k] === 'Namespace Name') {
                                seriesName +=
                                    row['resource']['labels'][
                                        'namespace_name'
                                    ] + '-';
                            } else if (groupBy[k] === 'Container Name') {
                                seriesName +=
                                    row['resource']['labels'][
                                        'container_name'
                                    ] + '-';
                            } else if (groupBy[k] === 'Severity') {
                                seriesName +=
                                    row['metric']['labels']['severity'] + '-';
                            } else if (groupBy[k] === 'Service Name') {
                                seriesName +=
                                    row['metadata']['systemLabels'][
                                        'service_name'
                                    ] + '-';
                            }
                        }
                        if (filteredSeriesdata[i].label === seriesName) {
                            row.points.forEach(function (point) {
                                logentrySum += parseInt(point.value.int64Value);
                            });
                        }
                    }
                }
            }
            setrawByteSize(CalculateTotalLogSize());
            setLogEntrySum(logentrySum);
        }
    }, [filteredSeries, filteredSeriesdata]);

    return (
        <>
            {dataLoading ? (
                <div
                    style={{
                        marginLeft: '600px',
                        marginTop: '400px',
                        position: 'absolute',
                        borderBlockColor: 'green',
                    }}
                    className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
                />
            ) : (
                <>
                    <HeaderTitle
                        title={'GCP Log Metrics'}
                        subtitle={'Track log metrics generated in GCP'}
                    />
                    <Grid container rowGap={2} columnSpacing={4}>
                <Grid xs={4}>
                <DD2
                            label={'Project'}
                            emptyLabel={'Select Project Type'}
                            options={projects}
                            value={project}
                            onChange={setProject}
                            displayProperty={'name'}
                            disabled={false}
                            isLoading={projectsLoading}
                        />
                </Grid>
                <Grid xs={4}>
                <DD2
                            label={'Resource Type'}
                            emptyLabel={'Select a Resource Type'}
                            options={resources}
                            value={resource}
                            onChange={setResource}
                            displayProperty={'name'}
                            disabled={!!!project}
                            isLoading={false}
                        />
                </Grid>
                <Grid xs={4}>
                <DD2
                            label={'Sub Type'}
                            emptyLabel={'Select Resource Sub Type'}
                            options={subTypes}
                            value={subType}
                            onChange={setSubType}
                            displayProperty={'name'}
                            disabled={!!!resource}
                            isLoading={subTypeLoading}
                        />
                </Grid>
                <Grid xs={4}>
                <DD2
                            label={'Log Type'}
                            emptyLabel={'Select a Log Type'}
                            options={logTypes}
                            value={logType}
                            onChange={setLogType}
                            displayProperty={'name'}
                            disabled={!!!subType}
                        />
                </Grid>
                <Grid xs={4}>
                <DD2
                            label={'Metric Type'}
                            emptyLabel={'Select a Metric Type'}
                            options={metricTypes}
                            value={metricType}
                            onChange={setMetricType}
                            displayProperty={'name'}
                            disabled={!!!logType}
                        />
                </Grid>
                <Grid xs={4}>
                <DD3
                            label={'Group By'}
                            emptyLabel={'Select ways to group by'}
                            options={groupByes}
                            value={groupBy}
                            onChange={setGroupBy}
                            displayProperty={'label'}
                            disabled={!!!metricType}
                        />
                </Grid>
                <Grid xs={4}>
                <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            onChange={setStartDate}
                        />
                </Grid>
                <Grid xs={4}>
                <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            onChange={setEndDate}
                        />
                </Grid>
                <Grid xs={4}/>
                <Grid xs={4}>
                <PrimaryButton
                                title={'Get Log Metrics'}
                                onClick={handleload}
                                disabled={load}
                            />
                </Grid>
                <Grid xs={4}/>
                <Grid xs={4}/>
                <Grid xs={12}>
                {(() => {
                            switch (ok) {
                                case 'false':
                                    return <></>;
                                case 'load':
                                    return (
                                        <div
                                            style={{
                                                marginLeft: '600px',
                                                marginTop: '400px',
                                                position: 'absolute',
                                                borderBlockColor: 'green',
                                            }}
                                            className="w-16 h-16 border-4 border-dashed rounded-full animate-spin "
                                        />
                                    );
                                case 'true':
                                    return (
                                        <>
                                        <Grid container rowGap={4}>
                                        <Grid xs={12}>
                                            <div
                                                className="grid md:grid-cols-3 md:gap-6"
                                                style={{ zIndex: 3 }}
                                            >
                                                <DD2
                                                    label={'Chart Type'}
                                                    emptyLabel={
                                                        'Select a Chart Type'
                                                    }
                                                    options={chartTypes}
                                                    value={chartType}
                                                    onChange={setChartType}
                                                    displayProperty={'name'}
                                                    disabled={false}
                                                />
                                                <DD3
                                                    label={'Filter By'}
                                                    options={filteredSeriesdata}
                                                    value={filteredSeries}
                                                    onChange={setfilteredSeries}
                                                    displayProperty={'label'}
                                                    disabled={false}
                                                    minWidth={900}
                                                />
                                            </div>
                                            </Grid>
                                            <Grid xs={12}>
                                            <div
                                                className="mt-auto"
                                                style={{ zIndex: 2 }}
                                            >
                                                {(() => {
                                                    switch (chartType.name) {
                                                        case 'line':
                                                            return (
                                                                <Chart
                                                                    options={
                                                                        options
                                                                    }
                                                                    series={
                                                                        dictArrayYAxis
                                                                    }
                                                                    type={
                                                                        chartType.name
                                                                    }
                                                                    height={350}
                                                                />
                                                            );
                                                        case 'area':
                                                            return (
                                                                <Chart
                                                                    options={
                                                                        options
                                                                    }
                                                                    series={
                                                                        dictArrayYAxis
                                                                    }
                                                                    type={
                                                                        chartType.name
                                                                    }
                                                                    height={350}
                                                                />
                                                            );
                                                        case 'table':
                                                            return (
                                                                <Datatable
                                                                    columns={
                                                                        columns
                                                                    }
                                                                    data={data}
                                                                />
                                                            );
                                                    }
                                                })()}
                                            </div>
                                            </Grid>
                                            <Grid xs={12}>
                                            <Description
                                                rawByteSize={rawByteSize}
                                                startDate={startDate}
                                                endDate={endDate}
                                                logEntrySum={logEntrySum}
                                                metricType={metricType}
                                            />
                                            </Grid>
                                            </Grid>
                                        </>
                                    );
                            }
                        })()}
                </Grid>
                </Grid>   
                </>
            )}
        </>
    );
}
