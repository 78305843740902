import ReactApexChart from 'react-apexcharts';
import ClusterDescription from '../table/clusterDescription';
import TeamDescription from '../table/teamDescription';
import VerticalDescription from '../table/verticalDescription';
import UppercaseFirstLetter from './cosmetic_functions';
import DescriptionTable from '../table/descriptionTable';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import { Line } from '@ant-design/plots';
import { useEffect, useState } from 'react';

export default function RowDescription({
    groupByName,
    data,
    mappingData,
    categories,
    groupByValue,
    periodicity,
}) {
    const [config, setConfig] = useState({
        data:[],
        xField: 'time',
        yField: 'value',
        label: {},
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        smooth: true,
        tooltip: {
            showMarkers: false,
        },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    });
    useEffect(() => {
        if (data && categories ) {
            var temp = [];
            for (var i = 0; i < data.length; i++) {
                temp.push({ value: data[i], time: categories[i] });
            }
            setConfig({
                data:temp,
                xField: 'time',
                yField: 'value',
                label: {},
                yAxis: {title:{text:`Cost on ${periodicity} basis in $$`}},
                point: {
                    size: 5,
                    shape: 'diamond',
                    style: {
                        fill: 'white',
                        stroke: '#5B8FF9',
                        lineWidth: 2,
                    },
                },
                tooltip: {
                    showMarkers: false,
                },
                state: {
                    active: {
                        style: {
                            shadowBlur: 4,
                            stroke: '#000',
                            fill: 'red',
                        },
                    },
                },
                interactions: [
                    {
                        type: 'marker-active',
                    },
                ],
            });
        }
    }, [data, categories]);

    return (
        <Grid container rowGap={3} columnSpacing={3}>
            <Grid xs={12}>
                <Line {...config} />
            </Grid>
            <Grid xs={4}/>
            <Grid xs={4}>
                {(() => {
                    switch (groupByName) {
                        case 'cluster':
                            return (
                                <ClusterDescription
                                    groupByValue={groupByValue}
                                    mappingData={mappingData}
                                />
                            );
                        case 'vertical':
                            return (
                                <VerticalDescription
                                    groupByValue={groupByValue}
                                    mappingData={mappingData}
                                />
                            );
                        case 'team':
                            return (
                                <TeamDescription
                                    groupByValue={groupByValue}
                                    mappingData={mappingData}
                                />
                            );
                    }
                })()}
            </Grid>
            <Grid xs={4}/>
        </Grid>
    );
}
