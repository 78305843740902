import MaterialReactTable from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import Svg from '../../SVG/svg';
import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Button, ListItemIcon, MenuItem, Typography } from '@mui/material';
import Graph from './graph';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}
function reverse(datevalue) {
    var datelst = datevalue.split('-');
    return datelst[2] + '-' + datelst[1] + '-' + datelst[0];
}
export default function ProjectTable({
    title,
    handleDescription,
    data,
    spikes,
    filter,
}) {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    //console.log(spikes);
    useEffect(() => {
        var value = [];
        for (var i = 0; i < data.length; i++) {
            var keys = Object.keys(data[i]);
            var cost = [];
            var spike = get(spikes, data[i].service, {
                value: 0,
                spikeDates: {},
            });
            for (var j = 0; j < keys.length; j++) {
                if (keys[j] !== 'service') {
                    var dateVal = reverse(keys[j].toString().split('T')[0]);
                    var dateWiseSpikeValue = get(spike['spikeDates'], dateVal, {
                        posValue: 0,
                        negValue: 0,
                    });
                    if (
                        dateWiseSpikeValue['posValue'] == 0 &&
                        dateWiseSpikeValue['negValue'] == 0
                    ) {
                        cost.push({
                            date: dateVal,
                            cost: data[i][keys[j]],
                            type: 'noSpike',
                            value: 0,
                        });
                        cost.push({
                            date: dateVal,
                            cost: 0,
                            type: 'posSpike',
                            value: dateWiseSpikeValue['posValue'],
                        });
                        cost.push({
                            date: dateVal,
                            cost: 0,
                            type: 'negSpike',
                            value: dateWiseSpikeValue['negValue'],
                        });
                    } else {
                        var posRatio =
                            dateWiseSpikeValue['posValue'] /
                            (dateWiseSpikeValue['posValue'] +
                                dateWiseSpikeValue['negValue']);
                        cost.push({
                            date: dateVal,
                            cost: 0,
                            type: 'noSpike',
                            value: 0,
                        });
                        cost.push({
                            date: dateVal,
                            cost: data[i][keys[j]] * posRatio,
                            type: 'posSpike',
                            value: dateWiseSpikeValue['posValue'],
                        });
                        cost.push({
                            date: dateVal,
                            cost: data[i][keys[j]] * (1 - posRatio),
                            type: 'negSpike',
                            value: dateWiseSpikeValue['negValue'],
                        });
                    }
                }
            }
            value.push({
                service: data[i].service,
                graph: { cost: cost, spike: spike['value'] },
            });
        }
        setTableData(value);
        setLoading(false);
    }, [data]);

    const columns = [
        {
            header: 'Service',
            accessorKey: 'service',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    fontWeight: 'bold',
                    //fontSize: '12px',
                },
            },
            Cell: ({ cell }) => (
                
                    <Button
                        onClick={() => {
                            handleDescription([
                                {
                                    id: 'project',
                                    value: title,
                                },
                                {
                                    id: 'service',
                                    value: cell.getValue(),
                                },
                            ]);
                        }}
                    >
                        <Typography
                            fontFamily={'serif'}
                            variant="overline"
                            style={{
                                marginLeft: '5px',
                                fontWeight: 'bold',
                                color: '#0E86D4',
                                fontSize: '13px',
                            }}
                        >
                            {cell.getValue()}
                        </Typography>
                        {/*<Svg serviceName={cell.getValue()} />*/}
                    </Button>
               
            ),
        },
        {
            header: 'Graph',
            accessorKey: 'graph',
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            sortingFn: 'myCustomSortingFn',
            Cell: ({ cell, row }) => (
                <Graph
                    data={cell.getValue().cost}
                    handleDescription={handleDescription}
                    project={title}
                    service={row.getValue('service')}
                />
            ),
        },
    ];

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);
    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    return (
        <>
            <MaterialReactTable
                className="p-4 w-auto max-h-[300px] mt-auto text-left bg-white rounded-lg border shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700"
                columns={columns}
                data={tableData}
                enableStickyHeader
                muiTableContainerProps={{
                    sx: { minHeight: 330, maxHeight: 330 },
                }}
                filterFns={{
                    customFilterFn: (row, id, filterValue) => {
                        return row.getValue(id).includes(filterValue);
                    },
                }}
                enableDensityToggle={false}
                enablePagination={false}
                state={{
                    isLoading: loading,
                    columnFilters:
                        filter === 'All'
                            ? []
                            : [{ id: 'service', value: filter }],
                }}
                initialState={{
                    density: 'compact',
                    sorting: [
                        {
                            id: 'graph',
                            desc: true,
                        },
                    ],
                    //pagination:{pageIndex:0,pageSize:3}
                }}
                sortingFns={{
                    //will add a new sorting function to the list of other sorting functions already available
                    myCustomSortingFn: (rowA, rowB, columnId) =>
                        rowA.getValue(columnId)['spike'] >
                        rowB.getValue(columnId)['spike']
                            ? 1
                            : -1,
                }}
                positionToolbarAlertBanner="bottom"
                renderTopToolbarCustomActions={() => (
                    <Button
                        onClick={() => {
                            handleDescription([
                                { id: 'project', value: title },
                            ]);
                        }}
                    >
                        <span className="inline-flex">
                            <Svg serviceName={'project'} />
                            <Typography
                                fontFamily={'serif'}
                                variant="h6"
                                style={{
                                    fontWeight: 'bold',
                                    marginLeft: '5px',
                                    color: '#0E86D4',
                                }}
                            >
                                {title}
                            </Typography>
                        </span>
                    </Button>
                )}
            />
        </>
    );
}
