import React from 'react';
import { Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
export default function NumberCard({
    textColor,
    heading,
    noOfEntities,
}) {
    return (
        <Paper
            style={{
                borderRadius: 15,
            }}
            elevation={3}
        >
            <div className="flex flex-inline" style={{justifyContent:'space-between',paddingLeft: 15,paddingRight: 15,paddingTop: 5,paddingBottom: 5}}>
            <Typography
                variant="overline"
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'black',
                }}
            >
                {heading}
            </Typography>
            
                <Typography
                    variant="h6"
                    style={{
                        fontWeight: 'bold',
                        textAlign: 'left',
                        marginLeft: 5,
                        color: textColor,
                    }}
                >
                    {noOfEntities}$
                </Typography>
            </div>
        </Paper>
    );
}
