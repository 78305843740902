import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import AnomalyTable from './table';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextInput from '../TextInput';
import { useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { Button, Typography } from '@mui/material';
import { LinearProgress } from '@mui/material';
import FlexRow from '../layout/FlexRow';
import DatePickerInput from '../DatePickerInput';
import DD2 from '../DD2';
import OrgProjectTable from './orgProjectTable';
import Alert from '@mui/material/Alert';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

export default function GcpOrgIncidents() {
    /*const periodicities = [
        { name: 'Daily', id: 'daily' },
        { name: 'Weekly', id: 'weekly' },
        { name: 'Monthly', id: 'monthly' },
    ];*/
    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);
    const axios = useAxios();
    const [spikeData, setSpikeData] = useState([]);
    const [graphData, setGraphData] = useState([])
    const [spikes, setSpikes] = useState({});
    const [spikeLimit, setSpikeLimit] = useState(0);
    const [loading, setLoading] = useState(false);
    //const [tableLoading, setTableLoading] = use
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [checked, setChecked] = useState(false);
    //const [periodicity, setPeriodicity] = useState(periodicities[0]);
    const [ok, setOk] = useState('false');

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const getSpikeSourceData = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${BACKEND_URL}/gcp/services-spike?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&credit=${checked}&spike=${spikeLimit}`
            );
            //console.log(data);
            setSpikeData(data);
        } catch (e) {
            console.error(e);
        }
    };

    const getServicesSourceData = async () => {
        setOk('true');
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${BACKEND_URL}/gcp/spiked-skus?startDate=${
                    startDate.toISOString().split('T')[0]
                }&endDate=${
                    endDate.toISOString().split('T')[0]
                }&credit=${checked}&periodicity=daily&spike=${spikeLimit}`
            );
            setGraphData(data);
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    //console.log(graphData);
    //console.log(spikeData);

    const getSourceData = async () => {
        if(spikeLimit===''||spikeLimit<0){
            alert('Put some valid spike limit >=0');
            return;
        }
        getSpikeSourceData();
        getServicesSourceData();
    };

    useEffect(()=>{
        if(spikeData.length>0){
            var value={};
            for(var i=0; i<spikeData.length; i++){
                var key = spikeData[i]['projectId']+'/'+spikeData[i]['service']+'/'+spikeData[i]['object']+'/'+spikeData[i]['skuId'];
                if(Object.keys(value).includes(key)===false){
                    value[key]={posSpikeDates:[],negSpikeDates:[],totPosSpike:0};
                }
                if(spikeData[i]['cost']>=spikeData[i]['mean']){
                    value[key]['posSpikeDates'].push(spikeData[i]['date'].split('T')[0])
                    value[key]['totPosSpike']+=spikeData[i]['cost']-spikeData[i]['mean'];
                }
                else{
                    value[key]['negSpikeDates'].push(spikeData[i]['date'].split('T')[0])
                }
            }
            setSpikes(value);
        }
    },[spikeData])

    useEffect(()=>{
        setGraphData([]);
        setSpikeData([]);
        setSpikes({});
        setOk('false');

    },[startDate, endDate, spikeLimit, checked])
    
    
    return (
        <>
            <FlexRow>
                <DatePickerInput
                    label={'Start Date'}
                    value={startDate}
                    onChange={setStartDate}
                    maxDate={endDate}
                />
                <DatePickerInput
                    label={'End Date'}
                    value={endDate}
                    onChange={setEndDate}
                    maxDate={dateObj}
                />
                {/*<DD2
                    label={'Periodicity'}
                    emptyLabel={'Select a periodicity'}
                    options={periodicities}
                    value={periodicity}
                    onChange={setPeriodicity}
                    displayProperty={'name'}
                />*/}
                <TextInput
                    label={`Enter daily minimum spike in $`}
                    emptyLabel={'Enter daily minimum spike in $'}
                    value={spikeLimit}
                    setId={setSpikeLimit}
                    marginTop={'44px'}
                />
                <div style={{ marginTop: '40px' }}>
                    <Box
                        sx={{
                            borderColor: 'black',
                            width: '150px',
                            height: '50px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                        }}
                    >
                        <FormControl
                            style={{ marginTop: '5px' }}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checked}
                                            onChange={handleCheck}
                                            inputProps={{
                                                'aria-label': 'controlled',
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bolder',
                                                color: 'red',
                                            }}
                                        >
                                            Credit
                                        </Typography>
                                    }
                                    labelPlacement="start"
                                />
                            </FormGroup>
                        </FormControl>
                    </Box>
                </div>
                <FlexRow>
                    <div className="mt-[40px]">
                        <Button
                            size={'large'}
                            color={'success'}
                            variant={'contained'}
                            sx={{
                                height: '55px',
                                width: '250px',
                                fontWeight: 'bold',
                            }}
                            onClick={getSourceData}
                            //disabled={buttonDisability}
                        >
                            Get Skus
                        </Button>
                    </div>
                </FlexRow>
                
            </FlexRow>

            <Box
                sx={{
                    marginTop: 5,
                    display: 'flex',
                    flexGrow: 1,
                    flexWrap: 'wrap',
                    flexFlow: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container xs={12} rowSpacing={3} columnSpacing={3}>
                    {(() => {
                        switch (ok) {
                            case 'false':
                                return <></>;
                            case 'true':
                                return (
                                    <>
                                        {loading ? (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    marginTop: '20vh',
                                                }}
                                            >
                                                <LinearProgress color="secondary" />
                                            </Box>
                                        ) : (
                                            <>
                                                <Grid xs={12}>
                                                    <Alert severity="error">
                                                        {`Red and Green bar shows that cost has crossed [mean ± 3std]. Where mean and std deviation are calculated based on past 100 days data for every date`}
                                                    </Alert>
                                                </Grid>
                                                <Grid xs={12} key={0}>
                                                    <div>
                                                        <OrgProjectTable
                                                            data={
                                                                graphData
                                                            }
                                                            spikes={spikes}
                                                            title={'SKU across Organization'}
                                                        />
                                                    </div>
                                                        </Grid>
                                            </>
                                        )}
                                    </>
                                );
                        }
                    })()}
                </Grid>
            </Box>
        </>
    );
}
