export default function Number({value,unit}){
    return (
      
        <span
            className={
                `px-3 py-1 uppercase leading-wide font-bold text-sm rounded-full shadow-sm whitespace-nowrap ${(() => {
                    if(value<=5){
                        return 'bg-blue-100 text-blue-700'
                    }
                    else{
                        return 'bg-lime-200 text-lime-950' 
                    }
                })()}`}
            style={{fontWeight:"bolder",fontSize:'13px'}}
        >
            {value+' '+unit}
        </span>
        
    ); 
}