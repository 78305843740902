import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PIIDownload from './piiDownload';
import TaskManager from './auditPiiDownload';
import OwnerRoute from '../../../components/navigation/OwnerRoute';
import MaliciousUser from './maliciousUser';
import BlacklistUser from './blacklistUser/blacklistuser';
const Audit = () => {
    return (
        <>
            <Routes>
                <Route path="pii-data-download/*" element={<PIIDownload />} />
                <Route path="malicious-user-download/*" element={<MaliciousUser />} />
                <Route path="blacklist-reset-user/*" element={<BlacklistUser />} />
                <Route element={<OwnerRoute />}>
                    <Route path="audit-pii-data-download/*" element={<TaskManager />} /> 
                </Route>
            </Routes>
        </>
    );
};

export default Audit;
