import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import { useAuth } from '../../contexts/auth';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Select,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import DD2InsideLabel from '../DD2InsideLabel';

const HeaderData = ({ tableData, setTableData }) => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const axios = useAxios();
    const { isAdmin, isOwner, isAdminApprover } = useAuth();
    const owner = isOwner();
    const adminApprover = isAdminApprover();
    function call(url, data, method) {
        const requestOptions = {
            url: `${BACKEND_URL}/user/${url}`,
            method: method,
            data: data,
        };
        axios(requestOptions);
    }

    /*useEffect(() => {
        axios({ url: `${BACKEND_URL}/user/all` }).then(function (res) {
            var temp = [];
            var data = res.data;
            for (var i = 0; i < data.length; i++) {
                temp.push(data[i]);
            }
            setTableData(temp);
        });
    }, []);*/

    const handleCreateNewRow = (values) => {
        if (values.description === '') {
            alert('Please provide description');
            return;
        }
        if (values.value === '') {
            alert('Please provide some value');
            return;
        }
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        if (!Object.keys(validationErrors).length) {
            tableData[row.index] = values;
            setTableData([...tableData]);
            exitEditingMode();
        }
    };

    const handleDeleteRow = useCallback(
        (row) => {
            if (!window.confirm(`Are you sure you want to delete this row}`)) {
                return;
            }
            tableData.splice(row.index, 1);
            setTableData([...tableData]);
        },
        [tableData]
    );

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'email'
                            ? validateEmail(event.target.value)
                            : validateRequired(event.target.value);
                    if (!isValid) {
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors]
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'inputType',
                header: 'Input Type',
                size: 140,
                muiTableBodyCellEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    children: ['Header', 'Data'].map((inputType) => (
                        <MenuItem key={inputType} value={inputType}>
                            {inputType}
                        </MenuItem>
                    )),
                },
            },
            {
                accessorKey: 'description',
                header: 'Description',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'value',
                header: 'value',
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ],
        [getCommonEditTextFieldProps]
    );

    return (
        <>
            <MaterialReactTable
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'left',
                        },
                        size: 120,
                    },
                }}
                columns={columns}
                data={tableData}
                editingMode="modal" //default
                enableColumnOrdering
                enableStickyFooter
                enableStickyHeader
                enableEditing
                enableDensityToggle={false}
                initialState={{ density: 'compact' }}
                onEditingRowSave={handleSaveRowEdits}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton
                                disabled={!isAdmin()}
                                onClick={() => table.setEditingRow(row)}
                            >
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteRow(row)}
                                disabled={!isAdmin()}
                            >
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <Button
                        color="primary"
                        onClick={() => setCreateModalOpen(true)}
                        variant="contained"
                    >
                        Add New Parameter
                    </Button>
                )}
            />
            <AddNewParameter
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow}
            />
        </>
    );
};

export const AddNewParameter = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
            acc[column.accessorKey ?? ''] = '';
            return acc;
        }, {})
    );
    const option = ['Header', 'Data'];
    const [inputType, setInputType] = useState(option[0]);
    const handleSubmit = () => {
        onSubmit(values);
        onClose();
    };

    useEffect(() => {
        setValues({
            ...values,
            inputType,
        });
    }, [inputType]);

    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">Add New User</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack
                        sx={{
                            width: '100%',
                            minWidth: { xs: '300px', sm: '360px', md: '400px' },
                            gap: '1.5rem',
                        }}
                    >
                        {(() => {
                            return (
                                <>
                                    <div className="text-left relative z-0">
                                        <div className="mt-4">
                                            <DD2InsideLabel
                                                label={'Input Type'}
                                                option={option}
                                                value={inputType}
                                                setValue={setInputType}
                                            />
                                        </div>
                                    </div>
                                    <TextField
                                        key={columns[1].accessorKey}
                                        label={columns[1].header}
                                        name={columns[1].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                    <TextField
                                        key={columns[2].accessorKey}
                                        label={columns[2].header}
                                        name={columns[2].accessorKey}
                                        onChange={(e) =>
                                            setValues({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            })
                                        }
                                    />
                                </>
                            );
                        })()}
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    color="secondary"
                    onClick={handleSubmit}
                    variant="contained"
                >
                    Add Parameter
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

export default HeaderData;
