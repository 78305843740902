import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import PiiTable from '../../../../components/AuditPii/PIITable';
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../../../components/tabs/tabPanel';
import { useState } from 'react';
import UserIdTokenTable from '../../../../components/AuditPii/UserIdToken';
import MaliciousUserData from '../../../../components/AuditPii/maliciousUserData';
const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
const MaliciousUser = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <HeaderTitle
                title={'Malicious users'}
                subtitle={'Get malicious users data'}
            />
            <Box
                sx={{
                    width: '100%',
                    marginTop: '30px',
                }}
            >
                <MaliciousUserData/>
            </Box>
        </>
    );
};

export default MaliciousUser;
