import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { SECURITY_BACKEND_URL } from '../../configs';
import DD2 from '../../components/DD2';
import FlexRow from '../layout/FlexRow';
import Datatable from '../costing/Datatable';
import { useAxios } from '../../contexts/axios';
import Chart from 'react-apexcharts';
import { Button } from '@mui/material';

function get(object, key, default_value) {
    if (Object.keys(object).includes(key)) {
        return object[key];
    } else {
        return default_value;
    }
}

function getConcatenatedIssues(issues) {
    var concatIssues = '';
    if (issues?.length > 0) {
        for (var i = 0; i < issues.length - 1; i++) {
            concatIssues = concatIssues + issues[i] + ', ';
        }
        concatIssues = concatIssues + issues[issues.length - 1];
    }
    return concatIssues;
}

function makeHref(issue, key) {
    const firstpart =
        'https://apnatime.atlassian.net/jira/software/c/projects/SE/issues/?jql=project%20%3D%20%22SE%22';
    const hrefpriority = {
        'Total Issues': '',
        None: '%20AND%20cf%5B10141%5D%3Dnull',
        'P0(Critical)': '%20AND%20cf%5B10141%5D%3D%22P0%28Critical%29%22',
        'P1(High)': '%20AND%20cf%5B10141%5D%3D%22P1%28High%29%22',
        'P2(Medium)': '%20AND%20cf%5B10141%5D%3D%22P2%28Medium%29%22',
        'P3(Low)': '%20AND%20cf%5B10141%5D%3D%22P3%28Low%29%22',
    };
    //console.log(key, issue);
    if (key === 'Total Issues') {
        return issue === 'All'
            ? firstpart
            : firstpart + '%20AND%20type%20%3D%20%22' + issue + '%22';
    } else {
        return issue === 'All'
            ? firstpart + hrefpriority[key]
            : firstpart +
                  '%20AND%20type%20%3D%20%22' +
                  issue +
                  '%22' +
                  hrefpriority[key];
    }
}

function makeHref1(issue, key, j, rowName) {
    const firstpart =
        'https://apnatime.atlassian.net/jira/software/c/projects/SE/issues/?jql=project%20%3D%20%22SE%22%20AND%20status%20%3D%20%22';
    const secondpart = {
        DOING: 'DOING',
        Done: 'Done',
        'To Do': 'To%20Do',
        Blocked: 'Blocked',
        Review: 'Review',
    };
    const thirdpart = '%22%20AND%20type%20%3D%20%22';
    const fourthpart = '%22%20AND%20cf%5B10141%5D%3D';
    const hrefpriority1 = {
        None: 'null',
        'P0(Critical)': '%27P0%28Critical%29%27',
        'P1(High)': '%27P1%28High%29%27',
        'P2(Medium)': '%27P2%28Medium%29%27',
        'P3(Low)': '%27P3%28Low%29%27',
    };
    const hrefpriority = {
        None: 'null',
        'P0(Critical)': '%22P0%28Critical%29%22',
        'P1(High)': '%22P1%28High%29%22',
        'P2(Medium)': '%22P2%28Medium%29%22',
        'P3(Low)': '%22P3%28Low%29%22',
    };

    if (issue == 'All') {
        var href =
            j === 0
                ? firstpart + secondpart[rowName] + '%22'
                : firstpart +
                  secondpart[rowName] +
                  fourthpart +
                  hrefpriority1[key];
        return href;
    } else {
        var href =
            j === 0
                ? firstpart + secondpart[rowName] + thirdpart + issue + '%22'
                : firstpart +
                  secondpart[rowName] +
                  thirdpart +
                  issue +
                  fourthpart +
                  hrefpriority[key];

        return href;
    }
}

function makeHref2(key, issue) {
    const hreflink1 =
        'https://apnatime.atlassian.net/jira/software/c/projects/SE/issues/?jql=project%20%3D%20%22SE%22%20AND%20status%20IN%20%28%22Blocked%22%2C%22DOING%22%2C%22Review%22%2C%22To%20Do%22%29%20AND%20cf%5B10141%5D%3D';
    const hreflink =
        'https://apnatime.atlassian.net/jira/software/c/projects/SE/issues/?jql=project%20%3D%20%22SE%22%20AND%20status%20IN%20%28%22Blocked%22%2C%22DOING%22%2C%22Review%22%2C%22To%20Do%22%29%20AND%20type%20%3D%20';
    const third = '%20AND%20cf%5B10141%5D%3D';
    const hrefpriority = {
        None: 'null',
        'P0(Critical)': '%27P0%28Critical%29%27',
        'P1(High)': '%27P1%28High%29%27',
        'P2(Medium)': '%27P2%28Medium%29%27',
        'P3(Low)': '%27P3%28Low%29%27',
    };
    const duepart1 = '%20AND%20due%20%3C%3D%20%220%22%20';
    const duepart = '%20AND%20due%20<%3D%20"0"%20';
    if (issue == 'All') {
        var href = hreflink1 + hrefpriority[key] + duepart1;
        return href;
    } else {
        var href = hreflink + issue + third + hrefpriority[key] + duepart;
        return href;
    }
}
export default function Home() {
    const nav = useNavigate();
    const axios = useAxios();
    const [ok, setOk] = useState('true');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [tableData, setTableData] = useState([]);
    const [column, setColumn] = useState([]);
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);
    const tableOption = [
        { name: 'Total security issues', id: 'jira' },
        { name: 'Current status', id: 'jira' },
        { name: 'Issue past due date', id: 'duedate' },
    ];
    const issueTypeOption = [
        { name: 'All', id: 'All' },
        { name: 'Bug', id: 'Bug' },
        { name: 'Epic', id: 'Epic' },
        { name: 'Incident', id: 'Incident' },
        { name: 'Security Engineering', id: 'SecEng' },
        { name: 'Sub Task', id: 'Sub-task' },
        { name: 'Task', id: 'Task' },
    ];
    const [table, setTable] = useState(tableOption[0]);
    const [issueType, setIssueType] = useState(issueTypeOption[0]);

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            url: `${SECURITY_BACKEND_URL}/${table?.id}?type=${issueType?.id}`,
        };
        setLoading(true);
        axios(requestOptions).then(function (res) {
            setData(res.data);
            setLoading(false);
        });
    }, [table, issueType]);

    useEffect(() => {
        if (table.name === 'Total security issues') {
            var columns = [
                {
                    header: 'Total Issues',
                    accessorKey: 'totalIssues',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    Cell: ({ cell }) => (
                        <a
                            style={{ color: 'brown', fontWeight: 'bold' }}
                            href={makeHref(issueType?.id, 'Total Issues')}
                            target="_blank"
                        >
                            {cell.getValue()}
                        </a>
                    ),
                    filterFn: 'customFilterFn',
                },
            ];
            var value = [];
            var total = 0;
            var keys = Object.keys(data);
            setOptions({ labels: keys });
            var plotData = [];
            var dataPoint = {};
            for (var i = 0; i < keys.length; i++) {
                //console.log((firstpart+'%20AND%20type%20%3D%20%22' + issueType?.id==="All"?'':issueType?.id + '%22' + hrefpriority[keys[i]]));
                var innerKeys = Object.keys(data[keys[i]]);
                var val = 0;
                for (var j = 0; j < innerKeys.length; j++) {
                    val += data[keys[i]][innerKeys[j]];
                }
                total += val;
                columns.push({
                    header: keys[i],
                    accessorKey: keys[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    Cell: ({ cell, column }) => (
                        <a
                            style={{ color: 'brown', fontWeight: 'bold' }}
                            href={makeHref(
                                issueType?.id,
                                column.columnDef.header
                            )}
                            target="_blank"
                        >
                            {cell.getValue()}
                        </a>
                    ),
                    filterFn: 'customFilterFn',
                });
                dataPoint[keys[i]] = val;
                plotData.push(val);
            }
            dataPoint['totalIssues'] = total;
            value.push(dataPoint);
            setSeries(plotData);
            setColumn(columns);
            setTableData(value);
        } else if (table.name === 'Current status') {
            setOptions({
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    categories: [
                        'None',
                        'P0(Critical)',
                        'P1(High)',
                        'P2(Medium)',
                        'P3(Low)',
                    ],
                },
            });
            var columns = [
                {
                    header: 'Status',
                    accessorKey: 'status',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    filterFn: 'customFilterFn',
                },
            ];
            var value = [];
            var keys = Object.keys(data);
            var innerKeys = [];
            for (var i = 0; i < keys.length; i++) {
                var inner = Object.keys(data[keys[i]]);
                for (var j = 0; j < inner.length; j++) {
                    innerKeys.push(inner[j]);
                }
            }
            var status = Array.from(new Set(innerKeys));

            for (var i = 0; i < keys.length; i++) {
                columns.push({
                    header: keys[i],
                    accessorKey: keys[i],
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    Cell: ({ cell, column, row }) => (
                        <a
                            style={{ color: 'brown', fontWeight: 'bold' }}
                            href={makeHref1(
                                issueType?.id,
                                column.columnDef.header,
                                column.index,
                                row.getValue('status')
                            )}
                            target="_blank"
                        >
                            {cell.getValue()}
                        </a>
                    ),
                    filterFn: 'customFilterFn',
                });
            }
            setColumn(columns);
            var plotData = [];
            for (var i = 0; i < status.length; i++) {
                var val = {};
                var row = [];
                val['name'] = status[i];
                var dataPoint = { status: status[i] };
                for (var j = 0; j < keys.length; j++) {
                    var point = get(data[keys[j]], status[i], 0);
                    dataPoint[keys[j]] = point;
                    row.push(point);
                }
                val['data'] = row;
                plotData.push(val);
                value.push(dataPoint);
            }
            setSeries(plotData);
            setTableData(value);
        } else {
            setColumn([
                {
                    header: 'Priority',
                    accessorKey: 'priority',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    Cell: ({ cell }) => (
                        <a
                            style={{ color: 'brown', fontWeight: 'bold' }}
                            href={makeHref2(cell.getValue(), issueType?.id)}
                            target="_blank"
                        >
                            {cell.getValue()}
                        </a>
                    ),
                    filterFn: 'customFilterFn',
                },
                {
                    header: 'Count',
                    accessorKey: 'count',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    filterFn: 'customFilterFn',
                },
                {
                    header: 'Issue Keys',
                    accessorKey: 'issueKeys',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                    filterFn: 'customFilterFn',
                },
            ]);

            var value = [];
            var keys = Object.keys(data);
            setOptions({ labels: keys });
            var plotData = [];
            for (var i = 0; i < keys.length; i++) {
                plotData.push(data[keys[i]]['value']);
                value.push({
                    priority: keys[i],
                    count: data[keys[i]]['value'],
                    issueKeys: getConcatenatedIssues(
                        data[keys[i]]['Issue-keys']
                    ),
                });
            }
            setSeries(plotData);
            setTableData(value);
        }
    }, [data]);

    const handleclick = () => {
        const requestOptions = {
            method: 'GET',
            url: `${SECURITY_BACKEND_URL}/refresh`,
        };
        setLoading(true);
        axios(requestOptions).then(function (res) {
            setLoading(false);
            window.location.reload();
        });
    };

    return (
        <>
            <FlexRow>
                <DD2
                    label={'Table Name'}
                    emptyLabel={'Select a Table'}
                    options={tableOption}
                    value={table}
                    onChange={setTable}
                    displayProperty={'name'}
                    disabled={false}
                />
                <DD2
                    label={'Issue Type'}
                    emptyLabel={'Select a Issue Type'}
                    options={issueTypeOption}
                    value={issueType}
                    onChange={setIssueType}
                    displayProperty={'name'}
                    disabled={false}
                />
                <Button style={{marginTop:'40px', width:'300px', height:'50px'}} onClick={handleclick} variant='contained' color='success'>Refresh Data</Button>
            </FlexRow>

            <div>
                {(() => {
                    switch (ok) {
                        case 'false':
                            return <></>;
                        case 'true':
                            return (
                                <>
                                    <Datatable
                                        data={tableData}
                                        columns={column}
                                        loading={loading}
                                    />
                                    <div className="mt-[30px]">
                                        {table?.name === 'Current status' ? (
                                            <Chart
                                                className="text-center"
                                                options={options}
                                                series={series}
                                                type="bar"
                                                height={'350'}
                                                width={'1560'}
                                            />
                                        ) : (
                                            <div className="ml-[500px]">
                                                <Chart
                                                    className="text-center"
                                                    options={options}
                                                    series={series}
                                                    type="pie"
                                                    width={'600'}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </>
                            );
                    }
                })()}
            </div>
        </>
    );
}
