import React from 'react';
import { useState, useEffect } from 'react';
//import Header from "../components/header";
import Grid from '@mui/material/Grid'; // Grid version 1
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import PackageList from '../../components/securityComponent/packageList';
import VulnerabilityList from '../../components/securityComponent/vulnerabilityList';
import BasicCard from '../../components/securityComponent/card';
import HeaderTitle from '../../components/typography/header-title';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Wazuh() {

    return (
        <>
            <HeaderTitle
                title={'Wazuh'}
                subtitle={'Track packages and vulnerabilities in systems'}
            />
            <div className="grid grid-rows-2 gap-1 p-1">
                <div
                    style={{ zIndex: 20 }}
                    className="grid grid-cols-5 gap-2 p-1"
                >
                    <div className="col-span-2">
                        <BasicCard />
                    </div>
                    <div className="col-span-3">
                        <PackageList />
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-2 p-1">
                    <VulnerabilityList />
                </div>
            </div>
        </>
    );
}
