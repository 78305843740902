import React from 'react';
import { useEffect, useState } from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import DD2 from '../../../../components/DD2';
import FlexRow from '../../../../components/layout/FlexRow';
import DatePickerInput from '../../../../components/DatePickerInput';
import PrimaryButton from '../../../../components/button/PrimaryButton';
import { useAxios } from '../../../../contexts/axios';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';

import MongoAtlasEventsTable from '../../../../components/costing/mongo-atlas-events-table';
import {
    useMongoClusters,
    useMongoClusterEvents,
} from '../../../../hooks/costing/useMongo';

import { useSearchParams } from 'react-router-dom';

const MongoAtlasEvents = () => {
    var dateObj = new Date();
    const [searchParams, setSearchParams] = useSearchParams();
    const [clusterEvents, setClusterEvents] = useState([]);
    const [cluster, setCluster] = useState(null);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [loading, setLoading] = useState(false);
    const [ok, setOk] = useState('false');
    const { data: clusters, isLoading: clustersLoading } = useMongoClusters();
    const authAxios = useAxios();

    const isInputValid = () => {
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };
    const getClusterEvents = async () => {
        if (!isInputValid() || loading) {
            return;
        }

        setOk('load');
        setLoading(true);
        try {
            const params = {
                cluster_id: cluster ? cluster['id'] : null,
                startDate: startDate.toISOString().split('T')[0],
                endDate: endDate.toISOString().split('T')[0],
            };
            const { data } = await authAxios.get(`/mongo/events`, { params });
            setClusterEvents(data.data);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
            setOk('true');
        }
    };

    useEffect(() => {
        const cluster_id = searchParams.get('cluster_id');
        if (!!cluster_id) {
            if (clusters) {
                for (const c of clusters) {
                    if (c.id === cluster_id) {
                        setCluster(c);
                    }
                }
            } else {
                setCluster({ id: cluster_id });
            }
        }
    }, [searchParams, clusters, cluster]);

    useEffect(() => {
        setClusterEvents([]);
        setOk('false')
    }, [startDate, endDate, cluster]);
    return (
        <>
            <HeaderTitle
                title={'Mongo DB Events'}
                subtitle={'Track Scaling up and Scaling down of Mongo Clusters'}
            />
            <FlexRow>
                <DD2
                    label={'Mongo Cluster'}
                    emptyLabel={'All Cluster'}
                    options={clusters}
                    value={cluster}
                    onChange={(item) => {
                        setSearchParams({
                            ...searchParams,
                            cluster_id: item.id,
                        });
                    }}
                    displayProperty={'name'}
                    idProperty={'id'}
                    disabled={false}
                    isLoading={clustersLoading}
                ></DD2>
            </FlexRow>
            <FlexRow>
                <DatePickerInput
                    label={'Start Date'}
                    value={startDate}
                    onChange={setStartDate}
                    maxDate={endDate}
                />
                <DatePickerInput
                    label={'End Date'}
                    value={endDate}
                    onChange={setEndDate}
                    maxDate={dateObj}
                />
                <FlexRow></FlexRow>
                <FlexRow></FlexRow>
            </FlexRow>
            <div className="mt-8 mb-8">
                <PrimaryButton
                    title={'$ Get MongoDb Events'}
                    onClick={getClusterEvents}
                />
            </div>

            {(() => {
                switch (ok) {
                    case 'false':
                        return <></>;
                    case 'load':
                        return <LinearProgress />;
                    case 'true':
                        return (
                            <MongoAtlasEventsTable
                                data={clusterEvents}
                                loading={loading}
                            />
                        );
                }
            })()}
        </>
    );
};

export default MongoAtlasEvents;
