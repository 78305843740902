import { React, useState, useEffect } from 'react';
import { useAxios } from '../../contexts/axios';
import { BACKEND_URL } from '../../configs';
import Loading from './Loading';
import TotalDescription from '../table/totalDescription';
import DD2 from '../DD2';
import PrimaryButton from '../button/PrimaryButton';
import DatePickerInput from '../DatePickerInput';
import FlexRow from '../layout/FlexRow';
import Tabs from '@mui/material/Tabs';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import UnitCostingTotalTable from '../table/unitcostingtotaltable';
import Box from '@mui/material/Box';
import TabPanel from '../tabs/tabPanel';

import Datatable from './Datatable';

const a11yProps = ({ index }) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};
export default function TotalCostingTable() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const authAxios = useAxios();

    const tools = [
        { name: 'Mongo', id: 'mongo' },
        { name: 'Elastic', id: 'elastic' },
        { name: 'Bigquery Analysis', id: 'bigquery' },
        { name: 'Bigquery Analysis (Metabase)', id: 'bigquery' },
    ];
    const groupbys = {
        Mongo: [
            { name: 'Cluster', id: 'cluster' },
            { name: 'Vertical', id: 'vertical' },
            { name: 'Team', id: 'team' },
        ],
        Elastic: [
            { name: 'Deployment', id: 'deployment' },
            { name: 'Vertical', id: 'vertical' },
            { name: 'Team', id: 'team' },
        ],
        'Bigquery Analysis': [
            { name: 'User Id', id: 'principal' },
            { name: 'Agent', id: 'agent' },
        ],
        'Bigquery Analysis (Metabase)': [
            { name: 'Dashboard (Cost)', id: 'dashboard' },
            { name: 'Dashboard (Count)', id: 'dashboard' },
            { name: 'Question (Cost)', id: 'question' },
            { name: 'Question (Count)', id: 'question' },
        ],
    };

    var dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - 1);

    const [tool, setTool] = useState(tools[0]);
    const [groupBy, setGroupBy] = useState(null);
    const [endDate, setEndDate] = useState(dateObj);
    const [startDate, setStartDate] = useState(dateObj);
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState(0);

    const handleCheck = (event) => {
        setChecked(event.target.checked);
    };

    const isInputValid = () => {
        if (!!!tool) {
            alert('Please select a tool');
            return false;
        }
        if (!!!groupBy) {
            alert('Please select a way to group by');
            return false;
        }
        if (startDate > endDate) {
            alert('Please enter valid start and end date');
            return;
        }
        return true;
    };

    const getUnitCostingData = async () => {
        if (!isInputValid() || loading) {
            return;
        }

        setLoading(true);
        try {
            const { data } = await authAxios.get(
                `${BACKEND_URL}/unitcosting/?toolName=${tool.id}&groupBy=${
                    tool.name === 'Bigquery Analysis (Metabase)'
                        ? groupBy.name === 'Dashboard (Cost)'
                            ? 'dashboardCost'
                            : groupBy.name === 'Dashboard (Count)'
                            ? 'dashboardCount'
                            : groupBy.name === 'Question (Cost)'
                            ? 'questionCost'
                            : 'questionCount'
                        : groupBy.id
                }&startDate=${startDate.toISOString().split('T')[0]}&endDate=${
                    endDate.toISOString().split('T')[0]
                }&periodicity=${'none'}
                }&view=total&credit=${checked}`
            );
            var finalList = [];
            if (data?.length > 0) {
                if (groupBy.name.includes('Question')) {
                    for (var i = 0; i < data.length; i++) {
                        finalList.push(data[i]);
                        finalList[i]['question'] =
                            finalList[i]['question'].split(
                                '__#Question#__:'
                            )[1];
                    }
                    setData(finalList);
                } else {
                    setData(data);
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };
    // use effect to update the group by options when the tool changes.
    useEffect(() => {
        setGroupBy(groupbys[tool.name][0]);
    }, [tool]);

    useEffect(() => {
        setData(null);
        setValue(0);
    }, [startDate, endDate, groupBy, checked]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            {!!loading ? (
                <Loading />
            ) : (
                <>
                    <FlexRow>
                        <DD2
                            label={'Tool Name'}
                            emptyLabel={'Select a Tool Type'}
                            options={tools}
                            value={tool}
                            onChange={setTool}
                            displayProperty={'name'}
                            disabled={false}
                        />
                        <DD2
                            label={'Group By'}
                            emptyLabel={'Select a way to Group By'}
                            options={groupbys[tool.name]}
                            value={groupBy}
                            onChange={setGroupBy}
                            displayProperty={'name'}
                            disabled={!!!tool}
                        />
                        <FlexRow />
                        <FlexRow />
                    </FlexRow>
                    <FlexRow>
                        <DatePickerInput
                            label={'Start Date'}
                            value={startDate}
                            onChange={setStartDate}
                            maxDate={endDate}
                        />
                        <DatePickerInput
                            label={'End Date'}
                            value={endDate}
                            onChange={setEndDate}
                            maxDate={dateObj}
                        />
                        <FlexRow></FlexRow>
                        <FlexRow></FlexRow>
                    </FlexRow>
                    <div className="mt-8">
                        <PrimaryButton
                            title={'$ Get Total Costing Data'}
                            onClick={getUnitCostingData}
                        />
                    </div>
                    {!!data ? (
                        <Box sx={{ width: '100%', marginTop: '30px' }}>
                            <Box
                                sx={{ borderBottom: 1, borderColor: 'divider' }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                >
                                    <Tab label="TABLE" {...a11yProps(0)} />
                                    <Tab label="GRAPH" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <UnitCostingTotalTable
                                    groupByName={groupBy?.name}
                                    data={data}
                                    tool={tool.name}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="mt-8 w-full">
                                    <TotalDescription
                                        data={data}
                                        groupBy={groupBy?.id}
                                        groupByName={groupBy?.name}
                                        tool={tool?.name}
                                        startDate={
                                            startDate
                                                .toISOString()
                                                .split('T')[0]
                                        }
                                        endDate={
                                            endDate.toISOString().split('T')[0]
                                        }
                                    />
                                </div>
                            </TabPanel>
                        </Box>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
}
