import React from 'react';
import HeaderTitle from '../../../../components/typography/header-title';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SentryForm from '../../../../components/platform/createSentryForm';
import SentryTable from '../../../../components/platform/sentryTable';
import Grid from '@mui/material/Unstable_Grid2';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Sentry = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <HeaderTitle
                title={'Sentry'}
                subtitle={'Create dedicated In-House Sentry for your service'}
            />
            <Box
                sx={{
                    flexGrow: 1,
                    //bgcolor: 'background.paper',
                    display: 'flex',
                    height: 800,
                }}
            >
                <Tabs
                    orientation="vertical"
                    //variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider'}}
                >
                    <Tab label="create sentry" {...a11yProps(0)} />
                    <Tab label="manager approval" {...a11yProps(1)} />
                    <Tab label="information" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <SentryForm />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SentryTable />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
            </Box>
        </>
    );
};

export default Sentry;
