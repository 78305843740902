import React from 'react';
import BackButton from '../../../../components/navigation/back-button';
import HeaderTitle from '../../../../components/typography/header-title';
import LoadvsGcpCost from '../../../../components/costing/load-vs-gcp-cost';

export default function Loadgcpcost() {
    return (
        <>
            <HeaderTitle
                title={'Load vs GCP Cost'}
                subtitle={'GCP cost vs different loads'}
            />
            <LoadvsGcpCost />
        </>
    );
}
